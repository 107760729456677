@use "vars"as *;
@use "sass:map";

// レスポンシブ対応用 メディアクエリ
$breakpoints: ('s': '420px',
  'tb': '767px',
  'm': '1024px',
  'l': $width + ($width-m * 2), // 1440px
  'xl': '1920px'
) !default;

@mixin media ($breakpoint: tb) {
  @media only screen and (min-width: #{ map-get( $breakpoints, $breakpoint ) }) {
    @content;
  }
}

// コンパイル後のメディアクエリの記載順が前後しないよう、事前に記載しておく

@include media(s) {
  /* small-size */
}

@include media(tb) {
  /* tablet-size */
}

@include media(m) {
  /* middle-size */
}

@include media(l) {
  /* large-size */
}

@include media(xl) {
  /* XL-size */
}