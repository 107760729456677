@charset "UTF-8";
/*-------------------------------

	Reset : common.cssと衝突を避けるため、新たに追加

-------------------------------*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/*-------------------------------

	base : bodyタグ等のベース設定

-------------------------------*/
@media only screen and (min-width: 420px) {
  /* small-size */
}
@media only screen and (min-width: 767px) {
  /* tablet-size */
}
@media only screen and (min-width: 1024px) {
  /* middle-size */
}
@media only screen and (min-width: 1440px) {
  /* large-size */
}
@media only screen and (min-width: 1920px) {
  /* XL-size */
}
/* transition */
/* hover */
/* box-shadow */
/* text-aligh */
/* button arrow-icon */
/* line-height */
/* border-radius */
/* フォントサイズ */
/* absolute一括指定 */
/*
 * base
 */
html {
  font-size: 62.5%;
  scroll-behavior: smooth;
  scroll-padding-top: 8rem;
}

body {
  font-family: "Noto Sans JP", "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  color: #2D313D;
  font-size: 1.4rem;
  font-weight: 500;
}
@media only screen and (min-width: 767px) {
  body {
    font-size: 1.6rem;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

a {
  transition: all 0.3s linear;
}

a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
}

*:focus {
  outline: none;
}

p,
ul li,
ol li {
  text-align: justify;
  text-justify: inter-ideograph;
  word-break: break-all;
}

b {
  font-weight: bold;
}

img {
  vertical-align: top;
}

.lang-en p,
.lang-en ul li,
.lang-en ol li {
  text-align: left;
  word-break: normal;
}

main {
  hyphens: auto;
}

/*-------------------------------

	layout : レイアウトの設定

-------------------------------*/
/*
 * ヘッダーを考慮したページ上部の余白
 */
.l-about-top {
  margin-top: 4.8rem;
}
@media only screen and (min-width: 767px) {
  .l-about-top {
    margin-top: 5rem;
  }
}
@media only screen and (min-width: 1024px) {
  .l-about-top {
    /* middle-size */
    margin-top: 8rem;
  }
}

.l-about-normal {
  margin-top: 8.8rem;
}
@media only screen and (min-width: 767px) {
  .l-about-normal {
    margin-top: 12rem;
  }
}

/*
 * wrapper
 */
.l-base {
  margin-left: 20px;
  margin-right: 20px;
}
@media only screen and (min-width: 1440px) {
  .l-base {
    margin-left: calc((100% - 1400px) / 2);
    margin-right: calc((100% - 1400px) / 2);
  }
}

.l-base2 {
  margin-left: 20px;
  margin-right: 20px;
}
@media only screen and (min-width: 1024px) {
  .l-base2 {
    margin-left: calc((100% - 1215px) / 2);
    margin-right: calc((100% - 1215px) / 2);
  }
}

@media only screen and (min-width: 1440px) {
  .l-full {
    margin-left: calc((100% - 1440px) / 2);
    margin-right: calc((100% - 1440px) / 2);
  }
}

.l-wide {
  margin-left: 20px;
  margin-right: 20px;
}
@media only screen and (min-width: 767px) {
  .l-wide {
    margin-left: auto;
    margin-right: auto;
    width: min(100% - 40px, 1040px);
  }
}

.l-middle {
  margin-left: 20px;
  margin-right: 20px;
}
@media only screen and (min-width: 767px) {
  .l-middle {
    margin-left: auto;
    margin-right: auto;
    width: min(100% - 40px, 960px);
  }
}

.l-short-middle {
  margin-left: 20px;
  margin-right: 20px;
}
@media only screen and (min-width: 767px) {
  .l-short-middle {
    margin-left: auto;
    margin-right: auto;
    width: min(100% - 40px, 880px);
  }
}

.l-short {
  margin-left: 20px;
  margin-right: 20px;
}
@media only screen and (min-width: 767px) {
  .l-short {
    margin-left: auto;
    margin-right: auto;
    width: min(100% - 40px, 760px);
  }
}

.l-x-short {
  margin-left: auto;
  margin-right: auto;
  width: min(100% - 40px, 680px);
}

/*-------------------------------

	module : 各パーツの設定

-------------------------------*/
/*
 * 共通パーツ。頭に[c-]を付けることで区別を付ける。
 */
.c-txt {
  line-height: 1.71;
}
@media only screen and (min-width: 767px) {
  .c-txt {
    line-height: 1.87;
  }
}

.c-h1 {
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.33;
  text-align: center;
  margin-bottom: 1.5rem;
}
@media only screen and (min-width: 767px) {
  .c-h1 {
    font-size: 3.7rem;
    margin-bottom: 2rem;
  }
}
@media only screen and (min-width: 1024px) {
  .c-h1 {
    font-size: 5.4rem;
  }
}

.c-h2 {
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.43;
  text-align: center;
  margin-bottom: 1rem;
}
@media only screen and (min-width: 767px) {
  .c-h2 {
    font-size: 3.1rem;
  }
}
@media only screen and (min-width: 767px) {
  .c-h2 {
    margin-bottom: 2.5rem;
  }
}

.c-h3 {
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.5;
  text-align: center;
  margin-bottom: 2.5rem;
}
@media only screen and (min-width: 767px) {
  .c-h3 {
    font-size: 3.6rem;
  }
}
@media only screen and (min-width: 767px) {
  .c-h3 {
    margin-bottom: 5rem;
  }
}

.c-h4 {
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1.5;
}
@media only screen and (min-width: 767px) {
  .c-h4 {
    font-size: 2rem;
  }
}

.c-h5 {
  line-height: 1.71;
  font-weight: bold;
  text-align: center;
}
@media only screen and (min-width: 767px) {
  .c-h5 {
    line-height: 1.87;
  }
}

.c-page__ttl01 > span {
  font-size: 2rem;
  font-weight: 600;
}
@media only screen and (min-width: 767px) {
  .c-page__ttl01 > span {
    font-size: 2.3rem;
  }
}
.c-page__ttl01 > em {
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: 0.05em;
  display: block;
  font-size: 3.3rem;
}
@media only screen and (min-width: 767px) {
  .c-page__ttl01 > em {
    /* tablet-size */
    font-size: 4.4rem;
    letter-spacing: 0.08em;
  }
}
@media only screen and (min-width: 1024px) {
  .c-page__ttl01 > em {
    /* middle-size */
    font-size: 5.5rem;
  }
}

.c-bg-glay {
  background-color: #F4F4F6;
}

.c-we-bold {
  font-weight: 700;
}

.c-cl-blue {
  color: #0172E0;
}

.c-cl-pink {
  color: #E33F81;
}

.c-cl-purple {
  color: #5F2B9A;
}

.c-fs-20 {
  font-size: 2rem;
}

.c-icon {
  display: inline-block;
  width: 4rem;
  height: 2.4rem;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media only screen and (min-width: 767px) {
  .c-icon {
    width: 5.1rem;
    height: 3rem;
  }
}
.c-icon--small {
  width: 3.4rem;
  height: 2rem;
}
@media only screen and (min-width: 767px) {
  .c-icon--small {
    width: 3.4rem;
    height: 2rem;
  }
}
.c-icon__med {
  background-image: url("../images/common/icon-med.png");
}
.c-icon__sst {
  background-image: url("../images/common/icon-sst.png");
}
.c-icon__job {
  background-image: url("../images/common/icon-job.png");
}
.c-icon__relax {
  background-image: url("../images/common/icon-relax.png");
}
.c-icon__care {
  background-image: url("../images/common/icon-care.png");
}

.c-card__ttl-sub {
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 1.33;
  display: inline-block;
  margin-bottom: 0.5rem;
}
@media only screen and (min-width: 767px) {
  .c-card__ttl-sub {
    font-size: 1.4rem;
  }
}
@media only screen and (min-width: 767px) {
  .c-card__ttl-sub {
    margin-bottom: 1rem;
  }
}
.c-card__ttl-sub::after {
  content: "";
  display: block;
  height: 1px;
  background-color: #151826;
  margin-top: 0.2rem;
}
.c-card__ttl {
  font-size: 1.3rem;
  font-weight: bold;
  line-height: 1.5;
  margin-bottom: 1rem;
}
@media only screen and (min-width: 767px) {
  .c-card__ttl {
    font-size: 2rem;
  }
}
@media only screen and (min-width: 767px) {
  .c-card__ttl {
    margin-bottom: 1.5rem;
  }
}
.c-card__txt {
  font-size: 1.2rem;
}
@media only screen and (min-width: 767px) {
  .c-card__txt {
    font-size: 1.5rem;
  }
}
.c-card__link {
  margin-top: 1rem;
}
@media only screen and (min-width: 767px) {
  .c-card__link {
    margin-top: 2rem;
  }
}

.card-2col {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1rem;
}
@media only screen and (min-width: 767px) {
  .card-2col {
    margin: 0 -2rem;
  }
}
.card-2col__item {
  width: calc(50% - 2rem);
  margin: 0 1rem 3rem;
}
@media only screen and (min-width: 767px) {
  .card-2col__item {
    max-width: 32rem;
    margin: 0 2rem 3rem;
  }
}
.card-2col__fig {
  position: relative;
  margin-bottom: 1.3rem;
  height: 80px;
  overflow: hidden;
}
@media only screen and (min-width: 420px) {
  .card-2col__fig {
    height: 180px;
  }
}
@media only screen and (min-width: 767px) {
  .card-2col__fig {
    margin-bottom: 2rem;
  }
}
.card-2col__fig img {
  display: block;
  width: 100%;
}
.card-2col__tag-txt, .card-2col__tag-icon {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  display: inline-block;
}
@media only screen and (min-width: 767px) {
  .card-2col__tag-txt, .card-2col__tag-icon {
    top: 1rem;
    left: 1rem;
  }
}
.card-2col__tag-txt {
  font-size: 1rem;
  color: #fff;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-radius: 1.4rem;
}
@media only screen and (min-width: 767px) {
  .card-2col__tag-txt {
    font-size: 1.4rem;
  }
}
@media only screen and (min-width: 767px) {
  .card-2col__tag-txt {
    padding: 0.7rem 1.5rem;
  }
}
.card-2col__tag-txt.lightblue {
  background-color: #00A2FA;
}
.card-2col__tag-txt.darkblue {
  background-color: #365DFF;
}
.card-2col__tag-txt.red {
  background-color: #D64789;
}

.card-1col {
  margin: 0 2rem;
}
@media only screen and (min-width: 767px) {
  .card-1col {
    display: flex;
    justify-content: center;
    margin: 0 -2rem;
  }
}
@media only screen and (min-width: 767px) {
  .card-1col__item {
    flex: 1;
    max-width: 32rem;
    margin: 0 2rem;
  }
}
.card-1col__item + .card-1col__item {
  margin-top: 3rem;
}
@media only screen and (min-width: 767px) {
  .card-1col__item + .card-1col__item {
    margin-top: 0;
  }
}
.card-1col__thumb {
  display: block;
  width: 18.4rem;
  margin: 0 auto 1.5rem;
}
@media only screen and (min-width: 767px) {
  .card-1col__thumb {
    width: calc(100% - 4rem);
    margin-bottom: 2rem;
  }
}
.card-1col__ttl {
  font-size: 1.8rem;
}

.c-link {
  font-size: 1.2rem;
  font-weight: bold;
  color: #00A2FA;
  display: inline-block;
}
@media only screen and (min-width: 767px) {
  .c-link {
    font-size: 1.4rem;
  }
}
.c-link:hover {
  opacity: 0.7;
}
.c-link::after {
  content: "〉";
  display: inline-block;
  margin-left: 5px;
}

.c-gradient-darkblue {
  color: #fff;
  background: linear-gradient(45deg, #1F2536, #3F4D7B);
}

.c-gradient-lightblue {
  color: #fff;
  background: linear-gradient(45deg, #001B8E, #01B9B9);
}

.c-gradient-blue {
  color: #fff;
  background: linear-gradient(45deg, #1F79FA, #00A2F4);
}

.c-gradient-blue2 {
  color: #fff;
  background: linear-gradient(267.97deg, #5979FF 1.99%, #22377D 100%);
}

.c-gradient-violet {
  color: #fff;
  background: linear-gradient(45deg, #430067, #4A7AFF);
}

.c-gradient-violet2 {
  color: #fff;
  background: linear-gradient(45deg, #6D58BF, #AD4F9C);
}

.c-btn {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24rem;
  height: 6rem;
  margin: 0 1rem;
  text-align: center;
  font-weight: bold;
  line-height: 1.42;
  color: #fff;
  border-radius: 3rem;
  background-image: url("../images/common/btn-gradation-blue.png");
  background-size: auto 100%;
  background-position: center center;
}
.c-btn:hover {
  filter: brightness(120%);
}
.c-btn::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 2rem;
  margin-top: -0.6rem;
  width: 3rem;
  height: 1.2rem;
  background-image: url("../images/common/arrow-link.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media only screen and (min-width: 767px) {
  .c-btn {
    width: 32rem;
    background-size: 100% auto;
  }
}
.c-btn + .c-btn {
  margin-top: 1rem;
}
@media only screen and (min-width: 767px) {
  .c-btn + .c-btn {
    margin-top: 0;
  }
}

.c-btn-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;
}
@media only screen and (min-width: 767px) {
  .c-btn-list {
    flex-direction: row;
    justify-content: center;
    margin-top: 8rem;
  }
}

.c-btn-menu {
  display: block;
  width: 4.8rem;
  height: 4.8rem;
  padding: 0;
  margin: 0 -1.5rem 0 auto;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: initial;
  border: none;
  cursor: pointer;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}
.c-btn-menu--open {
  background-image: url("../images/common/menu.png");
}
.c-btn-menu--close {
  background-image: url("../images/common/menu-close.png");
  margin-right: -0.7rem;
}

.c-btn02 {
  position: relative;
  border-radius: 100px;
}
.c-btn02:hover {
  filter: brightness(120%);
}
.c-btn02::before, .c-btn02::after {
  display: block;
  position: absolute;
}
.c-btn02-gradient-purple {
  background: linear-gradient(271.74deg, #5D2899 2.97%, #A678DB 96.4%);
  width: 100%;
}
.c-btn02-gradient-purple::before {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 6.5rem;
  width: 0.1rem;
  height: 100%;
  margin: auto;
  background-color: #fff;
}
.c-btn02-gradient-purple::after {
  font-family: "Font Awesome 6 Free";
  display: inline-block;
  font-weight: 900;
  content: "\f054";
  color: #F6768B;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 2.6rem;
  margin: auto;
}
.c-followbtn, .c-followbtn-pink, .c-followbtn-purple {
  z-index: 2;
  position: fixed;
  box-shadow: 0px 3px 13px rgba(123, 123, 123, 0.25);
  justify-content: center;
  font-weight: 700;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #fff;
  letter-spacing: 0.03em;
  border-radius: 3px;
  font-size: 1.1rem;
  padding: 1rem;
  transition: inherit;
  left: 5%;
  bottom: 5%;
}
@media only screen and (min-width: 767px) {
  .c-followbtn, .c-followbtn-pink, .c-followbtn-purple {
    /* tablet-size */
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .c-followbtn:hover, .c-followbtn-pink:hover, .c-followbtn-purple:hover {
    filter: brightness(120%);
  }
}
@media only screen and (min-width: 1024px) {
  .c-followbtn, .c-followbtn-pink, .c-followbtn-purple {
    /* middle-size */
    padding: 1.5rem 1rem;
    writing-mode: vertical-rl;
    font-size: 1.4rem;
    right: 0;
    left: inherit;
  }
}
.c-followbtn-pink {
  background: linear-gradient(0deg, #EA3D82 0%, #F8858D 100%);
}
.c-followbtn-purple {
  background: linear-gradient(0deg, #5F2B9B 0%, #A678DC 100%);
}
.c-followbtn__icon {
  width: 1.3rem;
  height: 1.3rem;
  margin-left: 1rem;
}
@media only screen and (min-width: 767px) {
  .c-followbtn__icon {
    /* tablet-size */
  }
}
@media only screen and (min-width: 1024px) {
  .c-followbtn__icon {
    /* middle-size */
    width: 1.5rem;
    height: 1.5rem;
    margin-top: 1rem;
    margin-left: 0;
  }
}

.c-dr {
  border-radius: 4rem;
  background-color: #fff;
  box-shadow: 4px 4px 16px rgba(145, 144, 144, 0.16);
  position: relative;
  padding: 2rem 2rem 2.5rem;
}
@media only screen and (min-width: 767px) {
  .c-dr {
    border-radius: 10rem;
  }
}
@media only screen and (min-width: 767px) {
  .c-dr {
    padding: 4.7rem 6rem 4rem 12rem;
    margin-left: 2rem;
  }
}
@media only screen and (min-width: 1024px) {
  .c-dr {
    margin-left: 0;
  }
}
.c-dr + .c-dr {
  margin-top: 4rem;
}
@media only screen and (min-width: 767px) {
  .c-dr + .c-dr {
    margin-top: 2rem;
  }
}
.c-dr__ttl {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0 0 1.5rem 9.5rem;
}
@media only screen and (min-width: 767px) {
  .c-dr__ttl {
    margin: 0 0 1.5rem;
  }
}
.c-dr__ttl em {
  display: inline-block;
  font-size: 1.6rem;
  margin-top: 1rem;
}
@media only screen and (min-width: 767px) {
  .c-dr__ttl em {
    margin: 0 0 0 1rem;
  }
}
.c-dr__thumb {
  position: absolute;
  top: -1.6rem;
  left: 2rem;
  width: 8rem;
}
@media only screen and (min-width: 767px) {
  .c-dr__thumb {
    top: 50%;
    left: -2rem;
    transform: translateY(-50%);
    width: 12rem;
  }
}
.c-dr__txt {
  font-size: 1.4rem;
  line-height: 1.71;
}

.c-pt-pb {
  padding: 3.5rem 0 8rem;
}
@media only screen and (min-width: 767px) {
  .c-pt-pb {
    padding: 11rem 0 12rem;
  }
}

/*
 * その他のパーツ
 */
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(151, 151, 151, 0.25);
}
@media only screen and (min-width: 767px) {
  .header {
    /* tablet-size */
    box-shadow: none;
  }
}
.header__logo {
  width: 12rem;
  display: block;
}
.header__logo:hover {
  opacity: 0.7;
}
@media only screen and (min-width: 767px) {
  .header__logo {
    width: 20rem;
  }
}
@media only screen and (min-width: 1024px) {
  .header__logo {
    /* middle-size */
    margin-left: 20px;
  }
}
.header__logo img {
  width: 100%;
}
.header__logo2 {
  width: 12rem;
  display: block;
}
@media only screen and (min-width: 767px) {
  .header__logo2 {
    width: 20rem;
    margin-left: 20px;
  }
}
.header__logo2 img {
  width: 100%;
}
.header__logo3 {
  width: 17rem;
  display: block;
}
.header__logo3:hover {
  opacity: 0.7;
}
@media only screen and (min-width: 767px) {
  .header__logo3 {
    width: 30rem;
    margin-left: 10px;
  }
}
.header__logo3 img {
  width: 100%;
}
.header__inner {
  height: 4.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media only screen and (min-width: 767px) {
  .header__inner {
    height: 8rem;
  }
}
.header #js-header-nav {
  display: none;
}
@media only screen and (min-width: 1024px) {
  .header #js-header-nav {
    display: flex;
    align-items: center;
  }
}
@media only screen and (min-width: 1024px) {
  .header .js-sp-menu {
    display: none;
  }
}
.header__nav-list {
  font-size: 0;
  font-weight: bold;
}
@media only screen and (min-width: 1024px) {
  .header__nav-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
}
.header__nav-list li {
  margin: 3rem 0.5rem 3rem 0;
  text-align: left;
  position: relative;
  font-size: 1.4rem;
}
@media only screen and (min-width: 1024px) {
  .header__nav-list li {
    display: inline-block;
    margin: 0 1rem 0 0;
    line-height: 1.5;
    font-size: 1.2rem;
  }
}
@media only screen and (min-width: 1024px) {
  .header__nav-list li {
    margin-right: 3rem;
    font-size: 1.4rem;
  }
}
.header__nav-list li > a {
  color: #000;
}
.header__nav-list li > a:hover {
  opacity: 0.7;
}
.header__nav-list li.js-sp-nav-toggle {
  position: relative;
}
.header__nav-list li.js-sp-nav-toggle::after {
  content: "";
  position: absolute;
  top: 5px;
  right: -15px;
  height: 5px;
  width: 5px;
  display: inline-block;
  border-left: 2px solid #9ea0a5;
  border-bottom: 2px solid #9ea0a5;
  transform: rotate(-45deg);
  margin-left: 8px;
  transition: transform 0.5s;
  vertical-align: middle;
}
.header__nav-list li.js-sp-nav-toggle span {
  cursor: pointer;
  transition: all 0.3s linear;
  opacity: 1;
}
.header__nav-list li.js-sp-nav-toggle span:hover {
  opacity: 0.7;
}
.header__nav-list li.js-sp-nav-toggle.active::after {
  transform: rotate(-225deg);
}
.header__nav-list li .subnav-list {
  display: none;
  margin-top: 15px;
  font-size: 16px;
  background-color: #fff;
}
@media only screen and (min-width: 767px) {
  .header__nav-list li .subnav-list {
    display: none;
    position: absolute;
    bottom: 0px;
    left: 50%;
    padding: 52px 32px 22px;
    transform: translateY(100%) translateX(-50%);
  }
}
.header__nav-list li .subnav-list.active {
  display: block;
}
.header__nav-list li .subnav-list__item {
  white-space: nowrap;
}
.header__nav-list li .subnav-list__item + .subnav-list__item {
  margin-top: 16px;
}
@media only screen and (min-width: 767px) {
  .header__nav-list li .dropdown-menu, .header__nav-list li .dropdown-menu-make {
    transition: all 0.3s linear;
    position: relative;
    cursor: pointer;
    display: block;
  }
}
@media only screen and (min-width: 767px) {
  .header__nav-list li .dropdown-menu::before, .header__nav-list li .dropdown-menu-make::before {
    display: block;
    z-index: 1;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 3rem;
    transform: translate(0%, 100%);
    content: "";
  }
}
@media only screen and (min-width: 767px) {
  .header__nav-list li .dropdown-menu:hover, .header__nav-list li .dropdown-menu-make:hover {
    color: #828282;
  }
}
@media only screen and (min-width: 767px) {
  .header__nav-list li .dropdown-menu:hover > a, .header__nav-list li .dropdown-menu-make:hover > a {
    opacity: 1;
    pointer-events: inherit;
  }
}
@media only screen and (min-width: 767px) {
  .header__nav-list li .dropdown-menu:hover > a div p:hover, .header__nav-list li .dropdown-menu-make:hover > a div p:hover {
    opacity: 0.7;
  }
}
.header__nav-list li .dropdown-menu > span, .header__nav-list li .dropdown-menu-make > span {
  display: block;
  padding: 1rem 1.5rem;
  color: #fff;
  line-height: 1.5;
}
@media only screen and (min-width: 1024px) {
  .header__nav-list li .dropdown-menu > span, .header__nav-list li .dropdown-menu-make > span {
    color: #000;
    padding: 0;
  }
}
.header__nav-list li .dropdown-menu > a, .header__nav-list li .dropdown-menu-make > a {
  transform: translate(-0.9rem, 0px);
  background-color: #3F3F3F;
  width: calc(100% + 1.8rem);
  margin-top: 1rem;
  display: flex;
}
@media only screen and (min-width: 1024px) {
  .header__nav-list li .dropdown-menu > a, .header__nav-list li .dropdown-menu-make > a {
    transition: all 0.3s linear;
    filter: drop-shadow(0px 4px 10px rgba(62, 62, 62, 0.25));
    opacity: 0;
    pointer-events: none;
    display: flex;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 1.5;
    background-color: #fff;
    padding: 2rem;
    color: #2D313D;
    border: 0.2rem solid #D8D8D8;
    border-radius: 1rem;
    width: 27rem;
    transform: translate(-10%, 25%);
    box-sizing: border-box;
  }
}
@media only screen and (min-width: 1024px) {
  .header__nav-list li .dropdown-menu > a::before, .header__nav-list li .dropdown-menu-make > a::before, .header__nav-list li .dropdown-menu > a::after, .header__nav-list li .dropdown-menu-make > a::after {
    transition: all 0.3s linear;
    position: absolute;
    left: 50%;
    right: 50%;
    content: "";
  }
}
@media only screen and (min-width: 1024px) {
  .header__nav-list li .dropdown-menu > a::before, .header__nav-list li .dropdown-menu-make > a::before {
    z-index: 2;
    top: -22px;
    margin-left: -14px;
    border: 10px solid transparent;
    border-bottom: 12px solid #FFF;
  }
}
.header__nav-list li .dropdown-menu > a::after, .header__nav-list li .dropdown-menu-make > a::after {
  display: inline-block;
  display: flex;
  right: 2.6rem;
  -ms-flex-align: center;
  align-items: center;
  content: "\f054";
  font-weight: 900;
  font-family: "Font Awesome 6 Free";
}
@media only screen and (min-width: 1024px) {
  .header__nav-list li .dropdown-menu > a::after, .header__nav-list li .dropdown-menu-make > a::after {
    z-index: 1;
    top: -28px;
    margin-left: -17px;
    border: 13px solid transparent;
    border-bottom: 14px solid #D8D8D8;
  }
}
@media only screen and (min-width: 1024px) {
  .header__nav-list li .dropdown-menu > a div, .header__nav-list li .dropdown-menu-make > a div {
    display: flex;
  }
}
@media only screen and (min-width: 1024px) {
  .header__nav-list li .dropdown-menu > a div::after, .header__nav-list li .dropdown-menu-make > a div::after {
    font-family: "Font Awesome 6 Free";
    display: inline-block;
    font-weight: 900;
    content: "\f054";
    transition: all 0.3s linear;
    margin-left: 2rem;
    display: flex;
    align-items: center;
    color: #d8d8d8;
  }
}
.header__nav-list li .dropdown-menu > a div p, .header__nav-list li .dropdown-menu-make > a div p {
  font-size: 1rem;
  text-align: left;
  padding-left: 2rem;
  padding-right: 2rem;
}
@media only screen and (min-width: 1024px) {
  .header__nav-list li .dropdown-menu > a div p, .header__nav-list li .dropdown-menu-make > a div p {
    transition: all 0.3s linear;
    padding-left: 0;
    padding-right: 2rem;
    border-right: 1px solid #d8d8d8;
    font-size: 1.4rem;
  }
}
.header__nav-list li .dropdown-menu > a div p .c-fs-20, .header__nav-list li .dropdown-menu-make > a div p .c-fs-20 {
  font-size: 1rem;
}
@media only screen and (min-width: 1024px) {
  .header__nav-list li .dropdown-menu > a div p .c-fs-20, .header__nav-list li .dropdown-menu-make > a div p .c-fs-20 {
    font-size: 2rem;
  }
}
.header__nav-list li .dropdown-menu > a div img, .header__nav-list li .dropdown-menu-make > a div img {
  width: 100%;
  margin-top: 0.5rem;
}
@media only screen and (min-width: 1024px) {
  .header__nav-list li .dropdown-menu > a div img, .header__nav-list li .dropdown-menu-make > a div img {
    width: auto;
    display: inline;
    height: 1.5rem;
    vertical-align: baseline;
  }
}
.header__nav-list li .dropdown-menu .c-cl-purple, .header__nav-list li .dropdown-menu-make .c-cl-purple {
  color: #fff;
}
@media only screen and (min-width: 1024px) {
  .header__nav-list li .dropdown-menu .c-cl-purple, .header__nav-list li .dropdown-menu-make .c-cl-purple {
    color: #5F2B9A;
  }
}
@media only screen and (min-width: 1024px) {
  .header__nav-list li .dropdown-menu-make > a {
    transform: translate(-32%, 25%);
  }
}
@media only screen and (min-width: 1440px) {
  .header__nav-list li .dropdown-menu-make > a {
    transform: translate(-27%, 25%);
  }
}
@media only screen and (min-width: 767px) {
  .header__nav-list li .dropdown-menu-make .c-cl-blue {
    font-weight: 400;
  }
}
.header__btn-list {
  font-size: 0;
  font-weight: bold;
}
@media only screen and (min-width: 1024px) {
  .header__btn-list {
    display: flex;
  }
}
.header__btn-list li {
  font-size: 1.6rem;
}
@media only screen and (min-width: 767px) {
  .header__btn-list li {
    font-size: 1.2rem;
  }
}
@media only screen and (min-width: 1024px) {
  .header__btn-list li {
    display: inline-block;
  }
}
.header__btn-list li + li {
  margin-top: 1rem;
}
@media only screen and (min-width: 1024px) {
  .header__btn-list li + li {
    margin: 0 0 0 1rem;
  }
}
.header__btn-list li a {
  display: block;
  width: 100%;
  padding: 1.6rem;
  border-radius: 2.4rem;
  text-align: center;
}
.header__btn-list li a:hover {
  opacity: 0.7;
}
@media only screen and (min-width: 1024px) {
  .header__btn-list li a {
    width: 10rem;
    padding: 0.9rem 0;
    border-radius: 1.5rem;
  }
}

.header-nav-sp {
  transition: all 0.3s linear;
  position: fixed;
  right: -23rem;
  top: 0;
  bottom: 0;
  z-index: 11;
  width: 22rem;
  padding: 0 0.9rem;
  background-color: #2E2E2E;
  box-shadow: -4px 0px 4px rgba(145, 144, 144, 0.16);
}
.header-nav-sp.open {
  right: 0;
}
.header-nav-sp .header__nav-list {
  margin-top: 7rem;
}
.header-nav-sp .header__nav-list li {
  margin: 1.5rem 0 0 0;
}
.header-nav-sp .header__nav-list li:nth-of-type(1) {
  margin-top: 0;
}
.header-nav-sp .header__nav-list li a {
  color: #fff;
  padding: 1rem 1.5rem;
  display: flex;
  line-height: 1.5;
}
.header-nav-sp .header__nav-list li a.current {
  background: #5B5B5B;
}
.header-nav-sp .header__btn-list {
  margin-top: 2rem;
}
.header-nav-sp__sensor {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
}
.header-nav-sp__sensor.open {
  display: block;
}

.sub-header {
  font-weight: bold;
}
@media only screen and (min-width: 767px) {
  .sub-header {
    display: flex;
    align-items: center;
    margin-left: 2rem;
    padding-bottom: 2rem;
  }
}
.sub-header__ttl {
  display: flex;
  align-items: center;
  font-size: 0;
  padding: 1rem 0;
  cursor: pointer;
}
@media only screen and (min-width: 767px) {
  .sub-header__ttl {
    padding: 0;
    margin-right: 4rem;
  }
  .sub-header__ttl:hover {
    opacity: 0.7;
  }
}
.sub-header__ttl::after {
  transition: all 0.3s linear;
  content: "";
  display: inline-block;
  width: 0.8rem;
  height: 0.8rem;
  border-bottom: 2px solid #000;
  border-right: 2px solid #000;
  border-color: inherit;
  margin: -0.3rem 0 0 1rem;
  transform: rotate(45deg);
}
.open.sub-header__ttl::after {
  transform: rotate(-135deg);
  margin-top: 0.3rem;
}

@media only screen and (min-width: 767px) {
  .sub-header__ttl::after {
    display: none;
  }
}
.sub-header__ttl.lightblue {
  color: #00A2FA;
}
.sub-header__ttl.darkblue {
  color: #365DFF;
}
.sub-header__ttl.disable {
  cursor: none;
  pointer-events: none;
}
.sub-header__ttl.disable::after {
  display: none;
}
.sub-header__txt {
  font-size: 1.4rem;
  margin-left: 1rem;
}
@media only screen and (min-width: 767px) {
  .sub-header__txt {
    font-size: 1.8rem;
  }
}
.sub-header__list {
  display: none;
  font-size: 0;
  padding: 1.2rem 0 2rem 4.4rem;
}
@media only screen and (min-width: 767px) {
  .sub-header__list {
    display: block;
    padding: 0;
  }
}
@media only screen and (min-width: 767px) {
  .sub-header__list li {
    display: inline-block;
  }
}
.sub-header__list li + li {
  margin-top: 2rem;
}
@media only screen and (min-width: 767px) {
  .sub-header__list li + li {
    margin-top: 0;
  }
}
.sub-header__list a {
  font-size: 1.2rem;
  display: inline-block;
  margin-right: 2rem;
  color: inherit;
}
@media only screen and (min-width: 767px) {
  .sub-header__list a {
    font-size: 1.4rem;
  }
}
.sub-header__list a:hover {
  opacity: 0.7;
}
@media only screen and (min-width: 1024px) {
  .sub-header__list a {
    margin-right: 3rem;
  }
}

.about-mv {
  position: relative;
}
.about-mv__box {
  position: relative;
  left: -20px;
  z-index: 1;
  display: inline-block;
  width: max(100% - 8.6rem, 29rem);
  background-color: #151826;
  padding: 1rem 0 5.5rem 20px;
  margin-top: -3rem;
  font-weight: bold;
  color: #fff;
}
@media only screen and (min-width: 767px) {
  .about-mv__box {
    position: absolute;
    top: 20px;
    bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: auto;
    min-width: 46rem;
    padding: 0 0 0 4rem;
    margin-top: 0;
  }
}
.about-mv__box__nurse {
  padding: 3rem 0 4rem 20px;
  width: max(100%, 29rem);
}
.about-mv__sttl__nurse {
  font-size: 1.6rem;
}
@media only screen and (min-width: 767px) {
  .about-mv__sttl__nurse {
    font-size: 1.9rem;
  }
}
.about-mv__ttl {
  font-size: 2rem;
  line-height: 1.41;
}
@media only screen and (min-width: 767px) {
  .about-mv__ttl {
    font-size: 3.4rem;
  }
}
@media only screen and (min-width: 767px) {
  .about-mv__ttl {
    margin-bottom: 3rem;
  }
}
.about-mv__ttl__nurse {
  font-size: 2.8rem;
  margin-top: 1rem;
  line-height: 1.2;
}
@media only screen and (min-width: 767px) {
  .about-mv__ttl__nurse {
    font-size: 4.3rem;
  }
}
.about-mv__ttl-top {
  font-size: 2rem;
  line-height: 1.3;
  margin: 0.3rem 0 0.7rem;
}
@media only screen and (min-width: 767px) {
  .about-mv__ttl-top {
    font-size: 4.8rem;
  }
}
@media only screen and (min-width: 767px) {
  .about-mv__ttl-top {
    line-height: 1.1;
    margin: 0 0 1rem;
  }
}
.about-mv__ttl-btm {
  font-size: 1.2rem;
  letter-spacing: 0.05em;
  line-height: 1.33;
  margin-bottom: 0.5rem;
}
@media only screen and (min-width: 767px) {
  .about-mv__ttl-btm {
    font-size: 1.8rem;
  }
}
@media only screen and (min-width: 767px) {
  .about-mv__ttl-btm {
    margin-bottom: 3.5rem;
  }
}
.about-mv__txt__nurse {
  font-size: 1.2rem;
  margin-top: 5rem;
}
@media only screen and (min-width: 767px) {
  .about-mv__txt__nurse {
    font-size: 1.3rem;
  }
}
@media only screen and (min-width: 767px) {
  .about-mv__txt__nurse {
    margin-top: 6em;
    margin-bottom: 1em;
  }
}
.about-mv__btn {
  font-size: 1.2rem;
  position: relative;
  display: block;
  width: 50%;
  text-align: center;
  font-weight: bold;
  padding: 2.4rem 1rem;
}
@media only screen and (min-width: 767px) {
  .about-mv__btn {
    font-size: 1.6rem;
  }
}
.about-mv__btn:hover {
  filter: brightness(120%);
}
.about-mv__btn::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 2rem;
  margin-top: -0.6rem;
  width: 3rem;
  height: 1.2rem;
  background-image: url("../images/common/arrow-link.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media only screen and (min-width: 767px) {
  .about-mv__btn {
    text-align: left;
    width: 36rem;
    padding: 3.1rem 4rem;
  }
}
@media only screen and (min-width: 767px) {
  .about-mv__btn + .about-mv__btn {
    margin-top: 1rem;
  }
}
.about-mv__btn::after {
  display: none;
}
@media only screen and (min-width: 767px) {
  .about-mv__btn::after {
    display: block;
  }
}
.about-mv__list-btn-sp {
  position: relative;
  z-index: 2;
  display: flex;
  margin-top: -4.5rem;
}
@media only screen and (min-width: 767px) {
  .about-mv__list-btn-sp {
    display: none;
  }
}
.about-mv__list-btn-sp__nurse {
  justify-content: flex-end;
  margin-top: -2.5rem;
}
.about-mv__slide {
  height: 20rem;
  overflow: hidden;
}
@media only screen and (min-width: 767px) {
  .about-mv__slide {
    height: 55.5rem;
    margin: 0;
  }
}
@media only screen and (min-width: 767px) {
  .about-mv__slide--not-top {
    height: 55.5rem;
  }
}
.about-mv__slide li {
  height: 100%;
}
.about-mv__slide img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.about-mv__txtbox, .about-mv__txtbox-make {
  position: absolute;
  top: 0;
  right: 2.5rem;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
}
@media only screen and (min-width: 1024px) {
  .about-mv__txtbox, .about-mv__txtbox-make {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    right: 11.3rem;
  }
}
.about-mv__txtbox p, .about-mv__txtbox-make p {
  color: #fff;
  font-weight: 500;
  font-size: 1.9rem;
  line-height: 1.6;
  letter-spacing: 0.08em;
}
@media only screen and (min-width: 767px) {
  .about-mv__txtbox p, .about-mv__txtbox-make p {
    font-size: 3.6rem;
    line-height: 1.45;
  }
}
.about-mv__txtbox .cv__btnbox, .about-mv__txtbox .cv__btnbox-make, .about-mv__txtbox-make .cv__btnbox, .about-mv__txtbox-make .cv__btnbox-make {
  display: none;
}
@media only screen and (min-width: 1024px) {
  .about-mv__txtbox .cv__btnbox, .about-mv__txtbox .cv__btnbox-make, .about-mv__txtbox-make .cv__btnbox, .about-mv__txtbox-make .cv__btnbox-make {
    display: block;
    width: 41.7rem;
  }
}
.about-mv__txtbox .cv__btnbox-make, .about-mv__txtbox-make .cv__btnbox-make {
  display: none;
}
@media only screen and (min-width: 1024px) {
  .about-mv__txtbox .cv__btnbox-make, .about-mv__txtbox-make .cv__btnbox-make {
    display: block;
    width: 37.2rem;
    left: 9rem;
  }
}
.about-mv__txtbox-make {
  left: 2.5rem;
  right: 0;
}
@media only screen and (min-width: 1024px) {
  .about-mv__txtbox-make {
    left: 8rem;
  }
}
.about-mv__note {
  font-size: 1rem;
  margin-top: 1rem;
}
@media only screen and (min-width: 767px) {
  .about-mv__note {
    font-size: 1.1rem;
  }
}
@media only screen and (min-width: 767px) {
  .about-mv__note {
    margin: 1.5rem 0 -2.5rem;
  }
}

.about-mv-small {
  background: #F4F4F6;
}
@media only screen and (min-width: 767px) {
  .about-mv-small {
    background: linear-gradient(to top, #F4F4F6 4rem, #fff 4rem);
  }
}
.about-mv-small__inner {
  position: relative;
  margin: 0 -20px;
}
@media only screen and (min-width: 767px) {
  .about-mv-small__inner {
    margin: 0;
  }
}
.about-mv-small img {
  height: 20rem;
  width: 100%;
  object-fit: cover;
}
@media only screen and (min-width: 767px) {
  .about-mv-small img {
    height: 36rem;
  }
}
.about-mv-small__box {
  position: relative;
  z-index: 9;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.2rem 2rem 1.5rem 2rem;
  margin-top: -3rem;
  font-weight: bold;
  color: #fff;
  background-image: url("../images/mainview/box-bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media only screen and (min-width: 767px) {
  .about-mv-small__box {
    position: absolute;
    left: -20px;
    top: 6rem;
    bottom: 6rem;
    width: 42rem;
    padding: 0 0 0 6rem;
    margin: 0;
  }
}
.about-mv-small__ttl-top {
  font-size: 2rem;
  line-height: 1.33;
}
@media only screen and (min-width: 767px) {
  .about-mv-small__ttl-top {
    font-size: 3.6rem;
  }
}
.about-mv-small__ttl-btm {
  font-size: 1.2rem;
  line-height: 1.5;
  margin-top: 0.5rem;
}
@media only screen and (min-width: 767px) {
  .about-mv-small__ttl-btm {
    font-size: 1.6rem;
  }
}
@media only screen and (min-width: 767px) {
  .about-mv-small__ttl-btm {
    margin-top: 1.5rem;
    letter-spacing: 0.05em;
  }
}

.cv__btnbox, .cv__btnbox-make {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 3.5rem 1.5rem 0;
}
@media only screen and (min-width: 767px) {
  .cv__btnbox, .cv__btnbox-make {
    flex-direction: row;
  }
}
@media only screen and (min-width: 1024px) {
  .cv__btnbox, .cv__btnbox-make {
    margin: 4.5rem 0 0;
    display: none;
  }
}
.cv__btn, .cv__btn__make, .cv__btn__contact-jolly, .cv__btn__contact-make {
  color: #fff;
  font-weight: 700;
  display: block;
  padding: 1rem 0;
  line-height: 1.3;
  color: #fff;
}
.cv__btn:hover, .cv__btn__make:hover, .cv__btn__contact-jolly:hover, .cv__btn__contact-make:hover {
  filter: brightness(120%);
}
@media only screen and (min-width: 767px) {
  .cv__btn, .cv__btn__make, .cv__btn__contact-jolly, .cv__btn__contact-make {
    /* tablet-size */
  }
}
@media only screen and (min-width: 1024px) {
  .cv__btn, .cv__btn__make, .cv__btn__contact-jolly, .cv__btn__contact-make {
    /* middle-size */
  }
}
.cv__btn__make, .cv__btn__contact-jolly, .cv__btn__contact-make {
  transition: all 0.3s linear;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  padding: 1.7rem 2rem;
  position: relative;
  font-size: 1.2rem;
}
@media only screen and (min-width: 767px) {
  .cv__btn__make, .cv__btn__contact-jolly, .cv__btn__contact-make {
    /* tablet-size */
  }
}
@media only screen and (min-width: 1024px) {
  .cv__btn__make, .cv__btn__contact-jolly, .cv__btn__contact-make {
    /* middle-size */
    align-items: flex-start;
    position: relative;
    padding: 2.05rem 2rem;
  }
}
.cv__btn__make::before, .cv__btn__make::after, .cv__btn__contact-jolly::before, .cv__btn__contact-jolly::after, .cv__btn__contact-make::before, .cv__btn__contact-make::after {
  transition: all 0.3s linear;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}
.cv__btn__make::before, .cv__btn__contact-jolly::before, .cv__btn__contact-make::before {
  transition: all 0.3s linear;
  display: block;
  right: 4.5rem;
  width: 0.1rem;
  height: calc(100% - 2.2rem);
  content: "";
  background-color: #fff;
}
@media only screen and (min-width: 1024px) {
  .cv__btn__make::before, .cv__btn__contact-jolly::before, .cv__btn__contact-make::before {
    /* tablet-size */
    height: calc(100% - 3rem);
  }
}
.cv__btn__make::after, .cv__btn__contact-jolly::after, .cv__btn__contact-make::after {
  transition: all 0.3s linear;
  font-family: "Font Awesome 6 Free";
  display: inline-block;
  font-weight: 900;
  content: "\f054";
  right: 2rem;
  display: flex;
  align-items: center;
}
.cv__btn__make:hover:hover, .cv__btn__contact-jolly:hover:hover, .cv__btn__contact-make:hover:hover {
  filter: brightness(120%);
}
.cv__btn__make div, .cv__btn__contact-jolly div, .cv__btn__contact-make div {
  transition: all 0.3s linear;
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-align: left;
}
@media only screen and (min-width: 767px) {
  .cv__btn__make div, .cv__btn__contact-jolly div, .cv__btn__contact-make div {
    /* tablet-size */
    font-size: 1.4rem;
  }
}
@media only screen and (min-width: 1024px) {
  .cv__btn__make div, .cv__btn__contact-jolly div, .cv__btn__contact-make div {
    /* middle-size */
  }
}
.cv__btn__make div span, .cv__btn__contact-jolly div span, .cv__btn__contact-make div span {
  transition: all 0.3s linear;
  font-size: 1.1rem;
}
@media only screen and (min-width: 767px) {
  .cv__btn__make div span, .cv__btn__contact-jolly div span, .cv__btn__contact-make div span {
    /* tablet-size */
  }
}
@media only screen and (min-width: 1024px) {
  .cv__btn__make div span, .cv__btn__contact-jolly div span, .cv__btn__contact-make div span {
    /* middle-size */
    font-size: 1.5rem;
  }
}
.cv__btn__make {
  transition: all 0.3s linear;
  border-radius: 10rem;
  width: 100%;
  background-image: linear-gradient(0deg, #5C2798 0%, #A577DB 87.22%);
  margin-top: 1rem;
}
@media only screen and (min-width: 767px) {
  .cv__btn__make {
    /* tablet-size */
    margin-top: 0;
    width: calc(50% - 1rem);
  }
}
@media only screen and (min-width: 1024px) {
  .cv__btn__make {
    /* middle-size */
    margin-top: 2rem;
    width: 100%;
  }
}
.cv__btn__make.-en {
  padding: 1.7rem 5rem 1.7rem 2rem;
}
@media only screen and (min-width: 767px) {
  .cv__btn__make::after {
    /* tablet-size */
  }
}
@media only screen and (min-width: 1024px) {
  .cv__btn__make::after {
    /* middle-size */
  }
}
@media only screen and (min-width: 1440px) {
  .cv__btn__make::after {
    /* middle-size */
  }
}
.cv__btn__contact-jolly {
  transition: all 0.3s linear;
  width: 100%;
  background: linear-gradient(0deg, #EA3D82 0%, #F8858D 100%);
  border-radius: 10rem;
}
@media only screen and (min-width: 767px) {
  .cv__btn__contact-jolly {
    /* middle-size */
    width: calc(50% - 1rem);
  }
}
@media only screen and (min-width: 1024px) {
  .cv__btn__contact-jolly {
    /* middle-size */
    width: 100%;
  }
}
@media only screen and (min-width: 1024px) {
  .cv__btn__contact-jolly::after {
    /* middle-size */
  }
}
.cv__btn__contact-make {
  transition: all 0.3s linear;
  border-radius: 10rem;
  width: 100%;
  background-image: linear-gradient(0deg, #5C2798 0%, #A577DB 87.22%);
  margin-top: 0;
}
@media only screen and (min-width: 767px) {
  .cv__btn__contact-make {
    /* tablet-size */
    width: calc(50% - 1rem);
    margin: auto;
  }
}
@media only screen and (min-width: 1024px) {
  .cv__btn__contact-make {
    /* middle-size */
    width: 100%;
  }
}
@media only screen and (min-width: 767px) {
  .cv__btn__contact-make::after {
    /* tablet-size */
  }
}
@media only screen and (min-width: 1024px) {
  .cv__btn__contact-make::after {
    /* middle-size */
  }
}
@media only screen and (min-width: 1440px) {
  .cv__btn__contact-make::after {
    /* middle-size */
  }
}

.about-top-intro {
  background: linear-gradient(transparent 94%, #F4F4F6 94%);
}
.about-top-intro__inner {
  position: relative;
  margin-top: 6rem;
}
@media only screen and (min-width: 767px) {
  .about-top-intro__inner {
    margin-top: 7rem;
  }
}
.about-top-intro__logo {
  display: block;
  width: 20rem;
  margin: 0 auto 2.5rem;
}
@media only screen and (min-width: 767px) {
  .about-top-intro__logo {
    position: absolute;
    top: 36%;
    right: 60%;
    width: 30.5%;
  }
}
.about-top-intro__box {
  text-align: center;
  font-weight: bold;
}
@media only screen and (min-width: 767px) {
  .about-top-intro__box {
    text-align: left;
    margin-left: 50%;
  }
}
.about-top-intro__h1 {
  font-size: 2rem;
  line-height: 1.3;
  margin-bottom: 2.5rem;
}
@media only screen and (min-width: 767px) {
  .about-top-intro__h1 {
    font-size: 2.4rem;
  }
}
@media only screen and (min-width: 767px) {
  .about-top-intro__h1 {
    line-height: 1.5;
  }
}
.about-top-intro__txt {
  margin-bottom: 3rem;
}
.about-top-intro__thumb {
  display: block;
  width: min(100%, 950px);
  margin: 4rem auto 0;
}
.about-top-intro__thumb img {
  width: 100%;
}
.about-top-intro__link {
  font-size: 1.4rem;
}

.about-task {
  padding-top: 8rem;
  background: #F4F4F6;
}
@media only screen and (min-width: 767px) {
  .about-task {
    padding-top: 12rem;
  }
}
.about-task__box {
  border-radius: 4rem;
  background-color: #fff;
  box-shadow: 4px 4px 16px rgba(145, 144, 144, 0.16);
  position: relative;
  padding-top: 3.5rem;
}
@media only screen and (min-width: 767px) {
  .about-task__box {
    border-radius: 10rem;
  }
}
@media only screen and (min-width: 767px) {
  .about-task__box {
    padding-top: 7rem;
  }
}
.about-task__inner {
  width: min(100% - 40px, 88rem);
  margin: 0 auto;
  padding: 2.5rem 2rem 4rem;
}
@media only screen and (min-width: 767px) {
  .about-task__inner {
    padding: 2.5rem 0 10rem;
  }
}
.about-task__ill-line {
  position: absolute;
  top: -3rem;
  right: -1rem;
  width: 7.5rem;
}
@media only screen and (min-width: 767px) {
  .about-task__ill-line {
    top: -4rem;
    right: 2rem;
    width: 15rem;
  }
}
@media only screen and (min-width: 1024px) {
  .about-task__ill-line {
    top: -2rem;
  }
}
.about-task__fig {
  width: min(100%, 28.8rem);
  margin: 0 auto;
}
@media only screen and (min-width: 767px) {
  .about-task__fig {
    width: 44.2%;
  }
}
.about-task__fig img {
  width: 100%;
}
.about-task__box-txt {
  margin-bottom: 2.5rem;
}
@media only screen and (min-width: 767px) {
  .about-task__box-txt {
    width: 40rem;
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 767px) {
  .about-task__item {
    display: flex;
    align-items: center;
  }
}
.about-task__item + .about-task__item {
  margin-top: 3rem;
}
@media only screen and (min-width: 767px) {
  .about-task__item + .about-task__item {
    margin-top: 6rem;
  }
}
@media only screen and (min-width: 767px) {
  .about-task__item:nth-of-type(even) {
    justify-content: flex-end;
  }
}
@media only screen and (min-width: 767px) {
  .about-task__item:nth-of-type(even) .about-task__fig {
    margin: 0 0 0 2rem;
  }
}
@media only screen and (min-width: 767px) {
  .about-task__item:nth-of-type(odd) .about-task__fig {
    order: 1;
    margin: 0 2rem 0 0;
  }
}
@media only screen and (min-width: 767px) {
  .about-task__item:nth-of-type(odd) .about-task__box-txt {
    order: 2;
  }
}
.about-task__tag {
  font-size: 1rem;
  display: inline-block;
  letter-spacing: 0.2em;
  color: #fff;
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;
  border-radius: 3rem;
  background-color: #2D313D;
}
@media only screen and (min-width: 767px) {
  .about-task__tag {
    font-size: 1.4rem;
  }
}
@media only screen and (min-width: 767px) {
  .about-task__tag {
    padding: 0.8rem 1.9rem;
    margin-bottom: 1rem;
  }
}
.about-task__h2 {
  margin-bottom: 0.5rem;
}
@media only screen and (min-width: 767px) {
  .about-task__h2 {
    margin-bottom: 1.2rem;
    white-space: nowrap;
  }
}

.about-alt {
  padding-top: 4rem;
  background: linear-gradient(to top, #fff 4rem, #F4F4F6 4rem);
}
@media only screen and (min-width: 767px) {
  .about-alt {
    padding-top: 12rem;
  }
}
.about-alt--white {
  padding: 0 0 8rem;
  background: linear-gradient(#F4F4F6 4rem, #fff 4rem);
}
@media only screen and (min-width: 767px) {
  .about-alt--white {
    padding-top: 0;
  }
}
.about-alt__box {
  border-radius: 4rem;
  background-color: #fff;
  box-shadow: 4px 4px 16px rgba(145, 144, 144, 0.16);
  position: relative;
  z-index: 1;
  padding-top: 3.5rem;
}
@media only screen and (min-width: 767px) {
  .about-alt__box {
    border-radius: 10rem;
  }
}
@media only screen and (min-width: 767px) {
  .about-alt__box {
    padding-top: 7rem;
  }
}
.about-alt__inner {
  width: min(100% - 40px, 80rem);
  margin: 0 auto;
  padding: 1.5rem 0 3.5rem;
}
@media only screen and (min-width: 767px) {
  .about-alt__inner {
    padding: 3rem 0 8rem;
  }
}
.about-alt__thumb {
  display: block;
  width: 18rem;
  margin: 0 auto 2rem;
}
@media only screen and (min-width: 767px) {
  .about-alt__thumb {
    width: 35%;
  }
}
@media only screen and (min-width: 767px) {
  .about-alt__box-txt {
    width: 40rem;
  }
}
@media only screen and (min-width: 767px) {
  .about-alt__item {
    display: flex;
    align-items: center;
  }
}
.about-alt__item + .about-alt__item {
  margin-top: 3rem;
}
@media only screen and (min-width: 767px) {
  .about-alt__item + .about-alt__item {
    margin-top: 2rem;
  }
}
@media only screen and (min-width: 767px) {
  .about-alt__item:nth-of-type(even) {
    justify-content: flex-end;
  }
}
@media only screen and (min-width: 767px) {
  .about-alt__item:nth-of-type(even) .about-alt__thumb {
    order: 2;
    margin: 0 0 0 4rem;
  }
}
@media only screen and (min-width: 767px) {
  .about-alt__item:nth-of-type(even) .about-alt__box-txt {
    order: 1;
  }
}
@media only screen and (min-width: 767px) {
  .about-alt__item:nth-of-type(odd) .about-alt__thumb {
    margin: 0 4rem 0 0;
  }
}
.about-alt__h2 {
  margin-bottom: 0.5rem;
}
@media only screen and (min-width: 767px) {
  .about-alt__h2 {
    margin-bottom: 1rem;
    white-space: nowrap;
  }
}

.about-subv {
  margin: -4rem 0 4rem;
}
@media only screen and (min-width: 767px) {
  .about-subv {
    margin-bottom: 0;
  }
}
.about-subv img {
  width: 100%;
}

.about-3plus {
  padding: 3.5rem 0 8rem;
}
@media only screen and (min-width: 767px) {
  .about-3plus {
    padding: 7.5rem 0 12rem;
  }
}
@media only screen and (min-width: 767px) {
  .about-3plus--top {
    padding-top: 11rem;
  }
}
.about-3plus__h2 {
  font-size: 1.4rem;
  margin-bottom: 1.5rem;
}
@media only screen and (min-width: 767px) {
  .about-3plus__h2 {
    font-size: 2rem;
  }
}
@media only screen and (min-width: 767px) {
  .about-3plus__h2 {
    margin-bottom: 7.5rem;
  }
}
.about-3plus__list {
  margin-top: 2rem;
}
@media only screen and (min-width: 767px) {
  .about-3plus__list {
    padding-left: 4rem;
    margin-top: -4rem;
  }
}
@media screen and (min-width: 1140px) {
  .about-3plus__list {
    padding-left: 0;
  }
}
.about-3plus__item {
  border-radius: 4rem;
  background-color: #fff;
  box-shadow: 4px 4px 16px rgba(145, 144, 144, 0.16);
  padding: 2rem 2rem 3rem;
}
@media only screen and (min-width: 767px) {
  .about-3plus__item {
    border-radius: 10rem;
  }
}
@media only screen and (min-width: 767px) {
  .about-3plus__item {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    padding: 4rem 8rem;
  }
}
@media only screen and (min-width: 767px) {
  .about-3plus__item::before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -4rem;
    width: 8rem;
    height: 8rem;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}
.about-3plus__item + .about-3plus__item {
  margin-top: 2rem;
}
.about-3plus__item:nth-of-type(1)::before {
  background-image: url("../images/common/cross-blue.png");
}
.about-3plus__item:nth-of-type(1) .about-3plus__ttl {
  color: #00A2FA;
}
.about-3plus__item:nth-of-type(1) .about-3plus__ttl::before {
  background-image: url("../images/common/cross-blue.png");
}
.about-3plus__item:nth-of-type(2)::before {
  background-image: url("../images/common/cross-violet.png");
}
.about-3plus__item:nth-of-type(2) .about-3plus__ttl {
  color: #9C1FC1;
}
.about-3plus__item:nth-of-type(2) .about-3plus__ttl::before {
  background-image: url("../images/common/cross-violet.png");
}
.about-3plus__item:nth-of-type(3)::before {
  background-image: url("../images/common/cross-red.png");
}
.about-3plus__item:nth-of-type(3) .about-3plus__ttl {
  color: #E93A83;
}
.about-3plus__item:nth-of-type(3) .about-3plus__ttl::before {
  background-image: url("../images/common/cross-red.png");
}
.about-3plus__box-txt {
  flex: 1;
}
.about-3plus__ttl {
  font-weight: bold;
  margin-bottom: 1.8rem;
}
.about-3plus__ttl span {
  display: inline-block;
  position: relative;
}
.about-3plus__ttl span::before {
  content: "";
  position: absolute;
  right: 0;
  bottom: -5px;
  left: 0;
  height: 1px;
  border-top: 1px solid #000;
  border-color: inherit;
}
.about-3plus__ttl::before {
  content: "";
  display: inline-block;
  width: 4rem;
  height: 4rem;
  margin-right: 1rem;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media only screen and (min-width: 767px) {
  .about-3plus__ttl::before {
    display: none;
  }
}
.about-3plus__subttl {
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1.38;
  text-align: left;
  margin-bottom: 0.5rem;
}
@media only screen and (min-width: 767px) {
  .about-3plus__subttl {
    font-size: 2.6rem;
  }
}
@media only screen and (min-width: 1024px) {
  .about-3plus__subttl {
    text-align: justify;
    text-justify: inter-ideograph;
  }
}
.about-3plus__thumb {
  display: block;
  width: min(100%, 28.8rem);
  margin: 2.5rem auto 0;
}
@media only screen and (min-width: 767px) {
  .about-3plus__thumb {
    width: 36.5%;
    margin: 0 0 0 3rem;
  }
}

@media only screen and (min-width: 767px) {
  .plus3-label {
    display: flex;
    margin-top: 5.5rem;
  }
}
.plus3-label__item {
  flex: 1;
  display: flex;
  align-items: center;
  font-weight: bold;
  height: 6rem;
  background-size: contain;
  background-position: left 0;
  background-repeat: no-repeat;
}
@media only screen and (min-width: 767px) {
  .plus3-label__item {
    justify-content: center;
    height: 24rem;
    background-size: cover;
    background-position: center;
  }
}
.plus3-label__item:nth-of-type(1) {
  color: #00A2FA;
  background-image: url("../images/common/icon-gradation-blue.jpg");
}
@media only screen and (min-width: 767px) {
  .plus3-label__item:nth-of-type(1) {
    color: #fff;
    background-image: url("../images/common/gradation-blue.jpg");
  }
}
.plus3-label__item:nth-of-type(2) {
  color: #9C1FC1;
  background-image: url("../images/common/icon-gradation-violet.jpg");
}
@media only screen and (min-width: 767px) {
  .plus3-label__item:nth-of-type(2) {
    color: #fff;
    background-image: url("../images/common/gradation-violet.jpg");
  }
}
.plus3-label__item:nth-of-type(3) {
  color: #E93A83;
  background-image: url("../images/common/icon-gradation-red.jpg");
}
@media only screen and (min-width: 767px) {
  .plus3-label__item:nth-of-type(3) {
    color: #fff;
    background-image: url("../images/common/gradation-red.jpg");
  }
}
.plus3-label__h1 {
  display: inline-flex;
  align-items: center;
  position: relative;
  color: inherit;
  margin-left: 8rem;
}
@media only screen and (min-width: 767px) {
  .plus3-label__h1 {
    flex-direction: column;
    margin-left: 0;
  }
}
.plus3-label__h1::before {
  content: "";
  position: absolute;
  right: 0;
  bottom: -0.5rem;
  left: 0;
  height: 1px;
  border-top: 1px solid #000;
  border-color: inherit;
}
@media only screen and (min-width: 767px) {
  .plus3-label__h1::before {
    display: none;
  }
}
.plus3-label__h1 small {
  font-size: 1.4rem;
  margin-right: 1rem;
}
@media only screen and (min-width: 767px) {
  .plus3-label__h1 small {
    font-size: 2rem;
  }
}
@media only screen and (min-width: 767px) {
  .plus3-label__h1 small {
    margin: 0 0 1rem;
  }
}
.plus3-label__h1 em {
  font-size: 2rem;
}
@media only screen and (min-width: 767px) {
  .plus3-label__h1 em {
    font-size: 2.4rem;
    margin-bottom: 2rem;
  }
}
@media only screen and (min-width: 1024px) {
  .plus3-label__h1 em {
    font-size: 3rem;
  }
}

.about-top-needs {
  padding: 3.5rem 0 8rem;
}
@media only screen and (min-width: 767px) {
  .about-top-needs {
    padding-top: 11rem;
  }
}
.about-top-needs__thumb {
  display: block;
  width: min(100%, 58rem);
  margin: 0 auto;
}
.about-top-needs__fig {
  position: relative;
}
.about-top-needs__fig img {
  width: 100%;
}
.about-top-needs__cap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  color: #fff;
  font-weight: bold;
  line-height: 1.3;
  text-align: center;
  white-space: nowrap;
}
@media only screen and (min-width: 1024px) {
  .about-top-needs__cap {
    font-size: 3rem;
  }
}

.about-top-use {
  padding-top: 7.5rem;
}
.about-top-use__h2 {
  text-align: right;
  margin-bottom: 2.5rem;
}
@media only screen and (min-width: 767px) {
  .about-top-use__h2 {
    margin-bottom: 5rem;
  }
}
@media only screen and (min-width: 1440px) {
  .about-top-use__h2 {
    margin-right: calc((100% - 1024px) / 2);
  }
}
.about-top-use__fig {
  position: relative;
}
@media only screen and (min-width: 767px) {
  .about-top-use__fig {
    width: 65.5%;
  }
}
.about-top-use__fig img {
  border-radius: 4rem;
  width: 100%;
}
@media only screen and (min-width: 767px) {
  .about-top-use__fig img {
    border-radius: 10rem;
  }
}
.about-top-use__cap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2.4rem;
  color: #fff;
  font-weight: bold;
  white-space: nowrap;
}
@media only screen and (min-width: 1024px) {
  .about-top-use__cap {
    font-size: 4rem;
  }
}
.about-top-use__box-txt {
  border-radius: 4rem;
  background-color: #fff;
  box-shadow: 4px 4px 16px rgba(145, 144, 144, 0.16);
  position: relative;
  z-index: 1;
  padding: 1.5rem 2rem 2rem;
  border-radius: 2rem;
}
@media only screen and (min-width: 767px) {
  .about-top-use__box-txt {
    border-radius: 5rem;
  }
}
.about-top-use__box-txt::after {
  content: "";
  clear: both;
  display: block;
}
@media only screen and (min-width: 767px) {
  .about-top-use__box-txt {
    position: absolute;
    bottom: 4rem;
    left: calc(100% - 35.3rem);
    width: 35.2rem;
  }
}
@media only screen and (min-width: 1024px) {
  .about-top-use__box-txt {
    left: 61%;
    padding: 4rem;
  }
}
.about-top-use__h3 {
  font-size: 1.8rem;
  line-height: 1.33;
  font-weight: bold;
  margin-bottom: 1rem;
}
@media only screen and (min-width: 767px) {
  .about-top-use__h3 {
    font-size: 2.4rem;
  }
}
@media only screen and (min-width: 767px) {
  .about-top-use__h3 {
    line-height: 1.5;
  }
}
.about-top-use__link {
  font-size: 1.2rem;
  position: relative;
  font-weight: bold;
  color: inherit;
  margin-top: 1rem;
  padding-right: 4rem;
  float: right;
}
.about-top-use__link:hover {
  opacity: 0.7;
}
@media only screen and (min-width: 767px) {
  .about-top-use__link {
    font-size: 1.4rem;
  }
}
.about-top-use__link::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 2rem;
  margin-top: -0.6rem;
  width: 3rem;
  height: 1.2rem;
  background-image: url("../images/common/arrow-link.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media only screen and (min-width: 767px) {
  .about-top-use__link {
    margin-top: 3rem;
  }
}
.about-top-use__link::after {
  right: 0;
  background-image: url("../images/common/arrow-link-black.png");
}
.about-top-use__item {
  position: relative;
}
.about-top-use__item + .about-top-use__item {
  margin-top: 2rem;
}
@media only screen and (min-width: 767px) {
  .about-top-use__item + .about-top-use__item {
    margin-top: 4rem;
  }
}
@media only screen and (min-width: 1024px) {
  .about-top-use__item + .about-top-use__item {
    margin-top: 2rem;
  }
}
@media only screen and (min-width: 767px) {
  .about-top-use__item:nth-of-type(even) .about-top-use__fig {
    margin-left: auto;
  }
}
.about-top-use__item:nth-of-type(even) .about-top-use__box-txt {
  margin: -3rem 1rem 0 6rem;
}
@media only screen and (min-width: 767px) {
  .about-top-use__item:nth-of-type(even) .about-top-use__box-txt {
    left: auto;
    right: calc(100% - 35.3rem);
    margin: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .about-top-use__item:nth-of-type(even) .about-top-use__box-txt {
    right: 61%;
  }
}
.about-top-use__item:nth-of-type(odd) .about-top-use__box-txt {
  margin: -3rem 6rem 0 1rem;
}
@media only screen and (min-width: 767px) {
  .about-top-use__item:nth-of-type(odd) .about-top-use__box-txt {
    margin: 0;
  }
}

.about-cs {
  padding: 4rem 0 5rem;
}
@media only screen and (min-width: 767px) {
  .about-cs {
    padding: 7rem 0 12rem;
  }
}
.about-cs--top {
  padding-bottom: 4rem;
}
@media only screen and (min-width: 767px) {
  .about-cs--top {
    padding: 12rem 0;
  }
}
.about-cs__list-logo {
  border-radius: 4rem;
  background-color: #fff;
  box-shadow: 4px 4px 16px rgba(145, 144, 144, 0.16);
  margin-top: 1rem;
  padding: 3.5rem 2rem 4rem;
}
@media only screen and (min-width: 767px) {
  .about-cs__list-logo {
    border-radius: 10rem;
  }
}
@media only screen and (min-width: 767px) {
  .about-cs__list-logo {
    margin-top: 12rem;
    padding: 6rem;
  }
}
.about-cs__list-logo img {
  display: block;
  width: 100%;
}
@media only screen and (min-width: 767px) {
  .about-cs__list-logo img {
    width: 87%;
    margin: 0 auto;
  }
}
.about-cs__list-ttl {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 2.5rem;
}
@media only screen and (min-width: 767px) {
  .about-cs__list-ttl {
    font-size: 2.4rem;
  }
}
@media only screen and (min-width: 767px) {
  .about-cs__list-ttl {
    margin-bottom: 6rem;
  }
}

.about-news {
  padding-top: 7.5rem;
}
@media only screen and (min-width: 767px) {
  .about-news__h3 {
    margin-bottom: 3rem;
  }
}
.about-news__list {
  border-top: 1px solid #E8E7E5;
}
.about-news__item {
  position: relative;
  color: inherit;
  border-bottom: 1px solid #E8E7E5;
  padding: 1.7rem 7rem 1.5rem 1rem;
  position: relative;
  display: block;
}
.about-news__item::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 2rem;
  margin-top: -0.6rem;
  width: 3rem;
  height: 1.2rem;
  background-image: url("../images/common/arrow-link.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.about-news__item:hover {
  opacity: 0.7;
}
@media only screen and (min-width: 767px) {
  .about-news__item {
    display: flex;
    padding: 2.5rem 7rem 2.5rem 1rem;
  }
}
.about-news__item::after {
  background-image: url("../images/common/arrow-link-black.png");
  right: 1rem;
}
@media only screen and (min-width: 767px) {
  .about-news__item::after {
    right: 2rem;
  }
}
.about-news__item time {
  font-family: "Oswald", sans-serif;
  display: block;
  font-size: 2rem;
  margin-bottom: 0.5rem;
}
@media only screen and (min-width: 767px) {
  .about-news__item time {
    display: inline-block;
    margin: 0.3rem 3rem 0 0;
  }
}
.about-news__item time small {
  display: inline-block;
  font-size: 1.2rem;
  vertical-align: top;
  margin-right: 0.6rem;
  margin-top: 0.3rem;
}
.about-news__link {
  font-weight: bold;
  flex: 1;
}

.about-top-award {
  border-radius: 4rem;
  background-color: #fff;
  box-shadow: 4px 4px 16px rgba(145, 144, 144, 0.16);
  padding: 4rem 2rem 3.5rem;
  margin-bottom: 7.5rem;
}
@media only screen and (min-width: 767px) {
  .about-top-award {
    border-radius: 10rem;
  }
}
@media only screen and (min-width: 767px) {
  .about-top-award {
    margin-bottom: 11rem;
  }
}
@media only screen and (min-width: 767px) {
  .about-top-award {
    display: flex;
    align-items: center;
    padding: 3.2rem 8rem 2.8rem;
  }
}
.about-top-award__h4 {
  margin-bottom: 0.5rem;
}
@media only screen and (min-width: 767px) {
  .about-top-award__h4 {
    margin-bottom: 1rem;
  }
}
.about-top-award__thumb {
  display: block;
  width: 14.2rem;
  margin: 0 auto 2.5rem;
}
@media only screen and (min-width: 767px) {
  .about-top-award__thumb {
    width: 21.5%;
    margin: 0 7rem 0 0;
  }
}

.about-question {
  padding-bottom: 8rem;
  font-weight: bold;
}
@media only screen and (min-width: 767px) {
  .about-question {
    padding-bottom: 12rem;
  }
}
.about-question__h3 {
  margin-bottom: 2.5rem;
}
@media only screen and (min-width: 767px) {
  .about-question__h3 {
    margin-bottom: 3rem;
  }
}
.about-question__list {
  border-bottom: 1px solid #E8E7E5;
}
.about-question__q {
  font-size: 1.6rem;
  line-height: 1.5;
  padding: 2.5rem 4.5rem 2.5rem 2rem;
  border-top: 1px solid #E8E7E5;
  position: relative;
  cursor: pointer;
}
@media only screen and (min-width: 767px) {
  .about-question__q {
    font-size: 2rem;
  }
}
@media only screen and (min-width: 767px) {
  .about-question__q {
    padding: 4.5rem 5rem 4.5rem 2rem;
  }
}
.about-question__q::after {
  content: "";
  transition: all 0.3s linear;
  position: absolute;
  top: 50%;
  right: 2rem;
  margin-top: -0.8rem;
  width: 0.8rem;
  height: 0.8rem;
  border-bottom: 1px solid #2D313D;
  border-right: 1px solid #2D313D;
  transform: rotate(45deg);
}
.about-question__q.open::after {
  transform: rotate(-135deg);
}
.about-question__a {
  font-size: 1.2rem;
  display: none;
  line-height: 1.66;
  padding: 0 4.5rem 2.5rem 2rem;
}
@media only screen and (min-width: 767px) {
  .about-question__a {
    font-size: 1.4rem;
  }
}
@media only screen and (min-width: 767px) {
  .about-question__a {
    line-height: 1.42;
    padding: 0 14rem 5rem 2rem;
  }
}

.about-ipa {
  padding: 7.5rem 0;
}
@media only screen and (min-width: 767px) {
  .about-ipa {
    padding: 11rem 0;
  }
}
.about-ipa--glay {
  background-color: #F4F4F6;
  padding: 3.5rem 0;
}
.about-ipa__thumb {
  display: block;
  width: min(100%, 58rem);
  margin: 0 auto;
}

.p-evidence {
  padding-bottom: 5rem;
}

.about-growth {
  text-align: center;
  overflow: hidden;
}
.about-growth__inner {
  margin: 0 -1rem;
}
@media only screen and (min-width: 767px) {
  .about-growth__inner {
    margin: 0;
  }
}
.about-growth__list {
  width: min(100%, 355px);
  position: relative;
  padding: 3.5rem 0 4rem;
  margin: 0 auto;
}
@media only screen and (min-width: 767px) {
  .about-growth__list {
    width: auto;
    padding: 11rem 0 12rem;
  }
}
.about-growth__list::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1px;
  height: 70%;
  background-color: #919090;
}
.about-growth__list::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80rem;
  height: 80rem;
  border-radius: 50%;
  background-color: #F4F4F6;
  z-index: -1;
  margin-top: -3rem;
}
@media only screen and (min-width: 767px) {
  .about-growth__list::after {
    margin-top: 1rem;
  }
}
.about-growth__box-txt {
  border-radius: 4rem;
  background-color: #fff;
  box-shadow: 4px 4px 16px rgba(145, 144, 144, 0.16);
  width: 22rem;
  padding: 1.9rem 1rem 1.5rem;
  margin: -1rem auto 0;
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 767px) {
  .about-growth__box-txt {
    border-radius: 5rem;
  }
}
@media only screen and (min-width: 767px) {
  .about-growth__box-txt {
    width: 28rem;
    padding: 2.4rem 2rem 2rem;
    margin-top: 0;
  }
}
.about-growth__thumb {
  border-radius: 4rem;
  display: block;
  width: 16rem;
}
@media only screen and (min-width: 767px) {
  .about-growth__thumb {
    border-radius: 5rem;
  }
}
@media only screen and (min-width: 767px) {
  .about-growth__thumb {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 32rem;
  }
}
.about-growth__item {
  font-weight: bold;
  position: relative;
}
.about-growth__item + .about-growth__item {
  margin-top: 1rem;
}
@media only screen and (min-width: 767px) {
  .about-growth__item + .about-growth__item {
    margin-top: 6rem;
  }
}
.about-growth__item:nth-of-type(even) .about-growth__thumb {
  margin-left: auto;
}
@media only screen and (min-width: 767px) {
  .about-growth__item:nth-of-type(even) .about-growth__thumb {
    left: auto;
    right: 0;
    margin-left: 0;
  }
}
.about-growth__ttl {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 1.3rem;
}
@media only screen and (min-width: 767px) {
  .about-growth__ttl {
    font-size: 2.4rem;
  }
}
.about-growth__txt {
  font-size: 1.2rem;
  line-height: 1.66;
  text-align: center;
}
@media only screen and (min-width: 767px) {
  .about-growth__txt {
    font-size: 1.4rem;
  }
}
@media only screen and (min-width: 767px) {
  .about-growth__txt {
    line-height: 1.42;
  }
}

.about-sheet {
  padding: 3.5rem 0 4rem;
}
@media only screen and (min-width: 767px) {
  .about-sheet {
    padding: 11rem 0 12rem;
  }
}
.about-sheet__inner {
  width: min(100% - 40px, 1054px);
  margin: 0 auto;
}
.about-sheet__h5 {
  margin: 0 -20px;
}
@media only screen and (min-width: 767px) {
  .about-sheet__h5 {
    margin: 0;
  }
}
.about-sheet__contents {
  position: relative;
  margin-top: 5.5rem;
}
@media only screen and (min-width: 1024px) {
  .about-sheet__contents {
    height: 40rem;
  }
}
.about-sheet__right {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
@media only screen and (min-width: 1024px) {
  .about-sheet__right {
    justify-content: space-between;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    width: calc(50% + 1.5rem);
  }
}
.about-sheet__right .about-sheet__box-txt {
  margin-left: -5.5rem;
}
@media only screen and (min-width: 767px) {
  .about-sheet__right .about-sheet__box-txt {
    margin-left: 0;
  }
}
.about-sheet__right .about-sheet__box-txt::before {
  content: "";
  left: -5rem;
}
@media only screen and (min-width: 767px) {
  .about-sheet__right .about-sheet__thumb {
    margin-right: 4rem;
  }
}
@media only screen and (min-width: 1024px) {
  .about-sheet__right .about-sheet__thumb {
    margin-right: 0;
  }
}
.about-sheet__left {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 4rem;
}
@media only screen and (min-width: 1024px) {
  .about-sheet__left {
    justify-content: space-between;
    position: absolute;
    left: 0;
    top: 3rem;
    width: calc(50% + 1.5rem);
    margin-top: 0;
  }
}
@media only screen and (min-width: 767px) {
  .about-sheet__left .about-sheet__thumb {
    margin-left: 4rem;
  }
}
@media only screen and (min-width: 1024px) {
  .about-sheet__left .about-sheet__thumb {
    margin-left: 0;
  }
}
.about-sheet__left .about-sheet__box-txt {
  margin-right: -5.5rem;
}
@media only screen and (min-width: 767px) {
  .about-sheet__left .about-sheet__box-txt {
    margin-right: 0;
  }
}
.about-sheet__left .about-sheet__box-txt::before {
  content: "";
  right: -5rem;
}
.about-sheet__thumb {
  width: 20rem;
  box-shadow: 2px 2px 8px rgba(145, 144, 144, 0.16);
}
@media only screen and (min-width: 767px) {
  .about-sheet__thumb {
    width: 26rem;
  }
}
.about-sheet__box-txt {
  border-radius: 4rem;
  background-color: #fff;
  box-shadow: 4px 4px 16px rgba(145, 144, 144, 0.16);
  width: 19rem;
  padding: 1.5rem 2rem;
  position: relative;
  border-radius: 2rem;
}
@media only screen and (min-width: 767px) {
  .about-sheet__box-txt {
    border-radius: 5rem;
  }
}
@media only screen and (min-width: 767px) {
  .about-sheet__box-txt {
    width: 24.2rem;
    padding: 2.5rem 3rem;
  }
}
.about-sheet__box-txt + .about-sheet__box-txt {
  margin-top: 1rem;
}
@media only screen and (min-width: 767px) {
  .about-sheet__box-txt + .about-sheet__box-txt {
    margin-top: 2rem;
  }
}
@media only screen and (min-width: 767px) {
  .about-sheet__box-txt::before {
    content: "";
    position: absolute;
    top: 50%;
    height: 1px;
    width: 5rem;
    background-color: #919090;
  }
}
.about-sheet__box-txt.one {
  margin-top: 2rem;
}
@media only screen and (min-width: 767px) {
  .about-sheet__box-txt.one {
    margin-top: 1rem;
  }
}
.about-sheet__box-txt.three {
  margin-top: 2rem;
}
@media only screen and (min-width: 767px) {
  .about-sheet__box-txt.three {
    margin-top: 0;
  }
}
.about-sheet__ttl {
  margin-bottom: 0.5rem;
}
@media only screen and (min-width: 767px) {
  .about-sheet__ttl {
    margin-bottom: 0;
  }
}
.about-sheet__txt {
  text-align: justify;
  text-justify: inter-ideograph;
  font-size: 1.2rem;
  line-height: 1.66;
}
@media only screen and (min-width: 767px) {
  .about-sheet__txt {
    font-size: 1.4rem;
  }
}
@media only screen and (min-width: 767px) {
  .about-sheet__txt {
    line-height: 1.87;
  }
}

.about-ccm {
  padding: 7.5rem 0 8rem;
}
@media only screen and (min-width: 767px) {
  .about-ccm {
    padding: 11rem 0 12rem;
  }
}
.about-ccm__chart {
  display: block;
  width: min(100% - 40px, 33.5rem);
  margin: 3.5rem auto 4rem;
}
@media only screen and (min-width: 767px) {
  .about-ccm__chart {
    width: min(100% - 40px, 118rem);
    margin: 5.5rem auto 6rem;
  }
}
.about-ccm__list {
  margin: 0 2rem 5rem;
}
@media only screen and (min-width: 767px) {
  .about-ccm__list {
    display: flex;
    margin: 0 0 7rem;
  }
}
@media only screen and (min-width: 767px) {
  .about-ccm__item {
    flex: 1;
    max-width: 32rem;
  }
}
.about-ccm__item + .about-ccm__item {
  margin-top: 2.5rem;
}
@media only screen and (min-width: 767px) {
  .about-ccm__item + .about-ccm__item {
    margin: 0 0 0 4rem;
  }
}
.about-ccm__thumb {
  border-radius: 4rem;
  width: 100%;
  margin-bottom: 1.5rem;
}
@media only screen and (min-width: 767px) {
  .about-ccm__thumb {
    border-radius: 5rem;
  }
}
@media only screen and (min-width: 767px) {
  .about-ccm__thumb {
    margin-bottom: 2rem;
  }
}
.about-ccm__tag {
  font-size: 1rem;
  display: inline-block;
  width: 6.6rem;
  font-weight: bold;
  text-align: center;
  color: #919090;
  padding: 0.5rem 0;
  margin-bottom: 0.5rem;
  border: 1px solid #919090;
  border-radius: 1.5rem;
}
@media only screen and (min-width: 767px) {
  .about-ccm__tag {
    font-size: 1.4rem;
  }
}
@media only screen and (min-width: 767px) {
  .about-ccm__tag {
    width: 8rem;
    padding: 0.8rem 0;
  }
}
.about-ccm__ttl {
  margin-bottom: 0.5rem;
}
@media only screen and (min-width: 767px) {
  .about-ccm__ttl {
    margin-bottom: 1.5rem;
  }
}
.about-ccm__txt {
  display: flex;
}
.about-ccm__txt b {
  flex-shrink: 0;
}

.about-js-ccm {
  padding: 7.5rem 0 8rem;
}
@media only screen and (min-width: 1024px) {
  .about-js-ccm {
    padding: 11rem 0 12rem;
  }
}
.about-js-ccm__chart {
  display: block;
  width: min(100%, 32.2rem);
  margin: 3.5rem auto 4rem;
}
@media only screen and (min-width: 767px) {
  .about-js-ccm__chart {
    width: 100%;
    margin: 5.5rem 0 0;
  }
}
.about-js-ccm__list {
  position: relative;
  padding: 0 2rem 3rem;
  margin-bottom: 4rem;
}
@media only screen and (min-width: 767px) {
  .about-js-ccm__list {
    display: flex;
    justify-content: space-between;
    padding: 0 0 4rem;
    margin-bottom: 8rem;
  }
}
.about-js-ccm__list::before {
  content: "";
  border-radius: 4rem;
  position: absolute;
  top: 4rem;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background-color: #F4F4F6;
}
@media only screen and (min-width: 767px) {
  .about-js-ccm__list::before {
    border-radius: 5rem;
  }
}
@media only screen and (min-width: 767px) {
  .about-js-ccm__list::before {
    right: -20px;
    left: -20px;
  }
}
@media screen and (min-width: 1160px) {
  .about-js-ccm__list::before {
    right: -4rem;
    left: -4rem;
  }
}
@media only screen and (min-width: 767px) {
  .about-js-ccm__item {
    width: 31%;
  }
}
.about-js-ccm__item img {
  width: 100%;
}
.about-js-ccm__item + .about-js-ccm__item {
  margin-top: 4rem;
}
@media only screen and (min-width: 767px) {
  .about-js-ccm__item + .about-js-ccm__item {
    margin-top: 0;
  }
}
.about-js-ccm__thumb {
  border-radius: 4rem;
  margin-bottom: 1.5rem;
}
@media only screen and (min-width: 767px) {
  .about-js-ccm__thumb {
    border-radius: 5rem;
  }
}
.about-js-ccm__ttl {
  margin-bottom: 0.5rem;
}
@media only screen and (min-width: 767px) {
  .about-js-ccm__ttl {
    margin-bottom: 1rem;
  }
}
.about-js-ccm__slide {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}
@media only screen and (min-width: 767px) {
  .about-js-ccm__slide {
    margin-top: 1.8rem;
  }
}
.about-js-ccm__slide-item {
  width: 24.5%;
}
@media only screen and (min-width: 767px) {
  .about-js-ccm__slide-item {
    width: 24%;
  }
}
.about-js-ccm__slide-item img {
  border-radius: 1rem;
}
.about-js-ccm__slide-item figcaption {
  font-size: 1.1rem;
  font-weight: bold;
  text-align: center;
  line-height: 1.33;
  margin-top: 0.5rem;
}
@media only screen and (min-width: 767px) {
  .about-js-ccm__slide-item figcaption {
    font-size: 1.2rem;
  }
}

.about-merit {
  padding: 7.5rem 0 8rem;
  overflow: hidden;
}
@media only screen and (min-width: 767px) {
  .about-merit {
    padding: 11rem 2rem 12rem;
  }
}
.about-merit__outer {
  overflow: hidden;
}
.about-merit__list {
  position: relative;
  padding: 1rem 0 0;
}
@media only screen and (min-width: 767px) {
  .about-merit__list {
    padding: 2rem 0 10rem;
  }
}
.about-merit__list::before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 4rem;
  z-index: -1;
  width: 80rem;
  height: 80rem;
  border-radius: 50%;
  background-color: #F4F4F6;
}
@media only screen and (min-width: 767px) {
  .about-merit__list::before {
    bottom: 0;
  }
}
.about-merit__list--js::before {
  width: 92rem;
  height: 92rem;
}
@media only screen and (min-width: 767px) {
  .about-merit__list--js::before {
    width: 80rem;
    height: 80rem;
  }
}
.about-merit__thumb {
  display: block;
  width: 20.8rem;
  margin: 1.5rem auto 0;
}
@media only screen and (min-width: 767px) {
  .about-merit__thumb {
    width: 26rem;
    margin-top: 0;
  }
}
.about-merit__box-txt {
  flex: 1;
}
.about-merit__ttl {
  font-size: 1.8rem;
  font-weight: bold;
  margin-right: 1rem;
}
@media only screen and (min-width: 767px) {
  .about-merit__ttl {
    font-size: 2.4rem;
  }
}
@media only screen and (min-width: 767px) {
  .about-merit__ttl {
    margin-bottom: 2rem;
  }
}
.about-merit__ttl small {
  font-size: 1.2rem;
  display: block;
  margin-bottom: 1rem;
}
@media only screen and (min-width: 767px) {
  .about-merit__ttl small {
    font-size: 1.4rem;
  }
}
.about-merit__txt {
  margin: 0.8rem 0 0.5rem;
}
@media only screen and (min-width: 767px) {
  .about-merit__txt {
    margin: 1.5rem 0 1.3rem;
  }
}
.about-merit__note {
  font-size: 1rem;
  line-height: 1.5;
  margin-right: 1rem;
}
@media only screen and (min-width: 767px) {
  .about-merit__note {
    font-size: 1.2rem;
  }
}
@media only screen and (min-width: 767px) {
  .about-merit__note {
    display: flex;
  }
}
.about-merit__note span {
  flex-shrink: 0;
}
.about-merit__item {
  padding: 0 calc((100% - 335px) / 2);
}
@media only screen and (min-width: 767px) {
  .about-merit__item {
    display: flex;
    align-items: center;
    padding: 0 2rem;
  }
}
@media only screen and (min-width: 767px) {
  .about-merit__item--left-wide {
    margin-right: calc((100% - 880px) / 2);
  }
}
@media only screen and (min-width: 767px) {
  .about-merit__item:nth-of-type(even) .about-merit__thumb {
    margin-left: 3rem;
  }
}
@media only screen and (min-width: 767px) {
  .about-merit__item:nth-of-type(even) .about-merit__box-txt * {
    text-align: right;
  }
}
@media only screen and (min-width: 767px) {
  .about-merit__item:nth-of-type(even) .about-merit__note {
    justify-content: flex-end;
  }
}
.about-merit__item:nth-of-type(odd) .about-merit__thumb {
  order: 1;
}
@media only screen and (min-width: 767px) {
  .about-merit__item:nth-of-type(odd) .about-merit__thumb {
    margin-right: 3rem;
  }
}
.about-merit__item:nth-of-type(odd) .about-merit__box-txt {
  order: 2;
}
.about-merit__item + .about-merit__item {
  margin-top: 4rem;
}
@media only screen and (min-width: 767px) {
  .about-merit__item + .about-merit__item {
    margin-top: 0;
  }
}

.about-js-table {
  background: linear-gradient(#F4F4F6 4rem, #fff 4rem);
}
.about-js-table__inner {
  border-radius: 4rem;
  background-color: #fff;
  box-shadow: 4px 4px 16px rgba(145, 144, 144, 0.16);
  padding: 3.5rem 1rem;
  text-align: center;
}
@media only screen and (min-width: 767px) {
  .about-js-table__inner {
    border-radius: 10rem;
  }
}
@media only screen and (min-width: 767px) {
  .about-js-table__inner {
    padding: 11rem 1rem 11.5rem;
  }
}
@media only screen and (min-width: 1024px) {
  .about-js-table__inner {
    padding: 11rem 4rem 11.5rem;
  }
}
.about-js-table__thumb {
  width: 29.5rem;
  margin: 0 auto 1rem;
  border-radius: 4rem;
}
.about-js-table__contents {
  display: none;
}
@media only screen and (min-width: 767px) {
  .about-js-table__contents {
    display: table;
    table-layout: fixed;
    width: calc(100% + 8px);
    color: #919090;
    font-weight: bold;
    text-align: center;
    margin: 0 -4px;
  }
}
.about-js-table__contents th {
  vertical-align: middle;
  font-size: 1.2rem;
  line-height: 1.33;
  border-left: 4px solid #fff;
  border-right: 4px solid #fff;
}
.about-js-table__contents th:first-of-type {
  width: 10rem;
}
@media only screen and (min-width: 1024px) {
  .about-js-table__contents th:first-of-type {
    width: 12.8rem;
  }
}
.about-js-table__contents th:not(:first-of-type) {
  color: #fff;
  background-image: url("../images/jobsupport/balloon.png");
  background-size: 100% calc(100% - 1rem);
  background-repeat: no-repeat;
}
@media only screen and (min-width: 1024px) {
  .about-js-table__contents th:not(:first-of-type) {
    background-size: 100% auto;
  }
}
@media only screen and (min-width: 1024px) {
  .about-js-table__contents th:not(:first-of-type) br {
    display: none;
  }
}
.about-js-table__contents th em {
  display: block;
  font-size: 1.6rem;
  margin-bottom: 0.5rem;
}
.about-js-table__contents td {
  font-size: 1.6rem;
  vertical-align: middle;
  background-color: #F4F4F6;
  color: #00A2FA;
  border-left: 4px solid #fff;
  border-right: 4px solid #fff;
}
.about-js-table__contents td.none {
  font-size: 1.2rem;
  color: #919090;
}
.about-js-table__contents tr th,
.about-js-table__contents tr td {
  border-bottom: 1px dashed #919090;
}
.about-js-table__contents tr:first-of-type {
  height: 5rem;
}
.about-js-table__contents tr:first-of-type th {
  border-bottom: 1px solid #919090;
  padding-bottom: 1rem;
}
.about-js-table__contents tr:nth-of-type(2) {
  height: 6rem;
}
.about-js-table__contents tr:nth-of-type(3) {
  height: 8rem;
}
.about-js-table__contents tr:last-of-type {
  height: 8rem;
}
.about-js-table__contents tr:last-of-type th,
.about-js-table__contents tr:last-of-type td {
  border-bottom: 1px solid #919090;
}
.about-js-table__contents img {
  display: block;
  margin: 1rem;
  width: calc(100% - 2rem);
  border-radius: 1rem;
}
.about-js-table__note {
  font-size: 1.2rem;
  line-height: 1.5;
  text-align: right;
  margin-top: 1.5rem;
}

.about-contents {
  padding: 4rem 0 11rem;
}
@media only screen and (min-width: 767px) {
  .about-contents {
    padding: 8rem 0 12rem;
  }
}
.about-contents__item {
  border-radius: 4rem;
  background-color: #fff;
  box-shadow: 4px 4px 16px rgba(145, 144, 144, 0.16);
  padding: 2.7rem 2rem 5.5rem;
  position: relative;
}
@media only screen and (min-width: 767px) {
  .about-contents__item {
    border-radius: 10rem;
  }
}
@media only screen and (min-width: 767px) {
  .about-contents__item {
    padding: 6rem 6rem 5.5rem;
  }
}
.about-contents__item + .about-contents__item {
  margin-top: 5rem;
}
@media only screen and (min-width: 767px) {
  .about-contents__item + .about-contents__item {
    margin-top: 8rem;
  }
}
.about-contents__top {
  padding-bottom: 3rem;
  border-bottom: 1px solid #E8E7E5;
}
@media only screen and (min-width: 767px) {
  .about-contents__top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 2.5rem;
  }
}
.about-contents__bottom {
  display: grid;
  grid-template-columns: 9.5rem 1fr;
  grid-template-rows: 8rem 1fr;
  grid-template-areas: "thumb name" "text text";
  padding-top: 1.5rem;
}
@media only screen and (min-width: 767px) {
  .about-contents__bottom {
    grid-template-columns: 1fr 14rem;
    grid-template-rows: 3rem 1fr;
    grid-template-areas: "name thumb" "text thumb";
    padding-top: 3rem;
  }
}
.about-contents__cat {
  font-size: 1.4rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
@media only screen and (min-width: 767px) {
  .about-contents__cat {
    font-size: 2.1rem;
  }
}
@media only screen and (min-width: 767px) {
  .about-contents__cat {
    margin: 0 1rem 1.5rem 0;
  }
}
.about-contents__cat span {
  font-size: 1rem;
  display: block;
  width: 6.6rem;
  padding: 0.5rem 0;
  margin-left: 1rem;
  text-align: center;
  color: #919090;
  border: 1px solid #919090;
  border-radius: 1.5rem;
}
@media only screen and (min-width: 767px) {
  .about-contents__cat span {
    font-size: 1.4rem;
  }
}
@media only screen and (min-width: 767px) {
  .about-contents__cat span {
    width: 8rem;
    padding: 0.7rem 0;
  }
}
.about-contents__ttl {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 0.8rem;
}
@media only screen and (min-width: 767px) {
  .about-contents__ttl {
    font-size: 3rem;
  }
}
@media only screen and (min-width: 767px) {
  .about-contents__ttl {
    margin-bottom: 1.3rem;
  }
}
.about-contents__subttl {
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 1.66;
}
@media only screen and (min-width: 767px) {
  .about-contents__subttl {
    font-size: 1.4rem;
  }
}
@media only screen and (min-width: 767px) {
  .about-contents__subttl {
    line-height: 1.42;
  }
}
.about-contents__subttl + .about-contents__subttl {
  margin-top: 2.5rem;
}
.about-contents__list {
  font-size: 1.2rem;
  line-height: 1.71;
}
@media only screen and (min-width: 767px) {
  .about-contents__list {
    font-size: 1.4rem;
  }
}
.about-contents__list li {
  display: inline-flex;
}
.about-contents__list li::before {
  content: "●";
  color: #E8E7E5;
  display: inline-block;
  margin-right: 0.3rem;
}
@media only screen and (min-width: 767px) {
  .about-contents__list li::before {
    margin-right: 0.5rem;
  }
}
.about-contents__thumb {
  border-radius: 4rem;
  display: block;
  width: min(100%, 29.5rem);
  margin: 2.5rem auto 0;
}
@media only screen and (min-width: 767px) {
  .about-contents__thumb {
    border-radius: 5rem;
  }
}
@media only screen and (min-width: 767px) {
  .about-contents__thumb {
    width: 38%;
    margin: 0 0 0 3rem;
  }
}
.about-contents__name {
  grid-area: name;
  align-self: center;
  font-size: 1.2rem;
  font-weight: bold;
}
@media only screen and (min-width: 767px) {
  .about-contents__name {
    align-self: start;
  }
}
.about-contents__name em {
  display: inline-block;
  font-size: 1.6em;
  margin-top: 0.8rem;
}
@media only screen and (min-width: 767px) {
  .about-contents__name em {
    margin: 0 0 0 1rem;
  }
}
.about-contents__txt {
  grid-area: text;
  font-size: 1.4rem;
  line-height: 1.71;
  margin-top: 1rem;
}
@media only screen and (min-width: 767px) {
  .about-contents__txt {
    margin-top: 0;
  }
}
.about-contents__thumb-dr {
  grid-area: thumb;
  width: 8rem;
  margin-right: 1.5rem;
}
@media only screen and (min-width: 767px) {
  .about-contents__thumb-dr {
    width: 10.8rem;
    margin: 0 0 0 auto;
  }
}
.about-contents__btn {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
}

.about-skills {
  padding: 4rem 0 8rem;
}
@media only screen and (min-width: 767px) {
  .about-skills {
    padding: 8rem 0 12rem;
  }
}
.about-skills__list-btn {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 -1.5rem 4rem;
}
@media only screen and (min-width: 767px) {
  .about-skills__list-btn {
    margin: 0 0 5rem;
  }
}
.about-skills__btn {
  font-size: 1.2rem;
  display: block;
  font-weight: bold;
  margin: 0 0.5rem 1rem;
}
@media only screen and (min-width: 767px) {
  .about-skills__btn {
    font-size: 1.4rem;
  }
}
.about-skills__btn a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 11rem;
  height: 4.8rem;
  text-align: center;
  line-height: 1.33;
  color: #919090;
  border-radius: 3rem;
  background-color: #fff;
  box-shadow: 2px 2px 8px rgba(145, 144, 144, 0.16);
}
.about-skills__btn a:hover {
  opacity: 0.7;
}
@media only screen and (min-width: 767px) {
  .about-skills__btn a {
    width: 16rem;
    height: 6rem;
  }
}
@media only screen and (min-width: 767px) {
  .about-skills__btn br {
    display: none;
  }
}
.about-skills__item {
  border-radius: 4rem;
  background-color: #fff;
  box-shadow: 4px 4px 16px rgba(145, 144, 144, 0.16);
  padding: 2.5rem 2rem 0;
}
@media only screen and (min-width: 767px) {
  .about-skills__item {
    border-radius: 10rem;
  }
}
@media only screen and (min-width: 767px) {
  .about-skills__item {
    padding: 5rem 6rem 3.3rem;
  }
}
.about-skills__item + .about-skills__item {
  margin-top: 4rem;
}
.about-skills__cat {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1.3rem;
}
@media only screen and (min-width: 767px) {
  .about-skills__cat {
    font-size: 3rem;
  }
}
.about-skills__cat span {
  font-family: "Barlow", sans-serif;
  font-size: 2.4rem;
  margin-right: 1rem;
}
@media only screen and (min-width: 767px) {
  .about-skills__cat span {
    font-size: 3.6rem;
  }
}
.about-skills__cat br {
  display: none;
}
.about-skills__txt {
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1.42;
}
.about-skills__txt + .about-skills__txt {
  font-size: 1.2rem;
  margin-top: 2.5rem;
}
@media only screen and (min-width: 767px) {
  .about-skills__txt + .about-skills__txt {
    font-size: 1.4rem;
  }
}
.about-skills__list {
  font-size: 1.2rem;
  line-height: 1.71;
  padding-bottom: 2.5rem;
  margin-top: 0.5rem;
}
@media only screen and (min-width: 767px) {
  .about-skills__list {
    font-size: 1.4rem;
  }
}
.about-skills__list li {
  display: flex;
}
.about-skills__slide {
  display: flex;
  justify-content: space-between;
  margin: 0 -1rem;
  padding-bottom: 2.5rem;
}
@media only screen and (min-width: 767px) {
  .about-skills__slide {
    margin: 0;
    padding: 0;
  }
}
.about-skills__slide-item {
  width: 32%;
}
.about-skills__slide-item img {
  border-radius: 2rem;
  width: 100%;
  height: 7rem;
  object-fit: cover;
}
@media only screen and (min-width: 767px) {
  .about-skills__slide-item img {
    height: auto;
    border-radius: 5rem;
  }
}
.about-skills__slide-item figcaption {
  font-size: 1rem;
  display: block;
  font-weight: bold;
  line-height: 1.42;
  text-align: center;
  margin-top: 0.5rem;
}
@media only screen and (min-width: 767px) {
  .about-skills__slide-item figcaption {
    font-size: 1.4rem;
  }
}

.about-case__mv {
  padding-top: 2rem;
  background: linear-gradient(to top, #fff 4rem, #F4F4F6 4rem);
}
@media only screen and (min-width: 1024px) {
  .about-case__mv {
    padding-top: 4rem;
  }
}
.about-case__figure {
  position: relative;
}
.about-case__figure > img {
  border-radius: 4rem;
  width: calc(100% + 2rem);
  margin: 0 -1rem;
}
@media only screen and (min-width: 767px) {
  .about-case__figure > img {
    border-radius: 10rem;
  }
}
@media only screen and (min-width: 767px) {
  .about-case__figure > img {
    width: 100%;
    margin: 0;
  }
}
.about-case__figure > figcaption {
  font-size: 2rem;
  display: inline-flex;
  align-items: center;
  color: #fff;
  font-weight: bold;
  padding: 1.5rem 1.8rem 1.5rem 2rem;
  margin: -3rem 0 0 -2rem;
  background-image: url("../images/common/case-caption-bg.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media only screen and (min-width: 767px) {
  .about-case__figure > figcaption {
    font-size: 2.4rem;
  }
}
@media only screen and (min-width: 767px) {
  .about-case__figure > figcaption {
    position: absolute;
    top: 2rem;
    left: -2rem;
    min-width: 24rem;
    padding: 2.8rem 1rem 2.8rem 2rem;
    margin: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .about-case__figure > figcaption {
    left: -4rem;
  }
}
.about-case__figure > figcaption > small {
  font-size: 1.2rem;
  margin-left: 1rem;
}
@media only screen and (min-width: 767px) {
  .about-case__figure > figcaption > small {
    font-size: 1.6rem;
  }
}
@media only screen and (min-width: 767px) {
  .about-case__figure > figcaption > small {
    margin-left: 1.5rem;
  }
}
.about-case__art {
  margin-top: 4rem;
}
@media only screen and (min-width: 767px) {
  .about-case__art {
    margin-top: 8rem;
  }
}
.about-case__art h1 {
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.5;
  margin-bottom: 2.5rem;
}
@media only screen and (min-width: 767px) {
  .about-case__art h1 {
    font-size: 3.6rem;
  }
}
@media only screen and (min-width: 767px) {
  .about-case__art h1 {
    margin-bottom: 3rem;
  }
}
.about-case__art a {
  line-height: 1.71;
  color: #00A2FA;
  font-weight: bold;
  text-decoration: underline;
}
@media only screen and (min-width: 767px) {
  .about-case__art a {
    line-height: 1.87;
  }
}
.about-case__art a:hover {
  opacity: 0.7;
}
.about-case__name {
  font-weight: bold;
  line-height: 1.42;
  margin-bottom: 0.5rem;
}
.about-case__sec {
  border-radius: 4rem;
  padding: 3rem 2rem 2.5rem;
  margin-bottom: 2.5rem;
  background-color: #F4F4F6;
}
@media only screen and (min-width: 767px) {
  .about-case__sec {
    border-radius: 5rem;
  }
}
@media only screen and (min-width: 767px) {
  .about-case__sec {
    padding: 4rem 4rem 3.5rem;
    margin-bottom: 3.5rem;
  }
}
.about-case__sec h3 {
  font-weight: bold;
  margin-bottom: 1rem;
}
@media only screen and (min-width: 767px) {
  .about-case__sec h3 {
    margin-bottom: 1.5rem;
  }
}
.about-case__sec p {
  font-size: 1.4rem;
  line-height: 1.71;
  margin-bottom: 0.5rem;
}
@media only screen and (min-width: 767px) {
  .about-case__sec p {
    margin-bottom: 1rem;
  }
}
.about-case__sec a {
  font-size: 1.4rem;
}
.about-case__intro {
  margin: 0 1rem 7rem;
}
@media only screen and (min-width: 767px) {
  .about-case__intro {
    margin: 0 0 10.5rem;
  }
}
.about-case__intro p {
  line-height: 1.71;
  overflow-wrap: break-word;
}
@media only screen and (min-width: 767px) {
  .about-case__intro p {
    line-height: 1.87;
  }
}
.about-case__content {
  margin: 0 1rem 6rem;
}
@media only screen and (min-width: 767px) {
  .about-case__content {
    margin: 0 0 12rem;
  }
}
.about-case__content h2 {
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1.5;
  margin-bottom: 2rem;
}
@media only screen and (min-width: 767px) {
  .about-case__content h2 {
    font-size: 2.4rem;
  }
}
@media only screen and (min-width: 767px) {
  .about-case__content h2 {
    margin-bottom: 3rem;
  }
}
.about-case__content p {
  line-height: 1.71;
}
@media only screen and (min-width: 767px) {
  .about-case__content p {
    line-height: 1.87;
  }
}
.about-case__content p.question {
  color: #919090;
  font-weight: bold;
  margin-top: 2.5rem;
}
.about-case__content p.question::before {
  content: "── ";
}
.about-case__content img {
  border-radius: 4rem;
  width: 100%;
  margin: 2.5rem 0 8rem;
}
@media only screen and (min-width: 767px) {
  .about-case__content img {
    border-radius: 5rem;
  }
}
@media only screen and (min-width: 767px) {
  .about-case__content img {
    margin: 3.5rem 0 12rem;
  }
}

.function-ex {
  padding: 4rem 0 0;
  background: linear-gradient(to top, #fff 4rem, #F4F4F6 4rem);
}
@media only screen and (min-width: 767px) {
  .function-ex {
    padding: 8rem 0 0;
  }
}
.function-ex__outer {
  margin-top: 4rem;
}
@media only screen and (min-width: 767px) {
  .function-ex__inner {
    display: flex;
    justify-content: space-between;
  }
}
@media only screen and (min-width: 767px) {
  .function-ex__txtbox {
    width: 44%;
    margin-top: 3rem;
  }
}
.function-ex__item {
  border-radius: 4rem;
  background-color: #fff;
  box-shadow: 4px 4px 16px rgba(145, 144, 144, 0.16);
  padding: 3rem 2rem 2rem;
  position: relative;
}
@media only screen and (min-width: 767px) {
  .function-ex__item {
    border-radius: 10rem;
  }
}
@media only screen and (min-width: 767px) {
  .function-ex__item {
    padding: 6rem;
  }
}
.function-ex__item:nth-of-type(2) {
  padding-bottom: 5rem;
}
.function-ex__item + .function-ex__item {
  margin-top: 2rem;
}
@media only screen and (min-width: 767px) {
  .function-ex__item + .function-ex__item {
    margin-top: 4rem;
  }
}
.function-ex__cat {
  font-size: 2rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
@media only screen and (min-width: 767px) {
  .function-ex__cat {
    font-size: 3rem;
  }
}
@media only screen and (min-width: 767px) {
  .function-ex__cat {
    margin: 0 1rem 1.5rem 0;
  }
}
.function-ex__cat span {
  font-size: 1.2rem;
  display: block;
  width: 8rem;
  padding: 0.5rem 0;
  margin-right: 1rem;
  text-align: center;
  color: #fff;
  border-radius: 1.5rem;
}
@media only screen and (min-width: 767px) {
  .function-ex__cat span {
    font-size: 1.4rem;
  }
}
@media only screen and (min-width: 767px) {
  .function-ex__cat span {
    width: 10rem;
    padding: 0.7rem 0;
  }
}
.function-ex__txt {
  margin-top: 1rem;
  line-height: 1.7;
  font-size: 1.4rem;
}
@media only screen and (min-width: 767px) {
  .function-ex__txt {
    font-size: 1.6rem;
  }
}
.function-ex__thumb01 {
  width: 100%;
  margin-top: 2rem;
  position: relative;
}
@media only screen and (min-width: 767px) {
  .function-ex__thumb01 {
    margin-top: 0;
    width: 50%;
  }
}
.function-ex__thumb01--01 {
  width: 100%;
}
.function-ex__thumb01--02 {
  position: absolute;
  top: -1rem;
  right: -1rem;
  width: 16rem;
}
@media only screen and (min-width: 767px) {
  .function-ex__thumb01--02 {
    top: -2rem;
    right: -2rem;
    width: 25rem;
  }
}
.function-ex__thumb02 {
  width: 100%;
  margin-top: 2rem;
  position: relative;
}
@media only screen and (min-width: 767px) {
  .function-ex__thumb02 {
    margin-top: 0;
    width: 50%;
  }
}
.function-ex__thumb02--01 {
  width: 100%;
  transform: scale(1.08);
}
.function-ex__thumb02--02 {
  position: absolute;
  bottom: -5rem;
  left: -4rem;
  width: 31rem;
}
@media only screen and (min-width: 767px) {
  .function-ex__thumb02--02 {
    display: none;
  }
}
.function-structure {
  padding: 8rem 0 8rem;
}
@media only screen and (min-width: 767px) {
  .function-structure {
    padding: 12rem 0 13rem;
  }
}
.function-structure__item {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 767px) {
  .function-structure__item {
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
  }
}
.function-structure__item--rev {
  align-items: flex-end;
}
@media only screen and (min-width: 767px) {
  .function-structure__item--rev {
    flex-direction: row-reverse;
  }
}
.function-structure__item--rev .function-structure__txtbox {
  margin-right: 1rem;
  margin-left: 0;
}
@media only screen and (min-width: 767px) {
  .function-structure__item--rev .function-structure__txtbox {
    margin-right: -6rem;
  }
}
.function-structure__img {
  width: 100%;
}
@media only screen and (min-width: 767px) {
  .function-structure__img {
    width: 54rem;
  }
}
.function-structure__txtbox {
  background-color: #fff;
  box-shadow: 4px 4px 16px rgba(145, 144, 144, 0.16);
  padding: 2rem;
  position: relative;
  width: 27rem;
  margin-top: -3rem;
  margin-left: 1rem;
  border-radius: 2rem;
}
@media only screen and (min-width: 767px) {
  .function-structure__txtbox {
    margin-top: 0;
    margin-bottom: 4rem;
    margin-left: -6rem;
    padding: 3rem;
    border-radius: 5rem;
    width: 33rem;
  }
}
.function-structure__txtbox--ttl {
  font-weight: bold;
  font-size: 1.8rem;
}
@media only screen and (min-width: 767px) {
  .function-structure__txtbox--ttl {
    font-size: 2rem;
  }
}
.function-structure__txtbox--txt {
  margin-top: 1rem;
  line-height: 1.7;
}
.function-scene {
  padding: 4rem 0 0;
  background: linear-gradient(to top, #fff 4rem, #F4F4F6 4rem);
}
@media only screen and (min-width: 767px) {
  .function-scene {
    padding: 12rem 0 0;
  }
}
.function-scene__inner {
  background-color: #fff;
  box-shadow: 4px 4px 16px rgba(145, 144, 144, 0.16);
  padding: 4rem 0;
  position: relative;
  border-radius: 4rem;
}
@media only screen and (min-width: 767px) {
  .function-scene__inner {
    border-radius: 10rem;
    padding: 8rem 14rem;
  }
}
.function-scene__txtbox {
  display: flex;
  margin: 0 2rem;
}
@media only screen and (min-width: 767px) {
  .function-scene__txtbox {
    align-items: center;
    margin: 0;
  }
}
.function-scene__txtbox--ttl {
  font-size: 1.4rem;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8rem;
  height: 8rem;
  margin-right: 1rem;
  border-radius: 10rem;
}
@media only screen and (min-width: 767px) {
  .function-scene__txtbox--ttl {
    font-size: 1.8rem;
  }
}
@media only screen and (min-width: 767px) {
  .function-scene__txtbox--ttl {
    width: 12rem;
    height: 12rem;
    margin-right: 2rem;
  }
}
.function-scene__txtbox > div {
  width: calc(100% - 8rem);
}
@media only screen and (min-width: 767px) {
  .function-scene__txtbox > div {
    width: calc(100% - 12rem);
  }
}
.function-scene__txtbox--subttl {
  font-size: 1.8rem;
  font-weight: bold;
}
@media only screen and (min-width: 767px) {
  .function-scene__txtbox--subttl {
    font-size: 2.6rem;
  }
}
.function-scene__txtbox--txt {
  font-size: 1.4rem;
  margin-top: 1rem;
  line-height: 1.8;
}
@media only screen and (min-width: 767px) {
  .function-scene__txtbox--txt {
    font-size: 1.6rem;
  }
}
@media only screen and (min-width: 767px) {
  .function-scene__txtbox--txt {
    margin-top: 2rem;
  }
}
.function-scene__item {
  margin-top: 3rem;
}
@media only screen and (min-width: 767px) {
  .function-scene__item {
    margin-top: 6rem;
  }
}
.function-scene__item:nth-of-type(2) {
  margin-top: 6rem;
}
@media only screen and (min-width: 767px) {
  .function-scene__item:nth-of-type(2) {
    margin-top: 12rem;
  }
}
.function-scene__img {
  width: 100%;
  margin-top: 2rem;
}
.function-scene__img--sc {
  transform: scale(1.03);
}
@media only screen and (min-width: 767px) {
  .function-scene__img--sc {
    transform: scale(1);
  }
}
.function-equipment {
  padding: 6rem 0 8rem;
  text-align: center;
}
@media only screen and (min-width: 767px) {
  .function-equipment {
    padding: 12rem 0;
  }
}
.function-equipment__inner {
  margin-top: 4rem;
  margin-bottom: 4rem;
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 767px) {
  .function-equipment__inner {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 6rem;
    margin-bottom: 8rem;
    align-items: flex-start;
  }
}
.function-equipment__item {
  margin-top: 2rem;
  background-color: #F4F4F6;
  border-radius: 4rem;
  padding: 4rem 0;
  text-align: center;
}
@media only screen and (min-width: 767px) {
  .function-equipment__item {
    border-radius: 5rem;
  }
}
.function-equipment__item:nth-of-type(1) {
  margin-top: 0;
}
@media only screen and (min-width: 767px) {
  .function-equipment__item:nth-of-type(1) {
    width: 34%;
  }
}
@media only screen and (min-width: 767px) {
  .function-equipment__item:nth-of-type(2) {
    width: 63%;
    margin-top: 0;
  }
}
.function-equipment__item > ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.function-equipment__item > ul li {
  width: calc(50% - 2rem);
  text-align: center;
}
@media only screen and (min-width: 767px) {
  .function-equipment__item > ul li {
    width: 33.3333333333%;
  }
}
.function-equipment__ttl {
  font-weight: bold;
  font-size: 1.8rem;
}
@media only screen and (min-width: 767px) {
  .function-equipment__ttl {
    font-size: 2rem;
  }
}
.function-equipment__txt {
  text-align: center;
  margin-top: 2rem;
  font-weight: bold;
}
.function-equipment__img {
  margin-top: 3rem;
  height: 14rem;
}
@media only screen and (min-width: 767px) {
  .function-equipment__btn {
    width: 38rem;
  }
}

.guide-movie {
  padding: 4rem 0 0;
  background: linear-gradient(to top, #fff 4rem, #F4F4F6 4rem);
}
@media only screen and (min-width: 767px) {
  .guide-movie {
    padding: 8rem 0 0;
  }
}
.guide-movie__inner--iframe {
  position: relative;
  width: 100%;
  margin-top: 3rem;
  padding-top: 56.25%;
}
.guide-movie__inner--iframe iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.guide-flow {
  padding: 8rem 0;
}
@media only screen and (min-width: 767px) {
  .guide-flow {
    padding: 12rem 0;
  }
}
@media only screen and (min-width: 767px) {
  .guide-flow__outer {
    width: 68rem;
  }
}
.guide-flow__list {
  margin-top: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
@media only screen and (min-width: 767px) {
  .guide-flow__list {
    margin-top: 5rem;
  }
}
.guide-flow__item {
  background-color: #fff;
  box-shadow: 4px 4px 16px rgba(145, 144, 144, 0.16);
  border-radius: 4rem;
  padding: 3rem 2rem 3rem 7rem;
  position: relative;
  margin-top: 5rem;
  width: calc(100% - 1rem);
}
@media only screen and (min-width: 767px) {
  .guide-flow__item {
    margin-top: 4rem;
    border-radius: 5rem;
  }
}
.guide-flow__item:nth-of-type(1) {
  margin-top: 0;
}
.guide-flow__item:last-of-type::after {
  display: none;
}
.guide-flow__item::after {
  content: "";
  position: absolute;
  background-image: url("../images/common/arrow-bottom-gradation-blue.png");
  background-size: contain;
  margin: auto;
  left: 0;
  right: 0;
  bottom: -3rem;
  width: 2.8rem;
  height: 1.6rem;
  display: flex;
}
@media only screen and (min-width: 767px) {
  .guide-flow__item {
    padding: 4rem 4rem 4rem 12rem;
  }
}
.guide-flow__item--ttl {
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1.3;
}
@media only screen and (min-width: 767px) {
  .guide-flow__item--ttl {
    font-size: 2.4rem;
  }
}
@media only screen and (min-width: 767px) {
  .guide-flow__item--ttl {
    line-height: 1.5;
  }
}
.guide-flow__item--txt {
  font-size: 1.4rem;
  margin-top: 1rem;
  line-height: 1.7;
}
@media only screen and (min-width: 767px) {
  .guide-flow__item--txt {
    font-size: 1.6rem;
  }
}
@media only screen and (min-width: 767px) {
  .guide-flow__item--txt {
    margin-top: 2rem;
  }
}
.guide-flow__item--icon {
  position: absolute;
  left: -1rem;
  top: 1rem;
  width: 6.8rem;
  height: 6.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10rem;
}
@media only screen and (min-width: 767px) {
  .guide-flow__item--icon {
    width: 10rem;
    height: 10rem;
    left: -2rem;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}
.guide-flow__item--icon span {
  color: #fff;
  font-size: 1.4rem;
  font-family: "Barlow", sans-serif;
}
@media only screen and (min-width: 767px) {
  .guide-flow__item--icon span {
    font-size: 2rem;
  }
}
.guide-link__inner {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
@media only screen and (min-width: 767px) {
  .guide-link__inner {
    flex-direction: row;
  }
}
@media only screen and (min-width: 767px) {
  .guide-link__item:nth-of-type(2), .guide-link__item:nth-of-type(3) {
    width: 50%;
  }
}
.guide-link__item > a {
  display: flex;
  align-items: center;
  justify-content: center;
}
.guide-link__item > a:hover {
  opacity: 0.7;
}
.guide-link__box {
  position: absolute;
  z-index: 1;
}
@media only screen and (min-width: 767px) {
  .guide-link__box {
    width: 60rem;
  }
}
.guide-link__box--gradient {
  border-radius: 10rem;
  padding: 2.5rem 3rem;
}
.guide-link__box--ttl {
  color: #fff;
  font-size: 2rem;
  text-align: center;
  font-weight: bold;
}
@media only screen and (min-width: 767px) {
  .guide-link__box--ttl {
    font-size: 3rem;
  }
}
.guide-link__box--txt {
  font-size: 1.2rem;
  color: #fff;
  text-align: center;
  line-height: 1.6;
  margin-top: 1rem;
  font-weight: bold;
}
@media only screen and (min-width: 767px) {
  .guide-link__box--txt {
    font-size: 1.4rem;
  }
}
.guide-link__img {
  width: 100%;
}

.p-pri {
  transition: all 0.3s linear;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media only screen and (min-width: 767px) {
  .p-pri {
    flex-direction: row;
    align-items: flex-end;
  }
}
.p-pri__outer {
  transition: all 0.3s linear;
  margin-right: 10%;
  margin-left: 10%;
}
@media only screen and (min-width: 767px) {
  .p-pri__outer {
    margin-left: 20px;
    margin-right: 20px;
  }
}
.p-pri__h1 {
  transition: all 0.3s linear;
}
@media only screen and (min-width: 767px) {
  .p-pri__h1 {
    width: 50%;
  }
}
@media only screen and (min-width: 1024px) {
  .p-pri__h1 {
    width: auto;
  }
}
.p-pri__h1 > span {
  font-family: "Roboto", sans-serif;
  background-clip: text;
  -webkit-background-clip: text;
  color: rgba(0, 0, 0, 0);
  display: inline-block;
  line-height: 1.4;
  font-size: 2rem;
  background-image: linear-gradient(88.15deg, #286BFE 2.31%, #9616B6 101.86%);
}
@media only screen and (min-width: 767px) {
  .p-pri__h1 > span {
    font-size: 2.3rem;
  }
}
.p-pri__txt {
  transition: all 0.3s linear;
  font-weight: 600;
  line-height: 2;
  color: #000000;
  margin-top: 5rem;
  font-size: 1.2rem;
}
@media only screen and (min-width: 767px) {
  .p-pri__txt {
    /* tablet-size */
    margin-top: 0;
    font-size: 1.3rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-pri__txt {
    /* middle-size */
    font-size: 1.6rem;
  }
}
.p-pri01__outer {
  margin-top: 10rem;
}
.p-pri01__list {
  transition: all 0.3s linear;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
@media only screen and (min-width: 767px) {
  .p-pri01__list {
    /* tablet-size */
    flex-direction: row;
    justify-content: space-between;
  }
}
.p-pri01__item {
  transition: all 0.3s linear;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 2px 7px 20px 2px rgba(0, 0, 0, 0.08);
  padding: 2rem 3rem;
  margin-top: 1rem;
}
@media only screen and (min-width: 767px) {
  .p-pri01__item {
    padding: 2rem 2rem 4rem;
    margin-top: 0;
    width: calc(33.3333333333% - 1rem);
  }
}
@media only screen and (min-width: 1024px) {
  .p-pri01__item {
    /* middle-size */
    padding: 3rem 3rem 5rem;
  }
}
.p-pri01__box02, .p-pri01__box03 {
  transition: all 0.3s linear;
  padding-top: 2rem;
  margin-top: 2rem;
  border-top: 1px solid #D9D9D9;
}
@media only screen and (min-width: 1024px) {
  .p-pri01__box02, .p-pri01__box03 {
    /* middle-size */
    padding-top: 3rem;
    margin-top: 3rem;
  }
}
.p-pri01__box01__txt {
  transition: all 0.3s linear;
  font-size: 4rem;
  margin-top: 3rem;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  display: flex;
  align-items: baseline;
  justify-content: center;
}
@media only screen and (min-width: 767px) {
  .p-pri01__box01__txt {
    /* tablet-size */
    margin-top: 3rem;
    font-size: 3rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-pri01__box01__txt {
    /* middle-size */
    margin-top: 4rem;
    font-size: 4.5rem;
  }
}
.p-pri01__box01__txt span {
  transition: all 0.3s linear;
  font-size: 1.4rem;
}
@media only screen and (min-width: 767px) {
  .p-pri01__box01__txt span {
    /* tablet-size */
    font-size: 1.2rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-pri01__box01__txt span {
    /* middle-size */
    font-size: 2rem;
  }
}
.p-pri01__box02__txt {
  transition: all 0.3s linear;
  margin-top: 1.5rem;
  font-family: "Roboto", sans-serif;
  font-size: 4rem;
  font-weight: 600;
  display: flex;
  align-items: baseline;
  justify-content: center;
  font-size: 4rem;
}
@media only screen and (min-width: 767px) {
  .p-pri01__box02__txt {
    font-size: 4.5rem;
  }
}
@media only screen and (min-width: 767px) {
  .p-pri01__box02__txt {
    /* tablet-size */
    font-size: 3.5rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-pri01__box02__txt {
    /* middle-size */
    font-size: 4.5rem;
  }
}
.p-pri01__box02__txt span {
  transition: all 0.3s linear;
  font-size: 2rem;
}
@media only screen and (min-width: 767px) {
  .p-pri01__box02__txt span {
    /* tablet-size */
    font-size: 2rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-pri01__box02__txt span {
    /* middle-size */
    font-size: 3rem;
  }
}
.p-pri01__box03__txt {
  transition: all 0.3s linear;
  margin-top: 1.5rem;
  font-family: "Roboto", sans-serif;
  font-size: 2.2rem;
  font-weight: 600;
  display: flex;
  align-items: baseline;
  justify-content: center;
}
@media only screen and (min-width: 767px) {
  .p-pri01__box03__txt {
    font-size: 3rem;
  }
}
.p-pri01__ttl, .p-pri01__ttl-pink, .p-pri01__ttl-purple, .p-pri01__ttl-blue {
  transition: all 0.3s linear;
  font-size: 2rem;
  text-align: center;
  color: #fff;
  font-weight: 600;
  padding: 1rem;
  border-radius: 100px;
}
@media only screen and (min-width: 767px) {
  .p-pri01__ttl, .p-pri01__ttl-pink, .p-pri01__ttl-purple, .p-pri01__ttl-blue {
    /* tablet-size */
    font-size: 1.6rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-pri01__ttl, .p-pri01__ttl-pink, .p-pri01__ttl-purple, .p-pri01__ttl-blue {
    /* middle-size */
    font-size: 2rem;
  }
}
.p-pri01__ttl-blue {
  background-color: #00A2FA;
}
.p-pri01__ttl-purple {
  background-color: #6024AD;
}
.p-pri01__ttl-pink {
  background-color: #E93A83;
}
.p-pri01__subttl {
  transition: all 0.3s linear;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0.07em;
}
@media only screen and (min-width: 767px) {
  .p-pri01__subttl {
    /* tablet-size */
    font-size: 1.6rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-pri01__subttl {
    /* middle-size */
    font-size: 1.9rem;
  }
}
.p-pri02__outer {
  transition: all 0.3s linear;
  margin-top: 10rem;
}
@media only screen and (min-width: 767px) {
  .p-pri02__outer {
    /* tablet-size */
    margin-top: 15rem;
  }
}
.p-pri02__ttl {
  transition: all 0.3s linear;
  font-size: 2.1rem;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0.07em;
}
@media only screen and (min-width: 767px) {
  .p-pri02__ttl {
    font-size: 3.5rem;
  }
}
.p-pri02__box {
  transition: all 0.3s linear;
  background-color: #FFFFFF;
  box-shadow: 8px 10px 33px rgba(0, 0, 0, 0.08);
  border-radius: 15px;
  padding: 6rem 2.5rem 3rem;
  margin-top: 4rem;
}
@media only screen and (min-width: 767px) {
  .p-pri02__box {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    padding: 6rem 0;
    margin-top: 10rem;
    border-radius: 30px;
  }
}
.p-pri02__img {
  transition: all 0.3s linear;
  width: 100%;
}
@media only screen and (min-width: 767px) {
  .p-pri02__img {
    /* tablet-size */
    width: 45%;
  }
}
@media only screen and (min-width: 1024px) {
  .p-pri02__img {
    /* middle-size */
    width: 54.2rem;
  }
}
.p-pri02__txtbox {
  transition: all 0.3s linear;
  margin-top: 4rem;
}
@media only screen and (min-width: 767px) {
  .p-pri02__txtbox {
    /* tablet-size */
    margin-top: 0;
  }
}
.p-pri02__txt01 {
  transition: all 0.3s linear;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.2;
  color: #000000;
}
@media only screen and (min-width: 767px) {
  .p-pri02__txt01 {
    /* tablet-size */
    line-height: 1.5;
  }
}
@media only screen and (min-width: 1024px) {
  .p-pri02__txt01 {
    /* middle-size */
    font-size: 2.5rem;
  }
}
.p-pri02__txt01 span {
  transition: all 0.3s linear;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.2;
}
@media only screen and (min-width: 767px) {
  .p-pri02__txt01 span {
    font-size: 2rem;
  }
}
.p-pri02__txt02 {
  transition: all 0.3s linear;
  font-family: "Roboto", sans-serif;
  font-size: 4.5rem;
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  margin-top: 2rem;
  font-weight: 600;
  font-size: 4.5rem;
}
@media only screen and (min-width: 767px) {
  .p-pri02__txt02 {
    /* tablet-size */
    font-size: 4.8rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-pri02__txt02 {
    /* middle-size */
    font-size: 5.3rem;
  }
}
.p-pri02__txt02 span {
  transition: all 0.3s linear;
  font-size: 1.4rem;
}
@media only screen and (min-width: 767px) {
  .p-pri02__txt02 span {
    /* tablet-size */
    font-size: 1.9rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-pri02__txt02 span {
    /* middle-size */
    font-size: 2.4rem;
  }
}
.p-pri03__outer {
  transition: all 0.3s linear;
  margin-top: 10rem;
  padding-bottom: 6.5rem;
}
@media only screen and (min-width: 767px) {
  .p-pri03__outer {
    margin-left: 20px;
    margin-right: 20px;
  }
}
@media only screen and (min-width: 1024px) {
  .p-pri03__outer {
    /* middle-size */
    margin-left: calc((100% - 1030px) / 2);
    margin-right: calc((100% - 1030px) / 2);
    padding-bottom: 8rem;
  }
}
.p-pri03__ttl {
  transition: all 0.3s linear;
  font-size: 2.9rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-weight: 600;
  letter-spacing: 0.07em;
}
@media only screen and (min-width: 767px) {
  .p-pri03__ttl {
    font-size: 5rem;
  }
}
.p-pri03__ttl img {
  transition: all 0.3s linear;
  height: 1.9rem;
  object-fit: contain;
  margin-bottom: 1rem;
}
@media only screen and (min-width: 767px) {
  .p-pri03__ttl img {
    /* tablet-size */
    height: 2.4rem;
    margin-bottom: 2rem;
  }
}
.p-pri03__olist {
  transition: all 0.3s linear;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 80%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 4rem;
  border-bottom: 0.15rem solid #EBEBEB;
}
@media only screen and (min-width: 767px) {
  .p-pri03__olist {
    /* tablet-size */
    width: 100%;
    margin-top: 7rem;
  }
}
.p-pri03__item {
  transition: all 0.3s linear;
  border-top: 0.15rem solid #EBEBEB;
  padding: 2rem 2%;
}
@media only screen and (min-width: 767px) {
  .p-pri03__item {
    /* tablet-size */
    padding: 4rem 0;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
  }
}
.p-pri03__item:nth-of-type(1) {
  margin-top: 0;
}
.p-pri03__txtbox {
  transition: all 0.3s linear;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
@media only screen and (min-width: 767px) {
  .p-pri03__txtbox {
    /* tablet-size */
    align-items: flex-start;
    order: 2;
    width: 100%;
  }
}
.p-pri03__num, .p-pri03__num-purple, .p-pri03__num-pink, .p-pri03__num-blue, .p-pri03__num-lightblue {
  transition: all 0.3s linear;
  font-family: "Roboto", sans-serif;
  font-size: 1.1rem;
  font-weight: 500;
}
@media only screen and (min-width: 767px) {
  .p-pri03__num, .p-pri03__num-purple, .p-pri03__num-pink, .p-pri03__num-blue, .p-pri03__num-lightblue {
    font-size: 1.7rem;
  }
}
.p-pri03__num-lightblue {
  color: #00A2FA;
}
.p-pri03__num-blue {
  color: #365DFF;
}
.p-pri03__num-pink {
  color: #E93A83;
}
.p-pri03__num-purple {
  color: #6024AD;
}
.p-pri03__subttl {
  transition: all 0.3s linear;
  font-size: 1.8rem;
  font-weight: 600;
  color: #000000;
  margin-top: 1rem;
}
@media only screen and (min-width: 767px) {
  .p-pri03__subttl {
    font-size: 2.3rem;
  }
}
.p-pri03__img {
  transition: all 0.3s linear;
  width: 18rem;
  margin: 4rem auto 0;
  display: block;
}
@media only screen and (min-width: 767px) {
  .p-pri03__img {
    /* tablet-size */
    order: 1;
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 3rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-pri03__img {
    /* middle-size */
    margin-right: 8rem;
    margin-left: 10rem;
  }
}
.p-pri03__txt {
  transition: all 0.3s linear;
  margin-top: 3rem;
  font-weight: 500;
  line-height: 1.5;
}
@media only screen and (min-width: 767px) {
  .p-pri03__txt {
    /* tablet-size */
    margin-top: 1.5rem;
  }
}

.p-fun {
  transition: all 0.3s linear;
}
@media only screen and (min-width: 1024px) {
  .p-fun {
    margin-left: calc((100% - 1215px) / 2);
    margin-right: calc((100% - 1215px) / 2);
  }
}
.p-fun__outer {
  transition: all 0.3s linear;
}
@media only screen and (min-width: 1024px) {
  .p-fun__outer {
    background-image: url(../../assets/images/function/fun_fv-pc.jpg);
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 64rem;
  }
}
@media only screen and (min-width: 1440px) {
  .p-fun__outer {
    margin-left: calc((100% - 1440px) / 2);
    margin-right: calc((100% - 1440px) / 2);
  }
}
.p-fun__h1 {
  transition: all 0.3s linear;
  margin-right: 10%;
  margin-left: 10%;
}
@media only screen and (min-width: 767px) {
  .p-fun__h1 {
    width: 50%;
    margin-right: 2rem;
    margin-left: 2rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-fun__h1 {
    width: auto;
    margin-right: 0;
    margin-left: 0;
  }
}
.p-fun__h1 > span {
  font-family: "Roboto", sans-serif;
  background-clip: text;
  -webkit-background-clip: text;
  color: rgba(0, 0, 0, 0);
  display: inline-block;
  line-height: 1.4;
  font-size: 2rem;
  background-image: linear-gradient(88.15deg, #EB3E83 2.31%, #9616B6 101.86%);
}
@media only screen and (min-width: 767px) {
  .p-fun__h1 > span {
    font-size: 2.3rem;
  }
}
.p-fun__img {
  transition: all 0.3s linear;
  width: 100%;
  display: block;
  margin-top: 3rem;
}
@media only screen and (min-width: 1024px) {
  .p-fun__img {
    display: none;
  }
}
.p-fun01 {
  transition: all 0.3s linear;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
@media only screen and (min-width: 1024px) {
  .p-fun01 {
    margin-left: calc((100% - 1215px) / 2);
    margin-right: calc((100% - 1215px) / 2);
  }
}
.p-fun01__outer {
  transition: all 0.3s linear;
  padding-top: 9.5rem;
  padding-bottom: 7rem;
  background-position: 50% 0px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-image: url(../../assets/images/function/fun01_bg-sp.jpg), linear-gradient(11.16deg, #F7F7F7 4%, #F8F8F8 49.02%, #FFFFFF 94.04%);
}
@media only screen and (min-width: 1024px) {
  .p-fun01__outer {
    /* middle-size */
    padding-top: 36.4rem;
    background-image: url(../../assets/images/function/fun01_bg-pc.jpg), linear-gradient(11.16deg, #F7F7F7 4%, #F8F8F8 49.02%, #FFFFFF 94.04%);
    background-size: 144rem, cover;
  }
}
.p-fun01__box {
  transition: all 0.3s linear;
  padding: 3rem 4rem 6rem;
  background: #FFFFFF;
  box-shadow: 8px 10px 33px rgba(0, 0, 0, 0.08);
  border-radius: 15px;
}
@media only screen and (min-width: 1024px) {
  .p-fun01__box {
    /* middle-size */
    padding: 6rem 11.3rem 12rem;
    border-radius: 30px;
  }
}
.p-fun01__ttl {
  transition: all 0.3s linear;
  font-weight: 600;
  background-clip: text;
  -webkit-background-clip: text;
  color: rgba(0, 0, 0, 0);
  display: inline-block;
  line-height: 1.4;
  background-image: linear-gradient(90deg, #086EDE -7.85%, #545DBC 25.85%, #984F9E 63.49%, #FF4AA1 100%);
  font-size: 2.4rem;
  font-weight: 600;
  margin-left: -2rem;
}
@media only screen and (min-width: 767px) {
  .p-fun01__ttl {
    /* tablet-size */
    font-size: 4rem;
    margin-left: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .p-fun01__ttl {
    /* middle-size */
    font-size: 5.5rem;
  }
}
.p-fun01__olist {
  transition: all 0.3s linear;
  margin-top: 3.5rem;
}
@media only screen and (min-width: 1024px) {
  .p-fun01__olist {
    /* middle-size */
    margin-top: 5.5rem;
  }
}
.p-fun01__item {
  transition: all 0.3s linear;
  margin-top: 6rem;
}
@media only screen and (min-width: 767px) {
  .p-fun01__item {
    /* tablet-size */
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
}
@media only screen and (min-width: 1024px) {
  .p-fun01__item {
    /* middle-size */
    align-items: center;
  }
}
.p-fun01__item:nth-of-type(1) {
  margin-top: 0;
}
.p-fun01__img {
  transition: all 0.3s linear;
  margin-top: 3rem;
  border-radius: 6px;
  width: 100%;
}
@media only screen and (min-width: 767px) {
  .p-fun01__img {
    /* tablet-size */
    order: 1;
    margin-top: 0;
    width: 30.5rem;
    margin-right: 3rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-fun01__img {
    /* middle-size */
    width: 50.5rem;
    margin-right: 5rem;
  }
}
.p-fun01__txtbox {
  transition: all 0.3s linear;
}
@media only screen and (min-width: 767px) {
  .p-fun01__txtbox {
    /* tablet-size */
    order: 2;
  }
}
@media only screen and (min-width: 1024px) {
  .p-fun01__txtbox {
    /* middle-size */
    width: 100%;
  }
}
.p-fun01__point, .p-fun01__point-pink, .p-fun01__point-purple, .p-fun01__point-blue {
  transition: all 0.3s linear;
  font-family: "Oswald", sans-serif;
  display: inline-block;
  color: #fff;
  font-weight: 400;
  font-size: 1.7rem;
  padding: 0.2rem 1.5rem;
  border-radius: 100px;
  font-size: 1.4rem;
}
@media only screen and (min-width: 767px) {
  .p-fun01__point, .p-fun01__point-pink, .p-fun01__point-purple, .p-fun01__point-blue {
    /* tablet-size */
    font-size: 1.5rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-fun01__point, .p-fun01__point-pink, .p-fun01__point-purple, .p-fun01__point-blue {
    /* middle-size */
    font-size: 1.7rem;
  }
}
.p-fun01__point-blue {
  background: #079AF3;
  background: linear-gradient(to right, #0170E0 0%, #079AF3 100%);
}
.p-fun01__point-purple {
  background: #9752A7;
  background: linear-gradient(to right, #8355B3 0%, #9752A7 100%);
}
.p-fun01__point-pink {
  background: #E34081;
  background: linear-gradient(to right, #EB5985 0%, #E34081 100%);
}
.p-fun01__subttl, .p-fun01__subttl-pink, .p-fun01__subttl-purple, .p-fun01__subttl-blue {
  transition: all 0.3s linear;
  font-size: 1.7rem;
  font-weight: 600;
  line-height: 1.5;
}
@media only screen and (min-width: 767px) {
  .p-fun01__subttl, .p-fun01__subttl-pink, .p-fun01__subttl-purple, .p-fun01__subttl-blue {
    /* tablet-size */
    font-size: 1.8rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-fun01__subttl, .p-fun01__subttl-pink, .p-fun01__subttl-purple, .p-fun01__subttl-blue {
    /* middle-size */
    font-size: 2.8rem;
  }
}
.p-fun01__subttl-blue {
  color: #0271e1;
}
.p-fun01__subttl-purple {
  color: #8456B4;
}
.p-fun01__subttl-pink {
  color: #E44182;
}
.p-fun01__subttl em, .p-fun01__subttl-blue em, .p-fun01__subttl-purple em, .p-fun01__subttl-pink em {
  transition: all 0.3s linear;
  display: block;
  margin-top: 1.5rem;
}
@media only screen and (min-width: 767px) {
  .p-fun01__subttl em, .p-fun01__subttl-blue em, .p-fun01__subttl-purple em, .p-fun01__subttl-pink em {
    /* tablet-size */
    font-size: 1.4rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-fun01__subttl em, .p-fun01__subttl-blue em, .p-fun01__subttl-purple em, .p-fun01__subttl-pink em {
    /* middle-size */
    font-size: 2.8rem;
  }
}
.p-fun01__txt {
  transition: all 0.3s linear;
  font-weight: 600;
  line-height: 1.7;
  margin-top: 1.5rem;
}
@media only screen and (min-width: 767px) {
  .p-fun01__txt {
    /* tablet-size */
    font-size: 1.4rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-fun01__txt {
    /* middle-size */
    font-size: 1.6rem;
  }
}
.p-fun02 {
  transition: all 0.3s linear;
}
@media only screen and (min-width: 1024px) {
  .p-fun02 {
    margin-left: calc((100% - 1215px) / 2);
    margin-right: calc((100% - 1215px) / 2);
  }
}
.p-fun02__outer {
  transition: all 0.3s linear;
  padding: 6rem 0 13rem;
}
@media only screen and (min-width: 1024px) {
  .p-fun02__outer {
    /* middle-size */
    padding: 12rem 0 25rem;
  }
}
.p-fun02__ttl {
  transition: all 0.3s linear;
  text-align: center;
  font-weight: 600;
  font-size: 1.9rem;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.04em;
  flex-direction: column;
  flex-wrap: wrap;
  line-height: 1.2;
}
@media only screen and (min-width: 767px) {
  .p-fun02__ttl {
    /* tablet-size */
    font-size: 2.9rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-fun02__ttl {
    /* middle-size */
    font-size: 3.9rem;
  }
}
.p-fun02__ttl span {
  transition: all 0.3s linear;
  color: #0271e0;
  font-size: 1.4rem;
  display: block;
  margin-bottom: 1rem;
}
@media only screen and (min-width: 767px) {
  .p-fun02__ttl span {
    /* tablet-size */
    font-size: 2rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-fun02__ttl span {
    /* middle-size */
    font-size: 2.7rem;
  }
}
.p-fun02__box {
  transition: all 0.3s linear;
  background: #FFFFFF;
  box-shadow: 8px 10px 33px rgba(0, 0, 0, 0.08);
  border-radius: 30px;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  margin-top: 2.6rem;
  padding: 3.3rem 2rem 0;
}
@media only screen and (min-width: 767px) {
  .p-fun02__box {
    /* tablet-size */
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
}
@media only screen and (min-width: 1024px) {
  .p-fun02__box {
    /* middle-size */
    padding: 3rem 3rem 0 7rem;
    margin-top: 5rem;
    margin-right: 0;
    margin-left: 0;
  }
}
.p-fun02__txtbox {
  transition: all 0.3s linear;
}
@media only screen and (min-width: 767px) {
  .p-fun02__txtbox {
    /* tablet-size */
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 3rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-fun02__txtbox {
    /* middle-size */
    margin-right: 7rem;
  }
}
.p-fun02__subttl {
  transition: all 0.3s linear;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 1.6;
}
@media only screen and (min-width: 767px) {
  .p-fun02__subttl {
    /* tablet-size */
    font-size: 2rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-fun02__subttl {
    /* middle-size */
    line-height: 2;
    font-size: 2.4rem;
  }
}
.p-fun02__txt {
  transition: all 0.3s linear;
  margin-top: 3rem;
  margin-right: 1rem;
  margin-left: 1rem;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.5;
}
@media only screen and (min-width: 767px) {
  .p-fun02__txt {
    /* tablet-size */
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-size: 1.5rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-fun02__txt {
    /* middle-size */
    font-size: 1.7rem;
    margin-top: 4rem;
    margin-right: 0;
    line-height: 2;
    margin-left: 0;
  }
}
.p-fun02__img {
  transition: all 0.3s linear;
  width: 100%;
  margin-top: 4rem;
  transform: scale(1.05);
  transform-origin: 50% 100%;
}
@media only screen and (min-width: 767px) {
  .p-fun02__img {
    /* tablet-size */
    width: 30rem;
    margin-top: 0;
    transform: scale(1);
  }
}
@media only screen and (min-width: 1024px) {
  .p-fun02__img {
    /* middle-size */
    width: 64rem;
  }
}
.p-fun03 {
  transition: all 0.3s linear;
}
@media only screen and (min-width: 1024px) {
  .p-fun03 {
    margin-left: calc((100% - 1215px) / 2);
    margin-right: calc((100% - 1215px) / 2);
  }
}
.p-fun03__ttl {
  transition: all 0.3s linear;
  transform: translateY(-50%) !important;
  color: #fff;
  border-radius: 8px;
  font-weight: 600;
  font-size: 2rem;
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  letter-spacing: 0.13em;
  display: flex;
  width: 25.5rem;
  justify-content: center;
  margin: auto;
  background-color: #2D313D;
}
@media only screen and (min-width: 1024px) {
  .p-fun03__ttl {
    /* middle-size */
    width: 100%;
    font-size: 2.2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}
.p-fun03__outer {
  transition: all 0.3s linear;
  padding-bottom: 5rem;
  background: linear-gradient(21.5deg, #F7F7F7 4.38%, #F8F8F8 95.73%);
}
@media only screen and (min-width: 1024px) {
  .p-fun03__outer {
    /* middle-size */
    padding-bottom: 9rem;
  }
}
.p-fun03__list {
  transition: all 0.3s linear;
  margin-right: 3.5rem;
  margin-left: 3.5rem;
  margin-top: 3.5rem;
}
@media only screen and (min-width: 767px) {
  .p-fun03__list {
    /* tablet-size */
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 1.5rem;
    margin-left: 1.5rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-fun03__list {
    /* middle-size */
    margin-right: 0;
    margin-left: 0;
    margin-top: 3rem;
  }
}
.p-fun03__item {
  transition: all 0.3s linear;
  border-top: 1.5px solid #EBEBEB;
  margin-top: 6rem;
  padding-top: 6rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
@media only screen and (min-width: 767px) {
  .p-fun03__item {
    /* tablet-size */
    width: calc(25% - 1.5rem);
    border-top: 0;
    margin-top: 0;
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .p-fun03__item {
    /* middle-size */
    width: calc(25% - 3.5rem);
  }
}
.p-fun03__item:nth-of-type(1) {
  border-top: none;
  margin-top: 0;
  padding-top: 0;
}
.p-fun03__img {
  width: 100%;
  margin-top: 3rem;
  display: block;
  border-radius: 15px;
  filter: drop-shadow(0px 8px 15px rgba(56, 56, 56, 0.3));
}
.p-fun03__subttl {
  position: relative;
}
.p-fun03__subttl::after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  width: 2.8rem;
  height: 0.2rem;
  margin: auto;
  background-color: #2D313D;
}
.p-fun03__subttl span {
  transition: all 0.3s linear;
  font-weight: 600;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 1rem;
}
@media only screen and (min-width: 767px) {
  .p-fun03__subttl span {
    /* tablet-size */
    font-size: 1.8rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-fun03__subttl span {
    /* middle-size */
    font-size: 2.4rem;
    padding-bottom: 1.5rem;
  }
}
.p-fun03__txt {
  transition: all 0.3s linear;
  margin-top: 3rem;
  text-align: center !important;
  font-weight: 600;
  font-size: 1.5rem;
}
@media only screen and (min-width: 767px) {
  .p-fun03__txt {
    /* tablet-size */
    margin-top: 2rem;
    font-size: 1.3rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-fun03__txt {
    /* middle-size */
    font-size: 1.7rem;
  }
}
.p-fun03__txt span {
  color: #0271E0;
}

.p-abo01 {
  transition: all 0.3s linear;
}
@media only screen and (min-width: 767px) {
  .p-abo01 {
    /* tablet-size */
    display: flex;
    justify-content: space-between;
  }
}
.p-abo01__outer {
  transition: all 0.3s linear;
  margin-top: 6rem;
  margin-right: 3.5rem;
  margin-left: 3.5rem;
}
@media only screen and (min-width: 767px) {
  .p-abo01__outer {
    /* tablet-size */
    margin-top: 8rem;
    margin-right: 0;
    margin-left: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo01__outer {
    /* middle-size */
    margin-top: 10.5rem;
  }
}
.p-abo01__img {
  transition: all 0.3s linear;
  width: 100%;
  margin-top: 3rem;
}
@media only screen and (min-width: 767px) {
  .p-abo01__img {
    /* tablet-size */
    width: 50%;
    object-fit: contain;
    margin-top: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo01__img {
    /* middle-size */
    width: 75.5rem;
    padding-right: 8rem;
  }
}
.p-abo01__txtbox {
  transition: all 0.3s linear;
}
@media only screen and (min-width: 767px) {
  .p-abo01__txtbox {
    /* tablet-size */
    width: calc(50% - 3rem);
    padding-right: 3rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo01__txtbox {
    /* middle-size */
    width: calc(50% - 5.5rem);
    padding-right: 11rem;
  }
}
.p-abo01__ttl {
  font-weight: 700;
  letter-spacing: 0.05em;
}
.p-abo01__ttl__top {
  transition: all 0.3s linear;
  font-size: 1.9rem;
}
@media only screen and (min-width: 767px) {
  .p-abo01__ttl__top {
    /* tablet-size */
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo01__ttl__top {
    /* middle-size */
    font-size: 2.8rem;
  }
}
.p-abo01__ttl__top.-en {
  line-height: 1.3;
}
.p-abo01__ttl__bottom {
  transition: all 0.3s linear;
  font-size: 2.8rem;
  margin-top: 0.5rem;
}
@media only screen and (min-width: 767px) {
  .p-abo01__ttl__bottom {
    /* tablet-size */
    font-size: 3.9rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo01__ttl__bottom {
    /* middle-size */
    font-size: 5rem;
  }
}
.p-abo01__ttl__bottom.-en {
  margin-top: 1.5rem;
}
.p-abo01__ttl-dot {
  position: relative;
  color: #0172E0;
}
.p-abo01__ttl-dot::before {
  content: "・";
  position: absolute;
  top: -0.8em;
  left: 0;
}
.p-abo01__ttl-gradation {
  transition: all 0.3s linear;
  background-clip: text;
  -webkit-background-clip: text;
  color: rgba(0, 0, 0, 0);
  display: inline-block;
  line-height: 1.4;
  font-size: 5.5rem;
  background-image: linear-gradient(90deg, #016EDF 5.09%, #F9398E 99.99%, rgba(45, 49, 61, 0) 100%);
}
@media only screen and (min-width: 767px) {
  .p-abo01__ttl-gradation {
    /* tablet-size */
    font-size: 5rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo01__ttl-gradation {
    /* middle-size */
    font-size: 8.2rem;
  }
}
.p-abo01__txt {
  transition: all 0.3s linear;
  margin-top: 3rem;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 2;
}
@media only screen and (min-width: 767px) {
  .p-abo01__txt {
    /* tablet-size */
    margin-top: 1rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo01__txt {
    /* middle-size */
    margin-top: 3rem;
    width: 90%;
    font-size: 1.6rem;
    line-height: 2.2;
  }
}
.p-abo01__btn {
  transition: all 0.3s linear;
  transition: all 0.3s linear;
  font-size: 1.6rem;
  background: linear-gradient(93.4deg, #1586F8 37.21%, #3AB0F3 102.05%);
  margin-top: 4rem;
  padding: 2rem 0 2rem 3.6rem;
  border-radius: 10rem;
  display: block;
  font-weight: 600;
  border-style: solid;
  border-width: 0.2rem;
  position: relative;
  color: #fff;
}
@media only screen and (min-width: 767px) {
  .p-abo01__btn {
    /* tablet-size */
    margin-top: 2rem;
    font-size: 1.7rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo01__btn {
    width: 30rem;
    margin-top: 4rem;
    padding: 2.1rem 0 2.1rem 3.3rem;
    font-size: 1.4rem;
  }
}
.p-abo01__btn.-en {
  font-size: 1.3rem;
  padding: 2rem 0 2rem 2rem;
}
@media only screen and (min-width: 1024px) {
  .p-abo01__btn.-en {
    width: 34rem;
  }
}
.p-abo01__btn::before, .p-abo01__btn::after {
  transition: all 0.3s linear;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}
.p-abo01__btn::before {
  transition: all 0.3s linear;
  display: block;
  right: 6.5rem;
  width: 0.1rem;
  height: calc(100% - 2.2rem);
  content: "";
  background-color: #fff;
}
@media only screen and (min-width: 1024px) {
  .p-abo01__btn::before {
    /* tablet-size */
    height: calc(100% - 3rem);
  }
}
.p-abo01__btn::after {
  transition: all 0.3s linear;
  font-family: "Font Awesome 6 Free";
  display: inline-block;
  font-weight: 900;
  content: "\f054";
  right: 2.6rem;
  display: flex;
  align-items: center;
}
.p-abo01__btn:hover:hover {
  filter: brightness(120%);
}
.p-abo01__ttl2 {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 5rem;
}
@media only screen and (min-width: 767px) {
  .p-abo01__ttl2 {
    width: 100%;
    font-size: 2.9rem;
    margin-bottom: 11rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo01__ttl2 {
    font-size: 4rem;
    margin-bottom: 17rem;
  }
}
.p-abo01__ttl2 img {
  height: 2.9rem;
  margin: 0 0.5rem;
}
@media only screen and (min-width: 767px) {
  .p-abo01__ttl2 img {
    height: 4.6rem;
    margin: 0 1rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo01__ttl2 img {
    height: 6.4rem;
  }
}
.p-abo02__outer {
  margin-top: 13rem;
}
@media only screen and (min-width: 1024px) {
  .p-abo02__outer {
    /* middle-size */
    margin-top: 17rem;
  }
}
.p-abo02__ttl {
  transition: all 0.3s linear;
  font-weight: 700;
  font-size: 1.5rem;
  text-align: center;
  line-height: 1.9;
}
@media only screen and (min-width: 767px) {
  .p-abo02__ttl {
    /* tablet-size */
    font-size: 2.1rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo02__ttl {
    /* middle-size */
    font-size: 2.7rem;
    width: min(100% - 40px, 1075px);
    margin-right: auto;
    margin-left: auto;
  }
}
.p-abo02__ttl-gradation {
  background-clip: text;
  -webkit-background-clip: text;
  color: rgba(0, 0, 0, 0);
  display: inline-block;
  line-height: 1.4;
  background-image: linear-gradient(180deg, #343C5B 0%, #A4A9BB 100%);
}
.p-abo02__ttl01 {
  text-align: center;
  margin-left: 20px;
  margin-right: 20px;
}
.p-abo02__ttl02 {
  transition: all 0.3s linear;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  line-height: 2.4;
}
@media only screen and (min-width: 767px) {
  .p-abo02__ttl02 {
    /* tablet-size */
    flex-direction: row;
    align-items: flex-end;
    line-height: 1;
  }
}
.p-abo02__ttl02 span {
  transition: all 0.3s linear;
  font-size: 2.4rem;
}
@media only screen and (min-width: 767px) {
  .p-abo02__ttl02 span {
    /* tablet-size */
    font-size: 3.3rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo02__ttl02 span {
    /* middle-size */
    font-size: 4.2rem;
  }
}
.p-abo02__ttl02 img {
  transition: all 0.3s linear;
  width: 26.2rem;
  margin: auto;
}
@media only screen and (min-width: 767px) {
  .p-abo02__ttl02 img {
    /* tablet-size */
    margin: 0;
    width: 31rem;
    object-fit: contain;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo02__ttl02 img {
    /* middle-size */
    width: 35.7rem;
  }
}
.p-abo02__ttl02.-en span {
  margin-left: 1rem;
}
.p-abo02__wrap {
  background: linear-gradient(180deg, #fff 6rem, #F7F7F7 0);
}
.p-abo02__ulist {
  transition: all 0.3s linear;
  margin-top: 4rem;
  padding-right: 4rem;
  padding-left: 4rem;
}
@media only screen and (min-width: 767px) {
  .p-abo02__ulist {
    /* tablet-size */
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo02__ulist {
    /* middle-size */
    padding-right: 0;
    padding-left: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 6rem;
    width: min(100% - 40px, 1075px);
  }
}
.p-abo02__item {
  transition: all 0.3s linear;
  margin-top: 2.2rem;
}
@media only screen and (min-width: 767px) {
  .p-abo02__item {
    /* tablet-size */
    margin-top: 0;
    width: calc(33.3333333333% - 1rem);
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo02__item {
    /* middle-size */
    width: calc(33.3333333333% - 2rem);
  }
}
.p-abo02__item:nth-of-type(1) {
  margin-top: 0;
}
.p-abo02__item img {
  width: 100%;
  filter: drop-shadow(1px 3px 5px #9599b7);
}
.p-abo03 {
  transition: all 0.3s linear;
  margin-right: 1.5rem;
  margin-left: 1.5rem;
  background: #FFFFFF;
  box-shadow: 8px 10px 33px rgba(0, 0, 0, 0.08);
  border-radius: 15px;
  padding: 0 0 2rem 0;
}
@media only screen and (min-width: 767px) {
  .p-abo03 {
    /* tablet-size */
    margin-right: 3rem;
    margin-left: 3rem;
    padding-bottom: 5rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo03 {
    /* middle-size */
    width: min(100% - 40px, 1120px);
    padding-bottom: 9rem;
    margin: auto;
    border-radius: 30px;
  }
}
.p-abo03__outer {
  transition: all 0.3s linear;
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: #F7F7F7;
}
@media only screen and (min-width: 767px) {
  .p-abo03__outer {
    /* tablet-size */
    padding-top: 8rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo03__outer {
    /* middle-size */
    padding-bottom: 14rem;
    padding-top: 12rem;
  }
}
.p-abo03__ttl {
  transition: all 0.3s linear;
  font-size: 2.6rem;
  font-weight: 700;
  text-align: center;
  transform: translateY(-50%) !important;
  letter-spacing: 0.05em;
}
@media only screen and (min-width: 767px) {
  .p-abo03__ttl {
    /* tablet-size */
    font-size: 3.5rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo03__ttl {
    /* middle-size */
    font-size: 4.5rem;
  }
}
.p-abo03__olist {
  transition: all 0.3s linear;
  margin-right: 4rem;
  margin-left: 4rem;
  margin-top: 2rem;
}
.p-abo03__olist__item {
  transition: all 0.3s linear;
  margin-top: 5rem;
}
@media only screen and (min-width: 767px) {
  .p-abo03__olist__item {
    /* tablet-size */
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }
}
.p-abo03__olist__item:nth-of-type(1) {
  margin-top: 0;
}
@media only screen and (min-width: 767px) {
  .p-abo03__olist__item:nth-of-type(2n) {
    /* tablet-size */
    flex-direction: row;
  }
}
.p-abo03__olist__txtbox {
  transition: all 0.3s linear;
}
@media only screen and (min-width: 767px) {
  .p-abo03__olist__txtbox {
    /* tablet-size */
    width: calc(50% - 3rem);
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo03__olist__txtbox {
    /* middle-size */
  }
}
.p-abo03__olist__ttl, .p-abo03__olist__ttl-blue, .p-abo03__olist__ttl-purple, .p-abo03__olist__ttl-pink {
  transition: all 0.3s linear;
  background-clip: text;
  -webkit-background-clip: text;
  color: rgba(0, 0, 0, 0);
  display: inline-block;
  line-height: 1.4;
  font-weight: 700;
  position: relative;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
}
@media only screen and (min-width: 767px) {
  .p-abo03__olist__ttl, .p-abo03__olist__ttl-blue, .p-abo03__olist__ttl-purple, .p-abo03__olist__ttl-pink {
    /* tablet-size */
    font-size: 1.5rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo03__olist__ttl, .p-abo03__olist__ttl-blue, .p-abo03__olist__ttl-purple, .p-abo03__olist__ttl-pink {
    /* middle-size */
    font-size: 1.7rem;
    line-height: 1;
  }
}
.p-abo03__olist__ttl::before, .p-abo03__olist__ttl-blue::before, .p-abo03__olist__ttl-purple::before, .p-abo03__olist__ttl-pink::before {
  content: "";
  display: block;
  width: 1rem;
  height: 1rem;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 0.7rem;
}
.p-abo03__olist__ttl-blue {
  background-image: linear-gradient(90deg, #006EDE 3.93%, #079CF4 96.95%);
}
.p-abo03__olist__ttl-blue::before {
  background-image: url(../images/top/cross-blue2.png);
}
.p-abo03__olist__ttl-purple {
  background-image: linear-gradient(90deg, #8155B3 3.93%, #9751A6 96.95%);
}
.p-abo03__olist__ttl-purple::before {
  background-image: url(../images/top/cross-purple2.png);
}
.p-abo03__olist__ttl-pink {
  background-image: linear-gradient(90deg, #E33F81 3.93%, #EB5985 96.95%);
}
.p-abo03__olist__ttl-pink::before {
  background-image: url(../images/top/cross-red2.png);
}
.p-abo03__olist__subttl {
  transition: all 0.3s linear;
  font-weight: 700;
  font-size: 1.7rem;
  line-height: 1.6;
  margin-top: 1rem;
}
@media only screen and (min-width: 767px) {
  .p-abo03__olist__subttl {
    /* tablet-size */
    font-size: 2rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo03__olist__subttl {
    /* middle-size */
    font-size: 2.3rem;
  }
}
.p-abo03__olist__txt {
  transition: all 0.3s linear;
  margin-top: 1rem;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.7;
}
@media only screen and (min-width: 767px) {
  .p-abo03__olist__txt {
    /* tablet-size */
    font-size: 1.6rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo03__olist__txt {
    /* middle-size */
    padding-right: 10rem;
  }
}
.p-abo03__olist__img {
  transition: all 0.3s linear;
  width: 100%;
  margin-top: 2.5rem;
}
@media only screen and (min-width: 767px) {
  .p-abo03__olist__img {
    /* tablet-size */
    width: 50%;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo03__olist__img {
    /* middle-size */
    width: 52rem;
  }
}
.p-abo03__btn {
  transition: all 0.3s linear;
  font-size: 1.3rem;
  background: linear-gradient(93.4deg, #1586F8 37.21%, #3AB0F3 102.05%);
  padding: 2rem 0 2rem 2.5rem;
  border-radius: 10rem;
  display: block;
  font-weight: 600;
  border-style: solid;
  border-width: 0.2rem;
  position: relative;
  color: #fff;
  width: 30rem;
  margin: 4rem auto 0;
}
@media only screen and (min-width: 767px) {
  .p-abo03__btn {
    font-size: 1.8rem;
  }
}
@media only screen and (min-width: 767px) {
  .p-abo03__btn {
    /* tablet-size */
    width: 40rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo03__btn {
    width: 52.7rem;
    margin-top: 13rem;
    padding: 3.8rem 0 3.8rem 4.3rem;
  }
}
.p-abo03__btn.-en {
  padding: 2rem 6rem 2rem 2.5rem;
}
@media only screen and (min-width: 767px) {
  .p-abo03__btn.-en {
    width: 55rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo03__btn.-en {
    width: 60rem;
  }
}
.p-abo03__btn::before, .p-abo03__btn::after {
  transition: all 0.3s linear;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}
.p-abo03__btn::before {
  transition: all 0.3s linear;
  display: block;
  right: 4rem;
  width: 0.1rem;
  height: calc(100% - 2.2rem);
  content: "";
  background-color: #fff;
}
@media only screen and (min-width: 1024px) {
  .p-abo03__btn::before {
    /* tablet-size */
    height: calc(100% - 3rem);
    right: 9.8rem;
  }
}
.p-abo03__btn::after {
  font-family: "Font Awesome 6 Free";
  display: inline-block;
  font-weight: 900;
  content: "\f054";
  right: 1.9rem;
  display: flex;
  align-items: center;
}
@media only screen and (min-width: 1024px) {
  .p-abo03__btn::after {
    /* middle-size */
    right: 4.4rem;
  }
}
.p-abo03__btn:hover:hover {
  filter: brightness(120%);
}
.p-abo04 {
  transition: all 0.3s linear;
}
@media only screen and (min-width: 767px) {
  .p-abo04 {
    /* tablet-size */
    margin-right: 3rem;
    margin-left: 3rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo04 {
    /* middle-size */
  }
}
.p-abo04__outer {
  margin-top: 7rem;
}
.p-abo04__ttl {
  transition: all 0.3s linear;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 1.5;
  margin-right: 20px;
  margin-left: 20px;
}
@media only screen and (min-width: 1024px) {
  .p-abo04__ttl {
    /* middle-size */
    font-size: 5.2rem;
    width: min(100% - 40px, 1115px);
    margin-right: auto;
    margin-left: auto;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
.p-abo04__ttl-gradation {
  transition: all 0.3s linear;
  background-clip: text;
  -webkit-background-clip: text;
  color: rgba(0, 0, 0, 0);
  display: inline-block;
  line-height: 1.4;
  background-image: linear-gradient(90deg, #006EDE 3.93%, #FF378B 96.95%);
  font-size: 1.4rem;
}
@media only screen and (min-width: 767px) {
  .p-abo04__ttl-gradation {
    /* tablet-size */
    font-size: 2.1rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo04__ttl-gradation {
    /* middle-size */
    font-size: 2.8rem;
  }
}
.p-abo04__ulist01 {
  transition: all 0.3s linear;
  margin-top: 0;
  margin-right: 2rem;
  margin-left: 2rem;
}
@media only screen and (min-width: 767px) {
  .p-abo04__ulist01 {
    /* tablet-size */
    margin-right: 0;
    margin-left: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo04__ulist01 {
    /* middle-size */
    width: min(100% - 40px, 1115px);
    margin-right: auto;
    margin-left: auto;
  }
}
.p-abo04__ulist01__item {
  transition: all 0.3s linear;
  position: relative;
  margin-top: 4.5rem;
  padding-top: 9rem;
  border-top: 1px solid #EBEBEB;
}
@media only screen and (min-width: 767px) {
  .p-abo04__ulist01__item {
    /* tablet-size */
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo04__ulist01__item {
    /* middle-size */
    align-items: center;
    justify-content: flex-end;
    margin-top: 5rem;
    padding-top: 5rem;
  }
}
.p-abo04__ulist01__item::before {
  transition: all 0.3s linear;
  display: block;
  position: absolute;
  top: 4rem;
  left: 0;
  width: 4.3rem;
  height: 4.3rem;
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  content: "";
}
@media only screen and (min-width: 767px) {
  .p-abo04__ulist01__item::before {
    /* tablet-size */
    top: 3rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo04__ulist01__item::before {
    /* middle-size */
    width: 6rem;
    height: 6rem;
    left: 2rem;
  }
}
.p-abo04__ulist01__item:nth-of-type(1) {
  transition: all 0.3s linear;
  margin-top: 0;
  border-top: none;
}
@media only screen and (min-width: 767px) {
  .p-abo04__ulist01__item:nth-of-type(1) {
    /* tablet-size */
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo04__ulist01__item:nth-of-type(1) {
    /* middle-size */
    margin-top: 2rem;
  }
}
.p-abo04__ulist01__item:nth-of-type(1)::before {
  background-image: url(../images/common/cross-blue.png);
}
.p-abo04__ulist01__item:nth-of-type(2)::before {
  background-image: url(../images/common/cross-red.png);
}
.p-abo04__ulist01__item:nth-of-type(3)::before {
  background-image: url(../images/common/cross-purple.png);
}
.p-abo04__ulist01__txtbox {
  transition: all 0.3s linear;
  padding-left: 3.5rem;
  padding-right: 3.5rem;
}
@media only screen and (min-width: 767px) {
  .p-abo04__ulist01__txtbox {
    /* tablet-size */
    padding-right: 0;
    padding-left: 0;
    width: calc(56% - 3rem);
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo04__ulist01__txtbox {
    /* middle-size */
    width: calc(50% - 5.8rem - 6.5rem);
    margin-right: 6.5rem;
  }
}
.p-abo04__ulist01__ttl, .p-abo04__ulist01__ttl03, .p-abo04__ulist01__ttl02, .p-abo04__ulist01__ttl01 {
  transition: all 0.3s linear;
  font-weight: 700;
  font-size: 2rem;
  line-height: 1.35;
  letter-spacing: 0.03em;
}
@media only screen and (min-width: 767px) {
  .p-abo04__ulist01__ttl, .p-abo04__ulist01__ttl03, .p-abo04__ulist01__ttl02, .p-abo04__ulist01__ttl01 {
    /* tablet-size */
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo04__ulist01__ttl, .p-abo04__ulist01__ttl03, .p-abo04__ulist01__ttl02, .p-abo04__ulist01__ttl01 {
    /* middle-size */
    font-size: 2.8rem;
  }
}
.p-abo04__ulist01__ttl01 span:nth-of-type(1) {
  color: #0C96F6;
}
.p-abo04__ulist01__ttl01 span:nth-of-type(2) {
  color: #0E94F7;
}
.p-abo04__ulist01__ttl01 span:nth-of-type(3) {
  color: #4C5EC6;
}
.p-abo04__ulist01__ttl02 span:nth-of-type(1) {
  color: #E64782;
}
.p-abo04__ulist01__ttl03 span:nth-of-type(1) {
  color: #5F2B9A;
}
.p-abo04__ulist01__txt {
  transition: all 0.3s linear;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.7;
  margin-top: 1rem;
}
@media only screen and (min-width: 767px) {
  .p-abo04__ulist01__txt {
    /* tablet-size */
    line-height: 1.5;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo04__ulist01__txt {
    /* middle-size */
    font-size: 1.7rem;
    margin-top: 3rem;
    line-height: 1.7;
  }
}
.p-abo04__ulist01__img {
  transition: all 0.3s linear;
  width: calc(100% - 3.6rem);
  margin-top: 3rem;
  margin-left: auto;
  margin-right: auto;
  display: block;
  border-radius: 0.6rem;
}
@media only screen and (min-width: 767px) {
  .p-abo04__ulist01__img {
    /* tablet-size */
    width: 44%;
    margin: 0;
    align-self: flex-start;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo04__ulist01__img {
    /* middle-size */
    width: 45%;
  }
}
.p-abo04__box {
  transition: all 0.3s linear;
  margin-top: 6.5rem;
  margin-right: 3.7rem;
  margin-left: 3.7rem;
}
@media only screen and (min-width: 767px) {
  .p-abo04__box {
    /* tablet-size */
    margin-top: 7rem;
    margin-right: 0;
    margin-left: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo04__box {
    /* middle-size */
    width: min(100% - 40px, 1215px);
    margin-right: auto;
    margin-left: auto;
  }
}
.p-abo04__subttl {
  transition: all 0.3s linear;
  margin-top: 1rem;
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 1.4;
}
@media only screen and (min-width: 767px) {
  .p-abo04__subttl {
    /* tablet-size */
    font-size: 3.1rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo04__subttl {
    /* middle-size */
    font-size: 3.7rem;
    margin-top: 7rem;
  }
}
.p-abo04__subttl__mark {
  transition: all 0.3s linear;
  display: inline-block;
  color: #fff;
  font-weight: 700;
  font-size: 1.4rem;
  letter-spacing: 0.08em;
  padding: 0.6rem 1.5rem;
  background: linear-gradient(91.21deg, #E9337A 33.49%, #F46793 92.32%);
  border-radius: 100px;
  margin-bottom: 0.5rem;
}
@media only screen and (min-width: 767px) {
  .p-abo04__subttl__mark {
    /* tablet-size */
    font-size: 1.6rem;
    margin-bottom: 1rem;
    display: inline-block;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo04__subttl__mark {
    /* middle-size */
    font-size: 1.8rem;
  }
}
.p-abo04__subttl__pink {
  color: #e54582;
}
.p-abo04__ulist02 {
  transition: all 0.3s linear;
  margin-top: 2rem;
}
@media only screen and (min-width: 767px) {
  .p-abo04__ulist02 {
    /* tablet-size */
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo04__ulist02 {
    /* middle-size */
  }
}
.p-abo04__ulist02__item {
  transition: all 0.3s linear;
  margin-top: 1.5rem;
}
@media only screen and (min-width: 767px) {
  .p-abo04__ulist02__item {
    /* tablet-size */
    width: calc(50% - 0.1rem);
    margin-top: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo04__ulist02__item {
    /* middle-size */
  }
}
.p-abo04__ulist02__item:nth-of-type(1) {
  margin-top: 0;
}
.p-abo04__ulist02__img {
  width: 100%;
}
.p-abo04__ulist02__txt {
  transition: all 0.3s linear;
  font-weight: 600;
  font-size: 1.1rem;
  margin-top: 0.5rem;
  text-align: right !important;
}
@media only screen and (min-width: 767px) {
  .p-abo04__ulist02__txt {
    /* tablet-size */
    font-size: 1.2rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo04__ulist02__txt {
    /* middle-size */
    font-size: 1.4rem;
  }
}
.p-abo05__outer {
  transition: all 0.3s linear;
  margin-top: 7rem;
}
@media only screen and (min-width: 1024px) {
  .p-abo05__outer {
    /* middle-size */
    margin-top: 16rem;
  }
}
.p-abo05__ttl {
  transition: all 0.3s linear;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  font-weight: 700;
  font-size: 2.2rem;
}
@media only screen and (min-width: 767px) {
  .p-abo05__ttl {
    /* tablet-size */
    font-size: 2.7rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo05__ttl {
    /* middle-size */
    font-size: 3.2rem;
  }
}
.p-abo05__ttl-gradation {
  transition: all 0.3s linear;
  background-clip: text;
  -webkit-background-clip: text;
  color: rgba(0, 0, 0, 0);
  display: inline-block;
  line-height: 1.4;
  background-image: linear-gradient(89.64deg, #076DDD 2.04%, #E63D7A 104.42%);
  font-size: 3.8rem;
  margin-top: 1rem;
}
@media only screen and (min-width: 767px) {
  .p-abo05__ttl-gradation {
    /* tablet-size */
    font-size: 4.4rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo05__ttl-gradation {
    /* middle-size */
    font-size: 5rem;
  }
}
.p-abo05__wrap {
  transition: all 0.3s linear;
  margin-top: 4rem;
}
@media only screen and (min-width: 767px) {
  .p-abo05__wrap {
    /* tablet-size */
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    margin-top: 5rem;
    width: min(100% - 40px, 1295px);
    margin-right: auto;
    margin-left: auto;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo05__wrap {
    /* middle-size */
    margin-top: 5rem;
    width: min(100% - 40px, 1295px);
    margin-right: auto;
    margin-left: auto;
  }
}
.p-abo05__box {
  transition: all 0.3s linear;
  margin-top: 3rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-bottom: 3rem;
  margin-right: 0.1rem;
}
@media only screen and (min-width: 767px) {
  .p-abo05__box {
    /* tablet-size */
    margin-top: 0;
    margin-bottom: 0.2rem;
    width: calc(50% - 0.2rem);
    padding-bottom: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo05__box {
    /* middle-size */
  }
}
.p-abo05__box:nth-of-type(2n + 1) {
  flex-direction: row;
}
@media only screen and (min-width: 1024px) {
  .p-abo05__box:nth-of-type(2n + 1)::after {
    /* middle-size */
    left: inherit;
  }
}
.p-abo05__box:nth-of-type(2n) {
  flex-direction: row-reverse;
}
@media only screen and (min-width: 1024px) {
  .p-abo05__box:nth-of-type(2n)::after {
    /* middle-size */
    right: inherit;
  }
}
.p-abo05__box::after {
  transition: all 0.3s linear;
  display: block;
  position: absolute;
  top: 6rem;
  right: 0;
  bottom: 0;
  left: 0;
  width: 92%;
  margin: auto;
  border-radius: 20px;
  content: "";
  z-index: -1;
}
@media only screen and (min-width: 767px) {
  .p-abo05__box::after {
    /* tablet-size */
    top: 0;
    width: calc(100% - 6.5rem);
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo05__box::after {
    /* middle-size */
  }
}
.p-abo05__box:nth-of-type(1) {
  margin-top: 0;
}
.p-abo05__box:nth-of-type(1)::after {
  background: #EEF6FF;
}
@media only screen and (min-width: 767px) {
  .p-abo05__box:nth-of-type(1)::after {
    /* tablet-size */
    display: none;
  }
}
.p-abo05__box:nth-of-type(2)::after {
  background: #FAF4FF;
}
@media only screen and (min-width: 767px) {
  .p-abo05__box:nth-of-type(2)::after {
    /* tablet-size */
    display: none;
  }
}
.p-abo05__box:nth-of-type(3)::after {
  background: #EEF1FF;
}
@media only screen and (min-width: 767px) {
  .p-abo05__box:nth-of-type(3)::after {
    /* tablet-size */
    display: none;
  }
}
.p-abo05__box:nth-of-type(4)::after {
  background: #FFF3F7;
}
@media only screen and (min-width: 767px) {
  .p-abo05__box:nth-of-type(4)::after {
    /* tablet-size */
    display: none;
  }
}
.p-abo05__box__ttl, .p-abo05__box__ttl-pink, .p-abo05__box__ttl-navy, .p-abo05__box__ttl-purple, .p-abo05__box__ttl-blue {
  font-weight: 700;
  font-size: 2rem;
  line-height: 20px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-bottom: 1.2rem;
  text-align: center;
}
.p-abo05__box__ttl::after, .p-abo05__box__ttl-pink::after, .p-abo05__box__ttl-navy::after, .p-abo05__box__ttl-purple::after, .p-abo05__box__ttl-blue::after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  width: 6rem;
  height: 0.2rem;
  margin: auto;
}
.p-abo05__box__ttl-blue {
  color: #0F6CDA;
}
.p-abo05__box__ttl-blue::after {
  background-color: #0F6CDA;
}
.p-abo05__box__ttl-purple {
  color: #974F9E;
}
.p-abo05__box__ttl-purple::after {
  background-color: #974F9E;
}
.p-abo05__box__ttl-navy {
  color: #535DBC;
}
.p-abo05__box__ttl-navy::after {
  background-color: #535DBC;
}
.p-abo05__box__ttl-pink {
  color: #F0418B;
}
.p-abo05__box__ttl-pink::after {
  background-color: #F0418B;
}
.p-abo05__box__ulist {
  transition: all 0.3s linear;
  margin-top: 3rem;
}
@media only screen and (min-width: 767px) {
  .p-abo05__box__ulist {
    /* tablet-size */
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo05__box__ulist {
    /* middle-size */
    margin-top: 3rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
.p-abo05__box__item {
  transition: all 0.3s linear;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.4;
}
@media only screen and (min-width: 767px) {
  .p-abo05__box__item {
    /* tablet-size */
    font-size: 1.3rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo05__box__item {
    /* middle-size */
    width: calc(50% - 2rem);
    font-size: 1.4rem;
  }
}
.p-abo05__img {
  transition: all 0.3s linear;
  width: 26.3rem;
}
@media only screen and (min-width: 767px) {
  .p-abo05__img {
    /* tablet-size */
    width: 100%;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo05__img {
    /* middle-size */
  }
}
.p-abo05__txtbox {
  transition: all 0.3s linear;
  width: 26.3rem;
  margin-top: 2rem;
}
@media only screen and (min-width: 767px) {
  .p-abo05__txtbox {
    /* tablet-size */
    width: 42rem;
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: none;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo05__txtbox {
    /* middle-size */
  }
}
.p-abo06 {
  transition: all 0.3s linear;
  margin-right: 1.5rem;
  margin-left: 1.5rem;
}
@media only screen and (min-width: 767px) {
  .p-abo06 {
    /* tablet-size */
    margin-right: 3rem;
    margin-left: 3rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo06 {
    /* middle-size */
    width: min(100% - 40px, 1215px);
    margin-right: auto;
    margin-left: auto;
  }
}
.p-abo06__outer {
  transition: all 0.3s linear;
  margin-top: 7rem;
}
@media only screen and (min-width: 767px) {
  .p-abo06__outer {
    /* tablet-size */
    background: linear-gradient(179.98deg, #F3F3F3 -12.51%, #FFFFFF 19.91%);
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo06__outer {
    /* middle-size */
    margin-top: 12rem;
  }
}
.p-abo06__ttl {
  transition: all 0.3s linear;
  text-align: center;
}
@media only screen and (min-width: 767px) {
  .p-abo06__ttl {
    /* tablet-size */
    transform: translateY(-2rem) !important;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo06__ttl {
    /* middle-size */
  }
}
.p-abo06__ttl__style01 {
  transition: all 0.3s linear;
  background-color: #3B70F4;
  color: #fff;
  border-radius: 100px;
  font-weight: 700;
  font-size: 2.1rem;
  padding: 1.5rem 3rem;
  display: inline-block;
}
@media only screen and (min-width: 767px) {
  .p-abo06__ttl__style01 {
    /* tablet-size */
    font-size: 3rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo06__ttl__style01 {
    /* middle-size */
    font-size: 2.4rem;
  }
}
.p-abo06__ttl__style02 {
  transition: all 0.3s linear;
  font-weight: 700;
  font-size: 4.1rem;
  letter-spacing: 0.03em;
  color: #3B70F4;
  line-height: 1.6;
}
@media only screen and (min-width: 767px) {
  .p-abo06__ttl__style02 {
    /* tablet-size */
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo06__ttl__style02 {
    /* middle-size */
    font-size: 6rem;
  }
}
.p-abo06__ttl__style03 {
  transition: all 0.3s linear;
  font-weight: 700;
  font-size: 2rem;
  letter-spacing: 0.02em;
}
@media only screen and (min-width: 767px) {
  .p-abo06__ttl__style03 {
    /* tablet-size */
    font-size: 3rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo06__ttl__style03 {
    /* middle-size */
    font-size: 4.5rem;
  }
}
.p-abo06__ttl__style03.-en {
  line-height: 1.5;
}
.p-abo06__txt {
  transition: all 0.3s linear;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.7;
  text-align: center !important;
  margin-top: 3rem;
}
@media only screen and (min-width: 767px) {
  .p-abo06__txt {
    /* tablet-size */
    font-size: 1.8rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo06__txt {
    /* middle-size */
    font-size: 2rem;
    margin-top: 1rem;
  }
}
.p-abo06__img {
  transition: all 0.3s linear;
  width: 100%;
  margin-top: 6rem;
}
@media only screen and (min-width: 767px) {
  .p-abo06__img {
    /* tablet-size */
    margin-top: 9rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo06__img {
    /* middle-size */
    margin-top: 12rem;
  }
}
.p-abo06__ulist {
  transition: all 0.3s linear;
  margin-top: 6rem;
}
@media only screen and (min-width: 767px) {
  .p-abo06__ulist {
    /* tablet-size */
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo06__ulist {
    /* middle-size */
  }
}
.p-abo06__ulist__item {
  transition: all 0.3s linear;
  margin-top: 1rem;
  background: #FFFFFF;
  box-shadow: 0px 7px 20px rgba(129, 129, 129, 0.3);
}
@media only screen and (min-width: 767px) {
  .p-abo06__ulist__item {
    /* tablet-size */
    width: calc(33.3333333333% - 1rem);
    margin-top: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo06__ulist__item {
    /* middle-size */
  }
}
.p-abo06__ulist__item:nth-of-type(1) {
  margin-top: 0;
}
.p-abo06__ulist__item a {
  transition: all 0.3s linear;
}
.p-abo06__ulist__item a:hover {
  opacity: 0.7;
}
.p-abo06__ulist__item a,
.p-abo06__ulist__item span {
  padding: 3rem 4rem 4rem;
  display: block;
}
@media only screen and (min-width: 767px) {
  .p-abo06__ulist__item a,
  .p-abo06__ulist__item span {
    /* tablet-size */
    padding: 2rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo06__ulist__item a,
  .p-abo06__ulist__item span {
    /* middle-size */
    padding: 1rem 2rem 4rem;
  }
}
.p-abo06__ulist__ttl {
  transition: all 0.3s linear;
  font-weight: 700;
  font-size: 1.7rem;
  line-height: 2.4;
  color: #000;
}
@media only screen and (min-width: 767px) {
  .p-abo06__ulist__ttl {
    /* tablet-size */
    min-height: 3.5em;
    font-size: 1.5rem;
    line-height: 1.5;
    display: flex;
    align-items: center;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo06__ulist__ttl {
    /* middle-size */
    font-size: 1.8rem;
  }
}
@media only screen and (min-width: 767px) {
  .p-abo06__ulist__ttl.-en {
    align-items: flex-start;
    min-height: 5em;
  }
}
.p-abo06__ulist__img {
  width: 100%;
}
.p-abo06__ulist__txt {
  transition: all 0.3s linear;
  margin-top: 2.5rem;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.5;
  color: #292D36;
}
@media only screen and (min-width: 767px) {
  .p-abo06__ulist__txt {
    /* tablet-size */
    margin-top: 1.5rem;
    font-size: 1.5rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo06__ulist__txt {
    /* middle-size */
    font-size: 1.6rem;
    line-height: 1.75;
  }
}
.p-abo07 {
  transition: all 0.3s linear;
  background: #FFFFFF;
  box-shadow: 8px 10px 33px rgba(0, 0, 0, 0.08);
  border-radius: 30px;
  margin-right: 1.5rem;
  margin-left: 1.5rem;
  padding-bottom: 7rem;
}
@media only screen and (min-width: 767px) {
  .p-abo07 {
    /* tablet-size */
    padding-bottom: 5rem;
    margin-right: 3rem;
    margin-left: 3rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo07 {
    /* middle-size */
    width: min(100% - 40px, 1215px);
    margin-right: auto;
    margin-left: auto;
    padding: 0 6rem 6rem;
  }
}
.p-abo07__outer {
  transition: all 0.3s linear;
  margin-top: 10rem;
  padding-bottom: 6rem;
}
@media only screen and (min-width: 767px) {
  .p-abo07__outer {
    /* tablet-size */
    margin-top: 17rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo07__outer {
    /* middle-size */
    margin-top: 25rem;
    padding-bottom: 26rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo07__outer.-en {
    padding-bottom: 12rem;
  }
}
.p-abo07__outer02 {
  padding-bottom: 6rem;
}
@media only screen and (min-width: 767px) {
  .p-abo07__outer02 {
    /* tablet-size */
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo07__outer02 {
    /* middle-size */
    padding-bottom: 14rem;
  }
}
.p-abo07__ttl {
  transition: all 0.3s linear;
  background-clip: text;
  -webkit-background-clip: text;
  color: rgba(0, 0, 0, 0);
  display: inline-block;
  line-height: 1.4;
  background-image: linear-gradient(90deg, #086EDE 30%, #DB4080 66.6%);
  font-weight: 700;
  font-size: 2.2rem;
  transform: translateY(-50%) !important;
  text-align: center;
  display: block;
}
@media only screen and (min-width: 767px) {
  .p-abo07__ttl {
    /* tablet-size */
    font-size: 3.5rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo07__ttl {
    /* middle-size */
    font-size: 3.5rem;
  }
}
.p-abo07__ulist {
  transition: all 0.3s linear;
  margin-right: 3rem;
  margin-left: 3rem;
}
@media only screen and (min-width: 767px) {
  .p-abo07__ulist {
    /* tablet-size */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo07__ulist {
    /* middle-size */
    margin-top: 1rem;
  }
}
.p-abo07__ulist__item {
  transition: all 0.3s linear;
  margin-top: 3rem;
}
@media only screen and (min-width: 767px) {
  .p-abo07__ulist__item {
    /* tablet-size */
    width: calc(33.3333333333% - 4.5rem);
    margin-top: 0;
    position: relative;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo07__ulist__item {
    /* middle-size */
    width: calc(33.3333333333% - 5.7rem);
  }
}
.p-abo07__ulist__item::after {
  font-family: "Font Awesome 6 Free";
  display: inline-block;
  font-weight: 900;
  transition: all 0.3s linear;
  content: "\f078";
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  color: #2D313D;
  font-size: 2.8rem;
  text-align: center;
  margin-top: 3rem;
  display: block;
}
@media only screen and (min-width: 767px) {
  .p-abo07__ulist__item::after {
    /* tablet-size */
    position: absolute;
    top: 0;
    right: -4rem;
    bottom: 0;
    left: inherit;
    margin: auto;
    content: "\f054";
    font-size: 2.8rem;
    display: flex;
    align-items: center;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo07__ulist__item::after {
    /* middle-size */
    right: -5rem;
    transform: translateY(2rem);
  }
}
.p-abo07__ulist__item:last-of-type::after {
  display: none;
}
.p-abo07__ulist__ttl, .p-abo07__ulist__ttl-pink, .p-abo07__ulist__ttl-purple, .p-abo07__ulist__ttl-blue {
  transition: all 0.3s linear;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 1.8;
  text-align: center;
  letter-spacing: 0.05em;
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 767px) {
  .p-abo07__ulist__ttl, .p-abo07__ulist__ttl-pink, .p-abo07__ulist__ttl-purple, .p-abo07__ulist__ttl-blue {
    /* tablet-size */
    font-size: 1.4rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo07__ulist__ttl, .p-abo07__ulist__ttl-pink, .p-abo07__ulist__ttl-purple, .p-abo07__ulist__ttl-blue {
    /* middle-size */
    font-size: 2rem;
  }
}
.p-abo07__ulist__ttl span, .p-abo07__ulist__ttl-pink span, .p-abo07__ulist__ttl-purple span, .p-abo07__ulist__ttl-blue span {
  transition: all 0.3s linear;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 1.2rem;
  text-align: center;
  letter-spacing: 0.05em;
}
@media only screen and (min-width: 767px) {
  .p-abo07__ulist__ttl span, .p-abo07__ulist__ttl-pink span, .p-abo07__ulist__ttl-purple span, .p-abo07__ulist__ttl-blue span {
    /* tablet-size */
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo07__ulist__ttl span, .p-abo07__ulist__ttl-pink span, .p-abo07__ulist__ttl-purple span, .p-abo07__ulist__ttl-blue span {
    /* middle-size */
    font-size: 1.4rem;
    margin-bottom: 0.5rem;
  }
}
.p-abo07__ulist__ttl span::after, .p-abo07__ulist__ttl-pink span::after, .p-abo07__ulist__ttl-purple span::after, .p-abo07__ulist__ttl-blue span::after {
  content: "";
  display: block;
  right: 0;
  left: 0;
  bottom: 0;
  width: 3rem;
  height: 0.2rem;
  margin: auto;
  margin-top: 0.5rem;
}
.p-abo07__ulist__ttl-blue {
  color: #008FEA;
}
.p-abo07__ulist__ttl-blue span::after {
  background-color: #008FEA;
}
.p-abo07__ulist__ttl-purple {
  color: #9246A5;
}
.p-abo07__ulist__ttl-purple span::after {
  background-color: #9246A5;
}
.p-abo07__ulist__ttl-pink {
  color: #EE277B;
}
.p-abo07__ulist__ttl-pink span::after {
  background-color: #EE277B;
}
.p-abo07__ulist__img {
  width: 100%;
  margin-top: -1.5rem;
}
.p-abo08 {
  transition: all 0.3s linear;
  padding: 4.5rem 1.6rem 4.3rem;
}
@media only screen and (min-width: 767px) {
  .p-abo08 {
    /* tablet-size */
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo08 {
    /* middle-size */
  }
}
.p-abo08__outer {
  transition: all 0.3s linear;
  margin-top: 5rem;
  background-image: url(../images/top/abu08_bg-sp.jpg);
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-size: 100%;
}
@media only screen and (min-width: 767px) {
  .p-abo08__outer {
    /* tablet-size */
    background-image: url(../images/top/abu08_bg-pc.jpg);
    background-position: 60% 50%;
    background-size: 164rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo08__outer {
    /* middle-size */
    width: min(100% - 40px, 1215px);
    min-height: 53.4rem;
    margin-right: auto;
    margin-left: auto;
    background-size: contain;
    background-position: 50% 50%;
    padding: 5rem;
  }
}
.p-abo08__lead__txt {
  transition: all 0.3s linear;
  display: inline;
  padding: 0 0px 1.1rem;
  background: linear-gradient(transparent 92%, #fff 0%);
  color: #FFFFFF;
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 2;
  letter-spacing: 0.05em;
  position: relative;
  left: 2rem;
}
@media only screen and (min-width: 767px) {
  .p-abo08__lead__txt {
    /* tablet-size */
    font-size: 3.2rem;
    line-height: 2.4;
    background: linear-gradient(transparent 94%, #fff 0%);
    padding: 0 0 2rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo08__lead__txt {
    /* middle-size */
    font-size: 4rem;
    background: linear-gradient(transparent 96%, #fff 0%);
    line-height: 2.2;
  }
}
@media only screen and (min-width: 767px) {
  .p-abo08__lead {
    /* tablet-size */
    width: calc(50% - 5rem);
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo08__lead {
    /* middle-size */
  }
}
.p-abo08__box {
  transition: all 0.3s linear;
  margin-top: 3.5rem;
  box-shadow: 2px 7px 12px 3px rgba(131, 123, 153, 0.25);
  border-radius: 10px;
  padding: 3rem 1.7rem;
  background: rgba(255, 255, 255, 0.85);
}
@media only screen and (min-width: 767px) {
  .p-abo08__box {
    /* tablet-size */
    width: calc(50% - 5rem);
    margin-top: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo08__box {
    /* middle-size */
    width: 43.8rem;
    padding: 2rem 2.7rem 4rem;
  }
}
.p-abo08__txtbox {
  transition: all 0.3s linear;
  position: relative;
  padding: 1.5rem 0;
  border-bottom: 0.2rem solid #7147A0;
  line-height: 1.6;
  text-align: center !important;
}
@media only screen and (min-width: 767px) {
  .p-abo08__txtbox {
    /* tablet-size */
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo08__txtbox {
    /* middle-size */
    line-height: 1.4;
    padding: 1.5rem 0 2.5rem;
  }
}
.p-abo08__txtbox span {
  letter-spacing: 0.03em;
  font-weight: 700;
}
.p-abo08__txtbox__style01 {
  transition: all 0.3s linear;
  font-size: 2.2rem;
  color: #7147A0;
}
@media only screen and (min-width: 767px) {
  .p-abo08__txtbox__style01 {
    /* tablet-size */
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo08__txtbox__style01 {
    /* middle-size */
    font-size: 2.6rem;
  }
}
.p-abo08__txtbox__style02 {
  transition: all 0.3s linear;
  font-size: 1.6rem;
  color: #2D313D;
}
@media only screen and (min-width: 767px) {
  .p-abo08__txtbox__style02 {
    /* tablet-size */
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo08__txtbox__style02 {
    /* middle-size */
    font-size: 1.9rem;
  }
}
.p-abo08__txtbox__style03 {
  transition: all 0.3s linear;
  font-size: 2.5rem;
  color: #7E53A3;
}
@media only screen and (min-width: 767px) {
  .p-abo08__txtbox__style03 {
    /* tablet-size */
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo08__txtbox__style03 {
    /* middle-size */
    font-size: 3.2rem;
  }
}
.p-abo08__txtbox__style04 {
  transition: all 0.3s linear;
  font-size: 1.4rem;
  color: #2D313D;
}
@media only screen and (min-width: 767px) {
  .p-abo08__txtbox__style04 {
    /* tablet-size */
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo08__txtbox__style04 {
    /* middle-size */
    font-size: 1.9rem;
  }
}
.p-abo08__txtbox::before, .p-abo08__txtbox::after {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 14px 10px 0 10px;
  content: "";
}
.p-abo08__txtbox::before {
  bottom: -1.4rem;
  border-color: #7147A0 transparent transparent transparent;
}
.p-abo08__txtbox::after {
  bottom: -1rem;
  border-color: #f3f2f0 transparent transparent transparent;
}
.p-abo08__logo {
  transition: all 0.3s linear;
  display: block;
  width: 80%;
  margin: auto;
  margin-top: 2.5rem;
}
@media only screen and (min-width: 767px) {
  .p-abo08__logo {
    /* tablet-size */
  }
}
@media only screen and (min-width: 1024px) {
  .p-abo08__logo {
    /* middle-size */
    width: 100%;
    margin-top: 3.5rem;
  }
}
.p-abo08__btn {
  transition: all 0.3s linear;
  background: linear-gradient(92.6deg, #7046A0 10.21%, #7349A1 15.35%, #A273AC 100%);
  border-radius: 10rem;
  font-weight: 700;
  color: #fff;
  width: 30rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 2rem auto 0;
  padding: 1.3rem 0 1.3rem 1.5rem;
  font-size: 1.4rem;
  letter-spacing: 0.02em;
  box-shadow: 0px 0px 8px 2px rgba(84, 56, 165, 0.43);
}
@media only screen and (min-width: 1024px) {
  .p-abo08__btn {
    /* middle-size */
    width: 100%;
    font-size: 1.6rem;
    padding: 1.6rem 0 1.6rem 1.5rem;
    margin-top: 3rem;
  }
}
.p-abo08__btn:hover:hover {
  filter: brightness(120%);
}
.p-abo08__btn::before {
  transition: all 0.3s linear;
  font-family: "Font Awesome 6 Free";
  display: inline-block;
  font-weight: 900;
  content: "\f138";
  color: #fff;
  font-size: 2.9rem;
  margin-right: 1.8rem;
}
@media only screen and (min-width: 1024px) {
  .p-abo08__btn::before {
    /* middle-size */
    font-size: 3.7rem;
  }
}

.p-mak01 {
  transition: all 0.3s linear;
}
@media only screen and (min-width: 767px) {
  .p-mak01 {
    /* tablet-size */
    display: flex;
    justify-content: space-between;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak01 {
    /* middle-size */
    width: min(100% - 40px, 1155px);
    margin-right: auto;
    margin-left: auto;
  }
}
.p-mak01__outer {
  transition: all 0.3s linear;
  margin-top: 6rem;
  margin-right: 3.5rem;
  margin-left: 3.5rem;
}
@media only screen and (min-width: 767px) {
  .p-mak01__outer {
    /* tablet-size */
    margin-top: 8rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak01__outer {
    /* middle-size */
    margin-top: 10.5rem;
  }
}
.p-mak01__img {
  transition: all 0.3s linear;
  width: 100%;
  margin-top: 6rem;
  transform: scale(1.1);
  transform-origin: 50% 100%;
}
@media only screen and (min-width: 767px) {
  .p-mak01__img {
    /* tablet-size */
    object-fit: contain;
    margin-top: 0;
    transform: scale(1);
    width: calc(50% - 1rem);
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak01__img {
    /* middle-size */
    width: 64.5rem;
    padding-right: 0;
  }
}
.p-mak01__img img {
  width: 100%;
}
.p-mak01__txtbox {
  transition: all 0.3s linear;
}
@media only screen and (min-width: 767px) {
  .p-mak01__txtbox {
    /* tablet-size */
    width: calc(50% - 1rem);
    padding-right: 3rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak01__txtbox {
    /* middle-size */
    padding-right: 0;
  }
}
.p-mak01__ttl {
  font-weight: 700;
  letter-spacing: 0.05em;
}
.p-mak01__ttl__top {
  transition: all 0.3s linear;
  font-size: 1.9rem;
}
@media only screen and (min-width: 767px) {
  .p-mak01__ttl__top {
    /* tablet-size */
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak01__ttl__top {
    /* middle-size */
    font-size: 3rem;
  }
}
.p-mak01__ttl__bottom {
  transition: all 0.3s linear;
  background-clip: text;
  -webkit-background-clip: text;
  color: rgba(0, 0, 0, 0);
  display: inline-block;
  line-height: 1.4;
  background-image: linear-gradient(99.76deg, #3D17AA 3.24%, #7920A3 78%);
  font-size: 4.2rem;
  margin-top: 0.5rem;
}
@media only screen and (min-width: 767px) {
  .p-mak01__ttl__bottom {
    /* tablet-size */
    font-size: 4.2rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak01__ttl__bottom {
    /* middle-size */
    font-size: 7rem;
    margin-top: 2rem;
  }
}
.p-mak01__ttl-dot {
  position: relative;
  color: #5F2A9B;
}
.p-mak01__ttl-dot::before {
  content: "・";
  position: absolute;
  top: -0.8em;
  left: 0;
}
.p-mak01__ttl-small {
  transition: all 0.3s linear;
  font-size: 3.5rem;
}
@media only screen and (min-width: 767px) {
  .p-mak01__ttl-small {
    /* tablet-size */
    font-size: 4rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak01__ttl-small {
    /* middle-size */
    font-size: 4.5rem;
  }
}
.p-mak01__txt {
  transition: all 0.3s linear;
  margin-top: 3rem;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 2;
}
@media only screen and (min-width: 767px) {
  .p-mak01__txt {
    /* tablet-size */
    margin-top: 1rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak01__txt {
    /* middle-size */
    width: 90%;
    margin-top: 7rem;
    font-size: 1.6rem;
    line-height: 2.5;
  }
}
.p-mak01__btn {
  transition: all 0.3s linear;
  transition: all 0.3s linear;
  font-size: 1.6rem;
  background: linear-gradient(93.4deg, #1586F8 37.21%, #3AB0F3 102.05%);
  margin-top: 4rem;
  padding: 2rem 0 2rem 3.6rem;
  border-radius: 10rem;
  display: block;
  font-weight: 600;
  border-style: solid;
  border-width: 0.2rem;
  position: relative;
  color: #fff;
}
@media only screen and (min-width: 767px) {
  .p-mak01__btn {
    /* tablet-size */
    margin-top: 2rem;
    font-size: 1.7rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak01__btn {
    width: 30rem;
    margin-top: 4rem;
    padding: 2.1rem 0 2.1rem 3.3rem;
    font-size: 1.4rem;
  }
}
.p-mak01__btn::before, .p-mak01__btn::after {
  transition: all 0.3s linear;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}
.p-mak01__btn::before {
  transition: all 0.3s linear;
  display: block;
  right: 6.5rem;
  width: 0.1rem;
  height: calc(100% - 2.2rem);
  content: "";
  background-color: #fff;
}
@media only screen and (min-width: 1024px) {
  .p-mak01__btn::before {
    /* tablet-size */
    height: calc(100% - 3rem);
  }
}
.p-mak01__btn::after {
  transition: all 0.3s linear;
  font-family: "Font Awesome 6 Free";
  display: inline-block;
  font-weight: 900;
  content: "\f054";
  right: 2.6rem;
  display: flex;
  align-items: center;
}
.p-mak01__btn:hover:hover {
  filter: brightness(120%);
}
.p-mak01__ttl2 {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 5rem;
}
@media only screen and (min-width: 767px) {
  .p-mak01__ttl2 {
    width: 100%;
    font-size: 2.9rem;
    margin-bottom: 11rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak01__ttl2 {
    font-size: 4rem;
    margin-bottom: 17rem;
  }
}
.p-mak01__ttl2.-en {
  margin-left: -2rem;
  margin-right: -2rem;
}
@media only screen and (min-width: 767px) {
  .p-mak01__ttl2.-en {
    margin-right: 0;
    margin-left: 0;
  }
}
.p-mak01__ttl2 img {
  height: 2.5rem;
  margin: 0 0.5rem;
}
@media only screen and (min-width: 767px) {
  .p-mak01__ttl2 img {
    height: 4rem;
    margin: 0 1rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak01__ttl2 img {
    height: 5.1rem;
  }
}
.p-mak02__outer {
  margin-top: 8rem;
  padding-top: 9.5rem;
  padding-bottom: 12rem;
  padding-right: 2rem;
  padding-left: 2rem;
  background-image: url(../images/make/mak02_bg02-sp.png), url(../images/make/mak02_bg01-sp.png);
  background-position: 50% 100%, 50% 0%;
  background-size: 50rem;
  background-repeat: no-repeat;
}
@media only screen and (min-width: 767px) {
  .p-mak02__outer {
    /* tablet-size */
    background-size: 99.1rem;
    padding-right: 0;
    padding-left: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak02__outer {
    /* middle-size */
    background-size: 147.7rem;
    margin-top: 13rem;
    background-image: url(../images/make/mak02_bg02-pc.png), url(../images/make/mak02_bg01-pc.png);
    padding-top: 16rem;
    padding-bottom: 19rem;
  }
}
.p-mak02__ttl {
  transition: all 0.3s linear;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
@media only screen and (min-width: 767px) {
  .p-mak02__ttl {
    /* tablet-size */
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak02__ttl {
    /* middle-size */
  }
}
.p-mak02__ttl__top {
  font-weight: 700;
  font-size: 1.4rem;
}
@media only screen and (min-width: 767px) {
  .p-mak02__ttl__top {
    /* tablet-size */
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak02__ttl__top {
    /* middle-size */
    font-size: 1.8rem;
  }
}
.p-mak02__ttl__bottom, .p-mak02__ttl__bottom-pink, .p-mak02__ttl__bottom-purple {
  font-weight: 700;
  font-size: 2.4rem;
  margin-top: 2rem;
}
@media only screen and (min-width: 767px) {
  .p-mak02__ttl__bottom, .p-mak02__ttl__bottom-pink, .p-mak02__ttl__bottom-purple {
    /* tablet-size */
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak02__ttl__bottom, .p-mak02__ttl__bottom-pink, .p-mak02__ttl__bottom-purple {
    /* middle-size */
    font-size: 4rem;
  }
}
.p-mak02__ttl__bottom-purple {
  color: #6A33C3;
}
.p-mak02__ttl__bottom-pink {
  color: #F06565;
}
.p-mak02__ulist {
  transition: all 0.3s linear;
  margin-top: 8rem;
}
@media only screen and (min-width: 767px) {
  .p-mak02__ulist {
    /* tablet-size */
    margin-top: 4rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 3.7rem;
    margin-left: 3.7rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak02__ulist {
    /* middle-size */
    margin-left: auto;
    margin-right: auto;
    margin-top: 6rem;
    width: min(100% - 40px, 975px);
  }
}
.p-mak02__item {
  transition: all 0.3s linear;
  margin-top: 10rem;
}
@media only screen and (min-width: 767px) {
  .p-mak02__item {
    /* tablet-size */
    margin-top: 0;
    width: calc(33.3333333333% - 1rem);
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak02__item {
    /* middle-size */
    width: calc(33.3333333333% - 4.5rem);
  }
}
.p-mak02__item:nth-of-type(1) {
  margin-top: 0;
}
.p-mak02__item__box {
  position: relative;
  height: 26.3rem;
}
.p-mak02__item__box::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: 0 auto;
  width: 263px;
  height: 263px;
  background: linear-gradient(180deg, #FFFFFF 42.19%, #D9DBFF 100%);
  box-shadow: 0px 7px 20px rgba(125, 127, 137, 0.25);
  border-radius: 100%;
  z-index: -1;
}
@media only screen and (min-width: 767px) {
  .p-mak02__item__box::before {
    /* tablet-size */
    width: 238px;
    height: 238px;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak02__item__box::before {
    /* middle-size */
    width: 263px;
    height: 263px;
  }
}
.p-mak02__item__img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 15rem;
}
@media only screen and (min-width: 767px) {
  .p-mak02__item__img {
    /* tablet-size */
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak02__item__img {
    /* middle-size */
    width: 17rem;
  }
}
.p-mak02__item__txt01 {
  font-weight: 700;
  font-size: 2rem;
  text-align: center !important;
  letter-spacing: 0.04em;
  color: #6948C7;
  transform: translateY(3rem);
  margin: 0 20px;
}
@media only screen and (min-width: 767px) {
  .p-mak02__item__txt01 {
    /* tablet-size */
    font-size: 1.6rem;
    margin: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak02__item__txt01 {
    /* middle-size */
    font-size: 2rem;
    margin-top: 1rem;
  }
}
.p-mak02__item__txt02 {
  margin-top: 2rem;
  font-weight: 700;
  font-size: 2.3rem;
  text-align: center !important;
  letter-spacing: 0.05em;
  color: #6A33C3;
  min-height: 2em;
  line-height: 1.4;
}
@media only screen and (min-width: 767px) {
  .p-mak02__item__txt02 {
    /* tablet-size */
    font-size: 2rem;
    margin-top: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak02__item__txt02 {
    /* middle-size */
    font-size: 2.3rem;
    margin-top: 2rem;
  }
}
.p-mak03 {
  transition: all 0.3s linear;
}
@media only screen and (min-width: 767px) {
  .p-mak03 {
    /* tablet-size */
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak03 {
    /* middle-size */
  }
}
.p-mak03__outer {
  transition: all 0.3s linear;
  padding-top: 6rem;
}
@media only screen and (min-width: 767px) {
  .p-mak03__outer {
    /* tablet-size */
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak03__outer {
    /* middle-size */
  }
}
.p-mak03__ttl {
  transition: all 0.3s linear;
}
@media only screen and (min-width: 767px) {
  .p-mak03__ttl {
    /* tablet-size */
    font-size: 3.5rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak03__ttl {
    /* middle-size */
    font-size: 4.5rem;
  }
}
.p-mak03__ttl__top {
  display: flex;
  align-items: center;
  justify-content: center;
}
.p-mak03__ttl__top-small {
  font-weight: 700;
  font-size: 1.9rem;
  margin-top: 0.3rem;
}
.p-mak03__ttl__top img {
  width: 20rem;
}
@media only screen and (min-width: 1024px) {
  .p-mak03__ttl__top img {
    /* middle-size */
    width: 22rem;
  }
}
.p-mak03__ttl__top img.-en {
  margin-right: 0.5rem;
}
.p-mak03__ttl__middle {
  text-align: center;
  font-size: 1.7rem;
  font-weight: 700;
  margin-top: 2rem;
  line-height: 1.5;
}
.p-mak03__ttl__middle-small {
  font-size: 1.4rem;
}
@media only screen and (min-width: 1024px) {
  .p-mak03__ttl__middle-small {
    /* middle-size */
    font-size: 3rem;
  }
}
.p-mak03__ttl__middle-md {
  font-size: 1.7rem;
}
@media only screen and (min-width: 1024px) {
  .p-mak03__ttl__middle-md {
    /* middle-size */
    font-size: 3rem;
  }
}
.p-mak03__ttl__middle-purple {
  color: #6A33C3;
  font-size: 2.4rem;
}
@media only screen and (min-width: 1024px) {
  .p-mak03__ttl__middle-purple {
    /* middle-size */
    font-size: 4.5rem;
  }
}
.p-mak03__ttl__bottom {
  font-weight: 700;
  font-size: 2.6rem;
  line-height: 1.6;
  text-align: center;
  letter-spacing: 0.04em;
}
@media only screen and (min-width: 1024px) {
  .p-mak03__ttl__bottom {
    /* middle-size */
    font-size: 4.5rem;
    line-height: 1.2;
  }
}
.p-mak03__olist {
  transition: all 0.3s linear;
  margin-top: 8rem;
}
@media only screen and (min-width: 767px) {
  .p-mak03__olist {
    /* tablet-size */
    margin-top: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak03__olist {
    /* middle-size */
    margin-top: 8rem;
  }
}
.p-mak03__olist__wrap {
  margin-right: 3.7rem;
  margin-left: 3.7rem;
}
@media only screen and (min-width: 1024px) {
  .p-mak03__olist__wrap {
    /* middle-size */
    width: min(100% - 40px, 1125px);
    margin: auto;
  }
}
.p-mak03__olist__item {
  transition: all 0.3s linear;
  padding: 5rem 0;
}
@media only screen and (min-width: 767px) {
  .p-mak03__olist__item {
    /* tablet-size */
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
}
.p-mak03__olist__item:nth-of-type(1) {
  margin-top: 0;
  padding-top: 0;
}
.p-mak03__olist__item:nth-of-type(2n) {
  background: #F4F4F4;
}
@media only screen and (min-width: 767px) {
  .p-mak03__olist__item:nth-of-type(2n) .p-mak03__olist__wrap {
    /* tablet-size */
    flex-direction: row;
  }
}
.p-mak03__olist__item:last-of-type {
  padding-bottom: 0;
}
@media only screen and (min-width: 767px) {
  .p-mak03__olist__item .p-mak03__olist__wrap {
    /* tablet-size */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
  }
}
.p-mak03__olist__txtbox {
  transition: all 0.3s linear;
}
@media only screen and (min-width: 767px) {
  .p-mak03__olist__txtbox {
    /* tablet-size */
    width: calc(50% - 3rem);
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak03__olist__txtbox {
    /* middle-size */
  }
}
.p-mak03__olist__ttl {
  transition: all 0.3s linear;
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  font-size: 1.2rem;
  letter-spacing: 0.03em;
  color: #6A33C3;
  line-height: 1;
}
@media only screen and (min-width: 767px) {
  .p-mak03__olist__ttl {
    /* tablet-size */
    font-size: 1.5rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak03__olist__ttl {
    /* middle-size */
    font-size: 1.8rem;
  }
}
.p-mak03__olist__subttl {
  transition: all 0.3s linear;
  font-weight: 700;
  font-size: 2rem;
  line-height: 1.4;
  margin-top: 1rem;
}
@media only screen and (min-width: 767px) {
  .p-mak03__olist__subttl {
    /* tablet-size */
    font-size: 3.4rem;
    line-height: 1.1;
    margin-top: 2rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak03__olist__subttl {
    /* middle-size */
    font-size: 3.4rem;
  }
}
.p-mak03__olist__subttl-purple {
  color: #6A33C3;
}
.p-mak03__olist__subttl-small {
  font-size: 1.6rem;
}
@media only screen and (min-width: 1024px) {
  .p-mak03__olist__subttl-small {
    /* middle-size */
    font-size: 2.2rem;
  }
}
.p-mak03__olist__subttl-pink {
  color: #F06565;
  line-height: 1.3;
}
.p-mak03__olist__txt {
  transition: all 0.3s linear;
  margin-top: 2rem;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.7;
}
@media only screen and (min-width: 767px) {
  .p-mak03__olist__txt {
    /* tablet-size */
    font-size: 1.6rem;
    line-height: 1.9;
    margin-top: 3rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak03__olist__txt {
    /* middle-size */
    margin-top: 5rem;
    padding-right: 10rem;
  }
}
.p-mak03__olist__img {
  transition: all 0.3s linear;
  width: 100%;
  margin-top: 3rem;
  border-radius: 10px;
}
@media only screen and (min-width: 767px) {
  .p-mak03__olist__img {
    /* tablet-size */
    width: 50%;
    border: 8px solid #E4DFF1;
    margin-top: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak03__olist__img {
    /* middle-size */
    width: 52rem;
  }
}
.p-mak03__btnbox {
  margin-top: 6rem;
  display: flex;
  justify-content: center;
}
@media only screen and (min-width: 1024px) {
  .p-mak03__btnbox {
    /* middle-size */
    width: min(100% - 40px, 1125px);
    margin-right: auto;
    margin-left: auto;
    margin-top: 4rem;
    justify-content: end;
  }
}
.p-mak03__btn {
  transition: all 0.3s linear;
  font-size: 1.4rem;
  background: #fff;
  padding: 2rem 2.5rem 2rem;
  border-radius: 10rem;
  display: block;
  font-weight: 700;
  border-style: solid;
  border-width: 0.2rem;
  border-color: #E4DFF1;
  position: relative;
  color: #fff;
  width: 30rem;
  color: #6A33C3;
  line-height: 1.3;
}
@media only screen and (min-width: 767px) {
  .p-mak03__btn {
    font-size: 1.8rem;
  }
}
@media only screen and (min-width: 767px) {
  .p-mak03__btn {
    /* tablet-size */
    width: 40rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak03__btn {
    width: 45.4rem;
    padding: 3.8rem 4.3rem 3.8rem;
    border-width: 0.5rem;
  }
}
.p-mak03__btn::after {
  transition: all 0.3s linear;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  font-family: "Font Awesome 6 Free";
  display: inline-block;
  font-weight: 900;
  content: "\f054";
  right: 1.9rem;
  display: flex;
  align-items: center;
  color: #E4DFF1;
}
@media only screen and (min-width: 1024px) {
  .p-mak03__btn::after {
    /* middle-size */
    right: 4.4rem;
    font-size: 2.3rem;
  }
}
.p-mak03__btn:hover {
  color: #fff;
  background-color: #6A33C3;
}
.p-mak03__btn:hover::after {
  color: #fff;
}
.p-mak04 {
  transition: all 0.3s linear;
  margin-right: 2rem;
  margin-left: 2rem;
}
@media only screen and (min-width: 767px) {
  .p-mak04 {
    /* tablet-size */
    margin-right: 0;
    margin-left: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak04 {
    /* middle-size */
    width: min(100% - 40px, 1125px);
    margin-right: auto;
    margin-left: auto;
  }
}
.p-mak04__outer {
  margin-top: 12rem;
  background-image: linear-gradient(180deg, #fff 1.5rem, #F2F2F2 1.5rem, rgba(239, 236, 241, 0) 6%);
}
@media only screen and (min-width: 767px) {
  .p-mak04__outer {
    /* tablet-size */
    background-image: linear-gradient(180deg, #fff 3rem, #F2F2F2 3rem, rgba(239, 236, 241, 0) 17.13%);
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak04__outer {
    /* middle-size */
    margin-top: 13rem;
    background-image: linear-gradient(180deg, #fff 4.5rem, #F2F2F2 4.3%, rgba(239, 236, 241, 0) 17.13%);
  }
}
.p-mak04__ttl {
  transition: all 0.3s linear;
  color: #6A33C3;
  font-weight: 700;
  font-size: 2.1rem;
  line-height: 1.5;
  text-align: center;
}
@media only screen and (min-width: 767px) {
  .p-mak04__ttl {
    /* tablet-size */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
    align-items: baseline;
    font-size: 2.5rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak04__ttl {
    /* middle-size */
    font-size: 5.2rem;
    width: min(100% - 40px, 1115px);
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    align-items: baseline;
    font-size: 3.5rem;
  }
}
.p-mak04__ttl-pink {
  transition: all 0.3s linear;
  font-weight: 700;
  font-size: 3.3rem;
  color: #F06565;
}
@media only screen and (min-width: 767px) {
  .p-mak04__ttl-pink {
    /* tablet-size */
    font-size: 3.7rem;
    margin: 0 1rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak04__ttl-pink {
    /* middle-size */
    font-size: 5.5rem;
  }
}
.p-mak04__img {
  width: calc(100% - 4rem);
  margin-right: 2rem;
  margin-left: 2rem;
  margin-top: 4rem;
  display: block;
}
@media only screen and (min-width: 1024px) {
  .p-mak04__img {
    /* middle-size */
    margin-top: 6rem;
  }
}
.p-mak04__ulist {
  transition: all 0.3s linear;
  margin-top: 10rem;
  margin-right: 1.7rem;
  margin-left: 1.7rem;
}
@media only screen and (min-width: 767px) {
  .p-mak04__ulist {
    /* tablet-size */
    margin-right: 0;
    margin-left: 0;
    margin-top: 5rem;
    display: flex;
    justify-content: space-between;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak04__ulist {
    /* middle-size */
    width: min(100% - 40px, 1115px);
    margin-right: auto;
    margin-left: auto;
    margin-top: 6rem;
  }
}
.p-mak04__ulist__item {
  transition: all 0.3s linear;
  margin-top: 8rem;
}
@media only screen and (min-width: 767px) {
  .p-mak04__ulist__item {
    /* tablet-size */
    width: calc(33.3333333333% - 2rem);
    margin-top: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak04__ulist__item {
    /* middle-size */
    width: calc(33.3333333333% - 2.5rem);
  }
}
.p-mak04__ulist__item:nth-of-type(1) {
  margin-top: 0;
}
.p-mak04__ulist__cat {
  display: block;
  width: 16rem;
  margin: auto;
  padding: 0.8rem 0;
  border-radius: 100px;
  background: #6A33C3;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 1.4rem;
  letter-spacing: 0.05em;
  text-align: center !important;
}
@media only screen and (min-width: 767px) {
  .p-mak04__ulist__cat {
    /* tablet-size */
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak04__ulist__cat {
    /* middle-size */
    width: 19.6rem;
    padding: 1rem 0;
    font-size: 1.7rem;
  }
}
.p-mak04__ulist__cat.-wide {
  width: 20rem;
}
@media only screen and (min-width: 767px) {
  .p-mak04__ulist__cat.-wide {
    width: 20rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak04__ulist__cat.-wide {
    width: 25rem;
  }
}
.p-mak04__ulist__cat.-max {
  width: 28rem;
}
@media only screen and (min-width: 767px) {
  .p-mak04__ulist__cat.-max {
    width: 100%;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak04__ulist__cat.-max {
    width: 32rem;
  }
}
.p-mak04__ulist__ttl {
  transition: all 0.3s linear;
  font-weight: 700;
  font-size: 2rem;
  margin-top: 1.5rem;
  text-align: center;
}
@media only screen and (min-width: 767px) {
  .p-mak04__ulist__ttl {
    /* tablet-size */
    font-size: 1.8rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak04__ulist__ttl {
    /* middle-size */
    margin-top: 2rem;
    font-size: 2.3rem;
  }
}
.p-mak04__ulist__ttl.-en {
  line-height: 1.3;
}
@media only screen and (min-width: 767px) {
  .p-mak04__ulist__ttl.-en {
    min-height: 4.7rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak04__ulist__ttl.-en {
    min-height: 6rem;
  }
}
.p-mak04__ulist__img {
  transition: all 0.3s linear;
  border-radius: 30px;
  filter: drop-shadow(0px 8px 14px rgba(0, 0, 0, 0.25));
  margin-top: 2rem;
  width: 100%;
}
@media only screen and (min-width: 767px) {
  .p-mak04__ulist__img {
    /* tablet-size */
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak04__ulist__img {
    /* middle-size */
  }
}
.p-mak05__outer {
  transition: all 0.3s linear;
  margin-top: 17rem;
  margin-bottom: 6rem;
  padding-bottom: 7rem;
  background-image: linear-gradient(180deg, #fff 2rem, #EAE6F5 2rem);
}
@media only screen and (min-width: 767px) {
  .p-mak05__outer {
    /* tablet-size */
    padding-bottom: 4.5rem;
    background-image: linear-gradient(180deg, #fff 1.5rem, #EAE6F5 1.5rem);
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak05__outer {
    /* middle-size */
    margin-top: 16rem;
    padding-bottom: 9rem;
    background-image: linear-gradient(180deg, #fff 2rem, #EAE6F5 2rem);
  }
}
.p-mak05__ttl {
  transition: all 0.3s linear;
  text-align: center;
}
@media only screen and (min-width: 767px) {
  .p-mak05__ttl {
    /* tablet-size */
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak05__ttl {
    /* middle-size */
  }
}
@media only screen and (min-width: 767px) {
  .p-mak05__ttl.-en {
    flex-direction: column;
  }
}
.p-mak05__ttl__large {
  font-size: 3.9rem;
  color: #6A33C3;
  margin-bottom: 3rem;
  font-weight: 700;
}
@media only screen and (min-width: 767px) {
  .p-mak05__ttl__large {
    /* tablet-size */
    margin-bottom: 0;
    font-size: 2.9rem;
    margin-right: 2rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak05__ttl__large {
    /* middle-size */
    font-size: 3.9rem;
  }
}
@media only screen and (min-width: 767px) {
  .p-mak05__ttl__large.-en {
    margin-right: 0;
    margin-bottom: 2rem;
  }
}
.p-mak05__ttl__small {
  display: inline;
  margin-top: 4rem;
  padding: 0 0 4px;
  background: linear-gradient(transparent 92%, #6A33C3 0%);
  color: #6A33C3;
  font-weight: 500;
  line-height: 2;
  font-size: 1.4rem;
}
@media only screen and (min-width: 767px) {
  .p-mak05__ttl__small {
    /* tablet-size */
    background: linear-gradient(transparent 97%, #6A33C3 0%);
    margin-top: 0;
    padding: 0 0px 2px;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak05__ttl__small {
    /* middle-size */
    font-size: 1.8rem;
  }
}
.p-mak05__ulist {
  margin-top: 6rem;
}
@media only screen and (min-width: 767px) {
  .p-mak05__ulist {
    /* tablet-size */
    display: flex;
    justify-content: space-between;
    margin-right: 3.7rem;
    margin-left: 3.7rem;
    margin-top: 3rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak05__ulist {
    /* middle-size */
    margin-top: 5rem;
    width: min(100% - 40px, 1133px);
    margin-right: auto;
    margin-left: auto;
    margin-top: 2rem;
  }
}
.p-mak05__ulist__item {
  position: relative;
}
@media only screen and (min-width: 767px) {
  .p-mak05__ulist__item {
    /* tablet-size */
    width: calc(33.3333333333% - 2rem);
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak05__ulist__item {
    /* middle-size */
    width: calc(33.3333333333% - 3.5rem);
  }
}
.p-mak05__ulist__item::after {
  font-family: "Font Awesome 6 Free";
  display: inline-block;
  font-weight: 900;
  content: "\f107";
  display: block;
  text-align: center;
  font-size: 3rem;
  margin: 2.5rem auto;
  color: #6a33c3;
}
@media only screen and (min-width: 767px) {
  .p-mak05__ulist__item::after {
    /* tablet-size */
    content: "\f105";
    position: absolute;
    top: 0;
    bottom: 0;
    right: -2.3rem;
    margin: auto;
    display: flex;
    align-items: center;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak05__ulist__item::after {
    /* middle-size */
    right: -3.5rem;
    font-size: 4rem;
  }
}
.p-mak05__ulist__item:nth-of-type(1) .p-mak05__ulist__box::before {
  background-image: url(../images/make/mak05_img01.png);
}
.p-mak05__ulist__item:nth-of-type(2) .p-mak05__ulist__box::before {
  background-image: url(../images/make/mak05_img02.png);
}
.p-mak05__ulist__item:nth-of-type(3)::after {
  display: none;
}
.p-mak05__ulist__item:nth-of-type(3) .p-mak05__ulist__box::before {
  background-image: url(../images/make/mak05_img03.png);
}
.p-mak05__ulist__box {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 29rem;
  height: 29rem;
  margin: auto;
  position: relative;
}
@media only screen and (min-width: 767px) {
  .p-mak05__ulist__box {
    /* tablet-size */
    width: 22rem;
    height: 22rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak05__ulist__box {
    /* middle-size */
    width: 33rem;
    height: 33rem;
  }
}
.p-mak05__ulist__box::before {
  content: "";
  display: block;
  position: absolute;
  width: 29rem;
  height: 29rem;
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-size: 100%;
  filter: drop-shadow(2px 4px 16px rgba(103, 103, 103, 0.25));
}
@media only screen and (min-width: 767px) {
  .p-mak05__ulist__box::before {
    /* tablet-size */
    width: 22rem;
    height: 22rem;
    display: flex;
    align-items: center;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak05__ulist__box::before {
    /* middle-size */
    width: 33rem;
    height: 33rem;
  }
}
.p-mak05__ulist__cat {
  position: absolute;
  font-weight: 700;
  font-size: 2rem;
  line-height: 1.8;
  text-align: center !important;
  color: #FFFFFF;
}
@media only screen and (min-width: 767px) {
  .p-mak05__ulist__cat {
    /* tablet-size */
    font-size: 1.8rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak05__ulist__cat {
    /* middle-size */
    font-size: 2.6rem;
    line-height: 1.4;
  }
}
.p-mak08 {
  transition: all 0.3s linear;
  padding: 4.5rem 1.6rem 4.3rem;
}
@media only screen and (min-width: 767px) {
  .p-mak08 {
    /* tablet-size */
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak08 {
    /* middle-size */
  }
}
.p-mak08__outer {
  transition: all 0.3s linear;
  margin-top: 5rem;
  background-image: url(../images/top/abu08_bg-sp.jpg);
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-size: 100%;
}
@media only screen and (min-width: 767px) {
  .p-mak08__outer {
    /* tablet-size */
    background-image: url(../images/top/abu08_bg-pc.jpg);
    background-position: 60% 50%;
    background-size: 164rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak08__outer {
    /* middle-size */
    width: min(100% - 40px, 1215px);
    min-height: 53.4rem;
    margin-right: auto;
    margin-left: auto;
    background-size: contain;
    background-position: 50% 50%;
    padding: 5rem;
  }
}
.p-mak08__lead__txt {
  transition: all 0.3s linear;
  display: inline;
  padding: 0 0px 1.1rem;
  background: linear-gradient(transparent 92%, #fff 0%);
  color: #FFFFFF;
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 2;
  letter-spacing: 0.05em;
  position: relative;
  left: 2rem;
}
@media only screen and (min-width: 767px) {
  .p-mak08__lead__txt {
    /* tablet-size */
    font-size: 3.2rem;
    line-height: 2.4;
    background: linear-gradient(transparent 94%, #fff 0%);
    padding: 0 0 2rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak08__lead__txt {
    /* middle-size */
    font-size: 4rem;
    background: linear-gradient(transparent 96%, #fff 0%);
    line-height: 2.2;
  }
}
@media only screen and (min-width: 767px) {
  .p-mak08__lead {
    /* tablet-size */
    width: calc(50% - 5rem);
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak08__lead {
    /* middle-size */
  }
}
.p-mak08__box {
  transition: all 0.3s linear;
  margin-top: 3.5rem;
  box-shadow: 2px 7px 12px 3px rgba(131, 123, 153, 0.25);
  border-radius: 10px;
  padding: 3rem 1.7rem;
  background: rgba(255, 255, 255, 0.85);
}
@media only screen and (min-width: 767px) {
  .p-mak08__box {
    /* tablet-size */
    width: calc(50% - 5rem);
    margin-top: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak08__box {
    /* middle-size */
    width: 43.8rem;
    padding: 2rem 2.7rem 4rem;
  }
}
.p-mak08__txtbox {
  transition: all 0.3s linear;
  position: relative;
  padding: 1.5rem 0;
  border-bottom: 0.2rem solid #7147A0;
  line-height: 1.6;
  text-align: center;
}
@media only screen and (min-width: 767px) {
  .p-mak08__txtbox {
    /* tablet-size */
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak08__txtbox {
    /* middle-size */
    line-height: 1.4;
    padding: 1.5rem 0 2.5rem;
  }
}
.p-mak08__txtbox span {
  letter-spacing: 0.03em;
  font-weight: 700;
}
.p-mak08__txtbox__style01 {
  transition: all 0.3s linear;
  font-size: 2.2rem;
  color: #7147A0;
}
@media only screen and (min-width: 767px) {
  .p-mak08__txtbox__style01 {
    /* tablet-size */
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak08__txtbox__style01 {
    /* middle-size */
    font-size: 2.6rem;
  }
}
.p-mak08__txtbox__style02 {
  transition: all 0.3s linear;
  font-size: 1.6rem;
  color: #2D313D;
}
@media only screen and (min-width: 767px) {
  .p-mak08__txtbox__style02 {
    /* tablet-size */
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak08__txtbox__style02 {
    /* middle-size */
    font-size: 1.9rem;
  }
}
.p-mak08__txtbox__style03 {
  transition: all 0.3s linear;
  font-size: 2.5rem;
  color: #7E53A3;
}
@media only screen and (min-width: 767px) {
  .p-mak08__txtbox__style03 {
    /* tablet-size */
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak08__txtbox__style03 {
    /* middle-size */
    font-size: 3.2rem;
  }
}
.p-mak08__txtbox__style04 {
  transition: all 0.3s linear;
  font-size: 1.4rem;
  color: #2D313D;
}
@media only screen and (min-width: 767px) {
  .p-mak08__txtbox__style04 {
    /* tablet-size */
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak08__txtbox__style04 {
    /* middle-size */
    font-size: 1.9rem;
  }
}
.p-mak08__txtbox::before, .p-mak08__txtbox::after {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 14px 10px 0 10px;
  content: "";
}
.p-mak08__txtbox::before {
  bottom: -1.4rem;
  border-color: #7147A0 transparent transparent transparent;
}
.p-mak08__txtbox::after {
  bottom: -1rem;
  border-color: #f3f2f0 transparent transparent transparent;
}
.p-mak08__logo {
  transition: all 0.3s linear;
  display: block;
  width: 80%;
  margin: auto;
  margin-top: 2.5rem;
}
@media only screen and (min-width: 767px) {
  .p-mak08__logo {
    /* tablet-size */
  }
}
@media only screen and (min-width: 1024px) {
  .p-mak08__logo {
    /* middle-size */
    width: 100%;
    margin-top: 3.5rem;
  }
}
.p-mak08__btn {
  transition: all 0.3s linear;
  background: linear-gradient(92.6deg, #7046A0 10.21%, #7349A1 15.35%, #A273AC 100%);
  border-radius: 10rem;
  font-weight: 700;
  color: #fff;
  width: 30rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 2rem auto 0;
  padding: 1.3rem 0 1.3rem 1.5rem;
  font-size: 1.4rem;
  letter-spacing: 0.02em;
  box-shadow: 0px 0px 8px 2px rgba(84, 56, 165, 0.43);
}
@media only screen and (min-width: 1024px) {
  .p-mak08__btn {
    /* middle-size */
    width: 100%;
    font-size: 1.6rem;
    padding: 1.6rem 0 1.6rem 1.5rem;
    margin-top: 3rem;
  }
}
.p-mak08__btn:hover:hover {
  filter: brightness(120%);
}
.p-mak08__btn::before {
  transition: all 0.3s linear;
  font-family: "Font Awesome 6 Free";
  display: inline-block;
  font-weight: 900;
  content: "\f138";
  color: #fff;
  font-size: 2.9rem;
  margin-right: 1.8rem;
}
@media only screen and (min-width: 1024px) {
  .p-mak08__btn::before {
    /* middle-size */
    font-size: 3.7rem;
  }
}

.p-mfun__h1 {
  font-size: 2.5rem;
  line-height: 1.6;
}
.p-mfun__h1 span {
  background-clip: text;
  -webkit-background-clip: text;
  color: rgba(0, 0, 0, 0);
  display: inline-block;
  line-height: 1.4;
  background-image: linear-gradient(88.15deg, #9717B6 2.31%, #6E28AE 101.86%);
}
.p-mfun__wrap {
  margin-top: 5rem;
}
@media only screen and (min-width: 767px) {
  .p-mfun__wrap {
    /* tablet-size */
    display: flex;
    justify-content: space-between;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mfun__wrap {
    /* middle-size */
    align-items: center;
  }
}
.p-mfun__txt {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 2;
  text-align: justify;
}
@media only screen and (min-width: 767px) {
  .p-mfun__txt {
    /* tablet-size */
    width: calc(50% - 2rem);
  }
}
@media only screen and (min-width: 1024px) {
  .p-mfun__txt {
    /* middle-size */
    font-size: 1.9rem;
    width: calc(100% - 72rem);
    line-height: 2.2;
  }
}
@media only screen and (min-width: 767px) {
  .p-mfun__txt p {
    /* tablet-size */
    margin-top: 2rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mfun__txt p {
    /* middle-size */
    margin-top: 6rem;
  }
}
@media only screen and (min-width: 767px) {
  .p-mfun__txt p:nth-of-type(1) {
    /* tablet-size */
    margin-top: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mfun__txt p:nth-of-type(1) {
    /* middle-size */
    margin-top: 0;
  }
}
.p-mfun__txt span {
  color: #6a33c3;
}
@media only screen and (min-width: 767px) {
  .p-mfun__box {
    /* tablet-size */
    width: calc(50% - 1rem);
  }
}
@media only screen and (min-width: 1024px) {
  .p-mfun__box {
    /* middle-size */
    width: 72rem;
  }
}
.p-mfun__box__img {
  width: 100%;
  margin-top: 4rem;
}
@media only screen and (min-width: 767px) {
  .p-mfun__box__img {
    /* tablet-size */
    width: 80%;
    margin: 0 auto;
    display: block;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mfun__box__img {
    /* middle-size */
    width: calc(100% - 4rem);
  }
}
.p-mfun__box__txt {
  background: #FFFFFF;
  box-shadow: 0px 4px 13px rgba(133, 133, 133, 0.25);
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 4rem;
  margin-top: 1.5rem;
}
@media only screen and (min-width: 767px) {
  .p-mfun__box__txt {
    /* tablet-size */
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mfun__box__txt {
    /* middle-size */
    padding: 3rem 2.5rem;
    border-radius: 15px;
    margin-top: -0.5rem;
    z-index: -1;
    position: relative;
  }
}
.p-mfun__box__txt p {
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.9;
}
@media only screen and (min-width: 767px) {
  .p-mfun__box__txt p {
    /* tablet-size */
    font-size: 1.2rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mfun__box__txt p {
    /* middle-size */
    font-size: 1.4rem;
  }
}
.p-mfun01 {
  margin-right: 3.7rem;
  margin-left: 3.7rem;
}
@media only screen and (min-width: 1024px) {
  .p-mfun01 {
    /* middle-size */
    width: min(100% - 40px, 1215px);
    margin-right: auto;
    margin-left: auto;
  }
}
.p-mfun01__outer {
  margin-top: 9rem;
}
@media only screen and (min-width: 767px) {
  .p-mfun01__ulist {
    /* tablet-size */
  }
}
@media only screen and (min-width: 1024px) {
  .p-mfun01__ulist {
    /* middle-size */
  }
}
.p-mfun01__ulist__item {
  margin-top: 8rem;
}
@media only screen and (min-width: 767px) {
  .p-mfun01__ulist__item {
    /* tablet-size */
    display: flex;
    align-items: flex-start;
    margin-top: 8rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mfun01__ulist__item {
    /* middle-size */
    margin-top: 10rem;
  }
}
.p-mfun01__ulist__item:nth-of-type(1) {
  margin-top: 0;
}
@media only screen and (min-width: 767px) {
  .p-mfun01__ulist__item:nth-of-type(1) {
    /* tablet-size */
    justify-content: flex-start;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mfun01__ulist__item:nth-of-type(1) {
    /* middle-size */
  }
}
@media only screen and (min-width: 767px) {
  .p-mfun01__ulist__item:nth-of-type(1) > img {
    /* tablet-size */
    margin-right: 3rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mfun01__ulist__item:nth-of-type(1) > img {
    /* middle-size */
    margin-right: 6rem;
  }
}
@media only screen and (min-width: 767px) {
  .p-mfun01__ulist__item:nth-of-type(2) {
    /* tablet-size */
    justify-content: flex-start;
    flex-direction: row-reverse;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mfun01__ulist__item:nth-of-type(2) {
    /* middle-size */
  }
}
@media only screen and (min-width: 767px) {
  .p-mfun01__ulist__item:nth-of-type(2) > img {
    /* tablet-size */
    margin-left: 3rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mfun01__ulist__item:nth-of-type(2) > img {
    /* middle-size */
    margin-left: 6rem;
  }
}
@media only screen and (min-width: 767px) {
  .p-mfun01__ulist__box {
    /* tablet-size */
    width: calc(50% - 3rem);
  }
}
@media only screen and (min-width: 1024px) {
  .p-mfun01__ulist__box {
    /* middle-size */
    width: 45rem;
  }
}
.p-mfun01__ulist__ttl {
  font-weight: 700;
  font-size: 2rem;
  line-height: 1.5;
  letter-spacing: 0.03em;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
@media only screen and (min-width: 767px) {
  .p-mfun01__ulist__ttl {
    /* tablet-size */
  }
}
@media only screen and (min-width: 1024px) {
  .p-mfun01__ulist__ttl {
    /* middle-size */
    font-size: 3rem;
  }
}
.p-mfun01__ulist__txt {
  margin-top: 2rem;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.7;
}
@media only screen and (min-width: 767px) {
  .p-mfun01__ulist__txt {
    /* tablet-size */
  }
}
@media only screen and (min-width: 1024px) {
  .p-mfun01__ulist__txt {
    /* middle-size */
    margin-top: 3rem;
    font-size: 1.6rem;
    line-height: 1.9;
  }
}
.p-mfun01__ulist__img {
  margin-top: 2rem;
  width: 100%;
}
@media only screen and (min-width: 767px) {
  .p-mfun01__ulist__img {
    /* tablet-size */
    width: calc(50% - 3rem);
    margin-top: 0;
    object-fit: contain;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mfun01__ulist__img {
    /* middle-size */
    width: 61.8rem;
  }
}
.p-mfun01__ulist__btn, .p-mfun01__ulist__btn-gradient, .p-mfun01__ulist__btn-purple {
  transition: all 0.3s linear;
  font-size: 1.4rem;
  margin-top: 3rem;
  border-radius: 10rem;
  display: block;
  font-weight: 600;
  border-style: solid;
  border-width: 0.2rem;
  position: relative;
  color: #fff;
  line-height: 1.3;
}
.p-mfun01__ulist__btn:hover, .p-mfun01__ulist__btn-gradient:hover, .p-mfun01__ulist__btn-purple:hover {
  filter: brightness(120%);
}
@media only screen and (min-width: 767px) {
  .p-mfun01__ulist__btn, .p-mfun01__ulist__btn-gradient, .p-mfun01__ulist__btn-purple {
    /* tablet-size */
    margin-top: 2rem;
    width: 80%;
    padding: 2rem 0 2rem 7%;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mfun01__ulist__btn, .p-mfun01__ulist__btn-gradient, .p-mfun01__ulist__btn-purple {
    width: 30rem;
    margin-top: 4rem;
    padding: 2.1rem 0 2.1rem 3.3rem;
  }
}
.p-mfun01__ulist__btn-purple {
  background-image: linear-gradient(271.74deg, #5D2899 2.97%, #A678DB 96.4%);
  padding: 2rem 0 2rem 7.5rem;
}
.p-mfun01__ulist__btn-purple.-en {
  padding: 2rem 0 2rem 3.5rem;
}
.p-mfun01__ulist__btn-gradient {
  background-image: linear-gradient(93.4deg, #007DFF 9.19%, #6E27B4 41.73%, #E3047D 80.03%, #EF536F 102.05%);
  padding: 2rem 0 2rem 3.5rem;
}
.p-mfun01__ulist__btn::before, .p-mfun01__ulist__btn-purple::before, .p-mfun01__ulist__btn-gradient::before, .p-mfun01__ulist__btn::after, .p-mfun01__ulist__btn-purple::after, .p-mfun01__ulist__btn-gradient::after {
  transition: all 0.3s linear;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}
.p-mfun01__ulist__btn::before, .p-mfun01__ulist__btn-purple::before, .p-mfun01__ulist__btn-gradient::before {
  transition: all 0.3s linear;
  display: block;
  right: 6.5rem;
  width: 0.1rem;
  height: calc(100% - 2.2rem);
  content: "";
  background-color: #fff;
}
@media only screen and (min-width: 767px) {
  .p-mfun01__ulist__btn::before, .p-mfun01__ulist__btn-purple::before, .p-mfun01__ulist__btn-gradient::before {
    /* tablet-size */
    right: 4.5rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mfun01__ulist__btn::before, .p-mfun01__ulist__btn-purple::before, .p-mfun01__ulist__btn-gradient::before {
    /* tablet-size */
    height: calc(100% - 3rem);
    right: 5.5rem;
  }
}
.p-mfun01__ulist__btn::after, .p-mfun01__ulist__btn-purple::after, .p-mfun01__ulist__btn-gradient::after {
  transition: all 0.3s linear;
  font-family: "Font Awesome 6 Free";
  display: inline-block;
  font-weight: 900;
  content: "\f054";
  right: 2.6rem;
  display: flex;
  align-items: center;
}
.p-mfun01__ulist__logo {
  height: 2.2rem;
}
.p-mfun01__ulist__logo.-en {
  vertical-align: baseline;
  margin-left: 1rem;
}
@media only screen and (min-width: 1024px) {
  .p-mfun02 {
    /* middle-size */
    width: min(100% - 40px, 1215px);
    margin-right: auto;
    margin-left: auto;
  }
}
.p-mfun02__outer {
  margin-top: 9rem;
  padding-bottom: 4rem;
  background: linear-gradient(11.16deg, #F7F7F7 4%, #F8F8F8 49.02%, #FFFFFF 94.04%);
}
@media only screen and (min-width: 1024px) {
  .p-mfun02__outer {
    /* middle-size */
    padding-bottom: 17rem;
    background: linear-gradient(360deg, #F5F5F5 0%, rgba(245, 241, 255, 0) 100%);
  }
}
.p-mfun02__box01 {
  margin-right: 1.5rem;
  margin-left: 1.5rem;
  padding: 5rem 2.5rem 4rem;
  background: #FFFFFF;
  box-shadow: 0px 4px 13px rgba(133, 133, 133, 0.25);
  border-radius: 7px;
}
@media only screen and (min-width: 767px) {
  .p-mfun02__box01 {
    /* tablet-size */
    margin-right: 3.7rem;
    margin-left: 3.7rem;
    padding: 4rem 7rem 2.5rem;
    border-radius: 25px;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mfun02__box01 {
    /* middle-size */
    border-radius: 50px;
    margin-top: 12rem;
    margin-right: 0;
    margin-left: 0;
  }
}
.p-mfun02__box01__img {
  width: 100%;
}
.p-mfun02__box02 {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;
}
@media only screen and (min-width: 1024px) {
  .p-mfun02__box02 {
    /* middle-size */
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}
.p-mfun02__box02__txt {
  font-weight: 700;
  font-size: 1.9rem;
  line-height: 1.6;
  text-align: center !important;
  color: #6a33c3;
  margin-left: 20px;
  margin-right: 20px;
}
@media only screen and (min-width: 767px) {
  .p-mfun02__box02__txt {
    /* tablet-size */
  }
}
@media only screen and (min-width: 1024px) {
  .p-mfun02__box02__txt {
    /* middle-size */
    text-align: left !important;
    margin-left: 0;
    margin-right: 5.3rem;
  }
}
.p-mfun02__box02__txt span {
  color: #f06565;
}
.p-mfun02__box02__btn {
  transition: all 0.3s linear;
  background-color: #6A33C3;
  border-radius: 10rem;
  font-weight: 700;
  color: #fff;
  width: 30rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 3rem auto 0;
  font-size: 1.4rem;
  letter-spacing: 0.02em;
  padding: 1.8rem 1.5rem 1.8rem 0;
}
.p-mfun02__box02__btn:hover {
  filter: brightness(120%);
}
@media only screen and (min-width: 1024px) {
  .p-mfun02__box02__btn {
    /* middle-size */
    margin: 0;
    width: 44.7rem;
    font-size: 1.8rem;
    padding: 3rem 3.3rem 3rem 4rem;
    justify-content: space-between;
  }
}
.p-mfun02__box02__btn::after {
  transition: all 0.3s linear;
  font-family: "Font Awesome 6 Free";
  display: inline-block;
  font-weight: 900;
  content: "\f138";
  color: #fff;
  font-size: 2.9rem;
  margin-left: 1.8rem;
}
@media only screen and (min-width: 1024px) {
  .p-mfun02__box02__btn::after {
    /* middle-size */
    font-size: 3.7rem;
  }
}
.p-mfun02__box02__btn span {
  margin-right: 2rem;
}
@media only screen and (min-width: 767px) {
  .p-mfun02__box02__btn span {
    margin-right: 0;
  }
}
@media only screen and (min-width: 767px) {
  .p-mfun03 {
    /* tablet-size */
  }
}
@media only screen and (min-width: 1024px) {
  .p-mfun03 {
    /* middle-size */
    width: min(100% - 40px, 1215px);
    margin-right: auto;
    margin-left: auto;
  }
}
.p-mfun03__outer {
  padding-top: 6rem;
}
@media only screen and (min-width: 767px) {
  .p-mfun03__outer {
    /* tablet-size */
  }
}
@media only screen and (min-width: 1024px) {
  .p-mfun03__outer {
    /* middle-size */
    padding-top: 16rem;
  }
}
.p-mfun03__ttl {
  color: #6a33c3;
  text-align: center;
  font-weight: 700;
  font-size: 2.4rem;
}
@media only screen and (min-width: 767px) {
  .p-mfun03__ttl {
    /* tablet-size */
    font-size: 3.1rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mfun03__ttl {
    /* middle-size */
    font-size: 3.8rem;
  }
}
.p-mfun03__txt {
  margin-top: 2rem;
  font-weight: 700;
  font-size: 1.4rem;
  text-align: center !important;
  line-height: 1.7;
  margin-right: 2.2rem;
  margin-left: 2.2rem;
}
@media only screen and (min-width: 767px) {
  .p-mfun03__txt {
    /* tablet-size */
  }
}
@media only screen and (min-width: 1024px) {
  .p-mfun03__txt {
    /* middle-size */
    font-size: 1.8rem;
  }
}
.p-mfun03__ulist {
  margin-top: 3.5rem;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
@media only screen and (min-width: 767px) {
  .p-mfun03__ulist {
    /* tablet-size */
    display: flex;
    justify-content: space-between;
    margin-right: 3.7rem;
    margin-left: 3.7rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mfun03__ulist {
    /* middle-size */
  }
}
.p-mfun03__ulist__item {
  background: #FFFFFF;
  box-shadow: 2px 7px 20px 2px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  padding: 3rem 2rem 4rem;
  margin-top: 2rem;
}
@media only screen and (min-width: 767px) {
  .p-mfun03__ulist__item {
    /* tablet-size */
    box-shadow: 2px 7px 20px 2px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    margin-top: 0;
    width: calc(33.3333333333% - 1rem);
    padding: 2rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mfun03__ulist__item {
    /* middle-size */
    padding: 3rem;
  }
}
.p-mfun03__ulist__item:nth-of-type(1) {
  margin-top: 0;
}
.p-mfun03__ulist__img {
  width: 100%;
}
.p-mfun03__ulist__box {
  margin-top: 3rem;
  margin-right: 2rem;
  margin-left: 2rem;
}
@media only screen and (min-width: 767px) {
  .p-mfun03__ulist__box {
    /* tablet-size */
    margin-right: 0;
    margin-left: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mfun03__ulist__box {
    /* middle-size */
  }
}
.p-mfun03__ulist__ttl {
  font-weight: 700;
  font-size: 2rem;
  color: #7349A0;
}
@media only screen and (min-width: 767px) {
  .p-mfun03__ulist__ttl {
    /* tablet-size */
    font-size: 1.8rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mfun03__ulist__ttl {
    /* middle-size */
    font-size: 2.2rem;
  }
}
.p-mfun03__ulist__txt {
  margin-top: 1rem;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 2;
}
@media only screen and (min-width: 767px) {
  .p-mfun03__ulist__txt {
    /* tablet-size */
  }
}
@media only screen and (min-width: 1024px) {
  .p-mfun03__ulist__txt {
    /* middle-size */
    font-size: 1.6rem;
    margin-top: 2rem;
  }
}
.p-mfun04 {
  background: #FFFFFF;
  box-shadow: 2px 7px 20px 2px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  margin-right: 1.5rem;
  margin-left: 1.5rem;
  padding: 5rem 2.3rem;
}
@media only screen and (min-width: 767px) {
  .p-mfun04 {
    /* tablet-size */
    margin-right: 3.7rem;
    margin-left: 3.7rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mfun04 {
    /* middle-size */
    width: min(100% - 40px, 1127px);
    margin-right: auto;
    margin-left: auto;
    box-shadow: 8px 10px 33px rgba(0, 0, 0, 0.08);
    border-radius: 30px;
    padding: 7rem 8rem;
  }
}
.p-mfun04__outer {
  margin-top: 7rem;
  padding-top: 27rem;
  padding-bottom: 7rem;
  background-image: url(../../assets/images/make/function/fun04_bg-sp.jpg);
  background-position: 50% 0px;
  background-size: 100%;
  background-repeat: no-repeat;
}
@media only screen and (min-width: 767px) {
  .p-mfun04__outer {
    /* tablet-size */
    padding-top: 14rem;
    background-image: url(../../assets/images/make/function/fun04_bg-pc.jpg);
  }
}
@media only screen and (min-width: 1024px) {
  .p-mfun04__outer {
    /* middle-size */
    padding-top: 28.3rem;
    padding-bottom: 15.2rem;
    background-image: url(../../assets/images/make/function/fun04_bg-pc.jpg);
    background-size: 144rem;
  }
}
.p-mfun04__ttl, .p-mfun04__ttl-navy, .p-mfun04__ttl-gradation {
  text-align: center;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 1.5;
}
@media only screen and (min-width: 767px) {
  .p-mfun04__ttl, .p-mfun04__ttl-navy, .p-mfun04__ttl-gradation {
    /* tablet-size */
  }
}
@media only screen and (min-width: 1024px) {
  .p-mfun04__ttl, .p-mfun04__ttl-navy, .p-mfun04__ttl-gradation {
    /* middle-size */
    font-size: 3rem;
  }
}
.p-mfun04__ttl p, .p-mfun04__ttl-navy p, .p-mfun04__ttl-gradation p,
.p-mfun04__ttl h2,
.p-mfun04__ttl-navy h2,
.p-mfun04__ttl-gradation h2 {
  text-align: center;
}
.p-mfun04__ttl p, .p-mfun04__ttl-navy p, .p-mfun04__ttl-gradation p {
  font-size: 1.4rem;
}
@media only screen and (min-width: 767px) {
  .p-mfun04__ttl p, .p-mfun04__ttl-navy p, .p-mfun04__ttl-gradation p {
    /* tablet-size */
  }
}
@media only screen and (min-width: 1024px) {
  .p-mfun04__ttl p, .p-mfun04__ttl-navy p, .p-mfun04__ttl-gradation p {
    /* middle-size */
    font-size: 2rem;
  }
}
.p-mfun04__ttl h2, .p-mfun04__ttl-navy h2, .p-mfun04__ttl-gradation h2 {
  margin-top: 1rem;
}
@media only screen and (min-width: 1024px) {
  .p-mfun04__ttl h2, .p-mfun04__ttl-navy h2, .p-mfun04__ttl-gradation h2 {
    /* middle-size */
    margin-top: 0;
  }
}
.p-mfun04__ttl-gradation {
  background-clip: text;
  -webkit-background-clip: text;
  color: rgba(0, 0, 0, 0);
  display: inline-block;
  line-height: 1.4;
  background-image: linear-gradient(90deg, #AA00D5 12.91%, #3F00C4 55.14%);
  font-size: 2.7rem;
}
@media only screen and (min-width: 767px) {
  .p-mfun04__ttl-gradation {
    /* tablet-size */
  }
}
@media only screen and (min-width: 1024px) {
  .p-mfun04__ttl-gradation {
    /* middle-size */
    font-size: 4.5rem;
  }
}
.p-mfun04__ttl-navy {
  color: #2100A5;
  font-size: 2.7rem;
}
@media only screen and (min-width: 767px) {
  .p-mfun04__ttl-navy {
    /* tablet-size */
  }
}
@media only screen and (min-width: 1024px) {
  .p-mfun04__ttl-navy {
    /* middle-size */
    font-size: 4.5rem;
  }
}
@media only screen and (min-width: 767px) {
  .p-mfun04__wrap {
    /* tablet-size */
    display: flex;
    justify-content: space-around;
    align-items: baseline;
    margin-top: 4rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mfun04__wrap {
    /* middle-size */
    justify-content: space-between;
  }
}
.p-mfun04__ulist {
  margin-top: 4rem;
}
@media only screen and (min-width: 767px) {
  .p-mfun04__ulist {
    /* tablet-size */
    display: flex;
    justify-content: space-between;
    margin-top: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mfun04__ulist {
    /* middle-size */
  }
}
.p-mfun04__ulist__item {
  margin-top: 3rem;
  text-align: center;
}
@media only screen and (min-width: 767px) {
  .p-mfun04__ulist__item {
    /* tablet-size */
    margin-top: 0;
    width: calc(50% - 1rem);
  }
}
@media only screen and (min-width: 1024px) {
  .p-mfun04__ulist__item {
    /* middle-size */
    width: 21rem;
  }
}
.p-mfun04__ulist__item:nth-of-type(1) {
  margin-top: 0;
}
.p-mfun04__ulist__item:nth-of-type(1) img {
  height: 22rem;
}
@media only screen and (min-width: 767px) {
  .p-mfun04__ulist__item:nth-of-type(1) img {
    /* tablet-size */
    height: auto;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mfun04__ulist__item:nth-of-type(2) {
    /* middle-size */
    margin-left: 5rem;
  }
}
.p-mfun04__ulist__item:nth-of-type(2) img {
  height: 15.5rem;
}
@media only screen and (min-width: 767px) {
  .p-mfun04__ulist__item:nth-of-type(2) img {
    /* tablet-size */
    height: auto;
  }
}
@media only screen and (min-width: 767px) {
  .p-mfun04__ulist__img {
    /* tablet-size */
    width: 100%;
    object-fit: contain;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mfun04__ulist__img {
    /* middle-size */
  }
}
.p-mfun04__ulist__txt {
  margin-top: 1rem;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 1.4;
  text-align: center !important;
  letter-spacing: 0.06em;
  color: #000000;
}
@media only screen and (min-width: 767px) {
  .p-mfun04__ulist__txt {
    /* tablet-size */
    font-size: 1.2rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mfun04__ulist__txt {
    /* middle-size */
  }
}
.p-mfun04__box {
  margin-top: 6rem;
}
@media only screen and (min-width: 767px) {
  .p-mfun04__box {
    /* tablet-size */
    margin-top: 0;
    margin-right: 0;
    margin-left: 5rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mfun04__box {
    /* middle-size */
    margin-left: 0;
    width: 41.2rem;
  }
}
.p-mfun04__box__txt {
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 2;
  margin-right: 1rem;
  margin-left: 1rem;
}
.p-mfun04__box__btn {
  transition: all 0.3s linear;
  font-size: 1.4rem;
  margin-top: 3rem;
  border-radius: 10rem;
  display: block;
  font-weight: 600;
  border-style: solid;
  border-width: 0.2rem;
  position: relative;
  color: #fff;
  margin-top: 4rem;
  padding: 1rem 2rem;
  background-image: linear-gradient(91.16deg, #6024AD 42.75%, #B58FE1 105.93%);
  line-height: 1.5;
}
.p-mfun04__box__btn:hover {
  filter: brightness(120%);
}
@media only screen and (min-width: 767px) {
  .p-mfun04__box__btn {
    /* tablet-size */
    margin-top: 2rem;
    padding: 2rem 0 2rem 1.6rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mfun04__box__btn {
    width: 100%;
    margin-top: 4rem;
    padding: 2.6rem 0 2.6rem 3.3rem;
  }
}
.p-mfun04__box__btn::before, .p-mfun04__box__btn::after {
  transition: all 0.3s linear;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}
.p-mfun04__box__btn::before {
  transition: all 0.3s linear;
  display: block;
  right: 5.5rem;
  width: 0.1rem;
  height: calc(100% - 3.4rem);
  content: "";
  background-color: #fff;
}
@media only screen and (min-width: 767px) {
  .p-mfun04__box__btn::before {
    /* tablet-size */
    right: 4rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mfun04__box__btn::before {
    /* tablet-size */
    height: calc(100% - 3rem);
  }
}
.p-mfun04__box__btn::after {
  transition: all 0.3s linear;
  font-family: "Font Awesome 6 Free";
  display: inline-block;
  font-weight: 900;
  content: "\f054";
  right: 2.6rem;
  display: flex;
  align-items: center;
}
@media only screen and (min-width: 767px) {
  .p-mfun04__box__btn::after {
    /* tablet-size */
    right: 1.6rem;
  }
}

.p-mcon__h1 {
  font-size: 2.5rem;
  line-height: 1.6;
}
.p-mcon__h1 span {
  background-clip: text;
  -webkit-background-clip: text;
  color: rgba(0, 0, 0, 0);
  display: inline-block;
  line-height: 1.4;
  background-image: linear-gradient(88.15deg, #9717B6 2.31%, #6E28AE 101.86%);
}
.p-mcon01__outer {
  margin-top: 4rem;
  padding-bottom: 4rem;
}
@media only screen and (min-width: 767px) {
  .p-mcon01__outer {
    /* tablet-size */
    margin-top: 6rem;
  }
}
@media only screen and (min-width: 1024px) {
  .p-mcon01__outer {
    /* middle-size */
    margin-top: 8rem;
  }
}

.nurse01 {
  padding: 6rem 0 0;
}
@media only screen and (min-width: 1024px) {
  .nurse01 {
    padding: 11rem 0 0;
  }
}
.nurse01__outer {
  margin-top: 4rem;
  padding: 3.5rem;
  background-color: #F0F0F0;
}
@media only screen and (min-width: 767px) {
  .nurse01__outer {
    margin-top: 2.5rem;
    background: linear-gradient(to bottom, #fff 50%, #F0F0F0 50%);
  }
}
@media only screen and (min-width: 1024px) {
  .nurse01__outer {
    margin-top: 8rem;
    padding: 0 0 18rem 0;
    background: linear-gradient(to bottom, #fff 25%, #F0F0F0 25%);
  }
}
.nurse01__ttl {
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.4;
}
@media only screen and (min-width: 767px) {
  .nurse01__ttl {
    font-size: 3.1rem;
  }
}
@media only screen and (min-width: 1024px) {
  .nurse01__ttl {
    font-size: 4.2rem;
  }
}
.nurse01__list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
@media only screen and (min-width: 767px) {
  .nurse01__list {
    flex-direction: row;
    justify-content: space-between;
  }
}
@media only screen and (min-width: 1024px) {
  .nurse01__list {
    margin-left: auto;
    margin-right: auto;
    width: min(100% - 40px, 1140px);
  }
}
.nurse01__item {
  margin-top: 4.5rem;
  display: flex;
  align-items: center;
  position: relative;
}
.nurse01__item:first-of-type {
  margin-top: 0;
}
@media only screen and (min-width: 767px) {
  .nurse01__item {
    width: calc(33.3333333333% - 2rem);
    margin-top: 0;
    display: flex;
    flex-direction: column;
  }
}
@media only screen and (min-width: 1024px) {
  .nurse01__item {
    width: calc(33.3333333333% - 2rem);
  }
}
.nurse01__txt {
  line-height: 1.5;
  margin-left: 1.5rem;
  font-weight: bold;
}
@media only screen and (min-width: 767px) {
  .nurse01__txt {
    font-size: 1.6rem;
    text-align: center;
    margin-left: 0;
    margin-top: 3rem;
  }
}
@media only screen and (min-width: 1024px) {
  .nurse01__txt {
    font-size: 2rem;
  }
}
.nurse01__img {
  width: 30%;
  position: relative;
}
@media only screen and (min-width: 767px) {
  .nurse01__img {
    width: 100%;
  }
}
@media only screen and (min-width: 1024px) {
  .nurse01__img {
    width: 27.7rem;
  }
}
.nurse01__img::before {
  display: block;
  content: "";
  position: absolute;
  background-image: url(../images/nurse/nur01_img04.png);
  top: -1.5rem;
  left: -1.9rem;
  width: 4.5rem;
  height: 5.2rem;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1;
}
@media only screen and (min-width: 767px) {
  .nurse01__img::before {
    top: -1.7rem;
    left: -2.7rem;
    width: 9.1rem;
    height: 10.3rem;
  }
}
.nurse01__img img {
  width: 100%;
}
.nurse02 {
  padding: 6rem 0;
}
.nurse02__outer {
  background-image: url(../images/nurse/nur02_bg01.png);
  background-size: contain;
  background-position: 50% 0;
  background-repeat: no-repeat;
  content: "";
  padding-top: 16rem;
}
@media only screen and (min-width: 1024px) {
  .nurse02__outer {
    background-image: url(../images/nurse/nur02_bg01_pc.png);
    background-position: 50% 0;
    background-size: 1440px;
    padding-top: 24.4rem;
  }
}
@media only screen and (min-width: 1440px) {
  .nurse02__outer {
    background-size: contain;
  }
}
.nurse02__inner {
  background: #FFFFFF;
  box-shadow: 8px 10px 33px rgba(0, 0, 0, 0.08);
  border-radius: 36px;
  padding: 4.5rem 2.5rem 4rem;
}
@media only screen and (min-width: 767px) {
  .nurse02__inner {
    padding: 10rem 5rem 5rem;
  }
}
@media only screen and (min-width: 1024px) {
  .nurse02__inner {
    padding: 15rem 10rem 6rem;
  }
}
.nurse02__ttl {
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.4;
  text-align: center;
}
@media only screen and (min-width: 767px) {
  .nurse02__ttl {
    font-size: 3.6rem;
  }
}
@media only screen and (min-width: 1024px) {
  .nurse02__ttl {
    font-size: 5.4rem;
  }
}
.nurse02__txt {
  line-height: 1.5;
  font-weight: bold;
  margin-top: 2rem;
  text-align: center;
}
@media only screen and (min-width: 767px) {
  .nurse02__txt {
    line-height: 2;
  }
}
@media only screen and (min-width: 1024px) {
  .nurse02__txt {
    margin-top: 4rem;
    font-size: 2rem;
  }
}
.nurse02__list {
  margin-top: 4rem;
}
@media only screen and (min-width: 767px) {
  .nurse02__list {
    margin-top: 6rem;
  }
}
@media only screen and (min-width: 1024px) {
  .nurse02__list {
    margin-top: 8rem;
  }
}
.nurse02__item {
  margin-top: 4.5rem;
  display: flex;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
  flex-direction: column;
}
@media only screen and (min-width: 767px) {
  .nurse02__item {
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 6rem;
  }
}
@media only screen and (min-width: 1024px) {
  .nurse02__item {
    margin-top: 8rem;
  }
}
.nurse02__item:first-of-type {
  margin-top: 0;
}
.nurse02__item01 .nurse02__box em {
  color: #00a2fa;
}
.nurse02__item01 .nurse02__box h3 {
  color: #00a2fa;
}
.nurse02__item02 .nurse02__box em {
  color: #E93A83;
}
.nurse02__item02 .nurse02__box h3 {
  color: #E93A83;
}
.nurse02__box {
  display: flex;
}
@media only screen and (min-width: 767px) {
  .nurse02__box {
    flex-wrap: wrap;
    flex-direction: column;
    width: 57.5%;
  }
}
.nurse02__box > em {
  font-size: 5.4rem;
  font-family: "Barlow", sans-serif;
  font-weight: 400;
  transform: translateY(-0.8rem);
}
@media only screen and (min-width: 767px) {
  .nurse02__box > em {
    font-size: 10rem;
  }
}
@media only screen and (min-width: 767px) {
  .nurse02__box > em {
    font-size: 7.7rem;
  }
}
.nurse02__box > div {
  margin-left: 1.5rem;
}
@media only screen and (min-width: 767px) {
  .nurse02__box > div {
    margin-left: 0;
  }
}
.nurse02__box > div > h3 {
  font-weight: 600;
  line-height: 1.4;
  font-size: 1.4rem;
}
@media only screen and (min-width: 767px) {
  .nurse02__box > div > h3 {
    font-size: 1.8rem;
  }
}
@media only screen and (min-width: 1024px) {
  .nurse02__box > div > h3 {
    font-size: 2.3rem;
  }
}
.nurse02__box > div > h3 span {
  font-size: 2rem;
  display: block;
}
@media only screen and (min-width: 767px) {
  .nurse02__box > div > h3 span {
    font-size: 3.1rem;
  }
}
@media only screen and (min-width: 767px) {
  .nurse02__box > div > h3 span {
    margin-top: 0.5rem;
  }
}
@media only screen and (min-width: 1024px) {
  .nurse02__box > div > h3 span {
    font-size: 4.2rem;
  }
}
.nurse02__box > div > p {
  line-height: 2rem;
}
@media only screen and (min-width: 767px) {
  .nurse02__box > div > p {
    line-height: 2.4rem;
    margin-top: 1.5rem;
  }
}
@media only screen and (min-width: 1024px) {
  .nurse02__box > div > p {
    margin-top: 2rem;
  }
}
.nurse02__img {
  margin-top: 2rem;
}
@media only screen and (min-width: 767px) {
  .nurse02__img {
    margin-top: 0;
    width: 37%;
  }
}
.nurse02__img img {
  width: 100%;
}
.nurse02__img2 {
  margin-top: 2rem;
}
@media only screen and (min-width: 767px) {
  .nurse02__img2 {
    margin-top: 0;
    width: 23%;
  }
}
.nurse02__img2 img {
  width: 100%;
}
.nurse02__card {
  border-top: 1px solid #ECECEC;
  margin-top: 2rem;
  width: 100%;
}
@media only screen and (min-width: 767px) {
  .nurse02__card {
    margin-top: 3.5rem;
    display: flex;
    justify-content: space-between;
    border-top: none;
    border-right: 1px solid #ECECEC;
  }
}
.nurse02__card > li {
  border-bottom: 1px solid #ECECEC;
}
@media only screen and (min-width: 767px) {
  .nurse02__card > li {
    border-bottom: none;
    border-left: 1px solid #ECECEC;
  }
}
.nurse02__card > li > a {
  transition: all 0.3s linear;
  display: flex;
  align-items: center;
  padding: 2rem 1rem;
  position: relative;
}
.nurse02__card > li > a:hover {
  opacity: 0.7;
}
@media only screen and (min-width: 767px) {
  .nurse02__card > li > a {
    flex-direction: column;
    justify-content: center;
    padding: 1rem 2rem 3rem;
  }
}
.nurse02__card > li > a::after {
  position: absolute;
  content: "";
  display: block;
  right: 2.5rem;
  top: 0;
  bottom: 0;
  margin: auto;
  background-image: url(../images/nurse/nur02_ico01.png);
  background-size: cover;
  background-repeat: no-repeat;
  content: "";
  width: 2.5rem;
  height: 2.5rem;
}
@media only screen and (min-width: 767px) {
  .nurse02__card > li > a::after {
    position: inherit;
    top: inherit;
    right: 0;
    margin-top: 1rem;
  }
}
.nurse02__card > li > a img {
  width: 9.4rem;
  border-radius: 1.8rem;
}
@media only screen and (min-width: 767px) {
  .nurse02__card > li > a img {
    width: 100%;
    border-radius: 2.1rem;
  }
}
@media only screen and (min-width: 1024px) {
  .nurse02__card > li > a img {
    border-radius: 2.4rem;
  }
}
.nurse02__card > li > a > p {
  margin-left: 2.5rem;
  font-weight: bold;
  line-height: 1.4;
}
@media only screen and (min-width: 767px) {
  .nurse02__card > li > a > p {
    margin-left: 0;
    text-align: center;
    margin-top: 1.5rem;
  }
}
.nurse03 {
  margin: 0 2rem;
}
.nurse03__outer {
  padding: 6rem 0;
}
@media only screen and (min-width: 767px) {
  .nurse03__outer {
    padding: 10rem 0;
  }
}
@media only screen and (min-width: 1024px) {
  .nurse03__outer {
    padding: 15rem 0 11rem;
  }
}
.nurse03__ttl {
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.4;
  text-align: center;
}
@media only screen and (min-width: 767px) {
  .nurse03__ttl {
    font-size: 3.1rem;
  }
}
@media only screen and (min-width: 1024px) {
  .nurse03__ttl {
    font-size: 4.2rem;
  }
}
.nurse03__list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 4rem;
}
@media only screen and (min-width: 767px) {
  .nurse03__list {
    margin-top: 6rem;
  }
}
@media only screen and (min-width: 1024px) {
  .nurse03__list {
    margin-top: 8rem;
    margin-left: auto;
    margin-right: auto;
    width: min(100% - 40px, 1344px);
  }
}
.nurse03__item {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 5rem;
}
@media only screen and (min-width: 767px) {
  .nurse03__item {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 1024px) {
  .nurse03__item {
    margin-top: 4rem;
  }
}
.nurse03__item:first-of-type {
  margin-top: 0;
}
.nurse03__item-01 .nurse03__txtbox--num {
  color: #42A3E9;
}
.nurse03__item-02 {
  background: #fff;
}
@media only screen and (min-width: 767px) {
  .nurse03__item-02 {
    margin-top: 8rem;
    flex-direction: row-reverse;
  }
}
.nurse03__item-02 .nurse03__img {
  margin-left: auto;
}
@media only screen and (min-width: 767px) {
  .nurse03__item-02 .nurse03__txtbox {
    padding: 0 3rem 0 0;
  }
}
@media only screen and (min-width: 1024px) {
  .nurse03__item-02 .nurse03__txtbox {
    padding: 0 8rem 0 0;
  }
}
.nurse03__item-02 .nurse03__txtbox--num {
  color: #9C1FC1;
}
.nurse03__item-03 .nurse03__txtbox--num {
  color: #E93A83;
}
.nurse03__img {
  width: 100%;
  object-fit: contain;
  border-radius: 5.4rem;
}
@media only screen and (min-width: 767px) {
  .nurse03__img {
    width: 54.5%;
  }
}
@media only screen and (min-width: 1024px) {
  .nurse03__img {
    border-radius: 10rem;
  }
}
.nurse03__txtbox {
  padding: 3rem 3rem 0;
}
@media only screen and (min-width: 767px) {
  .nurse03__txtbox {
    width: 45.4%;
    padding: 0 0 0 3rem;
  }
}
@media only screen and (min-width: 1024px) {
  .nurse03__txtbox {
    padding-left: 8rem;
  }
}
.nurse03__txtbox--num {
  font-size: 1.6rem;
  font-family: "Oswald", sans-serif;
  font-weight: bold;
  letter-spacing: 1px;
}
@media only screen and (min-width: 767px) {
  .nurse03__txtbox--num {
    font-size: 2rem;
  }
}
@media only screen and (min-width: 1024px) {
  .nurse03__txtbox--num {
    font-size: 2.5rem;
  }
}
.nurse03__txtbox--num span {
  font-size: 2.7rem;
}
@media only screen and (min-width: 767px) {
  .nurse03__txtbox--num span {
    font-size: 3.3rem;
  }
}
@media only screen and (min-width: 1024px) {
  .nurse03__txtbox--num span {
    font-size: 4rem;
  }
}
.nurse03__txtbox--ttl {
  line-height: 1.5;
  font-weight: bold;
  margin-top: 1.5rem;
  font-size: 1.8rem;
}
@media only screen and (min-width: 767px) {
  .nurse03__txtbox--ttl {
    font-size: 2.4rem;
  }
}
@media only screen and (min-width: 767px) {
  .nurse03__txtbox--ttl {
    line-height: 1.3;
    margin-top: 2rem;
  }
}
@media only screen and (min-width: 1024px) {
  .nurse03__txtbox--ttl {
    margin-top: 3rem;
    font-size: 3rem;
  }
}
.nurse03__txtbox--txt {
  line-height: 1.75;
  margin-top: 1rem;
}
@media only screen and (min-width: 767px) {
  .nurse03__txtbox--txt {
    width: 100%;
    margin-top: 2rem;
  }
}
@media only screen and (min-width: 1024px) {
  .nurse03__txtbox--txt {
    margin-top: 3rem;
  }
}
.nurse04__outer {
  padding: 6rem 0;
  background-color: #F0F0F0;
}
@media only screen and (min-width: 767px) {
  .nurse04__outer {
    padding: 8rem 0;
  }
}
@media only screen and (min-width: 1024px) {
  .nurse04__outer {
    padding: 11rem 0;
  }
}
.nurse04__ttl {
  font-size: 2rem;
  text-align: center;
  font-weight: bold;
}
@media only screen and (min-width: 767px) {
  .nurse04__ttl {
    font-size: 3.1rem;
  }
}
@media only screen and (min-width: 1024px) {
  .nurse04__ttl {
    font-size: 4.2rem;
  }
}
.nurse04__list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 4.5rem;
  margin-right: 3rem;
  margin-left: 3rem;
}
@media only screen and (min-width: 767px) {
  .nurse04__list {
    justify-content: space-between;
    width: 100%;
    margin-top: 6rem;
    margin-right: auto;
    margin-left: auto;
  }
}
@media only screen and (min-width: 1024px) {
  .nurse04__list {
    width: min(100% - 40px, 1226px);
    margin-top: 8rem;
  }
}
.nurse04__item {
  border-radius: 0px 3.6rem 3.6rem 3.6rem;
  background-color: #fff;
  padding: 2.7rem;
  margin-top: 4.5rem;
  box-shadow: 0px 6px 30px #CFCFCF;
  position: relative;
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 767px) {
  .nurse04__item {
    margin-top: 0;
    width: 100%;
    padding: 3rem 3rem 3rem 4rem;
    flex-direction: row;
    margin-top: 4rem;
    align-items: center;
  }
}
@media only screen and (min-width: 1024px) {
  .nurse04__item {
    padding: 6rem 6rem 6rem 9rem;
    margin-top: 8rem;
  }
}
.nurse04__item:nth-of-type(1) {
  margin-top: 0;
}
.nurse04__item:nth-of-type(1)::before {
  background-image: url(../images/nurse/nur04_ico01.png);
}
.nurse04__item:nth-of-type(1) h3 span {
  color: #42A3E9;
}
.nurse04__item:nth-of-type(2)::before {
  background-image: url(../images/nurse/nur04_ico02.png);
}
.nurse04__item:nth-of-type(2) h3 span {
  color: #9C1FC1;
}
.nurse04__item:nth-of-type(3)::before {
  background-image: url(../images/nurse/nur04_ico03.png);
}
.nurse04__item:nth-of-type(3) h3 span {
  color: #E93A83;
}
.nurse04__item::before {
  position: absolute;
  top: -2.5rem;
  left: -1.5rem;
  width: 5.6rem;
  height: 5.6rem;
  margin: auto;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  content: "";
}
@media only screen and (min-width: 1024px) {
  .nurse04__item::before {
    top: -4.3rem;
    left: -4.3rem;
    width: 14.3rem;
    height: 14.3rem;
  }
}
@media only screen and (min-width: 767px) {
  .nurse04__txtbox {
    padding-right: 2.5rem;
  }
}
@media only screen and (min-width: 1024px) {
  .nurse04__txtbox {
    padding-right: 5rem;
  }
}
.nurse04__subttl {
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 1.4;
}
@media only screen and (min-width: 767px) {
  .nurse04__subttl {
    font-size: 2.4rem;
  }
}
@media only screen and (min-width: 1024px) {
  .nurse04__subttl {
    font-size: 3rem;
  }
}
.nurse04__subttl span {
  display: block;
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
}
@media only screen and (min-width: 767px) {
  .nurse04__subttl span {
    font-size: 1.6rem;
  }
}
@media only screen and (min-width: 1024px) {
  .nurse04__subttl span {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
}
.nurse04__txt {
  line-height: 1.5;
  margin-top: 1rem;
}
@media only screen and (min-width: 767px) {
  .nurse04__txt {
    min-height: 7.5rem;
  }
}
@media only screen and (min-width: 1024px) {
  .nurse04__txt {
    font-size: 1.8rem;
  }
}
.nurse04__img {
  width: 100%;
  margin-top: 1.5rem;
  border-radius: 2.4rem;
}
@media only screen and (min-width: 767px) {
  .nurse04__img {
    width: 40%;
    height: auto;
    object-fit: cover;
    margin-top: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .nurse04__img {
    width: 49.8rem;
  }
}
.nurse05 {
  margin-right: 2rem;
  margin-left: 2rem;
}
@media only screen and (min-width: 1024px) {
  .nurse05 {
    width: min(100% - 40px, 1280px);
    margin-right: auto;
    margin-left: auto;
  }
}
.nurse05__outer {
  padding: 6rem 0;
}
@media only screen and (min-width: 767px) {
  .nurse05__outer {
    padding: 8rem 0;
  }
}
@media only screen and (min-width: 1024px) {
  .nurse05__outer {
    padding: 11rem 0;
  }
}
.nurse05__ttl {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
}
@media only screen and (min-width: 767px) {
  .nurse05__ttl {
    font-size: 3.1rem;
  }
}
@media only screen and (min-width: 1024px) {
  .nurse05__ttl {
    font-size: 4.2rem;
  }
}
.nurse05__ttl2 {
  font-size: 1.6rem;
  font-weight: bold;
  margin-top: 1rem;
  line-height: 1.5;
}
@media only screen and (min-width: 767px) {
  .nurse05__ttl2 {
    font-size: 1.8rem;
  }
}
@media only screen and (min-width: 1024px) {
  .nurse05__ttl2 {
    font-size: 2.3rem;
  }
}
.nurse05__list {
  margin-top: 5rem;
}
@media only screen and (min-width: 767px) {
  .nurse05__list {
    margin-top: 6rem;
  }
}
@media only screen and (min-width: 1024px) {
  .nurse05__list {
    margin-top: 8rem;
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
  }
}
.nurse05__item {
  margin-top: 5rem;
}
@media only screen and (min-width: 767px) {
  .nurse05__item {
    display: flex;
    align-items: center;
  }
}
@media only screen and (min-width: 1024px) {
  .nurse05__item {
    margin-top: 6rem;
  }
}
.nurse05__item:first-of-type {
  margin-top: 0;
}
@media only screen and (min-width: 1024px) {
  .nurse05__item:nth-of-type(2), .nurse05__item:nth-of-type(3), .nurse05__item:nth-of-type(4) {
    flex-direction: column;
    width: calc(33.3333333333% - 4rem);
  }
}
@media only screen and (min-width: 1024px) {
  .nurse05__item:nth-of-type(2) .nurse05__txtbox, .nurse05__item:nth-of-type(3) .nurse05__txtbox, .nurse05__item:nth-of-type(4) .nurse05__txtbox {
    padding-left: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .nurse05__item:nth-of-type(2) .nurse05__img, .nurse05__item:nth-of-type(3) .nurse05__img, .nurse05__item:nth-of-type(4) .nurse05__img {
    width: 100%;
  }
}
@media only screen and (min-width: 1024px) {
  .nurse05__item:nth-of-type(2) .nurse05__cat, .nurse05__item:nth-of-type(3) .nurse05__cat, .nurse05__item:nth-of-type(4) .nurse05__cat {
    margin-top: 2rem;
  }
}
.nurse05__img {
  width: 100%;
  border-radius: 5.4rem;
}
@media only screen and (min-width: 767px) {
  .nurse05__img {
    width: 50%;
    height: auto;
    object-fit: cover;
    border-radius: 4.2rem;
  }
}
@media only screen and (min-width: 1024px) {
  .nurse05__img {
    border-radius: 3rem;
    width: 43%;
  }
}
@media only screen and (min-width: 767px) {
  .nurse05__txtbox {
    padding-left: 3rem;
  }
}
@media only screen and (min-width: 1024px) {
  .nurse05__txtbox {
    padding-left: 5rem;
  }
}
.nurse05__cat {
  font-size: 1.4rem;
  margin-top: 2rem;
  font-weight: 600;
  position: relative;
  display: inline-block;
}
@media only screen and (min-width: 767px) {
  .nurse05__cat {
    font-size: 1.6rem;
  }
}
@media only screen and (min-width: 767px) {
  .nurse05__cat {
    margin-top: 0;
  }
}
.nurse05__cat::after {
  content: "";
  height: 0.1rem;
  width: 100%;
  display: block;
  position: absolute;
  left: 0;
  bottom: -0.5rem;
  background-color: #000;
}
.nurse05__cat-blue {
  color: #42A3E9;
}
.nurse05__cat-blue::after {
  background-color: #42A3E9;
}
.nurse05__cat-purple {
  color: #9C1FC1;
}
.nurse05__cat-purple::after {
  background-color: #9C1FC1;
}
.nurse05__cat-pink {
  color: #E93A83;
}
.nurse05__cat-pink::after {
  background-color: #E93A83;
}
.nurse05__txt {
  margin-top: 0.5rem;
  line-height: 1.5;
}
@media only screen and (min-width: 1024px) {
  .nurse05__txt {
    margin-top: 1rem;
  }
}
.nurse05__txt span {
  font-size: 1rem;
  display: block;
}
@media only screen and (min-width: 767px) {
  .nurse05__txt span {
    font-size: 1.1rem;
  }
}
@media only screen and (min-width: 1024px) {
  .nurse05__txt span {
    margin-top: 1rem;
  }
}
.nurse05__link {
  transition: all 0.3s linear;
  position: relative;
  margin-top: 2.5rem;
  margin-left: auto;
  font-weight: 600;
  display: block;
  text-align: right;
  padding-right: 4rem;
}
.nurse05__link:hover {
  opacity: 0.7;
}
@media only screen and (min-width: 767px) {
  .nurse05__link {
    margin-top: 1rem;
    font-size: 1.4rem;
  }
}
@media only screen and (min-width: 1024px) {
  .nurse05__link {
    margin-top: 2rem;
    text-align: left;
    display: inline-block;
  }
}
.nurse05__link::after {
  display: block;
  width: 2.9rem;
  height: 1.1rem;
  background-image: url(../images/nurse/nur05_ico01.png);
  background-size: cover;
  background-repeat: no-repeat;
  content: "";
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  position: absolute;
}
@media only screen and (min-width: 767px) {
  .nurse06 {
    width: min(100% - 40px, 1226px);
    margin-right: auto;
    margin-left: auto;
  }
}
.nurse06__outer {
  padding-bottom: 6rem;
}
@media only screen and (min-width: 767px) {
  .nurse06__outer {
    padding-bottom: 8rem;
  }
}
@media only screen and (min-width: 1024px) {
  .nurse06__outer {
    padding-bottom: 10rem;
  }
}
.nurse06__ttl {
  font-size: 2rem;
  text-align: center;
  font-weight: bold;
}
@media only screen and (min-width: 767px) {
  .nurse06__ttl {
    font-size: 3.1rem;
  }
}
@media only screen and (min-width: 1024px) {
  .nurse06__ttl {
    font-size: 4.2rem;
  }
}
.nurse06__list {
  margin-top: 3rem;
  padding: 0 3.5rem;
}
@media only screen and (min-width: 1024px) {
  .nurse06__list {
    display: flex;
    justify-content: space-between;
    margin-top: 8rem;
    flex-direction: column;
  }
}
.nurse06__item {
  width: 100%;
  margin-top: 2rem;
  padding: 2.5rem;
  background-color: #fff;
  box-shadow: 8px 10px 33px rgba(0, 0, 0, 0.08);
  border-radius: 3.6rem;
}
@media only screen and (min-width: 767px) {
  .nurse06__item {
    display: flex;
    justify-content: space-between;
    padding: 5rem;
    margin-top: 4rem;
  }
}
@media only screen and (min-width: 1024px) {
  .nurse06__item {
    align-items: center;
    padding: 4rem 6rem;
  }
}
.nurse06__item:first-of-type {
  margin-top: 0;
}
.nurse06__ttl2 {
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1.5;
}
@media only screen and (min-width: 767px) {
  .nurse06__ttl2 {
    font-size: 2.1rem;
  }
}
@media only screen and (min-width: 1024px) {
  .nurse06__ttl2 {
    font-size: 3rem;
  }
}
.nurse06__box {
  margin-top: 2.5rem;
  display: flex;
}
@media only screen and (min-width: 767px) {
  .nurse06__box {
    margin-top: 0;
    flex-direction: column;
  }
}
@media only screen and (min-width: 1024px) {
  .nurse06__box {
    width: 25.5rem;
  }
}
.nurse06__personal {
  margin-left: 1.5rem;
}
@media only screen and (min-width: 767px) {
  .nurse06__personal {
    margin-left: 0;
  }
}
@media only screen and (min-width: 767px) {
  .nurse06__txtbox {
    margin-left: 3rem;
  }
}
@media only screen and (min-width: 1024px) {
  .nurse06__txtbox {
    width: calc(100% - 25.5rem);
  }
}
.nurse06__img {
  width: 9.4rem;
  height: 9.4rem;
  display: block;
}
@media only screen and (min-width: 767px) {
  .nurse06__img {
    width: 19rem;
    height: 19rem;
  }
}
@media only screen and (min-width: 1024px) {
  .nurse06__img {
    width: 21rem;
    height: 21rem;
  }
}
.nurse06__position {
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 1.5;
}
@media only screen and (min-width: 767px) {
  .nurse06__position {
    font-size: 1.6rem;
  }
}
@media only screen and (min-width: 767px) {
  .nurse06__position {
    font-size: 1.4rem;
    margin-top: 1rem;
  }
}
.nurse06__name {
  font-size: 1.6rem;
  margin-top: 0.5rem;
  font-weight: bold;
  line-height: 1.2;
}
@media only screen and (min-width: 767px) {
  .nurse06__name {
    font-size: 1.8rem;
  }
}
@media only screen and (min-width: 1024px) {
  .nurse06__name {
    font-size: 2rem;
  }
}
.nurse06__name span {
  font-size: 1.2rem;
  font-weight: 300;
  margin-top: 0.5rem;
  display: block;
}
.nurse06__txt {
  line-height: 1.6;
  margin-top: 2rem;
}
@media only screen and (min-width: 1024px) {
  .nurse06__txt {
    margin-top: 1rem;
  }
}
.nurse07__outer {
  padding: 6rem 0 1rem;
  background-color: #F0F0F0;
}
@media only screen and (min-width: 767px) {
  .nurse07__outer {
    padding: 8rem 0 2rem;
  }
}
@media only screen and (min-width: 1024px) {
  .nurse07__outer {
    padding: 11rem 0 2rem;
  }
}
.nurse07__ttl {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  line-height: 1.4;
}
@media only screen and (min-width: 767px) {
  .nurse07__ttl {
    font-size: 3.1rem;
  }
}
@media only screen and (min-width: 1024px) {
  .nurse07__ttl {
    font-size: 4.2rem;
  }
}
.nurse07__txt {
  font-weight: bold;
  text-align: center;
  line-height: 1.4;
  margin-top: 2rem;
}
@media only screen and (min-width: 1024px) {
  .nurse07__txt {
    margin-top: 4rem;
  }
}
.nurse07__list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-top: 4rem;
}
@media only screen and (min-width: 767px) {
  .nurse07__list {
    margin-top: 6rem;
    flex-direction: row;
    flex-wrap: inherit;
    justify-content: space-between;
  }
}
@media only screen and (min-width: 1024px) {
  .nurse07__list {
    margin-top: 8rem;
    margin-right: auto;
    margin-left: auto;
    width: min(100% - 40px, 1100px);
  }
}
.nurse07__item {
  margin-top: 3rem;
  display: flex;
}
@media only screen and (min-width: 767px) {
  .nurse07__item {
    flex-direction: column;
    width: calc(33.3333333333% - 3rem);
    margin-top: 0;
  }
}
.nurse07__item:first-of-type {
  margin-top: 0;
}
@media only screen and (min-width: 767px) {
  .nurse07__item:last-of-type .nurse07__img::after {
    display: none;
  }
}
.nurse07__txtbox {
  margin-left: 1.5rem;
}
@media only screen and (min-width: 767px) {
  .nurse07__txtbox {
    margin-left: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 2rem;
  }
}
@media only screen and (min-width: 1024px) {
  .nurse07__txtbox {
    margin-top: 3rem;
  }
}
.nurse07__txtbox > h3 {
  margin-top: 0.8rem;
  font-weight: 600;
  font-size: 1.6rem;
}
@media only screen and (min-width: 767px) {
  .nurse07__txtbox > h3 {
    margin-top: 0;
    margin-left: 1.5rem;
  }
}
@media only screen and (min-width: 1024px) {
  .nurse07__txtbox > h3 {
    font-size: 2.3rem;
  }
}
.nurse07__txtbox > p {
  margin-top: 1rem;
  line-height: 1.5;
}
@media only screen and (min-width: 1024px) {
  .nurse07__txtbox > p {
    margin-top: 2rem;
  }
}
.nurse07__num {
  font-weight: 600;
  font-family: "Barlow", sans-serif;
}
@media only screen and (min-width: 767px) {
  .nurse07__num {
    font-size: 1.6rem;
  }
}
@media only screen and (min-width: 1024px) {
  .nurse07__num {
    font-size: 2.3rem;
  }
}
.nurse07__num-blue {
  color: #42a3e9;
}
.nurse07__num-purple {
  color: #9c1fc1;
}
.nurse07__num-pink {
  color: #e93a83;
}
@media only screen and (min-width: 767px) {
  .nurse07__img {
    position: relative;
  }
}
.nurse07__img img {
  width: 9.4rem;
  height: 9.4rem;
}
@media only screen and (min-width: 767px) {
  .nurse07__img img {
    width: 18rem;
    height: 18rem;
    margin-right: auto;
    margin-left: auto;
    display: block;
  }
}
@media only screen and (min-width: 1024px) {
  .nurse07__img img {
    width: 27.7rem;
    height: 27.7rem;
  }
}
@media only screen and (min-width: 767px) {
  .nurse07__img::after {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 0;
    height: 0;
    margin: auto;
    border-top: solid 16px transparent;
    border-right: solid 24px transparent;
    border-bottom: solid 16px transparent;
    border-left: solid 24px #D9D9D9;
    content: "";
    transform: translateX(calc(100% + 1.5rem));
  }
}
.nurse07__att {
  margin-top: 5rem;
  text-align: right;
}
@media only screen and (min-width: 1024px) {
  .nurse07__att {
    margin-top: 8rem;
  }
}
.nurse07__att span {
  font-size: 1rem;
  display: inline-block;
  text-align: left;
  line-height: 1.5;
  font-size: 1rem;
}
@media only screen and (min-width: 767px) {
  .nurse07__att span {
    font-size: 1.1rem;
  }
}
@media only screen and (min-width: 767px) {
  .nurse07__att span {
    text-align: right;
  }
}
@media only screen and (min-width: 1024px) {
  .nurse08 {
    width: min(100% - 40px, 1265px);
    margin-right: auto;
    margin-left: auto;
    padding: 0 3.5rem;
  }
}
.nurse08__outer {
  padding: 6rem 0 0;
}
@media only screen and (min-width: 1024px) {
  .nurse08__outer {
    padding: 11rem 0 0;
  }
}
.nurse08__outer.exp08__outer {
  padding-top: 0;
}
.nurse08__ttl {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  line-height: 1.3;
}
@media only screen and (min-width: 767px) {
  .nurse08__ttl {
    font-size: 3.1rem;
  }
}
@media only screen and (min-width: 1024px) {
  .nurse08__ttl {
    font-size: 4.2rem;
  }
}

.aside {
  background: linear-gradient(126.57deg, #EC4684 14.74%, #F5718A 53.56%, #F8838D 92.39%);
}
.aside__make {
  background: linear-gradient(126.57deg, #5F23AC 14.74%, #A262D5 53.56%, #B999E3 92.39%);
}
.aside__wrap {
  transition: all 0.3s linear;
  padding-left: 9%;
  padding-right: 9%;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 90%;
  background-image: url("../images/common/aside_bg-sp.png");
  overflow: hidden;
}
@media only screen and (min-width: 767px) {
  .aside__wrap {
    padding-right: 3%;
    padding-left: 3%;
    width: min(100% - 40px, 1446px);
    background-size: 54.7rem;
    background-image: url("../images/common/aside_bg-pc.png");
  }
}
@media only screen and (min-width: 1024px) {
  .aside__wrap {
    /* middle-size */
    margin-left: 0;
    margin-right: 0;
  }
}
@media only screen and (min-width: 1440px) {
  .aside__wrap {
    margin-left: auto;
    margin-right: auto;
  }
}
.aside__inner {
  transition: all 0.3s linear;
  font-weight: 600;
  padding: 9rem 0 8.4rem;
  margin: auto;
}
@media only screen and (min-width: 767px) {
  .aside__inner {
    /* tablet-size */
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
}
@media only screen and (min-width: 1024px) {
  .aside__inner {
    padding: 12rem 0 9rem;
    width: min(100% - 40px, 1110px);
  }
}
.aside__box-heading {
  transition: all 0.3s linear;
}
@media only screen and (min-width: 767px) {
  .aside__box-heading {
    width: 42.34%;
  }
}
.aside__box-foot {
  transition: all 0.3s linear;
  margin-top: 6rem;
  color: #fff;
  font-size: 3.2rem;
}
@media only screen and (min-width: 767px) {
  .aside__box-foot {
    font-size: 4.5rem;
  }
}
@media only screen and (min-width: 767px) {
  .aside__box-foot {
    width: 37.84%;
    margin-top: 0;
  }
}
.aside__h1 {
  transition: all 0.3s linear;
  font-size: 3.2rem;
  color: #fff;
  line-height: 1.5;
  text-align: left;
}
@media only screen and (min-width: 767px) {
  .aside__h1 {
    /* tablet-size */
    font-size: 3rem;
  }
}
@media only screen and (min-width: 1024px) {
  .aside__h1 {
    line-height: 1.3;
    font-size: 4.5rem;
  }
}
.aside__txtbox {
  margin-top: 5rem;
}
.aside__txtbox__txt:nth-of-type(1) {
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 1.5;
}
.aside__txtbox__txt:nth-of-type(2) {
  margin-top: 2rem;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.8;
}
.aside__txt {
  transition: all 0.3s linear;
  font-size: 1.4rem;
  color: #fff;
  line-height: 1.8;
  font-weight: 600;
}
@media only screen and (min-width: 767px) {
  .aside__txt {
    font-size: 1.5rem;
  }
}
@media only screen and (min-width: 1024px) {
  .aside__txt {
    /* tablet-size */
    line-height: 2;
  }
}
.aside__note {
  font-size: 1rem;
  color: #fff;
  line-height: 1.45;
  text-align: center;
  margin-top: 1rem;
}
@media only screen and (min-width: 767px) {
  .aside__note {
    font-size: 1.1rem;
  }
}
@media only screen and (min-width: 767px) {
  .aside__note {
    text-align: left;
  }
}
.aside__list {
  color: #fff;
}
@media only screen and (min-width: 767px) {
  .aside__list {
    width: min(60%, 48rem);
  }
}
.aside__list2 {
  margin-top: 4rem;
}
.aside__list2 li {
  transition: all 0.3s linear;
  border-bottom: 1px solid #fff;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 2rem;
  color: #fff;
  width: 200%;
  font-size: 2rem;
}
@media only screen and (min-width: 767px) {
  .aside__list2 li {
    /* tablet-size */
    width: 100%;
    font-size: 1.8rem;
  }
}
@media only screen and (min-width: 1024px) {
  .aside__list2 li {
    /* tablet-size */
    width: 100%;
    font-size: 2.4rem;
  }
}
.aside__list2 li:nth-of-type(1) {
  border-top: 1px solid #fff;
}
.aside__item * {
  display: block;
}
.aside__item small {
  font-size: 1.2rem;
}
@media only screen and (min-width: 767px) {
  .aside__item small {
    font-size: 1.4rem;
  }
}
.aside__item em {
  font-size: 1.8rem;
  margin-top: 1rem;
}
@media only screen and (min-width: 767px) {
  .aside__item em {
    font-size: 2.4rem;
  }
}
@media only screen and (min-width: 767px) {
  .aside__item em {
    margin-top: 1.2rem;
  }
}
.aside__item a {
  position: relative;
  color: inherit;
  padding: 2.5rem 4rem;
}
.aside__item a:hover {
  filter: brightness(120%);
}
.aside__item a::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 2rem;
  margin-top: -0.6rem;
  width: 3rem;
  height: 1.2rem;
  background-image: url("../images/common/arrow-link.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.aside__item + .aside__item {
  margin-top: 2rem;
}
.aside__btn, .aside__btn-purple, .aside__btn-pink {
  transition: all 0.3s linear;
  font-size: 1.6rem;
  margin-top: 4rem;
  padding: 2rem 0 2rem 3.6rem;
  border-radius: 10rem;
  background-color: #fff;
  display: block;
  font-weight: 600;
  border-style: solid;
  border-width: 0.2rem;
  border-color: transparent;
  position: relative;
}
@media only screen and (min-width: 1024px) {
  .aside__btn, .aside__btn-purple, .aside__btn-pink {
    margin-top: 3rem;
    padding: 3rem 0 3rem 3.3rem;
    font-size: 1.8rem;
  }
}
.aside__btn::before, .aside__btn-purple::before, .aside__btn-pink::before, .aside__btn::after, .aside__btn-purple::after, .aside__btn-pink::after {
  transition: all 0.3s linear;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}
.aside__btn::before, .aside__btn-purple::before, .aside__btn-pink::before {
  display: block;
  right: 6.5rem;
  width: 0.1rem;
  height: calc(100% - 2.2rem);
  content: "";
}
@media only screen and (min-width: 1024px) {
  .aside__btn::before, .aside__btn-purple::before, .aside__btn-pink::before {
    /* tablet-size */
    height: calc(100% - 3rem);
  }
}
.aside__btn::after, .aside__btn-purple::after, .aside__btn-pink::after {
  font-family: "Font Awesome 6 Free";
  display: inline-block;
  font-weight: 900;
  content: "\f054";
  right: 2.6rem;
  display: flex;
  align-items: center;
}
.aside__btn:hover, .aside__btn-purple:hover, .aside__btn-pink:hover {
  color: #fff;
  border-color: #fff;
}
.aside__btn:hover::before, .aside__btn-purple:hover::before, .aside__btn-pink:hover::before {
  background-color: #fff;
}
.aside__btn:hover::after, .aside__btn-purple:hover::after, .aside__btn-pink:hover::after {
  color: #fff;
}
.aside__btn-pink {
  color: #F6768B;
}
.aside__btn-pink::before {
  background-color: #F6768B;
}
.aside__btn-pink::after {
  color: #F6768B;
}
.aside__btn-pink:hover {
  background-color: #F6768B;
}
.aside__btn-purple {
  color: #6024AD;
}
.aside__btn-purple::before {
  background-color: #6024AD;
}
.aside__btn-purple::after {
  color: #6024AD;
}
.aside__btn-purple:hover {
  background-color: #6024AD;
}

.aside-vr {
  display: block;
  margin: 0 -20px;
}
.aside-vr:hover {
  opacity: 0.7;
}
@media only screen and (min-width: 1440px) {
  .aside-vr {
    margin: 0;
  }
}
.aside-vr__bg {
  position: relative;
  height: 40rem;
  background-image: url("../images/medical/aside-vr-sp.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
@media only screen and (min-width: 767px) {
  .aside-vr__bg {
    height: 44rem;
    background-image: url("../images/medical/aside-vr-pc.jpg");
    background-position: left;
  }
}
@media only screen and (min-width: 767px) {
  .aside-vr__box {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    margin-top: 1rem;
  }
}
@media only screen and (min-width: 1024px) {
  .aside-vr__box {
    right: 12.5%;
  }
}
.aside-vr__cap {
  font-size: 1.8rem;
  font-weight: bold;
  color: #fff;
  line-height: 1.44;
  text-align: center;
  white-space: nowrap;
  padding: 3.5rem 0 1rem;
}
@media only screen and (min-width: 767px) {
  .aside-vr__cap {
    font-size: 2.6rem;
  }
}
@media only screen and (min-width: 767px) {
  .aside-vr__cap {
    padding: 0 0 1.5rem;
    line-height: 1.38;
  }
}
.aside-vr__logo {
  display: block;
  margin: 0 auto;
  width: 20.6rem;
  margin-bottom: 3.5rem;
}
@media only screen and (min-width: 767px) {
  .aside-vr__logo {
    width: 24rem;
  }
}
.aside-vr__fig {
  display: none;
}
@media only screen and (min-width: 767px) {
  .aside-vr__fig {
    display: flex;
  }
}
.aside-vr__fig img {
  display: block;
  width: 26rem;
  border-radius: 4rem;
}
.aside-vr__fig img + img {
  margin-left: 2rem;
}

.footer {
  padding: 4rem 4rem 2rem;
}
@media only screen and (min-width: 767px) {
  .footer {
    padding: 8rem 20px;
  }
}
.footer__inner {
  width: min(100%, 1120px);
}
@media only screen and (min-width: 767px) {
  .footer__inner {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }
}
.footer__logo {
  display: block;
  width: 16rem;
  margin-bottom: 4rem;
}
.footer__logo:hover {
  opacity: 0.7;
}
@media only screen and (min-width: 767px) {
  .footer__logo {
    margin-bottom: 0;
    margin-right: 2rem;
  }
}
@media only screen and (min-width: 1024px) {
  .footer__logo {
    width: 26.3rem;
  }
}
.footer__logo img {
  width: 100%;
}
.footer__logo2 {
  display: block;
  width: 16rem;
  margin-bottom: 4rem;
}
@media only screen and (min-width: 767px) {
  .footer__logo2 {
    width: 26.3rem;
    margin-bottom: 0;
  }
}
.footer__logo2 img {
  width: 100%;
}
.footer__logo3 {
  display: block;
  width: 16rem;
  margin-bottom: 4rem;
}
.footer__logo3:hover {
  opacity: 0.7;
}
@media only screen and (min-width: 767px) {
  .footer__logo3 {
    width: 24.7rem;
    margin-right: 2rem;
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .footer__logo3 {
    width: 26.3rem;
  }
}
.footer__logo3 img {
  width: 100%;
}
.footer__nav-list {
  font-size: 0;
  margin-bottom: 4rem;
}
@media only screen and (min-width: 767px) {
  .footer__nav-list {
    display: flex;
    flex-wrap: wrap;
  }
}
.footer__nav-list > li {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
}
@media only screen and (min-width: 767px) {
  .footer__nav-list > li {
    display: inline-block;
    margin-bottom: 2rem;
    margin-right: 2rem;
  }
}
@media only screen and (min-width: 1024px) {
  .footer__nav-list > li {
    margin-bottom: 0;
    margin-right: 3rem;
  }
}
@media only screen and (min-width: 1024px) {
  .footer__nav-list > li:last-of-type {
    margin-right: 0;
  }
}
.footer__nav-list > li a {
  color: inherit;
}
.footer__nav-list > li a:hover {
  opacity: 0.7;
}
@media only screen and (min-width: 767px) {
  .footer__nav-sublist {
    display: flex;
    flex-direction: column;
  }
}
.footer__nav-sublist > li {
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 1.5rem;
  margin-left: 1em;
}
@media only screen and (min-width: 767px) {
  .footer__nav-sublist > li {
    display: inline-block;
    margin-top: 1rem;
    margin-left: 0;
  }
}
.footer__nav-sublist > li a {
  color: inherit;
  color: #7e7e7e;
}
.footer__nav-sublist > li a:hover {
  opacity: 0.7;
}
.footer__c {
  font-size: 1rem;
}
@media only screen and (min-width: 767px) {
  .footer__c {
    text-align: right !important;
    margin-top: 1.7rem;
  }
}

.back2top {
  display: block;
  position: fixed;
  bottom: 5%;
  right: 5%;
  z-index: 2;
  width: 4.8rem;
  height: 4.8rem;
}
.back2top img {
  transition: all 0.3s linear;
  width: 100%;
  border-radius: 50%;
  box-shadow: 2px 2px 6px rgba(217, 213, 208, 0.4);
}
.back2top img:hover {
  opacity: 0.7;
}
@media only screen and (min-width: 1440px) {
  .back2top {
    right: auto;
    left: calc(50% + 56rem);
  }
}

.sp {
  display: block;
}
@media only screen and (min-width: 767px) {
  .sp {
    display: none;
  }
}

.pc {
  display: none;
}
@media only screen and (min-width: 767px) {
  .pc {
    display: block;
  }
}

/* コンテンツフェードイン。top.cssより引用 */
.fade {
  transform: translateY(50px);
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1) 0.2s;
  opacity: 0;
}
.fade.blockIn {
  transform: translateY(0);
  opacity: 1;
}