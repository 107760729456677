//---------- 幅 ----------
$width: 1400px;
$width2: 1215px;
$width-m: 20px; // スマホ左右マージン

//---------- 色の設定 ----------
$color_font: #2D313D; // 基本文字色
$color_main01: #151826; // サイトカラー
$color_main02: #00A2FA; // サイトカラー
$color_main03: #F4F4F6; // サイトカラー
$color_main04: #9C1FC1; // サイトカラー
$color_main05: #E93A83; // サイトカラー
$color_main06: #365DFF; // サイトカラー
$color_main07: #D64789; // サイトカラー
$color_main08: #919090; // サイトカラー
$color_main09: #F6768B; // サイトカラー
$color_main10: #6024AD; // サイトカラー
$color_main11: #0172E0; // サイトカラー
$color_main12: #E33F81; // サイトカラー
$color_main13: #5F2B9A; // サイトカラー