@charset "UTF-8";

/*-------------------------------
 
	Reset : common.cssと衝突を避けるため、新たに追加
 
-------------------------------*/
@use "reset";

/*-------------------------------
 
	base : bodyタグ等のベース設定
 
-------------------------------*/
@use "base";

/*-------------------------------
 
	layout : レイアウトの設定
 
-------------------------------*/
@use "layout";

/*-------------------------------
 
	module : 各パーツの設定
 
-------------------------------*/
@use "module";