@use "global"as *;

/*
 * base
 */
html {
  font-size: 62.5%;
  scroll-behavior: smooth;
  scroll-padding-top: 8rem;
}

body {
  font-family: 'Noto Sans JP', '游ゴシック体',
    YuGothic,
    '游ゴシック',
    'Yu Gothic',
    sans-serif;
  color: $color_font;
  @include fontsize(14, 16);
  font-weight: 500;

}

*,
*::before,
*::after {
  box-sizing: border-box;
}

a {
  @include transition;
}

a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
}

*:focus {
  outline: none;
}

p,
ul li,
ol li {
  @include justify();
  word-break: break-all;
}

b {
  font-weight: bold;
}

img {
  vertical-align: top;
}

.lang-en {

  p,
  ul li,
  ol li {
    text-align: left;
    word-break: normal;
  }
}

main {
  hyphens: auto;
}