@use "global"as *;
@use "sass:math";

/*
 * ヘッダーを考慮したページ上部の余白
 */
.l-about-top {
  margin-top: 4.8rem;

  @include media(tb) {
    margin-top: 5rem;
  }

  @include media(m) {
    /* middle-size */
    margin-top: 8rem;
  }
}

.l-about-normal {
  margin-top: 8.8rem;

  @include media(tb) {
    margin-top: 12rem;
  }
}

/*
 * wrapper
 */
.l-base {
  margin-left: $width-m;
  margin-right: $width-m;

  @include media(l) {
    margin-left: calc((100% - $width)/ 2);
    margin-right: calc((100% - $width)/ 2);
  }
}

.l-base2 {
  margin-left: $width-m;
  margin-right: $width-m;

  @include media(m) {
    margin-left: calc((100% - $width2)/ 2);
    margin-right: calc((100% - $width2)/ 2);
  }
}

.l-full {
  @include media(l) {
    margin-left: calc((100% - ($width + ($width-m * 2)))/ 2);
    margin-right: calc((100% - ($width + ($width-m * 2)))/ 2);
  }
}

.l-wide {
  margin-left: $width-m;
  margin-right: $width-m;

  @include media(tb) {
    margin-left: auto;
    margin-right: auto;
    width: min(calc(100% - ($width-m * 2)), 1040px);
  }
}

.l-middle {
  margin-left: $width-m;
  margin-right: $width-m;

  @include media(tb) {
    margin-left: auto;
    margin-right: auto;
    width: min(calc(100% - ($width-m * 2)), 960px);
  }
}

.l-short-middle {
  margin-left: $width-m;
  margin-right: $width-m;

  @include media(tb) {
    margin-left: auto;
    margin-right: auto;
    width: min(calc(100% - ($width-m * 2)), 880px);
  }
}

.l-short {
  margin-left: $width-m;
  margin-right: $width-m;

  @include media(tb) {
    margin-left: auto;
    margin-right: auto;
    width: min(calc(100% - ($width-m * 2)), 760px);
  }
}

.l-x-short {
  margin-left: auto;
  margin-right: auto;
  width: min(calc(100% - ($width-m * 2)), 680px);
}