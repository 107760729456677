@use "global"as *;
@use "sass:math";

/*
 * 共通パーツ。頭に[c-]を付けることで区別を付ける。
 */
// 通常テキスト
.c-txt {
  @include lineheight-normal;
}

// 大見出し
.c-h1 {
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.33;
  text-align: center;
  margin-bottom: 1.5rem;

  @include media(tb) {
    font-size: math.div(2rem + 5.4rem, 2);
    margin-bottom: 2rem;
  }

  @include media(m) {
    font-size: 5.4rem;
  }
}

// 小見出し
.c-h2 {
  @include fontsize(20, 31);
  font-weight: bold;
  line-height: 1.43;
  text-align: center;
  margin-bottom: 1rem;

  @include media(tb) {
    margin-bottom: 2.5rem;
  }
}

// 小見出し
.c-h3 {
  @include fontsize(20, 36);
  font-weight: bold;
  line-height: 1.5;
  text-align: center;
  margin-bottom: 2.5rem;

  @include media(tb) {
    margin-bottom: 5rem;
  }
}

// 小見出し 
.c-h4 {
  @include fontsize(18, 20);
  font-weight: bold;
  line-height: 1.5;
}

// 最小見出し 
.c-h5 {
  @include lineheight-normal;
  font-weight: bold;
  text-align: center;
}

// 下層ページ大見出し
.c-page__ttl01 {
  >span {
    @include fontsize(20, 23);
    font-weight: 600;


  }

  >em {
    font-weight: 600;
    line-height: 1.2;
    letter-spacing: 0.05em;
    display: block;
    font-size: 3.3rem;

    @include media(tb) {
      /* tablet-size */
      font-size: 4.4rem;
      letter-spacing: 0.08em;
    }

    @include media(m) {
      /* middle-size */
      font-size: 5.5rem;

    }
  }
}


// 背景色
.c-bg-glay {
  background-color: $color_main03;
}

// 文字の太さ
.c-we-bold {
  font-weight: 700;
}

// 文字色
.c-cl-blue {
  color: $color_main11;
}

.c-cl-pink {
  color: $color_main12;
}

.c-cl-purple {
  color: $color_main13;
}

// 文字サイズ
.c-fs-20 {
  font-size: 2rem;
}

// アイコン
.c-icon {
  display: inline-block;
  width: 4rem;
  height: 2.4rem;
  @include background-image();

  @include media(tb) {
    width: 5.1rem;
    height: 3rem;
  }

  &--small {
    width: 3.4rem;
    height: 2rem;

    @include media(tb) {
      width: 3.4rem;
      height: 2rem;
    }
  }

  &__med {
    background-image: url("../images/common/icon-med.png");
  }

  &__sst {
    background-image: url("../images/common/icon-sst.png");
  }

  &__job {
    background-image: url("../images/common/icon-job.png");
  }

  &__relax {
    background-image: url("../images/common/icon-relax.png");
  }

  &__care {
    background-image: url("../images/common/icon-care.png");
  }
}

// 一覧表示用のカード
.c-card {
  &__ttl-sub {
    @include fontsize(12, 14);
    font-weight: bold;
    line-height: 1.33;
    display: inline-block;
    margin-bottom: 0.5rem;

    @include media(tb) {
      margin-bottom: 1rem;
    }

    &::after {
      content: '';
      display: block;
      height: 1px;
      background-color: $color_main01;
      margin-top: 0.2rem;
    }
  }

  &__ttl {
    @include fontsize(13, 20);
    font-weight: bold;
    line-height: 1.5;
    margin-bottom: 1rem;

    @include media(tb) {
      margin-bottom: 1.5rem;
    }
  }

  &__txt {
    @include fontsize(12, 15);
  }

  &__link {
    margin-top: 1rem;

    @include media(tb) {
      margin-top: 2rem;
    }
  }
}

.card-2col {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1rem;

  @include media(tb) {
    // justify-content: center;
    margin: 0 -2rem;
  }

  &__item {
    width: calc(50% - 2rem);
    margin: 0 1rem 3rem;

    @include media(tb) {
      // flex: 1;
      max-width: 32rem;
      margin: 0 2rem 3rem;
    }
  }

  &__fig {
    position: relative;
    margin-bottom: 1.3rem;
    height: 80px;
    overflow: hidden;

    @include media(s) {
      height: 180px;
    }

    @include media(tb) {
      margin-bottom: 2rem;
    }

    img {
      display: block;
      width: 100%;
    }
  }

  &__tag-txt,
  &__tag-icon {
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    display: inline-block;

    @include media(tb) {
      top: 1rem;
      left: 1rem;
    }
  }

  &__tag-txt {
    @include fontsize(10, 14);
    color: #fff;
    font-weight: bold;
    padding: 0.5rem 1rem;
    border-radius: 1.4rem;

    @include media(tb) {
      padding: 0.7rem 1.5rem;
    }

    &.lightblue {
      background-color: $color_main02;
    }

    &.darkblue {
      background-color: $color_main06;
    }

    &.red {
      background-color: $color_main07;
    }
  }
}

.card-1col {
  margin: 0 2rem;

  @include media(tb) {
    display: flex;
    justify-content: center;
    margin: 0 -2rem;
  }

  &__item {
    @include media(tb) {
      flex: 1;
      max-width: 32rem;
      margin: 0 2rem;
    }

    &+& {
      margin-top: 3rem;

      @include media(tb) {
        margin-top: 0;
      }
    }
  }

  &__thumb {
    display: block;
    width: 18.4rem;
    margin: 0 auto 1.5rem;

    @include media(tb) {
      width: calc(100% - 4rem);
      margin-bottom: 2rem;
    }
  }

  &__ttl {
    font-size: 1.8rem;
  }
}

// リンク先
.c-link {
  @include fontsize(12, 14);
  @include opacity;
  font-weight: bold;
  color: $color_main02;
  display: inline-block;

  &::after {
    content: '〉';
    display: inline-block;
    margin-left: 5px;
  }
}

// グラデーション背景
.c-gradient-darkblue {
  color: #fff;
  background: linear-gradient(45deg, #1F2536, #3F4D7B);
}

.c-gradient-lightblue {
  color: #fff;
  background: linear-gradient(45deg, #001B8E, #01B9B9);
}

.c-gradient-blue {
  color: #fff;
  background: linear-gradient(45deg, #1F79FA, #00A2F4);
}

.c-gradient-blue2 {
  color: #fff;
  background: linear-gradient(267.97deg, #5979FF 1.99%, #22377D 100%);
}

.c-gradient-violet {
  color: #fff;
  background: linear-gradient(45deg, #430067, #4A7AFF);
}

.c-gradient-violet2 {
  color: #fff;
  background: linear-gradient(45deg, #6D58BF, #AD4F9C);
}




// ボタン
.c-btn {
  @include brightness;
  @include btn-arrow-icon;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24rem;
  height: 6rem;
  margin: 0 1rem;
  text-align: center;
  font-weight: bold;
  line-height: 1.42;
  color: #fff;
  border-radius: 3rem;
  background-image: url("../images/common/btn-gradation-blue.png");
  background-size: auto 100%;
  background-position: center center;

  @include media(tb) {
    width: 32rem;
    background-size: 100% auto;
  }

  &+& {
    margin-top: 1rem;

    @include media(tb) {
      margin-top: 0;
    }
  }
}

// ボタン横並び
.c-btn-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;

  @include media(tb) {
    flex-direction: row;
    justify-content: center;
    margin-top: 8rem;
  }
}

// SP版メニューボタン
.c-btn-menu {
  display: block;
  width: 4.8rem;
  height: 4.8rem;
  padding: 0;
  margin: 0 -1.5rem 0 auto;
  @include background-image();
  // background-color: #fff;
  background-color: initial;
  border: none;
  cursor: pointer;
  // 以下、テキスト非表示化
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;

  &--open {
    background-image: url("../images/common/menu.png");
  }

  &--close {
    background-image: url("../images/common/menu-close.png");
    margin-right: -.7rem;
  }
}


// ボタン（矢印の左横に縦棒）
.c-btn02 {
  @include brightness;
  position: relative;
  border-radius: 100px;

  &::before,
  &::after {
    display: block;
    position: absolute;
  }

  &-gradient-purple {
    //詳しく聞きたい
    background: linear-gradient(271.74deg, #5D2899 2.97%, #A678DB 96.4%);
    width: 100%;

    &::before {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 6.5rem;
      width: .1rem;
      height: 100%;
      margin: auto;
      background-color: #fff;
    }

    &::after {
      @include fontAwesome__sol;
      content: "\f054";
      color: #F6768B;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 2.6rem;
      margin: auto;
    }
  }

  @include media(tb) {}
}

.c-followbtn {
  z-index: 2;
  position: fixed;
  box-shadow: 0px 3px 13px rgba(123, 123, 123, 0.25);
  justify-content: center;
  font-weight: 700;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #fff;
  letter-spacing: 0.03em;
  border-radius: 3px;
  font-size: 1.1rem;
  padding: 1rem;
  transition: inherit;

  left: 5%;
  bottom: 5%;

  @include media(tb) {
    /* tablet-size */
    // @include transition;
    @include brightness;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  @include media(m) {
    /* middle-size */
    padding: 1.5rem 1rem;
    writing-mode: vertical-rl;
    font-size: 1.4rem;
    right: 0;
    left: inherit;
  }

  &-pink,
  &-purple {
    @extend .c-followbtn;

  }

  &-pink {
    background: linear-gradient(0deg, #EA3D82 0%, #F8858D 100%);

  }

  &-purple {
    background: linear-gradient(0deg, #5F2B9B 0%, #A678DC 100%);
  }

  &__icon {
    width: 1.3rem;
    height: 1.3rem;
    margin-left: 1rem;

    @include media(tb) {
      /* tablet-size */

    }

    @include media(m) {
      /* middle-size */
      width: 1.5rem;
      height: 1.5rem;
      margin-top: 1rem;
      margin-left: 0;

    }
  }
}




// 医師からのコメント
.c-dr {
  @include box-shadow;
  position: relative;
  padding: 2rem 2rem 2.5rem;

  @include media(tb) {
    padding: 4.7rem 6rem 4rem 12rem;
    margin-left: 2rem; // 人物画像とウインドウの間に余白を設ける
  }

  @include media(m) {
    margin-left: 0; // 余白を元に戻す
  }

  &+& {
    margin-top: 4rem;

    @include media(tb) {
      margin-top: 2rem;
    }
  }

  &__ttl {
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0 0 1.5rem 9.5rem;

    @include media(tb) {
      margin: 0 0 1.5rem;
    }

    em {
      display: inline-block;
      font-size: 1.6rem;
      margin-top: 1rem;

      @include media(tb) {
        margin: 0 0 0 1rem;
      }
    }
  }

  &__thumb {
    @include absolute($top: -1.6rem, $left:2rem);
    width: 8rem;

    @include media(tb) {
      top: 50%;
      left: -2rem;
      transform: translateY(-50%);
      width: 12rem;
    }
  }

  &__txt {
    font-size: 1.4rem;
    line-height: 1.71;
  }
}

// コンテンツの上下余白
.c-pt-pb {
  padding: 3.5rem 0 8rem;

  @include media(tb) {
    padding: 11rem 0 12rem;
  }
}

/*
 * その他のパーツ
 */
// header
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(151, 151, 151, 0.25);

  @include media(tb) {
    /* tablet-size */
    box-shadow: none;

  }

  &__logo {
    width: 12rem;
    display: block;
    @include opacity;

    @include media(tb) {
      width: 20rem;
    }

    @include media(m) {
      /* middle-size */
      margin-left: $width-m;
    }

    img {
      width: 100%;
    }
  }

  &__logo2 {
    width: 12rem;
    display: block;

    @include media(tb) {
      width: 20rem;
      margin-left: $width-m;
    }

    img {
      width: 100%;
    }
  }

  &__logo3 {
    @include opacity;
    width: 17rem;
    display: block;

    @include media(tb) {
      width: 30rem;
      margin-left: 10px;
    }

    img {
      width: 100%;
    }
  }

  &__inner {
    height: 4.8rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media(tb) {
      height: 8rem;
    }
  }

  #js-header-nav {
    display: none;

    // @include media(tb) 調整{
    //   display: flex;
    //   align-items: center;
    // }
    @include media(m) {
      display: flex;
      align-items: center;
    }
  }

  .js-sp-menu {
    @include media(m) {
      display: none;
    }
  }

  &__nav-list {
    font-size: 0;
    font-weight: bold;

    @include media(m) {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
    }

    @include media(m) {}

    li {
      margin: 3rem 0.5rem 3rem 0;
      text-align: left;
      position: relative;
      font-size: 1.4rem;

      @include media(m) {
        display: inline-block;
        margin: 0 1rem 0 0;
        line-height: 1.5;
        font-size: 1.2rem;

      }

      @include media(m) {
        margin-right: 3rem;
        font-size: 1.4rem;

      }

      >a {
        @include opacity;
        color: #000;
      }

      &.js-sp-nav-toggle {
        position: relative;

        &::after {
          content: "";
          position: absolute;
          top: 5px;
          right: -15px;
          height: 5px;
          width: 5px;
          display: inline-block;
          border-left: 2px solid #9ea0a5;
          border-bottom: 2px solid #9ea0a5;
          transform: rotate(-45deg);
          margin-left: 8px;
          transition: transform 0.5s;
          vertical-align: middle;
        }

        span {
          cursor: pointer;
          transition: all 0.3s linear;
          opacity: 1;

          &:hover {
            opacity: 0.7;
          }
        }

        &.active {
          &::after {
            transform: rotate(-225deg);
          }
        }
      }

      .subnav-list {
        display: none;
        margin-top: 15px;
        font-size: 16px;
        background-color: #fff;

        @include media(tb) {
          display: none;
          position: absolute;
          bottom: -0px;
          left: 50%;
          padding: 52px 32px 22px;
          transform: translateY(100%) translateX(-50%);
        }

        &.active {
          display: block;
        }

        &__item {
          white-space: nowrap;

          &+.subnav-list__item {
            margin-top: 16px;
          }
        }
      }

      .dropdown-menu {
        @include media(tb) {
          @include transition;
          position: relative;
          cursor: pointer;
          display: block;
        }

        &::before {
          @include media(tb) {

            display: block;
            z-index: 1;
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 3rem;
            transform: translate(0%, 100%);
            content: "";
          }
        }

        &:hover {
          @include media(tb) {

            color: #828282;
          }

          >a {
            @include media(tb) {

              opacity: 1;
              pointer-events: inherit;
            }

            div {
              p {
                @include media(tb) {

                  @include opacity;
                }
              }
            }

          }
        }

        >span {
          display: block;
          padding: 1rem 1.5rem;
          color: #fff;
          line-height: 1.5;

          @include media(m) {
            color: #000;
            padding: 0;

          }
        }

        >a {
          transform: translate(-0.9rem, 0px);
          background-color: #3F3F3F;
          width: calc(100% + 1.8rem);
          // padding: 1rem 1.5rem 1rem 3.5rem;
          margin-top: 1rem;
          display: flex;

          @include media(m) {

            @include transition;
            filter: drop-shadow(0px 4px 10px rgba(62, 62, 62, 0.25));
            opacity: 0;
            pointer-events: none;
            display: flex;
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            font-weight: 700;
            font-size: 1.4rem;
            line-height: 1.5;
            background-color: #fff;
            padding: 2rem;
            color: $color_font;
            border: .2rem solid #D8D8D8;
            border-radius: 1rem;
            width: 27rem;
            transform: translate(-10%, 25%);
            box-sizing: border-box;
          }

          &::before,
          &::after {
            @include media(m) {
              @include transition;
              position: absolute;
              left: 50%;
              right: 50%;
              content: "";
            }
          }

          &::before {
            @include media(m) {
              z-index: 2;
              top: -22px;
              margin-left: -14px;
              border: 10px solid transparent;
              border-bottom: 12px solid #FFF;
            }
          }

          &::after {
            display: inline-block;
            display: flex;
            right: 2.6rem;
            -ms-flex-align: center;
            align-items: center;
            content: "\f054";
            font-weight: 900;
            font-family: "Font Awesome 6 Free";

            @include media(m) {
              z-index: 1;
              top: -28px;
              margin-left: -17px;
              border: 13px solid transparent;
              border-bottom: 14px solid #D8D8D8;
            }
          }


          div {
            @include media(m) {
              display: flex;
            }

            &::after {
              @include media(m) {

                @include fontAwesome__sol;
                content: "\f054";
                @include transition;
                margin-left: 2rem;
                display: flex;
                align-items: center;
                color: #d8d8d8;
              }
            }

            p {
              font-size: 1rem;
              text-align: left;
              padding-left: 2rem;
              padding-right: 2rem;

              @include media(m) {

                @include transition;
                padding-left: 0;
                padding-right: 2rem;
                border-right: 1px solid #d8d8d8;
                font-size: 1.4rem;

              }

              .c-fs-20 {
                font-size: 1rem;

                @include media(m) {
                  font-size: 2rem;

                }
              }
            }

            img {
              width: 100%;
              margin-top: 0.5rem;

              @include media(m) {
                width: auto;
                display: inline;
                height: 1.5rem;
                vertical-align: baseline;
              }
            }
          }
        }

        .c-cl-purple {
          color: #fff;

          @include media(m) {
            color: #5F2B9A;

          }
        }

        &-make {

          @extend .dropdown-menu;

          >a {
            @include media(m) {
              transform: translate(-32%, 25%);
            }

            @include media(l) {
              transform: translate(-27%, 25%);
            }
          }

          .c-cl-blue {
            @include media(tb) {

              font-weight: 400;
            }
          }
        }

      }


    }
  }

  &__btn-list {
    font-size: 0;
    font-weight: bold;

    @include media(m) {
      display: flex;
    }

    li {
      @include fontsize(16, 12);

      @include media(m) {
        display: inline-block;
      }

      &+li {
        margin-top: 1rem;

        @include media(m) {
          margin: 0 0 0 1rem;
        }
      }

      a {
        @include opacity;
        display: block;
        width: 100%;
        padding: 1.6rem;
        border-radius: 2.4rem;
        text-align: center;

        @include media(m) {
          width: 10rem;
          padding: 0.9rem 0;
          border-radius: 1.5rem;

        }
      }
    }
  }
}

.header-nav-sp {
  @include transition;
  position: fixed;
  right: -23rem; // box-shadowが見えないように横幅以上に移動させる
  top: 0;
  bottom: 0;
  z-index: 11;
  width: 22rem;
  padding: 0 .9rem;
  background-color: #2E2E2E;
  box-shadow: -4px 0px 4px rgba($color: $color_main08, $alpha: 0.16);

  &.open {
    right: 0;
  }

  .header__nav-list {
    margin-top: 7rem;

    li {
      margin: 1.5rem 0 0 0;

      &:nth-of-type(1) {
        margin-top: 0;
      }

      a {
        color: #fff;
        // background-color: #2E2E2E;
        padding: 1rem 1.5rem;
        display: flex;
        line-height: 1.5;

        &.current {
          background: #5B5B5B;
        }
      }
    }
  }

  .header__btn-list {
    margin-top: 2rem;
  }

  &__sensor {
    // ナビゲーション外のタップを感知し、メニューを閉じる
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;

    &.open {
      display: block;
    }
  }
}

.sub-header {
  font-weight: bold;

  @include media(tb) {
    display: flex;
    align-items: center;
    margin-left: 2rem;
    padding-bottom: 2rem;
  }

  &__ttl {
    display: flex;
    align-items: center;
    font-size: 0;
    padding: 1rem 0;
    cursor: pointer;

    @include media(tb) {
      @include opacity;
      padding: 0;
      margin-right: 4rem;
    }

    &::after {
      $this: &;
      @include transition;
      content: '';
      display: inline-block;
      width: 0.8rem;
      height: 0.8rem;
      border-bottom: 2px solid #000;
      border-right: 2px solid #000;
      border-color: inherit;
      margin: -0.3rem 0 0 1rem;
      transform: rotate(45deg);

      @include replace($this, '.open'+$this) {
        transform: rotate(-135deg);
        margin-top: 0.3rem;
      }

      @include media(tb) {
        display: none;
      }
    }

    &.lightblue {
      color: $color_main02;
    }

    &.darkblue {
      color: $color_main06;
    }

    &.disable {
      cursor: none;
      pointer-events: none;

      &::after {
        display: none;
      }
    }
  }

  &__txt {
    @include fontsize(14, 18);
    margin-left: 1rem;
  }

  &__list {
    display: none;
    font-size: 0;
    padding: 1.2rem 0 2rem 4.4rem;

    @include media(tb) {
      display: block;
      padding: 0;
    }

    li {
      @include media(tb) {
        display: inline-block;
      }

      &+li {
        margin-top: 2rem;

        @include media(tb) {
          margin-top: 0;
        }
      }
    }

    a {
      @include fontsize(12, 14);
      @include opacity;
      display: inline-block;
      margin-right: 2rem;
      color: inherit;

      @include media(m) {
        margin-right: 3rem;
      }
    }
  }
}

// about メインビュー laegeサイズ
.about-mv {
  position: relative;

  &__box {
    position: relative;
    left: ($width-m * -1);
    z-index: 1;
    display: inline-block;
    width: max(calc(100% - 8.6rem), 29rem);
    background-color: $color_main01;
    padding: 1rem 0 5.5rem $width-m;
    margin-top: -3rem;
    font-weight: bold;
    color: #fff;

    @include media(tb) {
      position: absolute;
      top: $width-m;
      bottom: $width-m;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: auto;
      min-width: 46rem;
      padding: 0 0 0 4rem;
      margin-top: 0;
    }

    &__nurse {
      padding: 3rem 0 4rem 20px;
      width: max(100%, 29rem);
    }
  }

  &__sttl {
    &__nurse {
      @include fontsize(16, 19);

      @include media(tb) {}
    }
  }

  &__ttl {
    @include fontsize(20, 34);
    line-height: 1.41;

    @include media(tb) {
      margin-bottom: 3rem;
    }

    &__nurse {
      @include fontsize(28, 43);
      margin-top: 1rem;
      line-height: 1.2;

      @include media(tb) {}
    }
  }

  &__ttl-top {
    @include fontsize(20, 48);
    line-height: 1.3;
    margin: 0.3rem 0 0.7rem;

    @include media(tb) {
      line-height: 1.1;
      margin: 0 0 1rem;
    }
  }

  &__ttl-btm {
    @include fontsize(12, 18);
    letter-spacing: 0.05em;
    line-height: 1.33;
    margin-bottom: 0.5rem;

    @include media(tb) {
      margin-bottom: 3.5rem;
    }
  }

  &__txt {
    &__nurse {
      @include fontsize(12, 13);
      margin-top: 5rem;

      @include media(tb) {
        margin-top: 6em;
        margin-bottom: 1em;

      }
    }
  }


  &__btn {
    @include fontsize(12, 16);
    @include brightness;
    @include btn-arrow-icon;
    display: block;
    width: 50%;
    text-align: center;
    font-weight: bold;
    padding: 2.4rem 1rem;

    @include media(tb) {
      text-align: left;
      width: 36rem;
      padding: 3.1rem 4rem;
    }

    &+& {
      @include media(tb) {
        margin-top: 1rem;
      }
    }

    &::after {
      display: none;

      @include media(tb) {
        display: block;
      }
    }
  }

  &__list-btn-sp {
    position: relative;
    z-index: 2;
    display: flex;
    margin-top: -4.5rem;

    @include media(tb) {
      display: none;
    }

    &__nurse {
      justify-content: flex-end;
      margin-top: -2.5rem;
    }
  }

  &__slide {
    height: 20rem;
    overflow: hidden;

    @include media(tb) {
      height: 55.5rem;
      margin: 0;
    }

    &--not-top {
      @include media(tb) {
        height: 55.5rem;
      }
    }

    li {
      height: 100%;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

  }

  &__txtbox {
    position: absolute;
    top: 0;
    right: 2.5rem;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;

    @include media(m) {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      right: 11.3rem;
    }


    p {
      color: #fff;
      font-weight: 500;
      font-size: 1.9rem;
      line-height: 1.6;
      letter-spacing: 0.08em;

      @include media(tb) {
        font-size: 3.6rem;
        line-height: 1.45;
      }
    }

    .cv__btnbox {
      display: none;

      @include media(m) {
        display: block;
        width: 41.7rem;
      }

      &-make {
        display: none;

        @include media(m) {
          display: block;
          width: 37.2rem;
          left: 9rem;
        }

      }
    }

    &-make {
      @extend .about-mv__txtbox;
      left: 2.5rem;
      right: 0;

      @include media(m) {
        left: 8rem;

      }

    }


  }

  &__note {
    @include fontsize(10, 11);
    margin-top: 1rem;

    @include media(tb) {
      margin: 1.5rem 0 -2.5rem;
    }
  }
}

// about メインビュー smallサイズ
.about-mv-small {
  background: $color_main03;

  @include media(tb) {
    background: linear-gradient(to top, $color_main03 4rem, #fff 4rem);
  }

  &__inner {
    position: relative;
    margin: 0 ($width-m * -1);

    @include media(tb) {
      margin: 0;
    }
  }

  img {
    height: 20rem;
    width: 100%;
    object-fit: cover;

    @include media(tb) {
      height: 36rem;
    }
  }

  &__box {
    position: relative;
    z-index: 9;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    padding: 1.2rem 2rem 1.5rem 2rem;
    margin-top: -3rem;
    font-weight: bold;
    color: #fff;
    background-image: url("../images/mainview/box-bg.png");
    @include background-image();

    @include media(tb) {
      position: absolute;
      left: ($width-m * -1);
      top: 6rem;
      bottom: 6rem;
      width: 42rem;
      padding: 0 0 0 6rem;
      margin: 0;
    }
  }

  &__ttl-top {
    @include fontsize(20, 36);
    line-height: 1.33;
  }

  &__ttl-btm {
    @include fontsize(12, 16);
    line-height: 1.5;
    margin-top: 0.5rem;

    @include media(tb) {
      margin-top: 1.5rem;
      letter-spacing: 0.05em;
    }
  }
}

// about/make cvボタン
.cv__btnbox {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 3.5rem 1.5rem 0;

  @include media(tb) {
    flex-direction: row;
  }

  @include media(m) {
    margin: 4.5rem 0 0;
    display: none;
  }

  &-make {
    @extend .cv__btnbox;

  }
}

.cv__btn {
  @include brightness;
  color: #fff;
  font-weight: 700;
  display: block;
  padding: 1rem 0;
  line-height: 1.3;
  color: #fff;

  @include media(tb) {
    /* tablet-size */

  }

  @include media(m) {
    /* middle-size */
    // min-height: 10.9rem;
  }

  &__make,
  &__contact-jolly,
  &__contact-make {
    @include transition;
    @extend .cv__btn;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    padding: 1.7rem 2rem;
    position: relative;
    font-size: 1.2rem;

    @include media(tb) {
      /* tablet-size */

    }

    @include media(m) {
      /* middle-size */
      align-items: flex-start;
      position: relative;
      padding: 2.05rem 2rem;

    }

    &::before,
    &::after {
      @include transition;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;

    }

    &::before {
      @include transition;

      display: block;
      right: 4.5rem;
      width: 0.1rem;
      height: calc(100% - 2.2rem);
      content: "";
      background-color: #fff;

      @include media(m) {
        /* tablet-size */
        height: calc(100% - 3rem);
      }
    }

    &::after {
      @include transition;
      @include fontAwesome__sol;
      content: "\f054";
      right: 2rem;
      display: flex;
      align-items: center;
    }

    &:hover {
      @include brightness;
    }

    div {
      @include transition;
      font-size: 1.2rem;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      text-align: center;
      text-align: left;

      @include media(tb) {
        /* tablet-size */
        font-size: 1.4rem;

      }

      @include media(m) {
        /* middle-size */
      }

      span {
        @include transition;

        font-size: 1.1rem;

        @include media(tb) {
          /* tablet-size */

        }

        @include media(m) {
          /* middle-size */
          font-size: 1.5rem;
        }
      }
    }
  }


  &__make {
    @include transition;
    border-radius: 10rem;
    width: 100%;
    background-image: linear-gradient(0deg, #5C2798 0%, #A577DB 87.22%);
    margin-top: 1rem;

    @include media(tb) {
      /* tablet-size */
      margin-top: 0;
      width: calc(100% / 2 - 1rem);
    }

    @include media(m) {
      /* middle-size */
      margin-top: 2rem;
      width: 100%;

    }

    &.-en {
      padding: 1.7rem 5rem 1.7rem 2rem;
    }

    @include media(l) {}

    &::after {
      @include media(tb) {
        /* tablet-size */
      }

      @include media(m) {
        /* middle-size */
      }

      @include media(l) {
        /* middle-size */
      }
    }
  }

  &__contact-jolly {
    @include transition;
    width: 100%;
    background: linear-gradient(0deg, #EA3D82 0%, #F8858D 100%);
    border-radius: 10rem;

    @include media(tb) {
      /* middle-size */
      width: calc(100% / 2 - 1rem);
    }

    @include media(m) {
      /* middle-size */
      width: 100%;

    }

    @include media(l) {}

    &::after {
      @include media(m) {
        /* middle-size */
      }
    }
  }

  &__contact-make {
    @include transition;
    border-radius: 10rem;
    width: 100%;
    background-image: linear-gradient(0deg, #5C2798 0%, #A577DB 87.22%);
    margin-top: 0;

    @include media(tb) {
      /* tablet-size */
      width: calc(100% / 2 - 1rem);
      margin: auto;
    }

    @include media(m) {
      /* middle-size */
      width: 100%;

    }

    @include media(l) {}

    &::after {
      @include media(tb) {
        /* tablet-size */
      }

      @include media(m) {
        /* middle-size */
      }

      @include media(l) {
        /* middle-size */
      }
    }
  }
}



// about トップ 導入部分
.about-top-intro {
  background: linear-gradient(transparent 94%, $color_main03 94%);

  &__inner {
    position: relative;
    margin-top: 6rem;

    @include media(tb) {
      margin-top: 7rem;
    }
  }

  &__logo {
    display: block;
    width: 20rem;
    margin: 0 auto 2.5rem;

    @include media(tb) {
      position: absolute;
      top: 36%;
      right: 60%;
      width: 30.5%;
    }
  }

  &__box {
    text-align: center;
    font-weight: bold;

    @include media(tb) {
      text-align: left;
      margin-left: 50%;
    }
  }

  &__h1 {
    @include fontsize(20, 24);
    line-height: 1.3;
    margin-bottom: 2.5rem;

    @include media(tb) {
      line-height: 1.5;
    }
  }

  &__txt {
    margin-bottom: 3rem;
  }

  &__thumb {
    display: block;
    width: min(100%, 950px);
    margin: 4rem auto 0;

    & img {
      width: 100%;
    }
  }

  &__link {
    font-size: 1.4rem;
  }
}

// about トップ 課題
.about-task {
  padding-top: 8rem;
  background: $color_main03;

  @include media(tb) {
    padding-top: 12rem;
  }

  &__box {
    @include box-shadow;
    position: relative;
    padding-top: 3.5rem;

    @include media(tb) {
      padding-top: 7rem;
    }
  }

  &__inner {
    width: min(calc(100% - ($width-m * 2)), 88rem);
    margin: 0 auto;
    padding: 2.5rem 2rem 4rem;

    @include media(tb) {
      padding: 2.5rem 0 10rem;
    }
  }

  &__ill-line {
    @include absolute($top: -3rem, $right: -1rem);
    width: 7.5rem;

    @include media(tb) {
      top: -4rem;
      right: 2rem;
      width: 15rem;
    }

    @include media(m) {
      top: -2rem;
    }
  }

  &__fig {
    width: min(100%, 28.8rem);
    margin: 0 auto;

    @include media(tb) {
      width: 44.2%;
    }

    & img {
      width: 100%;
    }
  }

  &__box-txt {
    margin-bottom: 2.5rem;

    @include media(tb) {
      width: 40rem;
      margin-bottom: 0;
    }
  }

  &__item {
    @include media(tb) {
      display: flex;
      align-items: center;
    }

    &+& {
      margin-top: 3rem;

      @include media(tb) {
        margin-top: 6rem;
      }
    }

    &:nth-of-type(even) {
      @include media(tb) {
        justify-content: flex-end;
      }

      .about-task__fig {
        @include media(tb) {
          margin: 0 0 0 2rem;
        }
      }
    }

    &:nth-of-type(odd) {
      .about-task__fig {
        @include media(tb) {
          order: 1;
          margin: 0 2rem 0 0;
        }
      }

      .about-task__box-txt {
        @include media(tb) {
          order: 2;
        }
      }
    }
  }

  &__tag {
    @include fontsize(10, 14);
    display: inline-block;
    letter-spacing: 0.2em;
    color: #fff;
    padding: 0.5rem 1rem;
    margin-bottom: 0.5rem;
    border-radius: 3rem;
    background-color: $color_font;

    @include media(tb) {
      padding: 0.8rem 1.9rem;
      margin-bottom: 1rem;
    }
  }

  &__h2 {
    margin-bottom: 0.5rem;

    @include media(tb) {
      margin-bottom: 1.2rem;
      white-space: nowrap;
    }
  }
}

// about 画像とテキストを交互に配置するレイアウト alt=alternative(交互)
.about-alt {
  padding-top: 4rem;
  background: linear-gradient(to top, #fff 4rem, $color_main03 4rem);

  @include media(tb) {
    padding-top: 12rem;
  }

  &--white {
    padding: 0 0 8rem;
    background: linear-gradient($color_main03 4rem, #fff 4rem);

    @include media(tb) {
      padding-top: 0;
    }
  }

  &__box {
    @include box-shadow;
    position: relative;
    z-index: 1;
    padding-top: 3.5rem;

    @include media(tb) {
      padding-top: 7rem;
    }
  }

  &__inner {
    width: min(calc(100% - ($width-m * 2)), 80rem);
    margin: 0 auto;
    padding: 1.5rem 0 3.5rem;

    @include media(tb) {
      padding: 3rem 0 8rem;
    }
  }

  &__thumb {
    display: block;
    width: 18rem;
    margin: 0 auto 2rem;

    @include media(tb) {
      width: 35%;
    }
  }

  &__box-txt {
    @include media(tb) {
      width: 40rem;
    }
  }

  &__item {
    @include media(tb) {
      display: flex;
      align-items: center;
    }

    &+& {
      margin-top: 3rem;

      @include media(tb) {
        margin-top: 2rem;
      }
    }

    &:nth-of-type(even) {
      @include media(tb) {
        justify-content: flex-end;
      }

      .about-alt__thumb {
        @include media(tb) {
          order: 2;
          margin: 0 0 0 4rem;
        }
      }

      .about-alt__box-txt {
        @include media(tb) {
          order: 1;
        }
      }
    }

    &:nth-of-type(odd) {
      .about-alt__thumb {
        @include media(tb) {
          margin: 0 4rem 0 0;
        }
      }
    }
  }

  &__h2 {
    margin-bottom: 0.5rem;

    @include media(tb) {
      margin-bottom: 1rem;
      white-space: nowrap;
    }
  }
}

// about サブビュー
.about-subv {
  margin: -4rem 0 4rem;

  @include media(tb) {
    margin-bottom: 0;
  }

  img {
    width: 100%;
  }
}

// about ３つのプラス
.about-3plus {
  padding: 3.5rem 0 8rem;

  @include media(tb) {
    padding: 7.5rem 0 12rem;
  }

  &--top {
    @include media(tb) {
      padding-top: 11rem;
    }
  }

  &__h2 {
    @include fontsize(14, 20);
    margin-bottom: 1.5rem;

    @include media(tb) {
      margin-bottom: 7.5rem;
    }
  }

  &__list {
    margin-top: 2rem;

    @include media(tb) {
      padding-left: 4rem; // 「+」記号が画面外に出てしまうので余白を設ける
      margin-top: -4rem;
    }

    @media screen and (min-width: 1140px) {
      padding-left: 0; // 「+」記号のための余白を削除
    }
  }

  &__item {
    @include box-shadow;
    padding: 2rem 2rem 3rem;

    @include media(tb) {
      position: relative;
      z-index: 1;
      display: flex;
      align-items: center;
      padding: 4rem 8rem;
    }

    &::before {
      @include media(tb) {
        content: '';
        @include center(y);
        left: -4rem;
        width: 8rem;
        height: 8rem;
        @include background-image();
      }
    }

    &+& {
      margin-top: 2rem;
    }

    &:nth-of-type(1) {
      &::before {
        background-image: url("../images/common/cross-blue.png");
      }

      .about-3plus__ttl {
        color: $color_main02;

        &::before {
          background-image: url("../images/common/cross-blue.png");
        }
      }
    }

    &:nth-of-type(2) {
      &::before {
        background-image: url("../images/common/cross-violet.png");
      }

      .about-3plus__ttl {
        color: $color_main04;

        &::before {
          background-image: url("../images/common/cross-violet.png");
        }
      }
    }

    &:nth-of-type(3) {
      &::before {
        background-image: url("../images/common/cross-red.png");
      }

      .about-3plus__ttl {
        color: $color_main05;

        &::before {
          background-image: url("../images/common/cross-red.png");
        }
      }
    }
  }

  &__box-txt {
    flex: 1;
  }

  &__ttl {
    font-weight: bold;
    margin-bottom: 1.8rem;

    span {
      display: inline-block;
      position: relative;

      &::before {
        content: '';
        @include absolute($right: 0, $bottom: -5px, $left: 0);
        height: 1px;
        border-top: 1px solid #000;
        border-color: inherit;
      }
    }

    &::before {
      content: '';
      display: inline-block;
      width: 4rem;
      height: 4rem;
      margin-right: 1rem;
      vertical-align: middle;
      @include background-image();

      @include media(tb) {
        display: none;
      }
    }
  }

  &__subttl {
    @include fontsize(18, 26);
    font-weight: bold;
    line-height: 1.38;
    text-align: left;
    margin-bottom: 0.5rem;

    @include media(m) {
      @include justify;
    }
  }

  &__thumb {
    display: block;
    width: min(100%, 28.8rem);
    margin: 2.5rem auto 0;

    @include media(tb) {
      width: 36.5%;
      margin: 0 0 0 3rem;
    }
  }
}

// about ３つのプラス 横一列のラベル
.plus3-label {
  @include media(tb) {
    display: flex;
    margin-top: 5.5rem;
  }

  &__item {
    flex: 1;
    display: flex;
    align-items: center;
    font-weight: bold;
    height: 6rem;
    background-size: contain;
    background-position: left 0;
    background-repeat: no-repeat;

    @include media(tb) {
      justify-content: center;
      height: 24rem;
      background-size: cover;
      background-position: center;
    }

    &:nth-of-type(1) {
      color: $color_main02;
      background-image: url("../images/common/icon-gradation-blue.jpg");

      @include media(tb) {
        color: #fff;
        background-image: url("../images/common/gradation-blue.jpg");
      }
    }

    &:nth-of-type(2) {
      color: $color_main04;
      background-image: url("../images/common/icon-gradation-violet.jpg");

      @include media(tb) {
        color: #fff;
        background-image: url("../images/common/gradation-violet.jpg");
      }
    }

    &:nth-of-type(3) {
      color: $color_main05;
      background-image: url("../images/common/icon-gradation-red.jpg");

      @include media(tb) {
        color: #fff;
        background-image: url("../images/common/gradation-red.jpg");
      }
    }
  }

  &__h1 {
    display: inline-flex;
    align-items: center;
    position: relative;
    color: inherit;
    margin-left: 8rem;

    @include media(tb) {
      flex-direction: column;
      margin-left: 0;
    }

    &::before {
      content: '';
      @include absolute($right:0, $bottom:-0.5rem, $left:0);
      height: 1px;
      border-top: 1px solid #000;
      border-color: inherit;

      @include media(tb) {
        display: none;
      }
    }

    small {
      @include fontsize(14, 20);
      margin-right: 1rem;

      @include media(tb) {
        margin: 0 0 1rem;
      }
    }

    em {
      font-size: 2rem;

      @include media(tb) {
        font-size: 2.4rem;
        margin-bottom: 2rem;
      }

      @include media(m) {
        font-size: 3rem;
      }
    }
  }
}

// about トップ 必要なこと
.about-top-needs {
  padding: 3.5rem 0 8rem;

  @include media(tb) {
    padding-top: 11rem;
  }

  &__thumb {
    display: block;
    width: min(100%, 58rem);
    margin: 0 auto;
  }

  &__fig {
    position: relative;

    img {
      width: 100%;
    }
  }

  &__cap {
    @include center(xy);
    font-size: 2rem;
    color: #fff;
    font-weight: bold;
    line-height: 1.3;
    text-align: center;
    white-space: nowrap;

    @include media(m) {
      font-size: 3rem;
    }
  }
}

// about トップ 活用一覧
.about-top-use {
  padding-top: 7.5rem;

  &__h2 {
    text-align: right;
    margin-bottom: 2.5rem;

    @include media(tb) {
      margin-bottom: 5rem;
    }

    @include media(l) {
      margin-right: calc((100% - 1024px)/ 2);
    }
  }

  &__fig {
    position: relative;

    @include media(tb) {
      width: 65.5%;
    }

    img {
      @include border-radius-10;
      width: 100%;
    }
  }

  &__cap {
    @include center(xy);
    font-size: 2.4rem;
    color: #fff;
    font-weight: bold;
    white-space: nowrap;

    @include media(m) {
      font-size: 4rem;
    }
  }

  &__box-txt {
    @include box-shadow-small;
    @include clearfix;
    position: relative;
    z-index: 1;
    padding: 1.5rem 2rem 2rem;
    border-radius: 2rem;

    @include media(tb) {
      position: absolute;
      bottom: 4rem;
      left: calc(100% - 35.3rem);
      width: 35.2rem;
    }

    @include media(m) {
      left: 61%;
      padding: 4rem;
    }
  }

  &__h3 {
    @include fontsize(18, 24);
    line-height: 1.33;
    font-weight: bold;
    margin-bottom: 1rem;

    @include media(tb) {
      line-height: 1.5;
    }
  }

  &__link {
    @include opacity;
    @include fontsize(12, 14);
    @include btn-arrow-icon;
    font-weight: bold;
    color: inherit;
    margin-top: 1rem;
    padding-right: 4rem;
    float: right;

    @include media(tb) {
      margin-top: 3rem;
    }

    &::after {
      right: 0;
      background-image: url("../images/common/arrow-link-black.png");
    }
  }

  &__item {
    position: relative;

    &+& {
      margin-top: 2rem;

      @include media(tb) {
        margin-top: 4rem;
      }

      @include media(m) {
        margin-top: 2rem;
      }
    }

    &:nth-of-type(even) {
      .about-top-use__fig {
        @include media(tb) {
          margin-left: auto;
        }
      }

      .about-top-use__box-txt {
        margin: -3rem 1rem 0 6rem;

        @include media(tb) {
          left: auto;
          right: calc(100% - 35.3rem);
          margin: 0;
        }

        @include media(m) {
          right: 61%;
        }
      }
    }

    &:nth-of-type(odd) {
      .about-top-use__box-txt {
        margin: -3rem 6rem 0 1rem;

        @include media(tb) {
          margin: 0;
        }
      }
    }
  }
}

// about 導入事例(case study) = cs
.about-cs {
  padding: 4rem 0 5rem;

  @include media(tb) {
    padding: 7rem 0 12rem;
  }

  &--top {
    padding-bottom: 4rem;

    @include media(tb) {
      padding: 12rem 0;
    }
  }

  &__list-logo {
    @include box-shadow;
    margin-top: 1rem;
    padding: 3.5rem 2rem 4rem;

    @include media(tb) {
      margin-top: 12rem;
      padding: 6rem;
    }

    img {
      display: block;
      width: 100%;

      @include media(tb) {
        width: 87%;
        margin: 0 auto;
      }
    }
  }

  &__list-ttl {
    @include fontsize(20, 24);
    font-weight: bold;
    text-align: center;
    margin-bottom: 2.5rem;

    @include media(tb) {
      margin-bottom: 6rem;
    }
  }
}

// about ニュース一覧
.about-news {
  padding-top: 7.5rem;

  &__h3 {
    @include media(tb) {
      margin-bottom: 3rem;
    }
  }

  &__list {
    border-top: 1px solid #E8E7E5;
  }

  &__item {
    @include btn-arrow-icon;
    @include opacity;
    color: inherit;
    border-bottom: 1px solid #E8E7E5;
    padding: 1.7rem 7rem 1.5rem 1rem;
    position: relative;
    display: block;

    @include media(tb) {
      display: flex;
      padding: 2.5rem 7rem 2.5rem 1rem;
    }

    &::after {
      background-image: url("../images/common/arrow-link-black.png");
      right: 1rem;

      @include media(tb) {
        right: 2rem;
      }
    }

    time {
      @include fontOswald;
      display: block;
      font-size: 2rem;
      margin-bottom: 0.5rem;

      @include media(tb) {
        display: inline-block;
        margin: 0.3rem 3rem 0 0;
      }

      small {
        display: inline-block;
        font-size: 1.2rem;
        vertical-align: top;
        margin-right: 0.6rem;
        margin-top: 0.3rem;
      }
    }
  }

  &__link {
    font-weight: bold;
    flex: 1;
  }
}

// about トップ 受賞歴
.about-top-award {
  @include box-shadow;
  padding: 4rem 2rem 3.5rem;
  margin-bottom: 7.5rem;

  @include media(tb) {
    margin-bottom: 11rem;
  }

  @include media(tb) {
    display: flex;
    align-items: center;
    padding: 3.2rem 8rem 2.8rem;
  }

  &__h4 {
    margin-bottom: 0.5rem;

    @include media(tb) {
      margin-bottom: 1rem;
    }
  }

  &__thumb {
    display: block;
    width: 14.2rem;
    margin: 0 auto 2.5rem;

    @include media(tb) {
      width: 21.5%;
      margin: 0 7rem 0 0;
    }
  }
}

// about よくある質問
.about-question {
  padding-bottom: 8rem;
  font-weight: bold;

  @include media(tb) {
    padding-bottom: 12rem;
  }

  &__h3 {
    margin-bottom: 2.5rem;

    @include media(tb) {
      margin-bottom: 3rem;
    }
  }

  &__list {
    border-bottom: 1px solid #E8E7E5;
  }

  &__q {
    @include fontsize(16, 20);
    line-height: 1.5;
    padding: 2.5rem 4.5rem 2.5rem 2rem;
    border-top: 1px solid #E8E7E5;
    position: relative;
    cursor: pointer;

    @include media(tb) {
      padding: 4.5rem 5rem 4.5rem 2rem;
    }

    &::after {
      content: '';
      @include transition;
      position: absolute;
      top: 50%;
      right: 2rem;
      margin-top: -0.8rem;
      width: 0.8rem;
      height: 0.8rem;
      border-bottom: 1px solid $color_font;
      border-right: 1px solid $color_font;
      transform: rotate(45deg);
    }

    &.open {
      &::after {
        transform: rotate(-135deg);
      }
    }
  }

  &__a {
    @include fontsize(12, 14);
    display: none;
    line-height: 1.66;
    padding: 0 4.5rem 2.5rem 2rem;

    @include media(tb) {
      line-height: 1.42;
      padding: 0 14rem 5rem 2rem;
    }
  }
}

// about 活用・可能性について述べるコンテンツ ipa = possibility(可能性、見込み、将来性、実現性)
.about-ipa {
  padding: 7.5rem 0;

  @include media(tb) {
    padding: 11rem 0;
  }

  &--glay {
    background-color: $color_main03;
    padding: 3.5rem 0;
  }

  &__thumb {
    display: block;
    width: min(100%, 58rem);
    margin: 0 auto;
  }
}

// エビデンス・論文採択事例
.p-evidence {
  padding-bottom: 5rem;
}

// about 成長に合わせた豊富なコンテンツ
.about-growth {
  text-align: center;
  overflow: hidden;

  &__inner {
    margin: 0 -1rem;

    @include media(tb) {
      margin: 0;
    }
  }

  &__list {
    width: min(100%, 355px);
    position: relative;
    padding: 3.5rem 0 4rem;
    margin: 0 auto;

    @include media(tb) {
      width: auto;
      padding: 11rem 0 12rem;
    }

    &::before {
      content: '';
      @include center(xy);
      width: 1px;
      height: 70%;
      background-color: $color_main08;
    }

    &::after {
      content: '';
      @include center(xy);
      width: 80rem;
      height: 80rem;
      border-radius: 50%;
      background-color: $color_main03;
      z-index: -1;
      margin-top: -3rem;

      @include media(tb) {
        margin-top: 1rem;
      }
    }
  }

  &__box-txt {
    @include box-shadow-small;
    width: 22rem;
    padding: 1.9rem 1rem 1.5rem;
    margin: -1rem auto 0;
    position: relative;
    z-index: 1;

    @include media(tb) {
      width: 28rem;
      padding: 2.4rem 2rem 2rem;
      margin-top: 0;
    }
  }

  &__thumb {
    @include border-radius-5;
    display: block;
    width: 16rem;

    @include media(tb) {
      @include center(y);
      left: 0;
      width: 32rem;
    }
  }

  &__item {
    font-weight: bold;
    position: relative;

    &+& {
      margin-top: 1rem;

      @include media(tb) {
        margin-top: 6rem;
      }
    }

    &:nth-of-type(even) {
      .about-growth__thumb {
        margin-left: auto;

        @include media(tb) {
          left: auto;
          right: 0;
          margin-left: 0;
        }
      }
    }
  }

  &__ttl {
    @include fontsize(20, 24);
    text-align: center;
    margin-bottom: 1.3rem;
  }

  &__txt {
    @include fontsize(12, 14);
    line-height: 1.66;
    text-align: center;

    @include media(tb) {
      line-height: 1.42;
    }
  }
}

// about 進行シート
.about-sheet {
  padding: 3.5rem 0 4rem;

  @include media(tb) {
    padding: 11rem 0 12rem;
  }

  &__inner {
    width: min(calc(100% - ($width-m * 2)), 1054px);
    margin: 0 auto;
  }

  &__h5 {
    margin: 0 ($width-m * -1);

    @include media(tb) {
      margin: 0;
    }
  }

  &__contents {
    position: relative;
    margin-top: 5.5rem;

    @include media(m) {
      height: 40rem;
    }
  }

  &__right {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    @include media(m) {
      justify-content: space-between;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
      width: calc(50% + 1.5rem);
    }

    .about-sheet__box-txt {
      margin-left: -5.5rem;

      @include media(tb) {
        margin-left: 0;
      }

      &::before {
        content: '';
        left: -5rem;
      }
    }

    .about-sheet__thumb {
      @include media(tb) {
        margin-right: 4rem;
      }

      @include media(m) {
        margin-right: 0;
      }
    }
  }

  &__left {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 4rem;

    @include media(m) {
      justify-content: space-between;
      position: absolute;
      left: 0;
      top: 3rem;
      width: calc(50% + 1.5rem);
      margin-top: 0;
    }

    .about-sheet__thumb {
      @include media(tb) {
        margin-left: 4rem;
      }

      @include media(m) {
        margin-left: 0;
      }
    }

    .about-sheet__box-txt {
      margin-right: -5.5rem;

      @include media(tb) {
        margin-right: 0;
      }

      &::before {
        content: '';
        right: -5rem;
      }
    }
  }

  &__thumb {
    width: 20rem;
    box-shadow: 2px 2px 8px rgba($color: #919090, $alpha: 0.16);

    @include media(tb) {
      width: 26rem;
    }
  }

  &__box-txt {
    @include box-shadow-small;
    width: 19rem;
    padding: 1.5rem 2rem;
    position: relative;
    border-radius: 2rem;

    @include media(tb) {
      width: 24.2rem;
      padding: 2.5rem 3rem;
    }

    &+& {
      margin-top: 1rem;

      @include media(tb) {
        margin-top: 2rem;
      }
    }

    &::before {
      @include media(tb) {
        content: '';
        position: absolute;
        top: 50%;
        height: 1px;
        width: 5rem;
        background-color: $color_main08;
      }
    }

    &.one {
      margin-top: 2rem;

      @include media(tb) {
        margin-top: 1rem;
      }
    }

    &.three {
      margin-top: 2rem;

      @include media(tb) {
        margin-top: 0;
      }
    }
  }

  &__ttl {
    margin-bottom: 0.5rem;

    @include media(tb) {
      margin-bottom: 0;
    }
  }

  &__txt {
    @include justify;
    @include fontsize(12, 14);
    line-height: 1.66;

    @include media(tb) {
      line-height: 1.87;
    }
  }
}

// about カリキュラム Curriculum -> ccm
.about-ccm {
  padding: 7.5rem 0 8rem;

  @include media(tb) {
    padding: 11rem 0 12rem;
  }

  &__chart {
    display: block;
    width: min(calc(100% - ($width-m * 2)), 33.5rem);
    margin: 3.5rem auto 4rem;

    @include media(tb) {
      width: min(calc(100% - ($width-m * 2)), 118rem);
      margin: 5.5rem auto 6rem;
    }
  }

  &__list {
    margin: 0 2rem 5rem;

    @include media(tb) {
      display: flex;
      margin: 0 0 7rem;
    }
  }

  &__item {
    @include media(tb) {
      flex: 1;
      max-width: 32rem;
    }

    &+& {
      margin-top: 2.5rem;

      @include media(tb) {
        margin: 0 0 0 4rem;
      }
    }
  }

  &__thumb {
    @include border-radius-5;
    width: 100%;
    margin-bottom: 1.5rem;

    @include media(tb) {
      margin-bottom: 2rem;
    }
  }

  &__tag {
    @include fontsize(10, 14);
    display: inline-block;
    width: 6.6rem;
    font-weight: bold;
    text-align: center;
    color: $color_main08;
    padding: 0.5rem 0;
    margin-bottom: 0.5rem;
    border: 1px solid $color_main08;
    border-radius: 1.5rem;

    @include media(tb) {
      width: 8rem;
      padding: 0.8rem 0;
    }
  }

  &__ttl {
    margin-bottom: 0.5rem;

    @include media(tb) {
      margin-bottom: 1.5rem;
    }
  }

  &__txt {
    display: flex;

    b {
      flex-shrink: 0;
    }
  }
}

// about 就労支援カリキュラム Job Support Curriculum -> js-ccm
.about-js-ccm {
  padding: 7.5rem 0 8rem;

  @include media(m) {
    padding: 11rem 0 12rem;
  }

  &__chart {
    display: block;
    width: min(100%, 32.2rem);
    margin: 3.5rem auto 4rem;

    @include media(tb) {
      width: 100%;
      margin: 5.5rem 0 0;
    }
  }

  &__list {
    position: relative;
    padding: 0 2rem 3rem;
    margin-bottom: 4rem;

    @include media(tb) {
      display: flex;
      justify-content: space-between;
      padding: 0 0 4rem;
      margin-bottom: 8rem;
    }

    &::before {
      content: '';
      @include border-radius-5;
      @include absolute($top: 4rem, $right: 0, $bottom: 0, $left:0);
      z-index: -1;
      background-color: $color_main03;

      @include media(tb) {
        right: ($width-m * -1);
        left: ($width-m * -1);
      }

      @media screen and (min-width: 1160px) {
        right: -4rem;
        left: -4rem;
      }
    }
  }

  &__item {
    @include media(tb) {
      width: 31%;
    }

    img {
      width: 100%;
    }

    &+& {
      margin-top: 4rem;

      @include media(tb) {
        margin-top: 0;
      }
    }
  }

  &__thumb {
    @include border-radius-5;
    margin-bottom: 1.5rem;
  }

  &__ttl {
    margin-bottom: 0.5rem;

    @include media(tb) {
      margin-bottom: 1rem;
    }
  }

  &__slide {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;

    @include media(tb) {
      margin-top: 1.8rem;
    }
  }

  &__slide-item {
    width: 24.5%;

    @include media(tb) {
      width: 24%;
    }

    img {
      border-radius: 1rem;
    }

    figcaption {
      @include fontsize(11, 12);
      font-weight: bold;
      text-align: center;
      line-height: 1.33;
      margin-top: 0.5rem;
    }
  }
}

// about メリットや利点を表記するコンテンツ
.about-merit {
  padding: 7.5rem 0 8rem;
  overflow: hidden;

  @include media(tb) {
    padding: 11rem 2rem 12rem;
  }

  &__outer {
    overflow: hidden;
  }

  &__list {
    position: relative;
    padding: 1rem 0 0;

    @include media(tb) {
      padding: 2rem 0 10rem;
    }

    &::before {
      content: '';
      @include center(x);
      bottom: 4rem;
      z-index: -1;
      width: 80rem;
      height: 80rem;
      border-radius: 50%;
      background-color: $color_main03;

      @include media(tb) {
        bottom: 0;
      }
    }

    &--js {
      &::before {
        width: 92rem;
        height: 92rem;

        @include media(tb) {
          width: 80rem;
          height: 80rem;
        }
      }
    }
  }

  &__thumb {
    display: block;
    width: 20.8rem;
    margin: 1.5rem auto 0;

    @include media(tb) {
      width: 26rem;
      margin-top: 0;
    }
  }

  &__box-txt {
    flex: 1;
  }

  &__ttl {
    @include fontsize(18, 24);
    font-weight: bold;
    margin-right: 1rem;

    @include media(tb) {
      margin-bottom: 2rem;
    }

    small {
      @include fontsize(12, 14);
      display: block;
      margin-bottom: 1rem;
    }
  }

  &__txt {
    margin: 0.8rem 0 0.5rem;

    @include media(tb) {
      margin: 1.5rem 0 1.3rem;
    }
  }

  &__note {
    @include fontsize(10, 12);
    line-height: 1.5;
    margin-right: 1rem;

    @include media(tb) {
      display: flex;
    }

    span {
      flex-shrink: 0;
    }
  }

  &__item {
    padding: 0 calc((100% - 335px) / 2);

    @include media(tb) {
      display: flex;
      align-items: center;
      padding: 0 2rem;
    }

    &--left-wide {
      @include media(tb) {
        margin-right: calc((100% - 880px) / 2);
      }
    }

    &:nth-of-type(even) {
      .about-merit__thumb {
        @include media(tb) {
          margin-left: 3rem;
        }
      }

      .about-merit__box-txt {
        & * {
          @include media(tb) {
            text-align: right;
          }
        }
      }

      .about-merit__note {
        @include media(tb) {
          justify-content: flex-end;
        }
      }
    }

    &:nth-of-type(odd) {
      .about-merit__thumb {
        order: 1;

        @include media(tb) {
          margin-right: 3rem;
        }
      }

      .about-merit__box-txt {
        order: 2;
      }
    }

    &+& {
      margin-top: 4rem;

      @include media(tb) {
        margin-top: 0;
      }
    }
  }
}

// about 就労支援 Job Support のテーブル
.about-js-table {
  background: linear-gradient($color_main03 4rem, #fff 4rem);

  &__inner {
    @include box-shadow;
    padding: 3.5rem 1rem;
    text-align: center;

    @include media(tb) {
      padding: 11rem 1rem 11.5rem;
    }

    @include media(m) {
      padding: 11rem 4rem 11.5rem;
    }
  }

  &__thumb {
    width: 29.5rem;
    margin: 0 auto 1rem;
    border-radius: 4rem;
  }

  &__contents {
    display: none;

    @include media(tb) {
      display: table;
      table-layout: fixed;
      width: calc(100% + 8px);
      color: $color_main08;
      font-weight: bold;
      text-align: center;
      margin: 0 -4px; // borderの太さを考慮
    }

    th {
      vertical-align: middle;
      font-size: 1.2rem;
      line-height: 1.33;
      border-left: 4px solid #fff;
      border-right: 4px solid #fff;

      &:first-of-type {
        width: 10rem;

        @include media(m) {
          width: 12.8rem;
        }
      }

      &:not(:first-of-type) {
        color: #fff;
        background-image: url("../images/jobsupport/balloon.png");
        background-size: 100% calc(100% - 1rem);
        background-repeat: no-repeat;

        @include media(m) {
          background-size: 100% auto;
        }

        br {
          @include media(m) {
            display: none;
          }
        }
      }

      em {
        display: block;
        font-size: 1.6rem;
        margin-bottom: 0.5rem;
      }
    }

    td {
      font-size: 1.6rem;
      vertical-align: middle;
      background-color: $color_main03;
      color: $color_main02;
      border-left: 4px solid #fff;
      border-right: 4px solid #fff;

      &.none {
        font-size: 1.2rem;
        color: $color_main08;
      }
    }

    tr {

      th,
      td {
        border-bottom: 1px dashed $color_main08;
      }

      &:first-of-type {
        height: 5rem;

        th {
          border-bottom: 1px solid $color_main08;
          padding-bottom: 1rem;
        }
      }

      &:nth-of-type(2) {
        height: 6rem;
      }

      &:nth-of-type(3) {
        height: 8rem;
      }

      &:last-of-type {
        height: 8rem;

        th,
        td {
          border-bottom: 1px solid $color_main08;
        }
      }
    }

    img {
      display: block;
      margin: 1rem;
      width: calc(100% - 2rem);
      border-radius: 1rem;
    }
  }

  &__note {
    font-size: 1.2rem;
    line-height: 1.5;
    text-align: right;
    margin-top: 1.5rem;
  }
}

// 豊富なコンテンツ

.about-contents {
  padding: 4rem 0 11rem;

  @include media(tb) {
    padding: 8rem 0 12rem;
  }

  &__item {
    @include box-shadow;
    padding: 2.7rem 2rem 5.5rem;
    position: relative;

    @include media(tb) {
      padding: 6rem 6rem 5.5rem;
    }

    &+& {
      margin-top: 5rem;

      @include media(tb) {
        margin-top: 8rem;
      }
    }
  }

  &__top {
    padding-bottom: 3rem;
    border-bottom: 1px solid #E8E7E5;

    @include media(tb) {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding-bottom: 2.5rem;
    }
  }

  &__bottom {
    display: grid;
    grid-template-columns: 9.5rem 1fr;
    grid-template-rows: 8rem 1fr;
    grid-template-areas: "thumb name"
      "text text";
    padding-top: 1.5rem;

    @include media(tb) {
      grid-template-columns: 1fr 14rem;
      grid-template-rows: 3rem 1fr;
      grid-template-areas: "name thumb"
        "text thumb";
      padding-top: 3rem;
    }
  }

  &__cat {
    @include fontsize(14, 21);
    font-weight: bold;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    @include media(tb) {
      margin: 0 1rem 1.5rem 0;
    }

    span {
      @include fontsize(10, 14);
      display: block;
      width: 6.6rem;
      padding: 0.5rem 0;
      margin-left: 1rem;
      text-align: center;
      color: $color_main08;
      border: 1px solid $color_main08;
      border-radius: 1.5rem;

      @include media(tb) {
        width: 8rem;
        padding: 0.7rem 0;
      }
    }
  }

  &__ttl {
    @include fontsize(20, 30);
    font-weight: bold;
    margin-bottom: 0.8rem;

    @include media(tb) {
      margin-bottom: 1.3rem;
    }
  }

  &__subttl {
    @include fontsize(12, 14);
    font-weight: bold;
    line-height: 1.66;

    @include media(tb) {
      line-height: 1.42;
    }

    &+& {
      margin-top: 2.5rem;
    }
  }

  &__list {
    @include fontsize(12, 14);
    line-height: 1.71;

    li {
      display: inline-flex;

      &::before {
        content: '●';
        color: #E8E7E5;
        display: inline-block;
        margin-right: 0.3rem;

        @include media(tb) {
          margin-right: 0.5rem;
        }
      }
    }
  }

  &__thumb {
    @include border-radius-5;
    display: block;
    width: min(100%, 29.5rem);
    margin: 2.5rem auto 0;

    @include media(tb) {
      width: 38%;
      margin: 0 0 0 3rem;
    }
  }

  &__name {
    grid-area: name;
    align-self: center;
    font-size: 1.2rem;
    font-weight: bold;

    @include media(tb) {
      align-self: start;
    }

    em {
      display: inline-block;
      font-size: 1.6em;
      margin-top: 0.8rem;

      @include media(tb) {
        margin: 0 0 0 1rem;
      }
    }
  }

  &__txt {
    grid-area: text;
    font-size: 1.4rem;
    line-height: 1.71;
    margin-top: 1rem;

    @include media(tb) {
      margin-top: 0;
    }
  }

  &__thumb-dr {
    grid-area: thumb;
    width: 8rem;
    margin-right: 1.5rem;

    @include media(tb) {
      width: 10.8rem;
      margin: 0 0 0 auto;
    }
  }

  &__btn {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
  }
}

// about ソーシャルスキル
.about-skills {
  padding: 4rem 0 8rem;

  @include media(tb) {
    padding: 8rem 0 12rem;
  }

  &__list-btn {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 -1.5rem 4rem;

    @include media(tb) {
      margin: 0 0 5rem;
    }
  }

  &__btn {
    @include fontsize(12, 14);
    display: block;
    font-weight: bold;
    margin: 0 0.5rem 1rem;

    a {
      @include opacity;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 11rem;
      height: 4.8rem;
      text-align: center;
      line-height: 1.33;
      color: $color_main08;
      border-radius: 3rem;
      background-color: #fff;
      box-shadow: 2px 2px 8px rgba($color: #919090, $alpha: 0.16);

      @include media(tb) {
        width: 16rem;
        height: 6rem;
      }
    }

    br {
      @include media(tb) {
        display: none;
      }
    }
  }

  &__item {
    @include box-shadow;
    padding: 2.5rem 2rem 0;

    @include media(tb) {
      padding: 5rem 6rem 3.3rem;
    }

    &+& {
      margin-top: 4rem;
    }
  }

  &__cat {
    @include fontsize(20, 30);
    font-weight: bold;
    margin-bottom: 1.3rem;

    span {
      @include fontBarlow;
      @include fontsize(24, 36);
      margin-right: 1rem;
    }

    br {
      display: none;
    }
  }

  &__txt {
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 1.42;

    &+& {
      @include fontsize(12, 14);
      margin-top: 2.5rem;
    }
  }

  &__list {
    @include fontsize(12, 14);
    line-height: 1.71;
    padding-bottom: 2.5rem;
    margin-top: 0.5rem;

    li {
      display: flex;
    }
  }

  &__slide {
    display: flex;
    justify-content: space-between;
    margin: 0 -1rem;
    padding-bottom: 2.5rem;

    @include media(tb) {
      margin: 0;
      padding: 0;
    }
  }

  &__slide-item {
    width: 32%;

    img {
      border-radius: 2rem;
      width: 100%;
      height: 7rem;
      object-fit: cover;

      @include media(tb) {
        height: auto;
        border-radius: 5rem;
      }
    }

    figcaption {
      @include fontsize(10, 14);
      display: block;
      font-weight: bold;
      line-height: 1.42;
      text-align: center;
      margin-top: 0.5rem;
    }
  }
}

// about 導入事例
.about-case {
  &__mv {
    padding-top: 2rem;
    background: linear-gradient(to top, #fff 4rem, $color_main03 4rem);

    @include media(m) {
      padding-top: 4rem;
    }
  }

  &__figure {
    position: relative;

    // 記事画面で編集が加わるので、見やすさ重視でクラス名は付けない
    &>img {
      @include border-radius-10;
      width: calc(100% + 2rem);
      margin: 0 -1rem;

      @include media(tb) {
        width: 100%;
        margin: 0;
      }
    }

    &>figcaption {
      @include fontsize(20, 24);
      display: inline-flex;
      align-items: center;
      color: #fff;
      font-weight: bold;
      padding: 1.5rem 1.8rem 1.5rem 2rem;
      margin: -3rem 0 0 -2rem;
      background-image: url("../images/common/case-caption-bg.jpg");
      @include background-image();

      @include media(tb) {
        @include absolute($top: 2rem, $left: -2rem);
        min-width: 24rem;
        padding: 2.8rem 1rem 2.8rem 2rem;
        margin: 0;
      }

      @include media(m) {
        left: -4rem;
      }

      &>small {
        @include fontsize(12, 16);
        margin-left: 1rem;

        @include media(tb) {
          margin-left: 1.5rem;
        }
      }
    }

  }

  &__art {
    margin-top: 4rem;

    @include media(tb) {
      margin-top: 8rem;
    }

    // 記事画面で編集が加わるので、見やすさ重視でクラス名は付けない
    h1 {
      @include fontsize(20, 36);
      font-weight: bold;
      line-height: 1.5;
      margin-bottom: 2.5rem;

      @include media(tb) {
        margin-bottom: 3rem;
      }
    }

    a {
      @include lineheight-normal;
      @include opacity;
      color: $color_main02;
      font-weight: bold;
      text-decoration: underline;
    }
  }

  &__name {
    font-weight: bold;
    line-height: 1.42;
    margin-bottom: 0.5rem;
  }

  &__sec {
    @include border-radius-5;
    padding: 3rem 2rem 2.5rem;
    margin-bottom: 2.5rem;
    background-color: $color_main03;

    @include media(tb) {
      padding: 4rem 4rem 3.5rem;
      margin-bottom: 3.5rem;
    }

    // 記事画面で編集が加わるので、見やすさ重視でクラス名は付けない
    h3 {
      font-weight: bold;
      margin-bottom: 1rem;

      @include media(tb) {
        margin-bottom: 1.5rem;
      }
    }

    p {
      font-size: 1.4rem;
      line-height: 1.71;
      margin-bottom: 0.5rem;

      @include media(tb) {
        margin-bottom: 1rem;
      }
    }

    a {
      font-size: 1.4rem;
    }
  }

  &__intro {
    margin: 0 1rem 7rem;

    @include media(tb) {
      margin: 0 0 10.5rem;
    }

    p {
      @include lineheight-normal;
      overflow-wrap: break-word;
    }
  }

  &__content {
    margin: 0 1rem 6rem;

    @include media(tb) {
      margin: 0 0 12rem;
    }

    // 記事画面で編集が加わるので、見やすさ重視でクラス名は付けない
    h2 {
      @include fontsize(18, 24);
      font-weight: bold;
      line-height: 1.5;
      margin-bottom: 2rem;

      @include media(tb) {
        margin-bottom: 3rem;
      }
    }

    p {
      @include lineheight-normal;

      &.question {
        color: $color_main08;
        font-weight: bold;
        margin-top: 2.5rem;

        &::before {
          content: '── ';
        }
      }
    }

    img {
      @include border-radius-5;
      width: 100%;
      margin: 2.5rem 0 8rem;

      @include media(tb) {
        margin: 3.5rem 0 12rem;
      }
    }
  }
}

// function JOLLYGOOD＋の機能・特長
.function {

  &-ex {
    padding: 4rem 0 0;
    background: linear-gradient(to top, #fff 4rem, #F4F4F6 4rem);

    @include media(tb) {
      padding: 8rem 0 0;
    }

    &__outer {
      margin-top: 4rem;
    }

    &__inner {
      @include media(tb) {
        display: flex;
        justify-content: space-between;
      }


    }

    &__txtbox {
      @include media(tb) {
        width: 44%;
        margin-top: 3rem;
      }
    }


    &__item {
      @include box-shadow;
      padding: 3rem 2rem 2rem;
      position: relative;

      @include media(tb) {
        padding: 6rem;
      }

      &:nth-of-type(2) {
        padding-bottom: 5rem;

        @include media(tb) {}
      }

      &+& {
        margin-top: 2rem;

        @include media(tb) {
          margin-top: 4rem;
        }
      }
    }

    &__cat {
      @include fontsize(20, 30);
      font-weight: bold;
      display: flex;
      align-items: center;
      margin-bottom: 1rem;

      @include media(tb) {
        margin: 0 1rem 1.5rem 0;
      }

      span {
        @include fontsize(12, 14);
        display: block;
        width: 8rem;
        padding: 0.5rem 0;
        margin-right: 1rem;
        text-align: center;
        color: #fff;
        border-radius: 1.5rem;

        @include media(tb) {
          width: 10rem;
          padding: 0.7rem 0;
        }
      }

    }

    &__txt {
      margin-top: 1rem;
      line-height: 1.7;
      @include fontsize(14, 16);

      @include media(tb) {}
    }

    &__thumb01 {
      width: 100%;
      margin-top: 2rem;
      position: relative;

      @include media(tb) {
        margin-top: 0;
        width: 50%;
      }

      &--01 {
        width: 100%;

        @include media(tb) {}
      }

      &--02 {
        position: absolute;
        top: -1rem;
        right: -1rem;
        width: 16rem;

        @include media(tb) {
          top: -2rem;
          right: -2rem;
          width: 25rem;
        }
      }

    }

    &__thumb02 {
      width: 100%;
      margin-top: 2rem;
      position: relative;

      @include media(tb) {
        margin-top: 0;
        width: 50%;
      }

      &--01 {
        width: 100%;
        transform: scale(1.08);
      }

      &--02 {
        position: absolute;
        bottom: -5rem;
        left: -4rem;
        width: 31rem;

        @include media(tb) {
          display: none;
        }
      }

    }
  }

  &-structure {
    padding: 8rem 0 8rem;

    @include media(tb) {
      padding: 12rem 0 13rem;
    }

    &__item {
      margin-top: 4rem;
      display: flex;
      flex-direction: column;

      @include media(tb) {
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;
      }

      &--rev {
        align-items: flex-end;

        @include media(tb) {
          flex-direction: row-reverse;
        }

        .function-structure__txtbox {
          margin-right: 1rem;
          margin-left: 0;

          @include media(tb) {
            margin-right: -6rem;
          }
        }
      }
    }

    &__img {
      width: 100%;

      @include media(tb) {
        width: 54rem;
      }
    }

    &__txtbox {
      background-color: #fff;
      box-shadow: 4px 4px 16px rgba($color: #919090, $alpha: 0.16);
      padding: 2rem;
      position: relative;
      width: 27rem;
      margin-top: -3rem;
      margin-left: 1rem;
      border-radius: 2rem;

      @include media(tb) {
        margin-top: 0;
        margin-bottom: 4rem;
        margin-left: -6rem;
        padding: 3rem;
        border-radius: 5rem;
        width: 33rem;
      }

      &--ttl {
        font-weight: bold;
        @include fontsize(18, 20);

      }

      &--txt {
        margin-top: 1rem;
        line-height: 1.7;

      }

    }
  }

  &-scene {
    padding: 4rem 0 0;
    background: linear-gradient(to top, #fff 4rem, #F4F4F6 4rem);

    @include media(tb) {
      padding: 12rem 0 0;

    }

    &__inner {
      background-color: #fff;
      box-shadow: 4px 4px 16px rgba($color: #919090, $alpha: 0.16);
      padding: 4rem 0;
      position: relative;
      border-radius: 4rem;

      @include media(tb) {
        border-radius: 10rem;
        padding: 8rem 14rem;

      }

    }

    &__txtbox {
      display: flex;
      margin: 0 2rem;

      @include media(tb) {
        align-items: center;
        margin: 0;

      }

      &--ttl {
        @include fontsize(14, 18);
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 8rem;
        height: 8rem;
        margin-right: 1rem;
        border-radius: 10rem;

        @include media(tb) {
          width: 12rem;
          height: 12rem;
          margin-right: 2rem;

        }

      }

      >div {
        width: calc(100% - 8rem);

        @include media(tb) {
          width: calc(100% - 12rem);
        }
      }

      &--subttl {
        @include fontsize(18, 26);
        font-weight: bold;
      }

      &--txt {
        @include fontsize(14, 16);
        margin-top: 1rem;
        line-height: 1.8;

        @include media(tb) {

          margin-top: 2rem;
        }
      }
    }

    &__item {
      margin-top: 3rem;

      @include media(tb) {
        margin-top: 6rem;
      }

      &:nth-of-type(2) {
        margin-top: 6rem;

        @include media(tb) {
          margin-top: 12rem;
        }
      }
    }

    &__img {
      width: 100%;
      margin-top: 2rem;

      @include media(tb) {}

      &--sc {
        transform: scale(1.03);

        @include media(tb) {
          transform: scale(1);
        }

      }
    }
  }

  &-equipment {
    padding: 6rem 0 8rem;
    text-align: center;

    @include media(tb) {
      padding: 12rem 0;
    }

    &__inner {
      margin-top: 4rem;
      margin-bottom: 4rem;
      display: flex;
      flex-direction: column;

      @include media(tb) {
        flex-direction: row;
        justify-content: space-between;
        margin-top: 6rem;
        margin-bottom: 8rem;
        align-items: flex-start;
      }
    }

    &__item {
      margin-top: 2rem;
      background-color: #F4F4F6;
      border-radius: 4rem;
      padding: 4rem 0;
      text-align: center;

      @include media(tb) {
        border-radius: 5rem;
      }

      &:nth-of-type(1) {
        margin-top: 0;

        @include media(tb) {
          width: 34%;
        }
      }

      &:nth-of-type(2) {
        @include media(tb) {
          width: 63%;
          margin-top: 0;
        }
      }

      >ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

        li {
          width: calc(100% /2 - 2rem);
          text-align: center;

          @include media(tb) {
            width: calc(100% /3);

          }
        }
      }
    }

    &__ttl {
      font-weight: bold;
      @include fontsize(18, 20);

    }

    &__txt {
      text-align: center;
      margin-top: 2rem;
      font-weight: bold;
    }

    &__img {
      margin-top: 3rem;
      height: 14rem;
    }

    &__btn {

      @include media(tb) {
        width: 38rem;

      }
    }

  }

}

// guide JOLLYGOOD＋の使い方
.guide {

  &-movie {
    padding: 4rem 0 0;
    background: linear-gradient(to top, #fff 4rem, #F4F4F6 4rem);

    @include media(tb) {
      padding: 8rem 0 0;
    }

    &__inner {
      &--iframe {
        position: relative;
        width: 100%;
        margin-top: 3rem;
        padding-top: 56.25%;

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &-flow {
    padding: 8rem 0;

    @include media(tb) {
      padding: 12rem 0;
    }

    &__outer {

      @include media(tb) {
        width: 68rem;
      }

    }

    &__list {
      margin-top: 2.5rem;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      @include media(tb) {
        margin-top: 5rem;
      }

    }

    &__item {
      background-color: #fff;
      box-shadow: 4px 4px 16px rgba($color: #919090, $alpha: 0.16);
      border-radius: 4rem;
      padding: 3rem 2rem 3rem 7rem;
      position: relative;
      margin-top: 5rem;
      width: calc(100% - 1rem);

      @include media(tb) {
        margin-top: 4rem;
        border-radius: 5rem;

      }

      &:nth-of-type(1) {
        margin-top: 0;
      }

      &:last-of-type {
        &::after {
          display: none;
        }
      }

      &::after {
        content: "";
        position: absolute;
        background-image: url("../images/common/arrow-bottom-gradation-blue.png");
        background-size: contain;
        margin: auto;
        left: 0;
        right: 0;
        bottom: -3rem;
        width: 2.8rem;
        height: 1.6rem;
        display: flex;


      }

      @include media(tb) {
        padding: 4rem 4rem 4rem 12rem;
      }

      &--ttl {
        @include fontsize(18, 24);
        font-weight: bold;
        line-height: 1.3;

        @include media(tb) {
          line-height: 1.5;

        }
      }

      &--txt {
        @include fontsize(14, 16);
        margin-top: 1rem;
        line-height: 1.7;

        @include media(tb) {
          margin-top: 2rem;

        }
      }

      &--icon {
        position: absolute;
        left: -1rem;
        top: 1rem;
        width: 6.8rem;
        height: 6.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10rem;

        @include media(tb) {
          width: 10rem;
          height: 10rem;
          left: -2rem;
          top: 0;
          bottom: 0;
          margin: auto;
        }

        span {
          color: #fff;
          @include fontsize(14, 20);
          @include fontBarlow;

        }
      }
    }

  }

  &-link {

    &__inner {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;

      @include media(tb) {
        flex-direction: row;

      }
    }

    &__item {

      &:nth-of-type(2),
      &:nth-of-type(3) {
        @include media(tb) {
          width: 50%;
        }
      }

      >a {
        display: flex;
        align-items: center;
        justify-content: center;
        @include opacity;
      }
    }

    &__box {
      position: absolute;
      z-index: 1;

      @include media(tb) {
        width: 60rem;
      }

      &--gradient {
        border-radius: 10rem;
        padding: 2.5rem 3rem;
      }

      &--ttl {
        color: #fff;
        @include fontsize(20, 30);
        text-align: center;
        font-weight: bold;
      }

      &--txt {
        @include fontsize(12, 14);
        color: #fff;
        text-align: center;
        line-height: 1.6;
        margin-top: 1rem;
        font-weight: bold;

      }
    }

    &__img {
      width: 100%;
    }
  }

}

// Price JOLLYGOOD+サービス料金
.p-pri {
  @include transition;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include media(tb) {
    flex-direction: row;
    align-items: flex-end;
  }

  &__outer {
    @include transition;
    margin-right: 10%;
    margin-left: 10%;

    @include media(tb) {
      margin-left: $width-m;
      margin-right: $width-m;
    }
  }

  &__h1 {
    @include transition;

    @include media(tb) {
      width: 50%;
    }

    @include media(m) {
      width: auto;
    }

    >span {
      @include fontRoboto;
      @include textgradient;
      @include fontsize(20, 23);
      background-image: linear-gradient(88.15deg, #286BFE 2.31%, #9616B6 101.86%);
    }
  }

  &__txt {
    @include transition;
    font-weight: 600;
    line-height: 2;
    color: #000000;
    margin-top: 5rem;
    font-size: 1.2rem;

    @include media(tb) {
      /* tablet-size */
      margin-top: 0;
      font-size: 1.3rem;

    }

    @include media(m) {
      /* middle-size */
      font-size: 1.6rem;

    }
  }

  &01 {
    &__outer {
      margin-top: 10rem;
    }

    &__list {
      @include transition;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;

      @include media(tb) {
        /* tablet-size */
        flex-direction: row;
        justify-content: space-between;

      }
    }

    &__item {
      @include transition;
      @include box-shadow-large;
      padding: 2rem 3rem;
      margin-top: 1rem;

      @include media(tb) {
        padding: 2rem 2rem 4rem;
        margin-top: 0;
        width: calc(100% / 3 - 1rem);
      }

      @include media(m) {
        /* middle-size */
        padding: 3rem 3rem 5rem;

      }
    }

    &__box02,
    &__box03 {
      @include transition;
      padding-top: 2rem;
      margin-top: 2rem;
      border-top: 1px solid #D9D9D9;

      @include media(m) {
        /* middle-size */
        padding-top: 3rem;
        margin-top: 3rem;

      }
    }

    &__box01 {
      &__txt {
        @include transition;
        font-size: 4rem;
        margin-top: 3rem;
        @include fontRoboto;
        font-weight: 600;
        display: flex;
        align-items: baseline;
        justify-content: center;

        @include media(tb) {
          /* tablet-size */
          margin-top: 3rem;
          font-size: 3rem;

        }

        @include media(m) {
          /* middle-size */
          margin-top: 4rem;
          font-size: 4.5rem;
        }

        span {
          @include transition;

          font-size: 1.4rem;

          @include media(tb) {
            /* tablet-size */
            font-size: 1.2rem;

          }

          @include media(m) {
            /* middle-size */
            font-size: 2rem;
          }

        }
      }
    }

    &__box02 {
      &__txt {
        @include transition;
        margin-top: 1.5rem;
        @include fontRoboto;
        @include fontsize(40, 45);
        font-weight: 600;
        display: flex;
        align-items: baseline;
        justify-content: center;
        font-size: 4rem;

        @include media(tb) {
          /* tablet-size */
          font-size: 3.5rem;
        }

        @include media(m) {
          /* middle-size */
          font-size: 4.5rem;
        }

        span {
          @include transition;

          font-size: 2rem;

          @include media(tb) {
            /* tablet-size */
            font-size: 2rem;
          }

          @include media(m) {
            /* middle-size */
            font-size: 3rem;
          }
        }
      }
    }

    &__box03 {
      &__txt {
        @include transition;

        margin-top: 1.5rem;
        @include fontRoboto;
        @include fontsize(22, 30);
        font-weight: 600;
        display: flex;
        align-items: baseline;
        justify-content: center;
      }
    }

    &__ttl {
      @include transition;

      font-size: 2rem;
      text-align: center;
      color: #fff;
      font-weight: 600;
      padding: 1rem;
      border-radius: 100px;

      @include media(tb) {
        /* tablet-size */
        font-size: 1.6rem;
      }

      @include media(m) {
        /* middle-size */
        font-size: 2rem;

      }


      &-blue {
        @extend .p-pri01__ttl;
        background-color: $color_main02;

      }

      &-purple {
        @extend .p-pri01__ttl;

        background-color: $color_main10;

      }

      &-pink {
        @extend .p-pri01__ttl;

        background-color: $color_main05;

      }

    }

    &__subttl {
      @include transition;
      font-size: 1.5rem;
      font-weight: 600;
      text-align: center;
      letter-spacing: 0.07em;

      @include media(tb) {
        /* tablet-size */
        font-size: 1.6rem;

      }

      @include media(m) {
        /* middle-size */
        font-size: 1.9rem;

      }
    }

  }

  &02 {
    &__outer {
      @include transition;
      margin-top: 10rem;

      @include media(tb) {
        /* tablet-size */
        margin-top: 15rem;

      }
    }

    &__ttl {
      @include transition;
      @include fontsize(21, 35);
      font-weight: 600;
      text-align: center;
      letter-spacing: 0.07em;
    }

    &__box {
      @include transition;

      background-color: #FFFFFF;
      box-shadow: 8px 10px 33px rgba(0, 0, 0, 0.08);
      border-radius: 15px;
      padding: 6rem 2.5rem 3rem;
      margin-top: 4rem;

      @include media(tb) {
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        padding: 6rem 0;
        margin-top: 10rem;
        border-radius: 30px;

      }
    }

    &__img {
      @include transition;
      width: 100%;

      @include media(tb) {
        /* tablet-size */
        width: 45%;
      }

      @include media(m) {
        /* middle-size */
        width: 54.2rem;
      }
    }

    &__txtbox {
      @include transition;
      margin-top: 4rem;

      @include media(tb) {
        /* tablet-size */
        margin-top: 0;
      }
    }

    &__txt01 {
      @include transition;

      font-size: 1.8rem;
      font-weight: 600;
      line-height: 1.2;
      color: #000000;

      @include media(tb) {
        /* tablet-size */
        line-height: 1.5;

      }

      @include media(m) {
        /* middle-size */
        font-size: 2.5rem;

      }

      span {
        @include transition;

        @include fontsize(16, 20);
        font-weight: 600;
        line-height: 1.2;

      }
    }

    &__txt02 {
      @include transition;

      @include fontRoboto;
      font-size: 4.5rem;
      display: flex;
      align-items: baseline;
      justify-content: flex-start;
      margin-top: 2rem;
      font-weight: 600;
      font-size: 4.5rem;

      @include media(tb) {
        /* tablet-size */
        font-size: 4.8rem;
      }

      @include media(m) {
        /* middle-size */
        font-size: 5.3rem;

      }

      span {
        @include transition;

        font-size: 1.4rem;

        @include media(tb) {
          /* tablet-size */
          font-size: 1.9rem;
        }

        @include media(m) {
          /* middle-size */
          font-size: 2.4rem;

        }
      }
    }
  }

  &03 {
    &__outer {
      @include transition;

      margin-top: 10rem;
      padding-bottom: 6.5rem;

      @include media(tb) {
        margin-left: $width-m;
        margin-right: $width-m;
      }

      @include media(m) {
        /* middle-size */
        margin-left: calc((100% - 1030px)/ 2);
        margin-right: calc((100% - 1030px)/ 2);
        padding-bottom: 8rem;
      }
    }

    &__ttl {
      @include transition;

      @include fontsize(29, 50);
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      font-weight: 600;
      letter-spacing: 0.07em;

      img {
        @include transition;

        height: 1.9rem;
        object-fit: contain;
        margin-bottom: 1rem;

        @include media(tb) {
          /* tablet-size */
          height: 2.4rem;
          margin-bottom: 2rem;
        }
      }
    }

    &__olist {
      @include transition;

      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 80%;
      margin-right: auto;
      margin-left: auto;
      margin-top: 4rem;
      border-bottom: .15rem solid #EBEBEB;

      @include media(tb) {
        /* tablet-size */
        width: 100%;
        margin-top: 7rem;
      }
    }

    &__item {
      @include transition;

      border-top: .15rem solid #EBEBEB;
      padding: 2rem 2%;

      @include media(tb) {
        /* tablet-size */
        padding: 4rem 0;
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        align-items: center;
      }

      &:nth-of-type(1) {
        margin-top: 0;
      }
    }

    &__txtbox {
      @include transition;

      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      @include media(tb) {
        /* tablet-size */
        align-items: flex-start;
        order: 2;
        width: 100%;

      }
    }

    &__num {
      @include transition;

      @include fontRoboto;
      @include fontsize(11, 17);
      font-weight: 500;

      &-lightblue {
        @extend .p-pri03__num;
        color: $color_main02;
      }

      &-blue {
        @extend .p-pri03__num;
        color: $color_main06;
      }

      &-pink {
        @extend .p-pri03__num;
        color: $color_main05;
      }

      &-purple {
        @extend .p-pri03__num;
        color: $color_main10;
      }
    }

    &__subttl {
      @include transition;

      @include fontsize(18, 23);
      font-weight: 600;

      color: #000000;
      margin-top: 1rem;
    }

    &__img {
      @include transition;

      width: 18rem;
      margin: 4rem auto 0;
      display: block;

      @include media(tb) {
        /* tablet-size */
        order: 1;
        margin-top: 0;
        margin-bottom: 0;
        margin-right: 3rem;
      }

      @include media(m) {
        /* middle-size */
        margin-right: 8rem;
        margin-left: 10rem;
      }
    }


    &__txt {
      @include transition;

      margin-top: 3rem;
      font-weight: 500;
      line-height: 1.5;

      @include media(tb) {
        /* tablet-size */
        margin-top: 1.5rem;

      }
    }
  }
}

//サービスの機能・特徴
.p-fun {
  @include transition;

  @include media(m) {
    margin-left: calc((100% - $width2)/ 2);
    margin-right: calc((100% - $width2)/ 2);
  }

  &__outer {
    @include transition;

    @include media(m) {
      background-image: url(../../assets/images/function/fun_fv-pc.jpg);
      background-position: 100% 100%;
      background-repeat: no-repeat;
      background-size: contain;
      min-height: 64rem;
    }

    @include media(l) {
      margin-left: calc((100% - ($width + ($width-m * 2)))/ 2);
      margin-right: calc((100% - ($width + ($width-m * 2)))/ 2);
    }
  }


  &__h1 {
    @include transition;

    margin-right: 10%;
    margin-left: 10%;

    @include media(tb) {
      width: 50%;
      margin-right: 2rem;
      margin-left: 2rem;
    }

    @include media(m) {
      width: auto;
      margin-right: 0;
      margin-left: 0;
    }

    >span {
      @include fontRoboto;
      @include textgradient;
      @include fontsize(20, 23);
      background-image: linear-gradient(88.15deg, #EB3E83 2.31%, #9616B6 101.86%);
    }


  }

  &__img {
    @include transition;

    width: 100%;
    display: block;
    margin-top: 3rem;

    @include media(m) {
      display: none;

    }
  }

  &01 {
    @include transition;

    margin-left: 1.5rem;
    margin-right: 1.5rem;

    @include media(m) {
      margin-left: calc((100% - $width2)/ 2);
      margin-right: calc((100% - $width2)/ 2);
    }

    &__outer {
      @include transition;

      padding-top: 9.5rem;
      padding-bottom: 7rem;
      background-position: 50% 0px;
      background-size: 100%;
      background-repeat: no-repeat;
      background-image: url(../../assets/images/function/fun01_bg-sp.jpg),
        linear-gradient(11.16deg, #F7F7F7 4%, #F8F8F8 49.02%, #FFFFFF 94.04%);

      @include media(m) {
        /* middle-size */
        padding-top: 36.4rem;
        background-image: url(../../assets/images/function/fun01_bg-pc.jpg),
          linear-gradient(11.16deg, #F7F7F7 4%, #F8F8F8 49.02%, #FFFFFF 94.04%);
        background-size: 144rem,
          cover;

      }
    }

    &__box {
      @include transition;

      padding: 3rem 4rem 6rem;
      background: #FFFFFF;
      box-shadow: 8px 10px 33px rgba(0, 0, 0, 0.08);
      border-radius: 15px;

      @include media(m) {
        /* middle-size */
        padding: 6rem 11.3rem 12rem;
        border-radius: 30px;
      }
    }

    &__ttl {
      @include transition;

      font-weight: 600;
      @include textgradient;
      background-image: linear-gradient(90deg, #086EDE -7.85%, #545DBC 25.85%, #984F9E 63.49%, #FF4AA1 100%);
      font-size: 2.4rem;
      font-weight: 600;
      margin-left: -2rem;

      @include media(tb) {
        /* tablet-size */
        font-size: 4rem;
        margin-left: 0;
      }

      @include media(m) {
        /* middle-size */
        font-size: 5.5rem;

      }
    }

    &__olist {
      @include transition;

      margin-top: 3.5rem;

      @include media(m) {
        /* middle-size */
        margin-top: 5.5rem;

      }
    }

    &__item {
      @include transition;

      margin-top: 6rem;

      @include media(tb) {
        /* tablet-size */
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
      }

      @include media(m) {
        /* middle-size */
        align-items: center;

      }


      &:nth-of-type(1) {
        margin-top: 0;

      }
    }

    &__img {
      @include transition;

      margin-top: 3rem;
      border-radius: 6px;
      width: 100%;

      @include media(tb) {
        /* tablet-size */
        order: 1;
        margin-top: 0;
        width: 30.5rem;
        margin-right: 3rem;
      }

      @include media(m) {
        /* middle-size */
        width: 50.5rem;
        margin-right: 5rem;

      }
    }

    &__txtbox {
      @include transition;

      @include media(tb) {
        /* tablet-size */
        order: 2;

      }

      @include media(m) {
        /* middle-size */
        width: 100%;

      }
    }

    &__point {
      @include transition;

      @include fontOswald;
      display: inline-block;
      color: #fff;
      font-weight: 400;
      font-size: 1.7rem;
      padding: .2rem 1.5rem;
      border-radius: 100px;
      font-size: 1.4rem;

      @include media(tb) {
        /* tablet-size */
        font-size: 1.5rem;

      }

      @include media(m) {
        /* middle-size */
        font-size: 1.7rem;
      }

      &-blue {
        @extend .p-fun01__point;
        background: #079AF3;
        background: linear-gradient(to right, #0170E0 0%, #079AF3 100%);
      }

      &-purple {
        @extend .p-fun01__point;
        background: #9752A7;
        background: linear-gradient(to right, #8355B3 0%, #9752A7 100%);

      }

      &-pink {
        @extend .p-fun01__point;
        background: #E34081;
        background: linear-gradient(to right, #EB5985 0%, #E34081 100%);
      }
    }

    &__subttl {
      @include transition;

      font-size: 1.7rem;
      font-weight: 600;
      line-height: 1.5;

      @include media(tb) {
        /* tablet-size */
        font-size: 1.8rem;
      }

      @include media(m) {
        /* middle-size */
        font-size: 2.8rem;
      }

      &-blue {
        @extend .p-fun01__subttl;
        color: #0271e1;
      }

      &-purple {
        @extend .p-fun01__subttl;
        color: #8456B4;
      }

      &-pink {
        @extend .p-fun01__subttl;
        color: #E44182;
      }

      em {
        @include transition;

        display: block;
        margin-top: 1.5rem;

        @include media(tb) {
          /* tablet-size */
          font-size: 1.4rem;
        }

        @include media(m) {
          /* middle-size */
          font-size: 2.8rem;

        }
      }
    }

    &__txt {
      @include transition;

      font-weight: 600;
      line-height: 1.7;
      margin-top: 1.5rem;

      @include media(tb) {
        /* tablet-size */
        font-size: 1.4rem;
      }

      @include media(m) {
        /* middle-size */
        font-size: 1.6rem;

      }

    }


  }

  &02 {
    @include transition;

    @include media(m) {
      margin-left: calc((100% - $width2)/ 2);
      margin-right: calc((100% - $width2)/ 2);
    }

    &__outer {
      @include transition;

      padding: 6rem 0 13rem;

      @include media(m) {
        /* middle-size */
        padding: 12rem 0 25rem;
      }
    }

    &__ttl {
      @include transition;

      text-align: center;
      font-weight: 600;
      font-size: 1.9rem;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.04em;
      flex-direction: column;
      flex-wrap: wrap;
      line-height: 1.2;

      @include media(tb) {
        /* tablet-size */
        font-size: 2.9rem;

      }

      @include media(m) {
        /* middle-size */
        font-size: 3.9rem;

      }

      span {
        @include transition;

        color: #0271e0;
        font-size: 1.4rem;
        display: block;
        margin-bottom: 1rem;

        @include media(tb) {
          /* tablet-size */
          font-size: 2rem;

        }

        @include media(m) {
          /* middle-size */
          font-size: 2.7rem;

        }
      }

    }

    &__box {
      @include transition;

      background: #FFFFFF;
      box-shadow: 8px 10px 33px rgba(0, 0, 0, 0.08);
      border-radius: 30px;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      margin-top: 2.6rem;
      padding: 3.3rem 2rem 0;

      @include media(tb) {
        /* tablet-size */
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
      }

      @include media(m) {
        /* middle-size */

        padding: 3rem 3rem 0 7rem;
        margin-top: 5rem;
        margin-right: 0;
        margin-left: 0;
      }

    }

    &__txtbox {
      @include transition;

      @include media(tb) {
        /* tablet-size */
        margin-top: auto;
        margin-bottom: auto;
        margin-right: 3rem;
      }

      @include media(m) {
        /* middle-size */
        margin-right: 7rem;
      }

    }

    &__subttl {
      @include transition;

      font-weight: 600;
      font-size: 1.8rem;
      line-height: 1.6;

      @include media(tb) {
        /* tablet-size */
        font-size: 2rem;

      }

      @include media(m) {
        /* middle-size */
        line-height: 2;
        font-size: 2.4rem;
      }
    }

    &__txt {
      @include transition;

      margin-top: 3rem;
      margin-right: 1rem;
      margin-left: 1rem;
      font-weight: 400;
      font-size: 1.5rem;
      line-height: 1.5;

      @include media(tb) {
        /* tablet-size */
        margin-top: 2rem;
        margin-bottom: 2rem;
        font-size: 1.5rem;
      }

      @include media(m) {
        /* middle-size */
        font-size: 1.7rem;
        margin-top: 4rem;
        margin-right: 0;
        line-height: 2;
        margin-left: 0;
      }
    }

    &__img {
      @include transition;

      width: 100%;
      margin-top: 4rem;
      transform: scale(1.05);
      transform-origin: 50% 100%;

      @include media(tb) {
        /* tablet-size */
        width: 30rem;
        margin-top: 0;
        transform: scale(1);
      }

      @include media(m) {
        /* middle-size */
        width: 64rem;

      }
    }
  }

  &03 {
    @include transition;

    @include media(m) {
      margin-left: calc((100% - $width2)/ 2);
      margin-right: calc((100% - $width2)/ 2);
    }

    &__ttl {
      @include transition;

      transform: translateY(-50%) !important;
      color: #fff;
      border-radius: 8px;
      font-weight: 600;
      font-size: 2rem;
      text-align: center;
      padding-top: 1rem;
      padding-bottom: 1rem;
      letter-spacing: 0.13em;
      display: flex;
      width: 25.5rem;
      justify-content: center;
      margin: auto;
      background-color: #2D313D;

      @include media(m) {
        /* middle-size */
        width: 100%;
        font-size: 2.2rem;
        padding-top: 2rem;
        padding-bottom: 2rem;


      }
    }

    &__outer {
      @include transition;

      padding-bottom: 5rem;
      background: linear-gradient(21.5deg, #F7F7F7 4.38%, #F8F8F8 95.73%);

      @include media(m) {
        /* middle-size */
        padding-bottom: 9rem;

      }
    }

    &__list {
      @include transition;

      margin-right: 3.5rem;
      margin-left: 3.5rem;
      margin-top: 3.5rem;

      @include media(tb) {
        /* tablet-size */
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 1.5rem;
        margin-left: 1.5rem;
      }

      @include media(m) {
        /* middle-size */
        margin-right: 0;
        margin-left: 0;
        margin-top: 3rem;
      }
    }

    &__item {
      @include transition;

      border-top: 1.5px solid #EBEBEB;
      margin-top: 6rem;
      padding-top: 6rem;
      padding-left: 1.5rem;
      padding-right: 1.5rem;

      @include media(tb) {
        /* tablet-size */
        width: calc(100% / 4 - 1.5rem);
        border-top: 0;
        margin-top: 0;
        padding-top: 0;
        padding-left: 0;
        padding-right: 0;

      }

      @include media(m) {
        /* middle-size */
        width: calc(100% / 4 - 3.5rem);
      }

      &:nth-of-type(1) {
        border-top: none;
        margin-top: 0;
        padding-top: 0;

      }
    }

    &__img {
      width: 100%;
      margin-top: 3rem;
      display: block;
      border-radius: 15px;
      filter: drop-shadow(0px 8px 15px rgba(56, 56, 56, 0.3));
    }

    &__subttl {
      position: relative;

      &::after {
        content: "";
        display: block;
        position: absolute;
        right: 0;
        left: 0;
        bottom: 0;
        width: 2.8rem;
        height: .2rem;
        margin: auto;
        background-color: #2D313D;

      }

      span {
        @include transition;

        font-weight: 600;
        font-size: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 1rem;

        @include media(tb) {
          /* tablet-size */
          font-size: 1.8rem;

        }

        @include media(m) {
          /* middle-size */
          font-size: 2.4rem;
          padding-bottom: 1.5rem;

        }
      }

    }

    &__txt {
      @include transition;

      margin-top: 3rem;
      text-align: center !important;
      font-weight: 600;
      font-size: 1.5rem;

      @include media(tb) {
        /* tablet-size */
        margin-top: 2rem;
        font-size: 1.3rem;

      }

      @include media(m) {
        /* middle-size */
        font-size: 1.7rem;

      }

      span {
        color: #0271E0;
      }
    }
  }
}

//TOP
.p-abo {
  &01 {
    @include transition;

    @include media(tb) {
      /* tablet-size */
      display: flex;
      justify-content: space-between;
      // flex-wrap: wrap;
    }

    @include media(m) {}

    &__outer {
      @include transition;

      margin-top: 6rem;
      margin-right: 3.5rem;
      margin-left: 3.5rem;

      @include media(tb) {
        /* tablet-size */
        margin-top: 8rem;
        margin-right: 0;
        margin-left: 0;
      }

      @include media(m) {
        /* middle-size */
        margin-top: 10.5rem;
      }
    }

    &__img {
      @include transition;

      width: 100%;
      margin-top: 3rem;

      @include media(tb) {
        /* tablet-size */
        width: 50%;
        object-fit: contain;
        margin-top: 0;
      }

      @include media(m) {
        /* middle-size */
        width: 75.5rem;
        padding-right: 8rem;
      }
    }

    &__txtbox {
      @include transition;

      @include media(tb) {
        /* tablet-size */
        width: calc(100% / 2 - 3rem);
        padding-right: 3rem;
      }

      @include media(m) {
        /* middle-size */
        width: calc(100% / 2 - 5.5rem);
        padding-right: 11rem;

      }
    }

    &__ttl {

      font-weight: 700;
      letter-spacing: 0.05em;

      &__top {
        @include transition;

        font-size: 1.9rem;

        @include media(tb) {
          /* tablet-size */

        }

        @include media(m) {
          /* middle-size */
          font-size: 2.8rem;

        }

        &.-en {
          line-height: 1.3;
        }
      }

      &__bottom {
        @include transition;

        font-size: 2.8rem;
        margin-top: .5rem;

        @include media(tb) {
          /* tablet-size */

          font-size: 3.9rem;
        }

        @include media(m) {
          /* middle-size */
          font-size: 5rem;

        }

        &.-en {
          margin-top: 1.5rem;
        }
      }

      &-dot {
        position: relative;
        color: #0172E0;

        &::before {
          content: "・";
          position: absolute;
          top: -.8em;
          left: 0;
        }
      }

      &-gradation {
        @include transition;

        @include textgradient;
        font-size: 5.5rem;
        background-image: linear-gradient(90deg, #016EDF 5.09%, #F9398E 99.99%, rgba(45, 49, 61, 0) 100%);

        @include media(tb) {
          /* tablet-size */
          font-size: 5rem;
        }

        @include media(m) {
          /* middle-size */
          font-size: 8.2rem;

        }
      }
    }

    &__txt {
      @include transition;

      margin-top: 3rem;
      font-weight: 700;
      font-size: 1.4rem;
      line-height: 2;

      @include media(tb) {
        /* tablet-size */
        margin-top: 1rem;
      }

      @include media(m) {
        /* middle-size */
        margin-top: 3rem;
        width: 90%;
        font-size: 1.6rem;
        line-height: 2.2;
      }
    }

    &__btn {
      @include transition;

      @include transition;
      font-size: 1.6rem;
      background: linear-gradient(93.4deg, #1586F8 37.21%, #3AB0F3 102.05%);
      margin-top: 4rem;
      padding: 2rem 0 2rem 3.6rem;
      border-radius: 10rem;
      display: block;
      font-weight: 600;
      border-style: solid;
      border-width: .2rem;
      position: relative;
      color: #fff;

      @include media(tb) {
        /* tablet-size */
        margin-top: 2rem;
        font-size: 1.7rem;
      }

      @include media(m) {
        width: 30rem;
        margin-top: 4rem;
        padding: 2.1rem 0 2.1rem 3.3rem;
        font-size: 1.4rem;

      }

      &.-en {
        font-size: 1.3rem;
        padding: 2rem 0 2rem 2rem;

        @include media(m) {
          width: 34rem;
        }
      }

      &::before,
      &::after {
        @include transition;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;

      }

      &::before {
        @include transition;

        display: block;
        right: 6.5rem;
        width: 0.1rem;
        height: calc(100% - 2.2rem);
        content: "";
        background-color: #fff;

        @include media(m) {
          /* tablet-size */
          height: calc(100% - 3rem);
        }
      }

      &::after {
        @include transition;

        @include fontAwesome__sol;
        content: "\f054";
        right: 2.6rem;
        display: flex;
        align-items: center;
      }

      &:hover {
        @include brightness;
      }

    }

    &__ttl2 {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      font-size: 1.8rem;
      font-weight: 700;
      margin-bottom: 5rem;

      @include media(tb) {
        width: 100%;
        font-size: 2.9rem;
        margin-bottom: 11rem;
      }

      @include media(m) {
        font-size: 4rem;
        margin-bottom: 17rem;
      }

      img {
        height: 2.9rem;
        margin: 0 .5rem;

        @include media(tb) {
          height: 4.6rem;
          margin: 0 1rem;
        }

        @include media(m) {
          height: 6.4rem;
        }
      }
    }
  }

  &02 {
    &__outer {
      margin-top: 13rem;

      @include media(m) {
        /* middle-size */
        margin-top: 17rem;

      }
    }

    &__ttl {
      @include transition;

      font-weight: 700;
      font-size: 1.5rem;
      text-align: center;
      line-height: 1.9;

      @include media(tb) {
        /* tablet-size */
        font-size: 2.1rem;

      }

      @include media(m) {
        /* middle-size */
        font-size: 2.7rem;
        width: min(100% - 40px, 1075px);
        margin-right: auto;
        margin-left: auto;
      }

      &-gradation {
        @include textgradient;
        background-image: linear-gradient(180deg, #343C5B 0%, #A4A9BB 100%);
      }


      &01 {
        text-align: center;
        margin-left: $width-m;
        margin-right: $width-m;
      }

      &02 {
        @include transition;

        margin-top: 2rem;
        display: flex;
        justify-content: center;
        flex-direction: column;
        flex-wrap: wrap;
        line-height: 2.4;

        @include media(tb) {
          /* tablet-size */
          flex-direction: row;
          align-items: flex-end;
          line-height: 1;
        }

        span {
          @include transition;

          font-size: 2.4rem;

          @include media(tb) {
            /* tablet-size */
            font-size: 3.3rem;

          }

          @include media(m) {
            /* middle-size */
            font-size: 4.2rem;

          }
        }

        img {
          @include transition;

          width: 26.2rem;
          margin: auto;

          @include media(tb) {
            /* tablet-size */
            margin: 0;
            width: 31rem;
            object-fit: contain;

          }

          @include media(m) {
            /* middle-size */
            width: 35.7rem;

          }
        }

        &.-en {
          span {
            margin-left: 1rem;
          }
        }
      }
    }

    &__wrap {
      background: linear-gradient(180deg, #fff 6rem, #F7F7F7 0);

    }

    &__ulist {
      @include transition;

      margin-top: 4rem;
      padding-right: 4rem;
      padding-left: 4rem;

      @include media(tb) {
        /* tablet-size */
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-around;
      }

      @include media(m) {
        /* middle-size */
        padding-right: 0;
        padding-left: 0;
        margin-left: auto;
        margin-right: auto;
        margin-top: 6rem;
        width: min(calc(100% - ($width-m * 2)), 1075px);

      }

    }


    &__item {
      @include transition;

      margin-top: 2.2rem;

      @include media(tb) {
        /* tablet-size */
        margin-top: 0;
        width: calc(100% / 3 - 1rem);
      }

      @include media(m) {
        /* middle-size */
        width: calc(100% / 3 - 2rem);

      }

      &:nth-of-type(1) {
        margin-top: 0;
      }

      img {
        width: 100%;
        filter: drop-shadow(1px 3px 5px #9599b7);
      }
    }

  }

  &03 {
    @include transition;

    margin-right: 1.5rem;
    margin-left: 1.5rem;
    background: #FFFFFF;
    box-shadow: 8px 10px 33px rgba(0, 0, 0, 0.08);
    border-radius: 15px;
    padding: 0 0 2rem 0;

    @include media(tb) {
      /* tablet-size */
      margin-right: 3rem;
      margin-left: 3rem;
      padding-bottom: 5rem;
    }

    @include media(m) {
      /* middle-size */
      width: min(calc(100% - ($width-m * 2)), 1120px);
      padding-bottom: 9rem;
      margin: auto;
      border-radius: 30px;
    }

    &__outer {
      @include transition;

      padding-top: 5rem;
      padding-bottom: 5rem;
      background-color: #F7F7F7;

      @include media(tb) {
        /* tablet-size */
        padding-top: 8rem;

      }

      @include media(m) {
        /* middle-size */
        padding-bottom: 14rem;
        padding-top: 12rem;
      }
    }

    &__ttl {
      @include transition;

      font-size: 2.6rem;
      font-weight: 700;
      text-align: center;
      transform: translateY(-50%) !important;
      letter-spacing: 0.05em;

      @include media(tb) {
        /* tablet-size */
        font-size: 3.5rem;

      }

      @include media(m) {
        /* middle-size */
        font-size: 4.5rem;
      }
    }

    &__olist {
      @include transition;

      margin-right: 4rem;
      margin-left: 4rem;
      margin-top: 2rem;

      &__item {
        @include transition;

        margin-top: 5rem;

        @include media(tb) {
          /* tablet-size */
          display: flex;
          flex-wrap: wrap;
          flex-direction: row-reverse;
          justify-content: space-between;
          align-items: center;
        }

        &:nth-of-type(1) {
          margin-top: 0;
        }

        &:nth-of-type(2n) {
          @include media(tb) {
            /* tablet-size */
            flex-direction: row;
          }
        }

      }

      &__txtbox {
        @include transition;

        @include media(tb) {
          /* tablet-size */
          width: calc(100% / 2 - 3rem);
        }

        @include media(m) {
          /* middle-size */

        }
      }

      &__ttl {
        @include transition;

        @include textgradient;
        font-weight: 700;
        position: relative;
        display: flex;
        align-items: center;
        font-size: 1.2rem;

        @include media(tb) {
          /* tablet-size */
          font-size: 1.5rem;

        }

        @include media(m) {
          /* middle-size */
          font-size: 1.7rem;
          line-height: 1;
        }

        &::before {
          content: "";
          display: block;
          width: 1rem;
          height: 1rem;
          background-position: 50% 50%;
          background-repeat: no-repeat;
          background-size: contain;
          margin-right: 0.7rem;
        }

        &-blue,
        &-purple,
        &-pink {
          @extend .p-abo03__olist__ttl;

        }

        &-blue {
          background-image: linear-gradient(90deg, #006EDE 3.93%, #079CF4 96.95%);

          &::before {
            background-image: url(../images/top/cross-blue2.png);
          }
        }

        &-purple {
          background-image: linear-gradient(90deg, #8155B3 3.93%, #9751A6 96.95%);

          &::before {
            background-image: url(../images/top/cross-purple2.png);
          }
        }

        &-pink {
          background-image: linear-gradient(90deg, #E33F81 3.93%, #EB5985 96.95%);

          &::before {
            background-image: url(../images/top/cross-red2.png);
          }
        }

      }

      &__subttl {
        @include transition;

        font-weight: 700;
        font-size: 1.7rem;
        line-height: 1.6;
        margin-top: 1rem;

        @include media(tb) {
          /* tablet-size */
          font-size: 2rem;
        }

        @include media(m) {
          /* middle-size */
          font-size: 2.3rem;

        }
      }

      &__txt {
        @include transition;

        margin-top: 1rem;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.7;

        @include media(tb) {
          /* tablet-size */
          font-size: 1.6rem;

        }

        @include media(m) {
          /* middle-size */
          padding-right: 10rem;

        }
      }

      &__img {
        @include transition;

        width: 100%;
        margin-top: 2.5rem;

        @include media(tb) {
          /* tablet-size */
          width: calc(100% / 2);

        }

        @include media(m) {
          /* middle-size */
          width: 52rem;

        }
      }
    }

    &__btn {
      @include transition;
      @include fontsize(13, 18);
      background: linear-gradient(93.4deg, #1586F8 37.21%, #3AB0F3 102.05%);
      padding: 2rem 0 2rem 2.5rem;
      border-radius: 10rem;
      display: block;
      font-weight: 600;
      border-style: solid;
      border-width: .2rem;
      position: relative;
      color: #fff;
      width: 30rem;
      margin: 4rem auto 0;

      @include media(tb) {
        /* tablet-size */
        width: 40rem;

      }

      @include media(m) {
        width: 52.7rem;
        margin-top: 13rem;
        padding: 3.8rem 0 3.8rem 4.3rem;

      }

      &.-en {
        padding: 2rem 6rem 2rem 2.5rem;

        @include media(tb) {
          width: 55rem;
        }

        @include media(m) {
          width: 60rem;
        }
      }

      &::before,
      &::after {
        @include transition;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;

      }

      &::before {
        @include transition;

        display: block;
        right: 4rem;
        width: 0.1rem;
        height: calc(100% - 2.2rem);
        content: "";
        background-color: #fff;

        @include media(m) {
          /* tablet-size */
          height: calc(100% - 3rem);
          right: 9.8rem;
        }
      }

      &::after {
        @include fontAwesome__sol;
        content: "\f054";
        right: 1.9rem;
        display: flex;
        align-items: center;

        @include media(m) {
          /* middle-size */
          right: 4.4rem;
        }
      }

      &:hover {
        @include brightness;
      }

    }


  }

  &04 {
    @include transition;

    @include media(tb) {
      /* tablet-size */
      margin-right: 3rem;
      margin-left: 3rem;
    }

    @include media(m) {
      /* middle-size */

    }

    &__outer {
      margin-top: 7rem;


    }

    &__ttl {
      @include transition;

      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-weight: 700;
      font-size: 3.2rem;
      line-height: 1.5;
      margin-right: $width-m;
      margin-left: $width-m;

      @include media(m) {
        /* middle-size */
        font-size: 5.2rem;
        width: min(calc(100% - ($width-m * 2)), 1115px);
        margin-right: auto;
        margin-left: auto;
        justify-content: flex-start;
        align-items: flex-start;
      }

      &-gradation {
        @include transition;

        @include textgradient;
        background-image: linear-gradient(90deg, #006EDE 3.93%, #FF378B 96.95%);
        font-size: 1.4rem;

        @include media(tb) {
          /* tablet-size */
          font-size: 2.1rem;

        }

        @include media(m) {
          /* middle-size */
          font-size: 2.8rem;
        }

      }

    }

    &__ulist01 {
      @include transition;

      margin-top: 0;
      margin-right: 2rem;
      margin-left: 2rem;

      @include media(tb) {
        /* tablet-size */
        margin-right: 0;
        margin-left: 0;
      }

      @include media(m) {
        /* middle-size */
        width: min(calc(100% - ($width-m * 2)), 1115px);
        margin-right: auto;
        margin-left: auto;

      }

      &__item {
        @include transition;

        position: relative;
        margin-top: 4.5rem;
        padding-top: 9rem;
        border-top: 1px solid #EBEBEB;

        @include media(tb) {
          /* tablet-size */
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          justify-content: space-between;
        }

        @include media(m) {
          /* middle-size */
          align-items: center;
          justify-content: flex-end;
          margin-top: 5rem;
          padding-top: 5rem;
        }

        &::before {
          @include transition;

          display: block;
          position: absolute;
          top: 4rem;
          left: 0;
          width: 4.3rem;
          height: 4.3rem;
          background-position: 50% 50%;
          background-size: contain;
          background-repeat: no-repeat;
          content: "";

          @include media(tb) {
            /* tablet-size */
            top: 3rem;
          }

          @include media(m) {
            /* middle-size */
            width: 6rem;
            height: 6rem;
            left: 2rem;

          }
        }

        &:nth-of-type(1) {
          @include transition;

          margin-top: 0;
          border-top: none;

          @include media(tb) {
            /* tablet-size */

          }

          @include media(m) {
            /* middle-size */
            margin-top: 2rem;
          }

          &::before {
            background-image: url(../images/common/cross-blue.png);

          }
        }

        &:nth-of-type(2) {
          &::before {
            background-image: url(../images/common/cross-red.png);
          }
        }

        &:nth-of-type(3) {
          &::before {
            background-image: url(../images/common/cross-purple.png);
          }
        }
      }

      &__txtbox {
        @include transition;

        padding-left: 3.5rem;
        padding-right: 3.5rem;

        @include media(tb) {
          /* tablet-size */
          padding-right: 0;
          padding-left: 0;
          width: calc(100% - 44% - 3rem);
        }

        @include media(m) {
          /* middle-size */
          width: calc(100% / 2 - 5.8rem - 6.5rem);
          margin-right: 6.5rem;
        }
      }

      &__ttl {
        @include transition;

        font-weight: 700;
        font-size: 2rem;
        line-height: 1.35;
        letter-spacing: 0.03em;

        @include media(tb) {
          /* tablet-size */

        }

        @include media(m) {
          /* middle-size */
          font-size: 2.8rem;

        }

        &01 {
          @extend .p-abo04__ulist01__ttl;

          span {
            &:nth-of-type(1) {
              color: #0C96F6;
            }

            &:nth-of-type(2) {
              color: #0E94F7;
            }

            &:nth-of-type(3) {
              color: #4C5EC6;
            }
          }
        }

        &02 {
          @extend .p-abo04__ulist01__ttl;

          span {
            &:nth-of-type(1) {
              color: #E64782;
            }
          }
        }

        &03 {
          @extend .p-abo04__ulist01__ttl;

          span {
            &:nth-of-type(1) {
              color: #5F2B9A;
            }
          }
        }

      }

      &__txt {
        @include transition;

        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.7;
        margin-top: 1rem;

        @include media(tb) {
          /* tablet-size */
          line-height: 1.5;

        }

        @include media(m) {
          /* middle-size */
          font-size: 1.7rem;
          margin-top: 3rem;
          line-height: 1.7;


        }

      }

      &__img {
        @include transition;

        width: calc(100% - 1.8rem * 2);
        margin-top: 3rem;
        margin-left: auto;
        margin-right: auto;
        display: block;
        border-radius: 0.6rem;

        @include media(tb) {
          /* tablet-size */
          width: 44%;
          margin: 0;
          align-self: flex-start;
        }

        @include media(m) {
          /* middle-size */
          width: 45%;

        }
      }


    }

    &__box {
      @include transition;
      margin-top: 6.5rem;
      margin-right: 3.7rem;
      margin-left: 3.7rem;

      @include media(tb) {
        /* tablet-size */
        margin-top: 7rem;
        margin-right: 0;
        margin-left: 0;
      }

      @include media(m) {
        /* middle-size */
        width: min(calc(100% - ($width-m * 2)), 1215px);
        margin-right: auto;
        margin-left: auto;

      }
    }

    &__subttl {
      @include transition;

      margin-top: 1rem;
      font-weight: 700;
      font-size: 2.5rem;
      line-height: 1.4;

      @include media(tb) {
        /* tablet-size */
        font-size: 3.1rem;

      }

      @include media(m) {
        /* middle-size */
        font-size: 3.7rem;
        margin-top: 7rem;

      }

      &__mark {
        @include transition;
        display: inline-block;
        color: #fff;
        font-weight: 700;
        font-size: 1.4rem;
        letter-spacing: 0.08em;
        padding: .6rem 1.5rem;
        background: linear-gradient(91.21deg, #E9337A 33.49%, #F46793 92.32%);
        border-radius: 100px;
        margin-bottom: 0.5rem;

        @include media(tb) {
          /* tablet-size */
          font-size: 1.6rem;
          margin-bottom: 1rem;
          display: inline-block;
        }

        @include media(m) {
          /* middle-size */
          font-size: 1.8rem;

        }
      }

      &__pink {
        color: #e54582;
      }

    }

    &__ulist02 {
      @include transition;

      margin-top: 2rem;

      @include media(tb) {
        /* tablet-size */
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
      }

      @include media(m) {
        /* middle-size */

      }

      &__item {
        @include transition;

        margin-top: 1.5rem;

        @include media(tb) {
          /* tablet-size */
          width: calc(100% / 2 - .1rem);
          margin-top: 0;

        }

        @include media(m) {
          /* middle-size */

        }

        &:nth-of-type(1) {
          margin-top: 0;

        }

      }

      &__img {
        width: 100%;
      }

      &__txt {
        @include transition;

        font-weight: 600;
        font-size: 1.1rem;
        margin-top: .5rem;
        text-align: right !important;

        @include media(tb) {
          /* tablet-size */
          font-size: 1.2rem;

        }

        @include media(m) {
          /* middle-size */
          font-size: 1.4rem;


        }
      }
    }

  }

  &05 {
    &__outer {
      @include transition;

      margin-top: 7rem;

      @include media(m) {
        /* middle-size */
        margin-top: 16rem;
      }
    }

    &__ttl {
      @include transition;

      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      flex-direction: column;
      align-items: center;
      font-weight: 700;
      font-size: 2.2rem;

      @include media(tb) {
        /* tablet-size */
        font-size: 2.7rem;

      }

      @include media(m) {
        /* middle-size */
        font-size: 3.2rem;

      }

      &-gradation {
        @include transition;

        @include textgradient;
        background-image: linear-gradient(89.64deg, #076DDD 2.04%, #E63D7A 104.42%);
        font-size: 3.8rem;
        margin-top: 1rem;

        @include media(tb) {
          /* tablet-size */
          font-size: 4.4rem;

        }

        @include media(m) {
          /* middle-size */
          font-size: 5rem;

        }
      }
    }

    &__wrap {
      @include transition;

      margin-top: 4rem;

      @include media(tb) {
        /* tablet-size */
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        margin-top: 5rem;
        width: min(calc(100% - ($width-m * 2)), 1295px);
        margin-right: auto;
        margin-left: auto;
      }

      @include media(m) {
        /* middle-size */
        margin-top: 5rem;
        width: min(calc(100% - ($width-m * 2)), 1295px);
        margin-right: auto;
        margin-left: auto;

      }
    }

    &__box {
      @include transition;

      margin-top: 3rem;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      padding-bottom: 3rem;
      margin-right: 0.1rem;

      @include media(tb) {
        /* tablet-size */
        margin-top: 0;
        margin-bottom: 0.2rem;
        width: calc(100% / 2 - .2rem);
        padding-bottom: 0;
      }

      @include media(m) {
        /* middle-size */

      }

      &:nth-of-type(2n + 1) {
        flex-direction: row;

        &::after {
          @include media(m) {
            /* middle-size */
            left: inherit;
          }
        }
      }

      &:nth-of-type(2n) {
        flex-direction: row-reverse;

        &::after {
          @include media(m) {
            /* middle-size */
            right: inherit;
          }
        }
      }

      &::after {
        @include transition;

        display: block;
        position: absolute;
        top: 6rem;
        right: 0;
        bottom: 0;
        left: 0;
        width: 92%;
        margin: auto;
        border-radius: 20px;
        content: "";
        z-index: -1;

        @include media(tb) {
          /* tablet-size */
          top: 0;
          width: calc(100% - 6.5rem);
        }

        @include media(m) {
          /* middle-size */

        }
      }

      &:nth-of-type(1) {
        margin-top: 0;

        &::after {
          background: #EEF6FF;

          @include media(tb) {
            /* tablet-size */
            display: none;
          }
        }
      }

      &:nth-of-type(2) {

        &::after {
          background: #FAF4FF;

          @include media(tb) {
            /* tablet-size */
            display: none;
          }
        }

      }

      &:nth-of-type(3) {

        &::after {
          background: #EEF1FF;

          @include media(tb) {
            /* tablet-size */
            display: none;
          }
        }
      }

      &:nth-of-type(4) {

        &::after {
          background: #FFF3F7;

          @include media(tb) {
            /* tablet-size */
            display: none;
          }
        }
      }

      &__ttl {
        font-weight: 700;
        font-size: 2rem;
        line-height: 20px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        position: relative;
        padding-bottom: 1.2rem;
        text-align: center;

        &::after {
          content: "";
          display: block;
          position: absolute;
          right: 0;
          left: 0;
          bottom: 0;
          width: 6rem;
          height: .2rem;
          margin: auto;
        }

        &-blue {
          @extend .p-abo05__box__ttl;
          color: #0F6CDA;

          &::after {
            background-color: #0F6CDA;
          }
        }

        &-purple {
          @extend .p-abo05__box__ttl;
          color: #974F9E;

          &::after {
            background-color: #974F9E;
          }

        }

        &-navy {
          @extend .p-abo05__box__ttl;
          color: #535DBC;

          &::after {
            background-color: #535DBC;
          }

        }

        &-pink {
          @extend .p-abo05__box__ttl;
          color: #F0418B;

          &::after {
            background-color: #F0418B;
          }

        }
      }

      &__ulist {
        @include transition;

        margin-top: 3rem;

        @include media(tb) {
          /* tablet-size */

        }

        @include media(m) {
          /* middle-size */
          margin-top: 3rem;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }
      }

      &__item {
        @include transition;

        font-weight: 500;
        font-size: 1.5rem;
        line-height: 1.4;

        @include media(tb) {
          /* tablet-size */
          font-size: 1.3rem;

        }

        @include media(m) {
          /* middle-size */
          width: calc(100% / 2 - 2rem);
          font-size: 1.4rem;

        }
      }
    }

    &__img {
      @include transition;
      width: 26.3rem;

      @include media(tb) {
        /* tablet-size */
        // width: 21.9rem;
        width: 100%;

      }

      @include media(m) {
        /* middle-size */
      }

    }

    &__txtbox {
      @include transition;

      width: 26.3rem;
      margin-top: 2rem;

      @include media(tb) {
        /* tablet-size */
        width: 42rem;
        margin-top: 2rem;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        display: none;

      }

      @include media(m) {
        /* middle-size */
      }
    }

  }

  &06 {
    @include transition;

    margin-right: 1.5rem;
    margin-left: 1.5rem;

    @include media(tb) {
      /* tablet-size */
      margin-right: 3rem;
      margin-left: 3rem;
    }

    @include media(m) {
      /* middle-size */
      width: min(calc(100% - ($width-m * 2)), 1215px);
      margin-right: auto;
      margin-left: auto;
    }

    &__outer {
      @include transition;

      margin-top: 7rem;

      @include media(tb) {
        /* tablet-size */
        background: linear-gradient(179.98deg, #F3F3F3 -12.51%, #FFFFFF 19.91%);

      }

      @include media(m) {
        /* middle-size */
        margin-top: 12rem;

      }
    }

    &__ttl {
      @include transition;

      text-align: center;

      @include media(tb) {
        /* tablet-size */
        transform: translateY(-2rem) !important;
      }

      @include media(m) {
        /* middle-size */

      }

      &__style01 {
        @include transition;

        background-color: #3B70F4;
        color: #fff;
        border-radius: 100px;
        font-weight: 700;
        font-size: 2.1rem;
        padding: 1.5rem 3rem;
        display: inline-block;

        @include media(tb) {
          /* tablet-size */
          font-size: 3rem;

        }

        @include media(m) {
          /* middle-size */
          font-size: 2.4rem;
        }

      }

      &__style02 {
        @include transition;

        font-weight: 700;
        font-size: 4.1rem;
        letter-spacing: 0.03em;
        color: #3B70F4;
        line-height: 1.6;

        @include media(tb) {
          /* tablet-size */

        }

        @include media(m) {
          /* middle-size */
          font-size: 6rem;
        }
      }

      &__style03 {
        @include transition;

        font-weight: 700;
        font-size: 2rem;
        letter-spacing: 0.02em;

        @include media(tb) {
          /* tablet-size */
          font-size: 3rem;

        }

        @include media(m) {
          /* middle-size */
          font-size: 4.5rem;

        }

        &.-en {
          line-height: 1.5;
        }
      }
    }

    &__txt {
      @include transition;

      font-weight: 700;
      font-size: 1.4rem;
      line-height: 1.7;
      text-align: center !important;
      margin-top: 3rem;

      @include media(tb) {
        /* tablet-size */
        font-size: 1.8rem;

      }

      @include media(m) {
        /* middle-size */
        font-size: 2rem;
        margin-top: 1rem;

      }
    }

    &__img {
      @include transition;

      width: 100%;
      margin-top: 6rem;

      @include media(tb) {
        /* tablet-size */
        margin-top: 9rem;

      }

      @include media(m) {
        /* middle-size */
        margin-top: 12rem;
      }
    }

    &__ulist {
      @include transition;

      margin-top: 6rem;

      @include media(tb) {
        /* tablet-size */
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
      }

      @include media(m) {
        /* middle-size */

      }

      &__item {
        @include transition;

        margin-top: 1rem;
        background: #FFFFFF;
        box-shadow: 0px 7px 20px rgba(129, 129, 129, 0.3);

        @include media(tb) {
          /* tablet-size */
          width: calc(100% / 3 - 1rem);
          margin-top: 0;

        }

        @include media(m) {
          /* middle-size */

        }

        &:nth-of-type(1) {
          margin-top: 0;
        }

        a {
          @include transition;
          @include opacity;
        }

        a,
        span {
          padding: 3rem 4rem 4rem;
          display: block;

          @include media(tb) {
            /* tablet-size */
            padding: 2rem;

          }

          @include media(m) {
            /* middle-size */
            padding: 1rem 2rem 4rem;

          }
        }
      }

      &__ttl {
        @include transition;
        font-weight: 700;
        font-size: 1.7rem;
        line-height: 2.4;
        color: #000;

        @include media(tb) {
          /* tablet-size */
          min-height: 3.5em;
          font-size: 1.5rem;
          line-height: 1.5;
          display: flex;
          align-items: center;

        }

        @include media(m) {
          /* middle-size */
          font-size: 1.8rem;

        }

        &.-en {
          @include media(tb) {
            align-items: flex-start;
            min-height: 5em;
          }
        }
      }

      &__img {
        width: 100%;
      }

      &__txt {
        @include transition;

        margin-top: 2.5rem;
        font-weight: 700;
        font-size: 1.4rem;
        line-height: 1.5;
        color: #292D36;

        @include media(tb) {
          /* tablet-size */
          margin-top: 1.5rem;
          font-size: 1.5rem;

        }

        @include media(m) {
          /* middle-size */
          font-size: 1.6rem;
          line-height: 1.75;

        }
      }

    }

  }

  &07 {
    @include transition;

    background: #FFFFFF;
    box-shadow: 8px 10px 33px rgba(0, 0, 0, 0.08);
    border-radius: 30px;
    margin-right: 1.5rem;
    margin-left: 1.5rem;
    padding-bottom: 7rem;

    @include media(tb) {
      /* tablet-size */
      padding-bottom: 5rem;
      margin-right: 3rem;
      margin-left: 3rem;

    }

    @include media(m) {
      /* middle-size */
      width: min(calc(100% - ($width-m * 2)), 1215px);
      margin-right: auto;
      margin-left: auto;
      padding: 0 6rem 6rem;

    }

    &__outer {
      @include transition;

      margin-top: 10rem;
      padding-bottom: 6rem;

      @include media(tb) {
        /* tablet-size */
        margin-top: 17rem;
      }

      @include media(m) {
        /* middle-size */
        margin-top: 25rem;
        padding-bottom: 26rem;

      }

      &.-en {
        @include media(m) {
          padding-bottom: 12rem;
        }
      }

      &02 {
        padding-bottom: 6rem;

        @include media(tb) {
          /* tablet-size */
        }

        @include media(m) {
          /* middle-size */
          padding-bottom: 14rem;

        }
      }
    }

    &__ttl {
      @include transition;

      @include textgradient;
      background-image: linear-gradient(90deg, #086EDE 30%, #DB4080 66.6%);
      font-weight: 700;
      font-size: 2.2rem;
      transform: translateY(-50%) !important;
      text-align: center;
      display: block;

      @include media(tb) {
        /* tablet-size */
        font-size: 3.5rem;
      }

      @include media(m) {
        /* middle-size */
        font-size: 3.5rem;

      }
    }

    &__ulist {
      @include transition;

      margin-right: 3rem;
      margin-left: 3rem;

      @include media(tb) {
        /* tablet-size */
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      @include media(m) {
        /* middle-size */
        margin-top: 1rem;
      }

      &__item {
        @include transition;

        margin-top: 3rem;

        @include media(tb) {
          /* tablet-size */
          width: calc(100% / 3 - 4.5rem);
          margin-top: 0;
          position: relative;
        }

        @include media(m) {
          /* middle-size */
          width: calc(100% / 3 - 5.7rem);


        }

        &::after {
          @include fontAwesome__sol;
          @include transition;
          content: "\f078";
          right: 0;
          left: 0;
          bottom: 0;
          margin: auto;
          color: #2D313D;
          font-size: 2.8rem;
          text-align: center;
          margin-top: 3rem;
          display: block;

          @include media(tb) {
            /* tablet-size */
            position: absolute;
            top: 0;
            right: -4rem;
            bottom: 0;
            left: inherit;
            margin: auto;
            content: "\f054";
            font-size: 2.8rem;
            display: flex;
            align-items: center;
          }

          @include media(m) {
            /* middle-size */
            right: -5rem;
            transform: translateY(2rem);
          }
        }

        &:last-of-type {
          &::after {
            display: none;
          }
        }
      }

      &__ttl {
        @include transition;

        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 1.8;
        text-align: center;
        letter-spacing: 0.05em;
        position: relative;
        z-index: 1;

        @include media(tb) {
          /* tablet-size */
          font-size: 1.4rem;

        }

        @include media(m) {
          /* middle-size */
          font-size: 2rem;

        }

        span {
          @include transition;

          @include fontRoboto;
          font-weight: 700;
          font-size: 1.2rem;
          text-align: center;
          letter-spacing: 0.05em;

          @include media(tb) {
            /* tablet-size */

          }

          @include media(m) {
            /* middle-size */
            font-size: 1.4rem;
            margin-bottom: 0.5rem;
          }

          &::after {
            content: "";
            display: block;
            right: 0;
            left: 0;
            bottom: 0;
            width: 3rem;
            height: .2rem;
            margin: auto;
            margin-top: 0.5rem;

          }
        }

        &-blue {
          @extend .p-abo07__ulist__ttl;
          color: #008FEA;

          span {
            &::after {
              background-color: #008FEA;
            }
          }
        }

        &-purple {
          @extend .p-abo07__ulist__ttl;
          color: #9246A5;

          span {
            &::after {
              background-color: #9246A5;
            }
          }
        }

        &-pink {
          @extend .p-abo07__ulist__ttl;
          color: #EE277B;

          span {
            &::after {
              background-color: #EE277B;
            }
          }
        }

      }

      &__img {
        width: 100%;
        margin-top: -1.5rem;
      }
    }

  }

  &08 {
    @include transition;

    padding: 4.5rem 1.6rem 4.3rem;

    @include media(tb) {
      /* tablet-size */
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    @include media(m) {
      /* middle-size */

    }

    &__outer {
      @include transition;

      margin-top: 5rem;
      background-image: url(../images/top/abu08_bg-sp.jpg);
      background-position: 0 50%;
      background-repeat: no-repeat;
      background-size: 100%;

      @include media(tb) {
        /* tablet-size */
        background-image: url(../images/top/abu08_bg-pc.jpg);
        background-position: 60% 50%;
        background-size: 164rem;

      }

      @include media(m) {
        /* middle-size */
        width: min(calc(100% - ($width-m * 2)), 1215px);
        min-height: 53.4rem;
        margin-right: auto;
        margin-left: auto;
        background-size: contain;
        background-position: 50% 50%;
        padding: 5rem;
      }
    }

    &__lead {
      &__txt {
        @include transition;

        display: inline;
        padding: 0 0px 1.1rem;
        background: linear-gradient(transparent 92%, #fff 0%);
        color: #FFFFFF;
        font-weight: 700;
        font-size: 2.4rem;
        line-height: 2;
        letter-spacing: 0.05em;
        position: relative;
        left: 2rem;

        @include media(tb) {
          /* tablet-size */
          font-size: 3.2rem;
          line-height: 2.4;
          background: linear-gradient(transparent 94%, #fff 0%);
          padding: 0 0 2rem;
        }

        @include media(m) {
          /* middle-size */
          font-size: 4rem;
          background: linear-gradient(transparent 96%, #fff 0%);
          line-height: 2.2;
        }
      }

      @include media(tb) {
        /* tablet-size */
        width: calc(100% / 2 - 5rem);

      }

      @include media(m) {
        /* middle-size */
      }
    }

    &__box {
      @include transition;

      margin-top: 3.5rem;
      box-shadow: 2px 7px 12px 3px rgba(131, 123, 153, 0.25);
      border-radius: 10px;
      padding: 3rem 1.7rem;
      background: rgba(255, 255, 255, 0.85);

      @include media(tb) {
        /* tablet-size */
        width: calc(100% / 2 - 5rem);
        margin-top: 0;

      }

      @include media(m) {
        /* middle-size */
        width: 43.8rem;
        padding: 2rem 2.7rem 4rem;

      }
    }

    &__txtbox {
      @include transition;

      position: relative;
      padding: 1.5rem 0;
      border-bottom: .2rem solid #7147A0;
      line-height: 1.6;
      text-align: center !important;

      @include media(tb) {
        /* tablet-size */

      }

      @include media(m) {
        /* middle-size */
        line-height: 1.4;
        padding: 1.5rem 0 2.5rem
      }

      span {
        letter-spacing: 0.03em;
        font-weight: 700;

      }

      &__style01 {
        @include transition;

        font-size: 2.2rem;
        color: #7147A0;

        @include media(tb) {
          /* tablet-size */

        }

        @include media(m) {
          /* middle-size */
          font-size: 2.6rem;
        }
      }

      &__style02 {
        @include transition;

        font-size: 1.6rem;
        color: #2D313D;

        @include media(tb) {
          /* tablet-size */

        }

        @include media(m) {
          /* middle-size */
          font-size: 1.9rem;
        }

      }

      &__style03 {
        @include transition;

        font-size: 2.5rem;
        color: #7E53A3;

        @include media(tb) {
          /* tablet-size */

        }

        @include media(m) {
          /* middle-size */
          font-size: 3.2rem;
        }

      }

      &__style04 {
        @include transition;

        font-size: 1.4rem;
        color: #2D313D;

        @include media(tb) {
          /* tablet-size */

        }

        @include media(m) {
          /* middle-size */
          font-size: 1.9rem;
        }
      }

      &::before,
      &::after {
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 14px 10px 0 10px;
        content: "";
      }

      &::before {
        bottom: -1.4rem;
        border-color: #7147A0 transparent transparent transparent;
      }

      &::after {
        bottom: -1rem;
        border-color: #f3f2f0 transparent transparent transparent;
      }
    }

    &__logo {
      @include transition;
      display: block;
      width: 80%;
      margin: auto;
      margin-top: 2.5rem;

      @include media(tb) {
        /* tablet-size */
      }

      @include media(m) {
        /* middle-size */
        width: 100%;
        margin-top: 3.5rem;
      }
    }

    &__btn {
      @include transition;
      background: linear-gradient(92.6deg, #7046A0 10.21%, #7349A1 15.35%, #A273AC 100%);
      border-radius: 10rem;
      font-weight: 700;
      color: #fff;
      width: 30rem;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: 2rem auto 0;
      padding: 1.3rem 0 1.3rem 1.5rem;
      font-size: 1.4rem;
      letter-spacing: 0.02em;
      box-shadow: 0px 0px 8px 2px rgba(84, 56, 165, 0.43);

      @include media(m) {
        /* middle-size */
        width: 100%;
        font-size: 1.6rem;
        padding: 1.6rem 0 1.6rem 1.5rem;
        margin-top: 3rem;
      }

      &:hover {
        @include brightness;
      }

      &::before {
        @include transition;
        @include fontAwesome__sol;
        content: "\f138";
        color: #fff;
        font-size: 2.9rem;
        margin-right: 1.8rem;

        @include media(m) {
          /* middle-size */
          font-size: 3.7rem;

        }
      }

    }

  }
}

//make
.p-mak {

  &01 {
    @include transition;

    @include media(tb) {
      /* tablet-size */
      display: flex;
      justify-content: space-between;
    }

    @include media(m) {
      /* middle-size */
      width: min(calc(100% - ($width-m * 2)), 1155px);
      margin-right: auto;
      margin-left: auto;
    }

    &__outer {
      @include transition;

      margin-top: 6rem;
      margin-right: 3.5rem;
      margin-left: 3.5rem;

      @include media(tb) {
        /* tablet-size */
        margin-top: 8rem;
        // margin-right: 0;
        // margin-left: 0;
      }

      @include media(m) {
        /* middle-size */
        margin-top: 10.5rem;

      }
    }

    &__img {
      @include transition;
      width: 100%;
      margin-top: 6rem;
      transform: scale(1.1);
      transform-origin: 50% 100%;

      @include media(tb) {
        /* tablet-size */
        object-fit: contain;
        margin-top: 0;
        transform: scale(1);
        width: calc(50% - 1rem);
      }

      @include media(m) {
        /* middle-size */
        width: 64.5rem;
        padding-right: 0;
      }

      img {
        width: 100%;
      }
    }

    &__txtbox {
      @include transition;

      @include media(tb) {
        /* tablet-size */
        width: calc(50% - 1rem);
        padding-right: 3rem;

      }

      @include media(m) {
        /* middle-size */
        padding-right: 0;


      }
    }

    &__ttl {

      font-weight: 700;
      letter-spacing: 0.05em;

      &__top {
        @include transition;

        font-size: 1.9rem;

        @include media(tb) {
          /* tablet-size */

        }

        @include media(m) {
          /* middle-size */
          font-size: 3rem;


        }
      }

      &__bottom {
        @include transition;
        @include textgradient;
        background-image: linear-gradient(99.76deg, #3D17AA 3.24%, #7920A3 78%);
        font-size: 4.2rem;
        margin-top: .5rem;

        @include media(tb) {
          /* tablet-size */
          font-size: 4.2rem;
        }

        @include media(m) {
          /* middle-size */
          font-size: 7rem;
          margin-top: 2rem;
        }
      }

      &-dot {
        position: relative;
        color: #5F2A9B;

        &::before {
          content: "・";
          position: absolute;
          top: -.8em;
          left: 0;
        }
      }

      &-small {
        @include transition;
        font-size: 3.5rem;

        @include media(tb) {
          /* tablet-size */
          font-size: 4rem;
        }

        @include media(m) {
          /* middle-size */
          font-size: 4.5rem;

        }
      }
    }

    &__txt {
      @include transition;

      margin-top: 3rem;
      font-weight: 700;
      font-size: 1.4rem;
      line-height: 2;

      @include media(tb) {
        /* tablet-size */
        margin-top: 1rem;
      }

      @include media(m) {
        /* middle-size */
        width: 90%;
        margin-top: 7rem;
        font-size: 1.6rem;
        line-height: 2.5;
      }
    }

    &__btn {
      @include transition;

      @include transition;
      font-size: 1.6rem;
      background: linear-gradient(93.4deg, #1586F8 37.21%, #3AB0F3 102.05%);
      margin-top: 4rem;
      padding: 2rem 0 2rem 3.6rem;
      border-radius: 10rem;
      display: block;
      font-weight: 600;
      border-style: solid;
      border-width: .2rem;
      position: relative;
      color: #fff;

      @include media(tb) {
        /* tablet-size */
        margin-top: 2rem;
        font-size: 1.7rem;

      }

      @include media(m) {
        width: 30rem;
        margin-top: 4rem;
        padding: 2.1rem 0 2.1rem 3.3rem;
        font-size: 1.4rem;

      }

      &::before,
      &::after {
        @include transition;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;

      }

      &::before {
        @include transition;

        display: block;
        right: 6.5rem;
        width: 0.1rem;
        height: calc(100% - 2.2rem);
        content: "";
        background-color: #fff;

        @include media(m) {
          /* tablet-size */
          height: calc(100% - 3rem);
        }
      }

      &::after {
        @include transition;

        @include fontAwesome__sol;
        content: "\f054";
        right: 2.6rem;
        display: flex;
        align-items: center;
      }

      &:hover {
        @include brightness;
      }

    }

    &__ttl2 {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      font-size: 1.8rem;
      font-weight: 700;
      margin-bottom: 5rem;

      @include media(tb) {
        width: 100%;
        font-size: 2.9rem;
        margin-bottom: 11rem;
      }

      @include media(m) {
        font-size: 4rem;
        margin-bottom: 17rem;
      }

      &.-en {
        margin-left: -2rem;
        margin-right: -2rem;

        @include media(tb) {
          margin-right: 0;
          margin-left: 0;
        }
      }

      img {
        height: 2.5rem;
        margin: 0 .5rem;

        @include media(tb) {
          height: 4rem;
          margin: 0 1rem;
        }

        @include media(m) {
          height: 5.1rem;
        }
      }
    }
  }

  &02 {
    &__outer {
      margin-top: 8rem;
      padding-top: 9.5rem;
      padding-bottom: 12rem;
      padding-right: 2rem;
      padding-left: 2rem;
      background-image: url(../images/make/mak02_bg02-sp.png),
        url(../images/make/mak02_bg01-sp.png);
      background-position: 50% 100%,
        50% 0%;
      background-size: 50rem;
      background-repeat: no-repeat;

      @include media(tb) {
        /* tablet-size */
        background-size: 99.1rem;
        padding-right: 0;
        padding-left: 0;
      }

      @include media(m) {
        /* middle-size */
        background-size: 147.7rem;
        margin-top: 13rem;
        background-image: url(../images/make/mak02_bg02-pc.png),
          url(../images/make/mak02_bg01-pc.png);
        padding-top: 16rem;
        padding-bottom: 19rem;

      }
    }

    &__ttl {
      @include transition;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      @include media(tb) {
        /* tablet-size */

      }

      @include media(m) {
        /* middle-size */

      }

      &__top {
        font-weight: 700;
        font-size: 1.4rem;

        @include media(tb) {
          /* tablet-size */

        }

        @include media(m) {
          /* middle-size */
          font-size: 1.8rem;

        }
      }

      &__bottom {
        font-weight: 700;
        font-size: 2.4rem;
        margin-top: 2rem;

        @include media(tb) {
          /* tablet-size */

        }

        @include media(m) {
          /* middle-size */
          font-size: 4rem;

        }

        &-purple {
          @extend .p-mak02__ttl__bottom;
          color: #6A33C3;
        }

        &-pink {
          @extend .p-mak02__ttl__bottom;
          color: #F06565;
        }
      }


    }


    &__ulist {
      @include transition;
      margin-top: 8rem;

      @include media(tb) {
        /* tablet-size */
        margin-top: 4rem;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        margin-right: 3.7rem;
        margin-left: 3.7rem;
      }

      @include media(m) {
        /* middle-size */
        margin-left: auto;
        margin-right: auto;
        margin-top: 6rem;
        width: min(calc(100% - ($width-m * 2)), 975px);

      }

    }


    &__item {
      @include transition;
      margin-top: 10rem;

      @include media(tb) {
        /* tablet-size */
        margin-top: 0;
        width: calc(100% / 3 - 1rem);
      }

      @include media(m) {
        /* middle-size */
        width: calc(100% / 3 - 4.5rem);

      }

      &:nth-of-type(1) {
        margin-top: 0;
      }

      &__box {
        position: relative;
        height: 26.3rem;

        &::before {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          margin: 0 auto;
          width: 263px;
          height: 263px;
          background: linear-gradient(180deg, #FFFFFF 42.19%, #D9DBFF 100%);
          box-shadow: 0px 7px 20px rgba(125, 127, 137, 0.25);
          border-radius: 100%;
          z-index: -1;

          @include media(tb) {
            /* tablet-size */
            width: 238px;
            height: 238px;

          }

          @include media(m) {
            /* middle-size */
            width: 263px;
            height: 263px;

          }
        }

      }

      &__img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 15rem;

        @include media(tb) {
          /* tablet-size */

        }

        @include media(m) {
          /* middle-size */
          width: 17rem;

        }
      }

      &__txt01 {
        font-weight: 700;
        font-size: 2rem;
        text-align: center !important;
        letter-spacing: 0.04em;
        color: #6948C7;
        transform: translateY(3rem);
        margin: 0 $width-m;

        @include media(tb) {
          /* tablet-size */
          font-size: 1.6rem;
          margin: 0;
        }

        @include media(m) {
          /* middle-size */
          font-size: 2rem;
          margin-top: 1rem;
        }


      }

      &__txt02 {
        margin-top: 2rem;
        font-weight: 700;
        font-size: 2.3rem;
        text-align: center !important;
        letter-spacing: 0.05em;
        color: #6A33C3;
        min-height: 2em;
        line-height: 1.4;

        @include media(tb) {
          /* tablet-size */
          font-size: 2rem;
          margin-top: 0;
        }

        @include media(m) {
          /* middle-size */
          font-size: 2.3rem;
          margin-top: 2rem;
        }
      }
    }

  }

  &03 {
    @include transition;

    @include media(tb) {
      /* tablet-size */
    }

    @include media(m) {
      /* middle-size */

    }

    &__outer {
      @include transition;
      padding-top: 6rem;

      @include media(tb) {
        /* tablet-size */
      }

      @include media(m) {
        /* middle-size */
      }
    }

    &__ttl {
      @include transition;

      @include media(tb) {
        /* tablet-size */
        font-size: 3.5rem;

      }

      @include media(m) {
        /* middle-size */
        font-size: 4.5rem;
      }

      &__top {
        display: flex;
        align-items: center;
        justify-content: center;

        &-small {
          font-weight: 700;
          font-size: 1.9rem;
          margin-top: 0.3rem;
        }

        img {
          width: 20rem;

          @include media(m) {
            /* middle-size */
            width: 22rem;

          }

          &.-en {
            margin-right: 0.5rem;
          }
        }
      }

      &__middle {
        text-align: center;
        font-size: 1.7rem;
        font-weight: 700;
        margin-top: 2rem;
        line-height: 1.5;

        &-small {
          font-size: 1.4rem;

          @include media(m) {
            /* middle-size */
            font-size: 3rem;

          }
        }

        &-md {
          font-size: 1.7rem;

          @include media(m) {
            /* middle-size */
            font-size: 3rem;

          }
        }

        &-purple {
          color: #6A33C3;
          font-size: 2.4rem;

          @include media(m) {
            /* middle-size */
            font-size: 4.5rem;

          }
        }

      }

      &__bottom {
        font-weight: 700;
        font-size: 2.6rem;
        line-height: 1.6;
        text-align: center;
        letter-spacing: 0.04em;

        @include media(m) {
          /* middle-size */
          font-size: 4.5rem;
          line-height: 1.2;

        }
      }


    }

    &__olist {
      @include transition;
      margin-top: 8rem;

      @include media(tb) {
        /* tablet-size */
        margin-top: 0;

      }

      @include media(m) {
        /* middle-size */
        margin-top: 8rem;
      }

      &__wrap {
        margin-right: 3.7rem;
        margin-left: 3.7rem;

        @include media(m) {
          /* middle-size */
          width: min(calc(100% - ($width-m * 2)), 1125px);
          margin: auto;

        }
      }

      &__item {
        @include transition;
        padding: 5rem 0;

        @include media(tb) {
          /* tablet-size */
          display: flex;
          flex-wrap: wrap;
          flex-direction: row-reverse;
          justify-content: space-between;
          align-items: center;
          flex-direction: row;
        }

        &:nth-of-type(1) {
          margin-top: 0;
          padding-top: 0;
        }

        &:nth-of-type(2n) {
          background: #F4F4F4;

          .p-mak03__olist__wrap {

            @include media(tb) {
              /* tablet-size */
              flex-direction: row;
            }
          }
        }

        &:last-of-type {
          padding-bottom: 0;
        }

        .p-mak03__olist__wrap {
          @include media(tb) {
            /* tablet-size */
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            flex-direction: row-reverse;
          }
        }

      }

      &__txtbox {
        @include transition;

        @include media(tb) {
          /* tablet-size */
          width: calc(100% / 2 - 3rem);
        }

        @include media(m) {
          /* middle-size */

        }
      }

      &__ttl {
        @include transition;
        @include fontOswald;
        font-weight: 400;
        font-size: 1.2rem;
        letter-spacing: 0.03em;
        color: #6A33C3;
        line-height: 1;

        @include media(tb) {
          /* tablet-size */
          font-size: 1.5rem;

        }

        @include media(m) {
          /* middle-size */
          font-size: 1.8rem;
        }
      }

      &__subttl {
        @include transition;

        font-weight: 700;
        font-size: 2rem;
        line-height: 1.4;
        margin-top: 1rem;

        @include media(tb) {
          /* tablet-size */
          font-size: 3.4rem;
          line-height: 1.1;
          margin-top: 2rem;
        }

        @include media(m) {
          /* middle-size */
          font-size: 3.4rem;
        }

        &-purple {
          color: #6A33C3;
        }

        &-small {
          font-size: 1.6rem;

          @include media(m) {
            /* middle-size */
            font-size: 2.2rem;

          }
        }

        &-pink {
          color: #F06565;
          line-height: 1.3;
        }
      }

      &__txt {
        @include transition;

        margin-top: 2rem;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.7;

        @include media(tb) {
          /* tablet-size */
          font-size: 1.6rem;
          line-height: 1.9;
          margin-top: 3rem;
        }

        @include media(m) {
          /* middle-size */
          margin-top: 5rem;
          padding-right: 10rem;

        }
      }

      &__img {
        @include transition;

        width: 100%;
        margin-top: 3rem;
        border-radius: 10px;

        @include media(tb) {
          /* tablet-size */
          width: calc(100% / 2);
          border: 8px solid #E4DFF1;
          margin-top: 0;


        }

        @include media(m) {
          /* middle-size */
          width: 52rem;

        }
      }
    }

    &__btnbox {
      margin-top: 6rem;
      display: flex;
      justify-content: center;

      @include media(m) {
        /* middle-size */
        width: min(calc(100% - ($width-m * 2)), 1125px);
        margin-right: auto;
        margin-left: auto;
        margin-top: 4rem;
        justify-content: end;

      }
    }

    &__btn {
      @include transition;
      @include fontsize(14, 18);
      background: #fff;
      padding: 2rem 2.5rem 2rem;
      border-radius: 10rem;
      display: block;
      font-weight: 700;
      border-style: solid;
      border-width: .2rem;
      border-color: #E4DFF1;
      position: relative;
      color: #fff;
      width: 30rem;
      color: #6A33C3;
      line-height: 1.3;

      @include media(tb) {
        /* tablet-size */
        width: 40rem;

      }

      @include media(m) {
        width: 45.4rem;
        padding: 3.8rem 4.3rem 3.8rem;
        border-width: 0.5rem;

      }

      &::after {
        @include transition;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        @include fontAwesome__sol;
        content: "\f054";
        right: 1.9rem;
        display: flex;
        align-items: center;
        color: #E4DFF1;

        @include media(m) {
          /* middle-size */
          right: 4.4rem;
          font-size: 2.3rem;
        }
      }

      &:hover {
        color: #fff;
        background-color: #6A33C3;

        &::after {
          color: #fff;

        }
      }
    }

  }

  &04 {
    @include transition;
    margin-right: 2rem;
    margin-left: 2rem;

    @include media(tb) {
      /* tablet-size */
      margin-right: 0;
      margin-left: 0;
    }

    @include media(m) {
      /* middle-size */
      width: min(calc(100% - ($width-m * 2)), 1125px);
      margin-right: auto;
      margin-left: auto;

    }

    &__outer {
      margin-top: 12rem;
      background-image: linear-gradient(180deg, #fff 1.5rem, #F2F2F2 1.5rem, rgba(239, 236, 241, 0) 6%);

      @include media(tb) {
        /* tablet-size */
        background-image: linear-gradient(180deg, #fff 3rem, #F2F2F2 3rem, rgba(239, 236, 241, 0) 17.13%);
      }

      @include media(m) {
        /* middle-size */
        margin-top: 13rem;
        background-image: linear-gradient(180deg, #fff 4.5rem, #F2F2F2 4.3%, rgba(239, 236, 241, 0) 17.13%);


      }
    }

    &__ttl {
      @include transition;
      color: #6A33C3;
      font-weight: 700;
      font-size: 2.1rem;
      line-height: 1.5;
      text-align: center;

      @include media(tb) {
        /* tablet-size */
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        flex-direction: row;
        align-items: baseline;
        font-size: 2.5rem;

      }

      @include media(m) {
        /* middle-size */
        font-size: 5.2rem;
        width: min(calc(100% - ($width-m * 2)), 1115px);
        margin-right: auto;
        margin-left: auto;
        justify-content: center;
        align-items: baseline;
        font-size: 3.5rem;

      }

      &-pink {
        @include transition;
        font-weight: 700;
        font-size: 3.3rem;
        color: #F06565;


        @include media(tb) {
          /* tablet-size */
          font-size: 3.7rem;
          margin: 0 1rem;
        }

        @include media(m) {
          /* middle-size */
          font-size: 5.5rem;
        }

      }

    }

    &__img {
      width: calc(100% - 4rem);
      margin-right: 2rem;
      margin-left: 2rem;
      margin-top: 4rem;
      display: block;


      @include media(m) {
        /* middle-size */
        margin-top: 6rem;

      }
    }

    &__ulist {
      @include transition;
      margin-top: 10rem;
      margin-right: 1.7rem;
      margin-left: 1.7rem;

      @include media(tb) {
        /* tablet-size */
        margin-right: 0;
        margin-left: 0;
        margin-top: 5rem;
        display: flex;
        justify-content: space-between;
      }

      @include media(m) {
        /* middle-size */
        width: min(calc(100% - ($width-m * 2)), 1115px);
        margin-right: auto;
        margin-left: auto;
        margin-top: 6rem;

      }

      &__item {
        @include transition;
        margin-top: 8rem;

        @include media(tb) {
          /* tablet-size */
          width: calc(100% / 3 - 2rem);
          margin-top: 0;
        }

        @include media(m) {
          /* middle-size */
          width: calc(100% / 3 - 2.5rem);

        }


        &:nth-of-type(1) {
          margin-top: 0;

        }

      }

      &__cat {
        display: block;
        width: 16rem;
        margin: auto;
        padding: 0.8rem 0;
        border-radius: 100px;
        background: #6A33C3;
        color: #FFFFFF;
        font-weight: 700;
        font-size: 1.4rem;
        letter-spacing: 0.05em;
        text-align: center !important;

        @include media(tb) {
          /* tablet-size */

        }

        @include media(m) {
          /* middle-size */
          width: 19.6rem;
          padding: 1rem 0;
          font-size: 1.7rem;

        }

        &.-wide {
          width: 20rem;

          @include media(tb) {
            width: 20rem;
          }

          @include media(m) {
            width: 25rem;
          }
        }

        &.-max {
          width: 28rem;

          @include media(tb) {
            width: 100%;
          }

          @include media(m) {
            width: 32rem;
          }
        }
      }


      &__ttl {
        @include transition;
        font-weight: 700;
        font-size: 2rem;
        margin-top: 1.5rem;
        text-align: center;

        @include media(tb) {
          /* tablet-size */
          font-size: 1.8rem;
        }

        @include media(m) {
          /* middle-size */
          margin-top: 2rem;
          font-size: 2.3rem;

        }

        &.-en {
          line-height: 1.3;

          @include media(tb) {
            min-height: 4.7rem;
          }

          @include media(m) {
            min-height: 6rem;
          }
        }
      }

      &__img {
        @include transition;
        border-radius: 30px;
        filter: drop-shadow(0px 8px 14px rgba(0, 0, 0, 0.25));
        margin-top: 2rem;
        width: 100%;

        @include media(tb) {
          /* tablet-size */

        }

        @include media(m) {
          /* middle-size */


        }
      }
    }

  }

  &05 {
    &__outer {
      @include transition;
      margin-top: 17rem;
      margin-bottom: 6rem;
      padding-bottom: 7rem;
      background-image: linear-gradient(180deg, #fff 2rem, #EAE6F5 2rem);

      @include media(tb) {
        /* tablet-size */
        padding-bottom: 4.5rem;
        background-image: linear-gradient(180deg, #fff 1.5rem, #EAE6F5 1.5rem);
      }

      @include media(m) {
        /* middle-size */
        margin-top: 16rem;
        padding-bottom: 9rem;
        background-image: linear-gradient(180deg, #fff 2rem, #EAE6F5 2rem);

      }
    }

    &__ttl {
      @include transition;
      text-align: center;


      @include media(tb) {
        /* tablet-size */
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;

      }

      @include media(m) {
        /* middle-size */

      }

      &.-en {
        @include media(tb) {
          flex-direction: column;
        }
      }

      &__large {
        font-size: 3.9rem;
        color: #6A33C3;
        margin-bottom: 3rem;
        font-weight: 700;

        @include media(tb) {
          /* tablet-size */
          margin-bottom: 0;
          font-size: 2.9rem;
          margin-right: 2rem;
        }

        @include media(m) {
          /* middle-size */
          font-size: 3.9rem;


        }

        &.-en {
          @include media(tb) {
            margin-right: 0;
            margin-bottom: 2rem;
          }
        }
      }

      &__small {
        display: inline;
        margin-top: 4rem;
        padding: 0 0 4px;
        background: linear-gradient(transparent 92%, #6A33C3 0%);
        color: #6A33C3;
        font-weight: 500;
        line-height: 2;
        font-size: 1.4rem;

        @include media(tb) {
          /* tablet-size */
          background: linear-gradient(transparent 97%, #6A33C3 0%);
          margin-top: 0;
          padding: 0 0px 2px;
        }

        @include media(m) {
          /* middle-size */
          font-size: 1.8rem;
        }
      }


    }

    &__ulist {
      margin-top: 6rem;

      @include media(tb) {
        /* tablet-size */
        display: flex;
        justify-content: space-between;
        margin-right: 3.7rem;
        margin-left: 3.7rem;
        margin-top: 3rem;
      }

      @include media(m) {
        /* middle-size */
        margin-top: 5rem;
        width: min(calc(100% - ($width-m * 2)), 1133px);
        margin-right: auto;
        margin-left: auto;
        margin-top: 2rem;

      }

      &__item {
        position: relative;

        @include media(tb) {
          /* tablet-size */
          width: calc(100% / 3 - 2rem);
        }

        @include media(m) {
          /* middle-size */
          width: calc(100% / 3 - 3.5rem);


        }

        &::after {
          @include fontAwesome__sol;
          content: "\f107";
          display: block;
          text-align: center;
          font-size: 3rem;
          margin: 2.5rem auto;
          color: #6a33c3;

          @include media(tb) {
            /* tablet-size */
            content: "\f105";
            position: absolute;
            top: 0;
            bottom: 0;
            right: -2.3rem;
            margin: auto;
            display: flex;
            align-items: center;
          }

          @include media(m) {
            /* middle-size */

            right: -3.5rem;
            font-size: 4rem;
          }
        }

        &:nth-of-type(1) {
          .p-mak05__ulist__box {
            &::before {
              background-image: url(../images/make/mak05_img01.png);
            }
          }
        }

        &:nth-of-type(2) {
          .p-mak05__ulist__box {
            &::before {
              background-image: url(../images/make/mak05_img02.png);
            }
          }
        }

        &:nth-of-type(3) {
          &::after {
            display: none;
          }

          .p-mak05__ulist__box {
            &::before {
              background-image: url(../images/make/mak05_img03.png);
            }


          }

        }

      }

      &__box {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 29rem;
        height: 29rem;
        margin: auto;
        position: relative;

        @include media(tb) {
          /* tablet-size */
          width: 22rem;
          height: 22rem;
        }

        @include media(m) {
          /* middle-size */
          width: 33rem;
          height: 33rem;
        }

        &::before {
          content: "";
          display: block;
          position: absolute;
          width: 29rem;
          height: 29rem;
          background-position: 0 50%;
          background-repeat: no-repeat;
          background-size: 100%;
          filter: drop-shadow(2px 4px 16px rgba(103, 103, 103, 0.25));

          @include media(tb) {
            /* tablet-size */
            width: 22rem;
            height: 22rem;
            display: flex;
            align-items: center;
          }

          @include media(m) {
            /* middle-size */
            width: 33rem;
            height: 33rem;
          }
        }
      }

      &__cat {
        position: absolute;
        font-weight: 700;
        font-size: 2rem;
        line-height: 1.8;
        text-align: center !important;
        color: #FFFFFF;

        @include media(tb) {
          /* tablet-size */

          font-size: 1.8rem;
        }

        @include media(m) {
          /* middle-size */
          font-size: 2.6rem;
          line-height: 1.4;

        }
      }
    }
  }




  &08 {
    @include transition;

    padding: 4.5rem 1.6rem 4.3rem;

    @include media(tb) {
      /* tablet-size */
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    @include media(m) {
      /* middle-size */

    }

    &__outer {
      @include transition;

      margin-top: 5rem;
      background-image: url(../images/top/abu08_bg-sp.jpg);
      background-position: 0 50%;
      background-repeat: no-repeat;
      background-size: 100%;

      @include media(tb) {
        /* tablet-size */
        background-image: url(../images/top/abu08_bg-pc.jpg);
        background-position: 60% 50%;
        background-size: 164rem;

      }

      @include media(m) {
        /* middle-size */
        width: min(calc(100% - ($width-m * 2)), 1215px);
        min-height: 53.4rem;
        margin-right: auto;
        margin-left: auto;
        background-size: contain;
        background-position: 50% 50%;
        padding: 5rem;
      }
    }

    &__lead {
      &__txt {
        @include transition;

        display: inline;
        padding: 0 0px 1.1rem;
        background: linear-gradient(transparent 92%, #fff 0%);
        color: #FFFFFF;
        font-weight: 700;
        font-size: 2.4rem;
        line-height: 2;
        letter-spacing: 0.05em;
        position: relative;
        left: 2rem;

        @include media(tb) {
          /* tablet-size */
          font-size: 3.2rem;
          line-height: 2.4;
          background: linear-gradient(transparent 94%, #fff 0%);
          padding: 0 0 2rem;
        }

        @include media(m) {
          /* middle-size */
          font-size: 4rem;
          background: linear-gradient(transparent 96%, #fff 0%);
          line-height: 2.2;
        }
      }

      @include media(tb) {
        /* tablet-size */
        width: calc(100% / 2 - 5rem);

      }

      @include media(m) {
        /* middle-size */
      }
    }

    &__box {
      @include transition;

      margin-top: 3.5rem;
      box-shadow: 2px 7px 12px 3px rgba(131, 123, 153, 0.25);
      border-radius: 10px;
      padding: 3rem 1.7rem;
      background: rgba(255, 255, 255, 0.85);

      @include media(tb) {
        /* tablet-size */
        width: calc(100% / 2 - 5rem);
        margin-top: 0;

      }

      @include media(m) {
        /* middle-size */
        width: 43.8rem;
        padding: 2rem 2.7rem 4rem;

      }
    }

    &__txtbox {
      @include transition;

      position: relative;
      padding: 1.5rem 0;
      border-bottom: .2rem solid #7147A0;
      line-height: 1.6;
      text-align: center;

      @include media(tb) {
        /* tablet-size */

      }

      @include media(m) {
        /* middle-size */
        line-height: 1.4;
        padding: 1.5rem 0 2.5rem
      }

      span {
        letter-spacing: 0.03em;
        font-weight: 700;

      }

      &__style01 {
        @include transition;

        font-size: 2.2rem;
        color: #7147A0;

        @include media(tb) {
          /* tablet-size */

        }

        @include media(m) {
          /* middle-size */
          font-size: 2.6rem;
        }
      }

      &__style02 {
        @include transition;

        font-size: 1.6rem;
        color: #2D313D;

        @include media(tb) {
          /* tablet-size */

        }

        @include media(m) {
          /* middle-size */
          font-size: 1.9rem;
        }

      }

      &__style03 {
        @include transition;

        font-size: 2.5rem;
        color: #7E53A3;

        @include media(tb) {
          /* tablet-size */

        }

        @include media(m) {
          /* middle-size */
          font-size: 3.2rem;
        }

      }

      &__style04 {
        @include transition;

        font-size: 1.4rem;
        color: #2D313D;

        @include media(tb) {
          /* tablet-size */

        }

        @include media(m) {
          /* middle-size */
          font-size: 1.9rem;
        }
      }

      &::before,
      &::after {
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 14px 10px 0 10px;
        content: "";
      }

      &::before {
        bottom: -1.4rem;
        border-color: #7147A0 transparent transparent transparent;
      }

      &::after {
        bottom: -1rem;
        border-color: #f3f2f0 transparent transparent transparent;
      }
    }

    &__logo {
      @include transition;
      display: block;
      width: 80%;
      margin: auto;
      margin-top: 2.5rem;

      @include media(tb) {
        /* tablet-size */
      }

      @include media(m) {
        /* middle-size */
        width: 100%;
        margin-top: 3.5rem;
      }
    }

    &__btn {
      @include transition;
      background: linear-gradient(92.6deg, #7046A0 10.21%, #7349A1 15.35%, #A273AC 100%);
      border-radius: 10rem;
      font-weight: 700;
      color: #fff;
      width: 30rem;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: 2rem auto 0;
      padding: 1.3rem 0 1.3rem 1.5rem;
      font-size: 1.4rem;
      letter-spacing: 0.02em;
      box-shadow: 0px 0px 8px 2px rgba(84, 56, 165, 0.43);

      @include media(m) {
        /* middle-size */
        width: 100%;
        font-size: 1.6rem;
        padding: 1.6rem 0 1.6rem 1.5rem;
        margin-top: 3rem;
      }

      &:hover {
        @include brightness;
      }

      &::before {
        @include transition;
        @include fontAwesome__sol;
        content: "\f138";
        color: #fff;
        font-size: 2.9rem;
        margin-right: 1.8rem;

        @include media(m) {
          /* middle-size */
          font-size: 3.7rem;

        }
      }

    }

  }
}

//make - サービス
.p-mfun {
  &__h1 {
    font-size: 2.5rem;
    line-height: 1.6;

    span {
      @include textgradient;
      background-image: linear-gradient(88.15deg, #9717B6 2.31%, #6E28AE 101.86%);
    }
  }

  &__wrap {
    margin-top: 5rem;

    @include media(tb) {
      /* tablet-size */
      display: flex;
      justify-content: space-between;
    }

    @include media(m) {
      /* middle-size */
      align-items: center;

    }
  }

  &__txt {
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 2;
    text-align: justify;

    @include media(tb) {
      /* tablet-size */
      width: calc(100% / 2 - 2rem);
    }

    @include media(m) {
      /* middle-size */
      font-size: 1.9rem;
      width: calc(100% - 72rem);
      line-height: 2.2;
    }

    p {
      @include media(tb) {
        /* tablet-size */
        margin-top: 2rem;
      }

      @include media(m) {
        /* middle-size */
        margin-top: 6rem;


      }

      &:nth-of-type(1) {
        @include media(tb) {
          /* tablet-size */
          margin-top: 0;
        }

        @include media(m) {
          /* middle-size */
          margin-top: 0;

        }
      }
    }

    span {
      color: #6a33c3;
    }
  }


  &__box {
    @include media(tb) {
      /* tablet-size */
      width: calc(100% / 2 - 1rem);
    }

    @include media(m) {
      /* middle-size */
      width: 72rem;

    }

    &__img {
      width: 100%;
      margin-top: 4rem;

      @include media(tb) {
        /* tablet-size */
        width: 80%;
        margin: 0 auto;
        display: block;

      }

      @include media(m) {
        /* middle-size */
        width: calc(100% - 4rem);

      }
    }

    &__txt {
      background: #FFFFFF;
      box-shadow: 0px 4px 13px rgba(133, 133, 133, 0.25);
      border-radius: 7px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 2rem 4rem;
      margin-top: 1.5rem;

      @include media(tb) {
        /* tablet-size */
        padding-left: 2rem;
        padding-right: 2rem;

      }

      @include media(m) {
        /* middle-size */
        padding: 3rem 2.5rem;
        border-radius: 15px;
        margin-top: -0.5rem;
        z-index: -1;
        position: relative;

      }

      p {
        font-size: 1.4rem;
        font-weight: 600;
        line-height: 1.9;

        @include media(tb) {
          /* tablet-size */
          font-size: 1.2rem;

        }

        @include media(m) {
          /* middle-size */
          font-size: 1.4rem;


        }


      }
    }
  }

  &01 {
    margin-right: 3.7rem;
    margin-left: 3.7rem;

    @include media(m) {
      /* middle-size */
      width: min(calc(100% - ($width-m * 2)), 1215px);
      margin-right: auto;
      margin-left: auto;

    }

    &__outer {
      margin-top: 9rem;
    }

    &__ulist {
      @include media(tb) {
        /* tablet-size */

      }

      @include media(m) {
        /* middle-size */

      }

      &__item {
        margin-top: 8rem;

        @include media(tb) {
          /* tablet-size */
          display: flex;
          align-items: flex-start;
          margin-top: 8rem;

        }

        @include media(m) {
          /* middle-size */
          margin-top: 10rem;


        }

        &:nth-of-type(1) {
          margin-top: 0;

          @include media(tb) {
            /* tablet-size */
            justify-content: flex-start;

          }

          @include media(m) {
            /* middle-size */

          }

          >img {
            @include media(tb) {
              /* tablet-size */
              margin-right: 3rem;

            }

            @include media(m) {
              /* middle-size */
              margin-right: 6rem;

            }

          }

        }

        &:nth-of-type(2) {

          @include media(tb) {
            /* tablet-size */
            justify-content: flex-start;
            flex-direction: row-reverse;

          }

          @include media(m) {
            /* middle-size */
          }

          >img {
            @include media(tb) {
              /* tablet-size */
              margin-left: 3rem;

            }

            @include media(m) {
              /* middle-size */
              margin-left: 6rem;

            }

          }


        }

      }

      &__box {
        @include media(tb) {
          /* tablet-size */
          width: calc(100% / 2 - 3rem);
        }

        @include media(m) {
          /* middle-size */
          width: 45rem;


        }
      }

      &__ttl {
        font-weight: 700;
        font-size: 2rem;
        line-height: 1.5;
        letter-spacing: 0.03em;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        @include media(tb) {
          /* tablet-size */

        }

        @include media(m) {
          /* middle-size */
          font-size: 3rem;
        }
      }

      &__txt {
        margin-top: 2rem;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.7;

        @include media(tb) {
          /* tablet-size */

        }

        @include media(m) {
          /* middle-size */
          margin-top: 3rem;
          font-size: 1.6rem;
          line-height: 1.9;

        }
      }

      &__img {
        margin-top: 2rem;
        width: 100%;

        @include media(tb) {
          /* tablet-size */
          width: calc(100% / 2 - 3rem);
          margin-top: 0;
          object-fit: contain
        }

        @include media(m) {
          /* middle-size */
          width: 61.8rem;

        }


      }

      &__btn {
        @include brightness;
        @include transition;
        font-size: 1.4rem;
        margin-top: 3rem;
        border-radius: 10rem;
        display: block;
        font-weight: 600;
        border-style: solid;
        border-width: .2rem;
        position: relative;
        color: #fff;
        line-height: 1.3;

        @include media(tb) {
          /* tablet-size */
          margin-top: 2rem;
          width: 80%;
          padding: 2rem 0 2rem 7%;
        }

        @include media(m) {
          width: 30rem;
          margin-top: 4rem;
          padding: 2.1rem 0 2.1rem 3.3rem;

        }

        &-purple {
          @extend .p-mfun01__ulist__btn;
          background-image: linear-gradient(271.74deg, #5D2899 2.97%, #A678DB 96.4%);
          padding: 2rem 0 2rem 7.5rem;

          &.-en {
            padding: 2rem 0 2rem 3.5rem;
          }
        }

        &-gradient {
          @extend .p-mfun01__ulist__btn;
          background-image: linear-gradient(93.4deg, #007DFF 9.19%, #6E27B4 41.73%, #E3047D 80.03%, #EF536F 102.05%);
          padding: 2rem 0 2rem 3.5rem;

        }



        &::before,
        &::after {
          @include transition;
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;

        }

        &::before {
          @include transition;

          display: block;
          right: 6.5rem;
          width: 0.1rem;
          height: calc(100% - 2.2rem);
          content: "";
          background-color: #fff;

          @include media(tb) {
            /* tablet-size */
            right: 4.5rem;
          }

          @include media(m) {
            /* tablet-size */
            height: calc(100% - 3rem);
            right: 5.5rem;
          }
        }

        &::after {
          @include transition;

          @include fontAwesome__sol;
          content: "\f054";
          right: 2.6rem;
          display: flex;
          align-items: center;
        }



      }

      &__logo {
        height: 2.2rem;

        &.-en {
          vertical-align: baseline;
          margin-left: 1rem;
        }
      }
    }
  }

  &02 {
    @include media(m) {
      /* middle-size */
      width: min(calc(100% - ($width-m * 2)), 1215px);
      margin-right: auto;
      margin-left: auto;

    }

    &__outer {
      margin-top: 9rem;
      padding-bottom: 4rem;
      background: linear-gradient(11.16deg, #F7F7F7 4%, #F8F8F8 49.02%, #FFFFFF 94.04%);

      @include media(m) {
        /* middle-size */
        padding-bottom: 17rem;
        background: linear-gradient(360deg, #F5F5F5 0%, rgba(245, 241, 255, 0) 100%)
      }
    }

    &__box01 {
      margin-right: 1.5rem;
      margin-left: 1.5rem;
      padding: 5rem 2.5rem 4rem;
      background: #FFFFFF;
      box-shadow: 0px 4px 13px rgba(133, 133, 133, 0.25);
      border-radius: 7px;

      @include media(tb) {
        /* tablet-size */
        margin-right: 3.7rem;
        margin-left: 3.7rem;
        padding: 4rem 7rem 2.5rem;

        border-radius: 25px;
      }

      @include media(m) {
        /* middle-size */
        border-radius: 50px;
        margin-top: 12rem;
        margin-right: 0;
        margin-left: 0;
      }

      &__img {
        width: 100%;
      }
    }

    &__box02 {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 5rem;

      @include media(m) {
        /* middle-size */
        flex-direction: row;
        align-items: center;
        justify-content: center;

      }

      &__txt {
        font-weight: 700;
        font-size: 1.9rem;
        line-height: 1.6;
        text-align: center !important;
        color: #6a33c3;
        margin-left: $width-m;
        margin-right: $width-m;

        @include media(tb) {
          /* tablet-size */

        }

        @include media(m) {
          /* middle-size */
          text-align: left !important;
          margin-left: 0;
          margin-right: 5.3rem;
        }

        span {
          color: #f06565;
        }

      }

      &__btn {
        @include transition;
        @include brightness;
        background-color:
          #6A33C3;
        border-radius: 10rem;
        font-weight: 700;
        color: #fff;
        width: 30rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: 3rem auto 0;
        font-size: 1.4rem;
        letter-spacing: 0.02em;
        padding: 1.8rem 1.5rem 1.8rem 0;

        @include media(m) {
          /* middle-size */
          margin: 0;
          width: 44.7rem;
          font-size: 1.8rem;
          padding: 3rem 3.3rem 3rem 4rem;
          justify-content: space-between;
        }

        &::after {
          @include transition;
          @include fontAwesome__sol;
          content: "\f138";
          color: #fff;
          font-size: 2.9rem;
          margin-left: 1.8rem;

          @include media(m) {
            /* middle-size */
            font-size: 3.7rem;

          }
        }

        span {
          margin-right: 2rem;

          @include media(tb) {
            margin-right: 0;
          }
        }
      }

    }
  }

  &03 {
    @include media(tb) {
      /* tablet-size */

    }

    @include media(m) {
      /* middle-size */
      width: min(calc(100% - ($width-m * 2)), 1215px);
      margin-right: auto;
      margin-left: auto;

    }

    &__outer {
      padding-top: 6rem;

      @include media(tb) {
        /* tablet-size */

      }

      @include media(m) {
        /* middle-size */
        padding-top: 16rem;
      }
    }

    &__ttl {
      color: #6a33c3;
      text-align: center;
      font-weight: 700;
      font-size: 2.4rem;

      @include media(tb) {
        /* tablet-size */
        font-size: 3.1rem;

      }

      @include media(m) {
        /* middle-size */
        font-size: 3.8rem;

      }
    }

    &__txt {
      margin-top: 2rem;
      font-weight: 700;
      font-size: 1.4rem;
      text-align: center !important;
      line-height: 1.7;
      margin-right: 2.2rem;
      margin-left: 2.2rem;

      @include media(tb) {
        /* tablet-size */

      }

      @include media(m) {
        /* middle-size */
        font-size: 1.8rem;
      }

    }

    &__ulist {
      margin-top: 3.5rem;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      @include media(tb) {
        /* tablet-size */
        display: flex;
        justify-content: space-between;
        margin-right: 3.7rem;
        margin-left: 3.7rem;
      }

      @include media(m) {
        /* middle-size */

      }

      &__item {
        background: #FFFFFF;
        box-shadow: 2px 7px 20px 2px rgba(0, 0, 0, 0.08);
        border-radius: 10px;
        padding: 3rem 2rem 4rem;
        margin-top: 2rem;

        @include media(tb) {
          /* tablet-size */
          box-shadow: 2px 7px 20px 2px rgba(0, 0, 0, 0.08);
          border-radius: 10px;
          margin-top: 0;
          width: calc(100% / 3 - 1rem);
          padding: 2rem;

        }

        @include media(m) {
          /* middle-size */
          padding: 3rem;
        }

        &:nth-of-type(1) {
          margin-top: 0;

        }
      }

      &__img {
        width: 100%;
      }

      &__box {
        margin-top: 3rem;
        margin-right: 2rem;
        margin-left: 2rem;

        @include media(tb) {
          /* tablet-size */
          margin-right: 0;
          margin-left: 0;
        }

        @include media(m) {
          /* middle-size */

        }
      }

      &__ttl {
        font-weight: 700;
        font-size: 2rem;
        color: #7349A0;

        @include media(tb) {
          /* tablet-size */
          font-size: 1.8rem;
        }

        @include media(m) {
          /* middle-size */
          font-size: 2.2rem;

        }
      }

      &__txt {
        margin-top: 1rem;
        font-weight: 600;
        font-size: 1.4rem;
        line-height: 2;

        @include media(tb) {
          /* tablet-size */
        }

        @include media(m) {
          /* middle-size */
          font-size: 1.6rem;
          margin-top: 2rem;
        }

      }

    }
  }

  &04 {
    background: #FFFFFF;
    box-shadow: 2px 7px 20px 2px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    margin-right: 1.5rem;
    margin-left: 1.5rem;
    padding: 5rem 2.3rem;

    @include media(tb) {
      /* tablet-size */
      margin-right: 3.7rem;
      margin-left: 3.7rem;
    }

    @include media(m) {
      /* middle-size */
      width: min(calc(100% - ($width-m * 2)), 1127px);
      margin-right: auto;
      margin-left: auto;
      box-shadow: 8px 10px 33px rgba(0, 0, 0, 0.08);
      border-radius: 30px;
      padding: 7rem 8rem;
    }

    &__outer {
      margin-top: 7rem;
      padding-top: 27rem;
      padding-bottom: 7rem;
      background-image: url(../../assets/images/make/function/fun04_bg-sp.jpg);
      background-position: 50% 0px;
      background-size: 100%;
      background-repeat: no-repeat;

      @include media(tb) {
        /* tablet-size */
        padding-top: 14rem;
        background-image: url(../../assets/images/make/function/fun04_bg-pc.jpg);

      }

      @include media(m) {
        /* middle-size */
        padding-top: 28.3rem;
        padding-bottom: 15.2rem;
        background-image: url(../../assets/images/make/function/fun04_bg-pc.jpg);
        background-size: 144rem;
      }
    }

    &__ttl {
      text-align: center;
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 1.5;

      @include media(tb) {
        /* tablet-size */

      }

      @include media(m) {
        /* middle-size */
        font-size: 3rem;
      }

      p,
      h2 {
        text-align: center;
      }

      p {
        font-size: 1.4rem;

        @include media(tb) {
          /* tablet-size */

        }

        @include media(m) {
          /* middle-size */
          font-size: 2rem;
        }
      }

      h2 {
        margin-top: 1rem;

        @include media(m) {
          /* middle-size */
          margin-top: 0;
        }
      }


      &-gradation {
        @extend .p-mfun04__ttl;
        @include textgradient;
        background-image: linear-gradient(90deg, #AA00D5 12.91%, #3F00C4 55.14%);
        font-size: 2.7rem;

        @include media(tb) {
          /* tablet-size */

        }

        @include media(m) {
          /* middle-size */
          font-size: 4.5rem;

        }
      }

      &-navy {
        @extend .p-mfun04__ttl;
        color: #2100A5;
        font-size: 2.7rem;

        @include media(tb) {
          /* tablet-size */

        }

        @include media(m) {
          /* middle-size */
          font-size: 4.5rem;

        }
      }
    }

    &__wrap {
      @include media(tb) {
        /* tablet-size */
        display: flex;
        justify-content: space-around;
        align-items: baseline;
        margin-top: 4rem;

      }

      @include media(m) {
        /* middle-size */
        justify-content: space-between;

      }
    }

    &__ulist {
      margin-top: 4rem;

      @include media(tb) {
        /* tablet-size */
        display: flex;
        justify-content: space-between;
        margin-top: 0;

      }

      @include media(m) {
        /* middle-size */

      }

      &__item {
        margin-top: 3rem;
        text-align: center;

        @include media(tb) {
          /* tablet-size */
          margin-top: 0;
          width: calc(100% / 2 - 1rem);
        }

        @include media(m) {
          /* middle-size */
          width: 21rem;

        }

        &:nth-of-type(1) {
          margin-top: 0;

          img {
            height: 22rem;

            @include media(tb) {
              /* tablet-size */
              height: auto;

            }
          }
        }

        &:nth-of-type(2) {
          @include media(m) {
            /* middle-size */
            margin-left: 5rem;
          }

          img {
            height: 15.5rem;

            @include media(tb) {
              /* tablet-size */
              height: auto;

            }
          }
        }
      }

      &__img {

        @include media(tb) {
          /* tablet-size */
          width: 100%;
          object-fit: contain;
        }

        @include media(m) {
          /* middle-size */

        }
      }

      &__txt {
        margin-top: 1rem;
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 1.4;
        text-align: center !important;
        letter-spacing: 0.06em;
        color: #000000;

        @include media(tb) {
          /* tablet-size */
          font-size: 1.2rem;

        }

        @include media(m) {
          /* middle-size */

        }
      }


    }

    &__box {
      margin-top: 6rem;
      // margin-right: 2rem;
      // margin-left: 2rem;

      @include media(tb) {
        /* tablet-size */
        margin-top: 0;
        margin-right: 0;
        margin-left: 5rem;

      }

      @include media(m) {
        /* middle-size */
        margin-left: 0;

        width: 41.2rem;
      }

      &__txt {
        font-weight: 600;
        font-size: 1.4rem;
        line-height: 2;
        margin-right: 1rem;
        margin-left: 1rem;
      }

      &__btn {
        @include brightness;
        @include transition;
        font-size: 1.4rem;
        margin-top: 3rem;
        border-radius: 10rem;
        display: block;
        font-weight: 600;
        border-style: solid;
        border-width: .2rem;
        position: relative;
        color: #fff;
        margin-top: 4rem;
        padding: 1rem 2rem;
        background-image: linear-gradient(91.16deg, #6024AD 42.75%, #B58FE1 105.93%);
        line-height: 1.5;

        @include media(tb) {
          /* tablet-size */
          margin-top: 2rem;
          padding: 2rem 0 2rem 1.6rem;
        }

        @include media(m) {
          width: 100%;
          margin-top: 4rem;
          padding: 2.6rem 0 2.6rem 3.3rem
        }

        &::before,
        &::after {
          @include transition;
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;

        }

        &::before {
          @include transition;
          display: block;
          right: 5.5rem;
          width: 0.1rem;
          height: calc(100% - 3.4rem);
          content: "";
          background-color: #fff;

          @include media(tb) {
            /* tablet-size */
            right: 4rem;
          }


          @include media(m) {
            /* tablet-size */
            height: calc(100% - 3rem);
          }
        }

        &::after {
          @include transition;

          @include fontAwesome__sol;
          content: "\f054";
          right: 2.6rem;
          display: flex;
          align-items: center;

          @include media(tb) {
            /* tablet-size */
            right: 1.6rem;

          }
        }


      }

    }

  }
}

//make - お問い合わせ
.p-mcon {
  &__h1 {
    font-size: 2.5rem;
    line-height: 1.6;

    span {
      @include textgradient;
      background-image: linear-gradient(88.15deg, #9717B6 2.31%, #6E28AE 101.86%);
    }
  }

  &01 {

    &__outer {
      margin-top: 4rem;
      padding-bottom: 4rem;

      @include media(tb) {
        /* tablet-size */
        margin-top: 6rem;

      }

      @include media(m) {
        /* middle-size */
        margin-top: 8rem;

      }
    }

  }

}





.nurse {
  &01 {
    padding: 6rem 0 0;

    @include media(tb) {}

    @include media(m) {
      padding: 11rem 0 0;

    }

    &__outer {
      margin-top: 4rem;
      padding: 3.5rem;
      background-color: #F0F0F0;

      @include media(tb) {
        margin-top: 2.5rem;
        background: linear-gradient(to bottom, #fff 50%, #F0F0F0 50%);
      }

      @include media(m) {
        margin-top: 8rem;
        padding: 0 0 18rem 0;
        background: linear-gradient(to bottom, #fff 25%, #F0F0F0 25%);

      }

    }

    &__ttl {
      text-align: center;
      font-size: 2rem;
      font-weight: bold;
      line-height: 1.4;

      @include media(tb) {
        font-size: 3.1rem;

      }

      @include media(m) {
        font-size: 4.2rem;
      }
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;

      @include media(tb) {
        flex-direction: row;
        justify-content: space-between;
      }

      @include media(m) {
        margin-left: auto;
        margin-right: auto;
        width: min(calc(100% - ($width-m * 2)), 1140px);
      }


    }

    &__item {
      margin-top: 4.5rem;
      display: flex;
      align-items: center;
      position: relative;

      &:first-of-type {
        margin-top: 0;
      }

      @include media(tb) {
        width: calc(100% / 3 - 2rem);
        margin-top: 0;
        display: flex;
        flex-direction: column;

      }

      @include media(m) {
        width: calc(100% / 3 - 2rem);
      }
    }

    &__txt {
      line-height: 1.5;
      margin-left: 1.5rem;
      font-weight: bold;

      @include media(tb) {
        font-size: 1.6rem;
        text-align: center;
        margin-left: 0;
        margin-top: 3rem;
      }

      @include media(m) {
        font-size: 2rem;

      }

    }

    &__img {
      width: 30%;
      position: relative;

      @include media(tb) {
        width: 100%;
      }

      @include media(m) {
        width: 27.7rem;
      }

      &::before {
        display: block;
        content: "";
        position: absolute;
        background-image: url(../images/nurse/nur01_img04.png);
        top: -1.5rem;
        left: -1.9rem;
        width: 4.5rem;
        height: 5.2rem;
        background-size: cover;
        background-repeat: no-repeat;
        z-index: 1;

        @include media(tb) {
          top: -1.7rem;
          left: -2.7rem;
          width: 9.1rem;
          height: 10.3rem;
        }

        @include media(m) {}
      }

      img {
        width: 100%;
      }
    }


  }

  &02 {
    padding: 6rem 0;

    @include media(tb) {}

    @include media(m) {}

    &__outer {
      background-image: url(../images/nurse/nur02_bg01.png);
      background-size: contain;
      background-position: 50% 0;
      background-repeat: no-repeat;
      content: "";
      padding-top: 16rem;

      @include media(tb) {}

      @include media(m) {
        background-image: url(../images/nurse/nur02_bg01_pc.png);
        background-position: 50% 0;
        background-size: 1440px;
        padding-top: 24.4rem;
      }

      @include media(l) {
        background-size: contain;

      }


    }

    &__inner {
      background: #FFFFFF;
      box-shadow: 8px 10px 33px rgba(0, 0, 0, 0.08);
      border-radius: 36px;
      padding: 4.5rem 2.5rem 4rem;

      @include media(tb) {
        padding: 10rem 5rem 5rem;

      }

      @include media(m) {
        padding: 15rem 10rem 6rem;

      }
    }

    &__ttl {
      @include fontsize(20, 36);
      font-weight: bold;
      line-height: 1.4;
      text-align: center;

      @include media(tb) {}

      @include media(m) {
        font-size: 5.4rem;

      }
    }

    &__txt {
      line-height: 1.5;
      font-weight: bold;
      margin-top: 2rem;
      text-align: center;

      @include media(tb) {
        line-height: 2;

      }

      @include media(m) {
        margin-top: 4rem;
        font-size: 2rem;

      }
    }

    &__list {
      margin-top: 4rem;

      @include media(tb) {
        margin-top: 6rem;
      }

      @include media(m) {
        margin-top: 8rem;
      }
    }

    &__item {
      margin-top: 4.5rem;
      display: flex;
      align-items: center;
      position: relative;
      flex-wrap: wrap;
      flex-direction: column;

      @include media(tb) {
        align-items: flex-start;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 6rem;
      }

      @include media(m) {
        margin-top: 8rem;

      }

      &:first-of-type {
        margin-top: 0;
      }

      &01 {
        .nurse02__box {
          em {
            color: #00a2fa;
          }

          h3 {
            color: #00a2fa;
          }
        }
      }

      &02 {
        .nurse02__box {
          em {
            color: #E93A83;
          }

          h3 {
            color: #E93A83;
          }
        }
      }
    }

    &__box {
      display: flex;

      @include media(tb) {
        flex-wrap: wrap;
        flex-direction: column;
        width: 57.5%;
      }

      @include media(m) {}

      >em {
        @include fontsize(54, 100);
        @include fontBarlow;
        font-weight: 400;
        transform: translateY(-0.8rem);

        @include media(tb) {
          font-size: 7.7rem;
        }

        @include media(m) {}
      }

      >div {
        margin-left: 1.5rem;

        @include media(tb) {
          margin-left: 0;
        }

        @include media(m) {}

        >h3 {
          font-weight: 600;
          line-height: 1.4;
          @include fontsize(14, 18);

          @include media(tb) {}

          @include media(m) {
            font-size: 2.3rem;

          }

          span {
            @include fontsize(20, 31);
            display: block;

            @include media(tb) {
              margin-top: .5rem;
            }

            @include media(m) {
              font-size: 4.2rem;

            }
          }
        }

        >p {
          line-height: 2rem;

          @include media(tb) {
            line-height: 2.4rem;
            margin-top: 1.5rem;
          }

          @include media(m) {
            margin-top: 2rem;

          }
        }
      }
    }

    &__img {
      margin-top: 2rem;

      @include media(tb) {
        margin-top: 0;
        width: 37%;
      }

      @include media(m) {}

      img {
        width: 100%;
      }
    }

    &__img2 {
      margin-top: 2rem;

      @include media(tb) {
        margin-top: 0;
        width: 23%;
      }

      @include media(m) {}

      img {
        width: 100%;
      }
    }

    &__card {
      border-top: 1px solid #ECECEC;
      margin-top: 2rem;
      width: 100%;

      @include media(tb) {
        margin-top: 3.5rem;
        display: flex;
        justify-content: space-between;
        border-top: none;
        border-right: 1px solid #ECECEC;
      }

      @include media(m) {}

      >li {
        border-bottom: 1px solid #ECECEC;

        @include media(tb) {
          border-bottom: none;
          border-left: 1px solid #ECECEC;

        }

        @include media(m) {}

        >a {
          @include opacity;
          @include transition;
          display: flex;
          align-items: center;
          padding: 2rem 1rem;
          position: relative;

          @include media(tb) {
            flex-direction: column;
            justify-content: center;
            padding: 1rem 2rem 3rem;
          }

          @include media(m) {}

          &::after {
            position: absolute;
            content: "";
            display: block;
            right: 2.5rem;
            top: 0;
            bottom: 0;
            margin: auto;
            background-image: url(../images/nurse/nur02_ico01.png);
            background-size: cover;
            background-repeat: no-repeat;
            content: "";
            width: 2.5rem;
            height: 2.5rem;

            @include media(tb) {
              position: inherit;
              top: inherit;
              right: 0;
              margin-top: 1rem;
            }

            @include media(m) {}
          }

          img {
            width: 9.4rem;
            border-radius: 1.8rem;

            @include media(tb) {
              width: 100%;
              border-radius: 2.1rem;
            }

            @include media(m) {
              border-radius: 2.4rem;

            }
          }

          >p {
            margin-left: 2.5rem;
            font-weight: bold;

            line-height: 1.4;

            @include media(tb) {
              margin-left: 0;
              text-align: center;
              margin-top: 1.5rem;
            }

            @include media(m) {}
          }

        }
      }
    }
  }

  &03 {
    margin: 0 2rem;

    &__outer {
      padding: 6rem 0;

      @include media(tb) {
        padding: 10rem 0;
      }

      @include media(m) {
        padding: 15rem 0 11rem;
      }
    }

    &__ttl {
      @include fontsize(20, 31);
      font-weight: bold;
      line-height: 1.4;
      text-align: center;

      @include media(tb) {}

      @include media(m) {
        font-size: 4.2rem;
      }
    }

    &__list {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-top: 4rem;

      @include media(tb) {
        margin-top: 6rem;
      }

      @include media(m) {
        margin-top: 8rem;
        margin-left: auto;
        margin-right: auto;
        width: min(calc(100% - ($width-m * 2)), 1344px);
      }
    }

    &__item {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      margin-top: 5rem;

      @include media(tb) {
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }

      @include media(m) {
        margin-top: 4rem;
      }

      &:first-of-type {
        margin-top: 0;
      }

      &-01 {

        .nurse03__img {
          @include media(tb) {}
        }

        .nurse03__txtbox--num {
          color: #42A3E9;
        }
      }

      &-02 {
        background: #fff;

        @include media(tb) {
          margin-top: 8rem;
          flex-direction: row-reverse;
        }

        .nurse03__img {
          margin-left: auto;
        }

        .nurse03__txtbox {

          @include media(tb) {
            padding: 0 3rem 0 0;
          }

          @include media(m) {
            padding: 0 8rem 0 0;
          }
        }

        .nurse03__txtbox--num {
          color: #9C1FC1;
        }

      }

      &-03 {
        @include media(tb) {}

        @include media(m) {}

        .nurse03__txtbox--num {
          color: #E93A83;
        }
      }

    }

    &__img {
      width: 100%;
      object-fit: contain;
      border-radius: 5.4rem;

      @include media(tb) {
        width: 54.5%;
      }

      @include media(m) {
        border-radius: 10rem;
      }
    }

    &__txtbox {
      padding: 3rem 3rem 0;

      @include media(tb) {
        width: 45.4%;
        padding: 0 0 0 3rem;
      }

      @include media(m) {
        padding-left: 8rem;
      }

      &--num {
        @include fontsize(16, 20);
        @include fontOswald;
        font-weight: bold;
        letter-spacing: 1px;

        @include media(tb) {}

        @include media(m) {
          font-size: 2.5rem;
        }

        span {
          @include fontsize(27, 33);

          @include media(tb) {}

          @include media(m) {
            font-size: 4rem;
          }
        }
      }

      &--ttl {
        line-height: 1.5;
        font-weight: bold;
        margin-top: 1.5rem;
        @include fontsize(18, 24);

        @include media(tb) {
          line-height: 1.3;
          margin-top: 2rem;
        }

        @include media(m) {
          margin-top: 3rem;
          font-size: 3rem;
        }
      }

      &--txt {
        line-height: 1.75;
        margin-top: 1rem;

        @include media(tb) {
          width: 100%;
          margin-top: 2rem;
        }

        @include media(m) {
          margin-top: 3rem;
        }
      }
    }
  }

  &04 {

    &__outer {
      padding: 6rem 0;
      background-color: #F0F0F0;

      @include media(tb) {
        padding: 8rem 0;
      }

      @include media(m) {
        padding: 11rem 0;
      }

    }

    &__ttl {
      @include fontsize(20, 31);
      text-align: center;
      font-weight: bold;

      @include media(tb) {}

      @include media(m) {
        font-size: 4.2rem;
      }

    }

    &__list {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-top: 4.5rem;
      margin-right: 3rem;
      margin-left: 3rem;

      @include media(tb) {
        justify-content: space-between;
        width: 100%;
        margin-top: 6rem;
        margin-right: auto;
        margin-left: auto;
      }

      @include media(m) {
        width: min(calc(100% - ($width-m * 2)), 1226px);
        margin-top: 8rem;
      }
    }

    &__item {
      border-radius: 0px 3.6rem 3.6rem 3.6rem;
      background-color: #fff;
      padding: 2.7rem;
      margin-top: 4.5rem;
      box-shadow: 0px 6px 30px #CFCFCF;
      position: relative;
      display: flex;
      flex-direction: column;

      @include media(tb) {
        margin-top: 0;
        width: 100%;
        padding: 3rem 3rem 3rem 4rem;
        flex-direction: row;
        margin-top: 4rem;
        align-items: center;
      }

      @include media(m) {
        padding: 6rem 6rem 6rem 9rem;
        margin-top: 8rem;
      }

      &:nth-of-type(1) {
        margin-top: 0;

        &::before {
          background-image: url(../images/nurse/nur04_ico01.png);
        }

        h3 {
          span {
            color: #42A3E9;
          }
        }
      }

      &:nth-of-type(2) {
        &::before {
          background-image: url(../images/nurse/nur04_ico02.png);
        }

        h3 {
          span {
            color: #9C1FC1;
          }
        }
      }

      &:nth-of-type(3) {
        &::before {
          background-image: url(../images/nurse/nur04_ico03.png);
        }

        h3 {
          span {
            color: #E93A83;
          }
        }
      }

      &::before {
        position: absolute;
        top: -2.5rem;
        left: -1.5rem;
        width: 5.6rem;
        height: 5.6rem;
        margin: auto;
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
        content: "";

        @include media(m) {
          top: -4.3rem;
          left: -4.3rem;
          width: 14.3rem;
          height: 14.3rem;
        }
      }
    }

    &__txtbox {
      @include media(tb) {
        padding-right: 2.5rem;
      }

      @include media(m) {
        padding-right: 5rem;
      }
    }

    &__subttl {
      font-weight: 600;
      @include fontsize(18, 24);
      line-height: 1.4;

      @include media(tb) {}

      @include media(m) {
        font-size: 3rem;
      }

      span {
        display: block;
        @include fontsize(14, 16);
        margin-bottom: 0.5rem;

        @include media(tb) {}

        @include media(m) {
          font-size: 2.5rem;
          margin-bottom: 1rem;
        }
      }
    }

    &__txt {
      line-height: 1.5;
      margin-top: 1rem;

      @include media(tb) {
        min-height: 7.5rem;
      }

      @include media(m) {
        font-size: 1.8rem;
      }
    }

    &__img {
      width: 100%;
      margin-top: 1.5rem;
      border-radius: 2.4rem;

      @include media(tb) {
        width: 40%;
        height: auto;
        object-fit: cover;
        margin-top: 0;
      }

      @include media(m) {
        width: 49.8rem;
      }
    }

  }

  &05 {
    margin-right: 2rem;
    margin-left: 2rem;

    @include media(m) {
      width: min(calc(100% - ($width-m * 2)), 1280px);
      margin-right: auto;
      margin-left: auto;
    }

    &__outer {
      padding: 6rem 0;

      @include media(tb) {
        padding: 8rem 0;

      }

      @include media(m) {
        padding: 11rem 0;
      }
    }

    &__ttl {
      @include fontsize(20, 31);
      font-weight: bold;
      text-align: center;

      @include media(tb) {}

      @include media(m) {
        font-size: 4.2rem;
      }
    }

    &__ttl2 {
      @include fontsize(16, 18);
      font-weight: bold;
      margin-top: 1rem;
      line-height: 1.5;

      @include media(tb) {}

      @include media(m) {
        font-size: 2.3rem;
      }
    }

    &__list {
      margin-top: 5rem;

      @include media(tb) {
        margin-top: 6rem;
      }

      @include media(m) {
        margin-top: 8rem;
        justify-content: space-between;
        display: flex;
        flex-wrap: wrap;
      }
    }

    &__item {
      margin-top: 5rem;

      @include media(tb) {
        display: flex;
        align-items: center;
      }

      @include media(m) {
        margin-top: 6rem;
      }

      &:first-of-type {
        margin-top: 0;
      }

      &:nth-of-type(2),
      &:nth-of-type(3),
      &:nth-of-type(4) {
        @include media(m) {
          flex-direction: column;
          width: calc(100% / 3 - 4rem);
        }

        .nurse05__txtbox {
          @include media(m) {
            padding-left: 0;
          }
        }

        .nurse05__img {
          @include media(m) {
            width: 100%;
          }
        }

        .nurse05__cat {
          @include media(m) {
            margin-top: 2rem;
          }
        }
      }
    }

    &__img {
      width: 100%;
      border-radius: 5.4rem;

      @include media(tb) {
        width: 50%;
        height: auto;
        object-fit: cover;
        border-radius: 4.2rem;
      }

      @include media(m) {
        border-radius: 3rem;
        width: 43%;
      }
    }

    &__txtbox {
      @include media(tb) {
        padding-left: 3rem;
      }

      @include media(m) {
        padding-left: 5rem;
      }
    }

    &__cat {
      @include fontsize(14, 16);
      margin-top: 2rem;
      font-weight: 600;
      position: relative;
      display: inline-block;

      @include media(tb) {
        margin-top: 0;
      }

      @include media(m) {}

      &::after {
        content: "";
        height: .1rem;
        width: 100%;
        display: block;
        position: absolute;
        left: 0;
        bottom: -.5rem;
        background-color: #000;
      }

      &-blue {
        color: #42A3E9;

        &::after {
          background-color: #42A3E9;
        }
      }

      &-purple {
        color: #9C1FC1;

        &::after {
          background-color: #9C1FC1;
        }
      }

      &-pink {
        color: #E93A83;

        &::after {
          background-color: #E93A83;
        }
      }
    }

    &__txt {
      margin-top: 0.5rem;
      line-height: 1.5;

      @include media(m) {
        margin-top: 1rem;
      }

      span {
        @include fontsize(10, 11);
        display: block;

        @include media(m) {
          margin-top: 1rem;
        }
      }
    }

    &__link {
      @include opacity;
      @include transition;
      position: relative;
      margin-top: 2.5rem;
      margin-left: auto;
      font-weight: 600;
      display: block;
      text-align: right;
      padding-right: 4rem;

      @include media(tb) {
        margin-top: 1rem;
        font-size: 1.4rem;
      }

      @include media(m) {
        margin-top: 2rem;
        text-align: left;
        display: inline-block;
      }

      &::after {
        display: block;
        width: 2.9rem;
        height: 1.1rem;
        background-image: url(../images/nurse/nur05_ico01.png);
        background-size: cover;
        background-repeat: no-repeat;
        content: "";
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        position: absolute;
      }
    }
  }

  &06 {
    @include media(tb) {
      width: min(calc(100% - ($width-m * 2)), 1226px);
      margin-right: auto;
      margin-left: auto;
    }

    &__outer {
      padding-bottom: 6rem;

      @include media(tb) {
        padding-bottom: 8rem;
      }

      @include media(m) {
        padding-bottom: 10rem;
      }
    }

    &__ttl {
      @include fontsize(20, 31);
      text-align: center;
      font-weight: bold;

      @include media(tb) {}

      @include media(m) {
        font-size: 4.2rem;

      }
    }

    &__list {
      margin-top: 3rem;
      padding: 0 3.5rem;

      @include media(tb) {}

      @include media(m) {
        display: flex;
        justify-content: space-between;
        margin-top: 8rem;
        flex-direction: column;
      }
    }

    &__item {
      width: 100%;
      margin-top: 2rem;
      padding: 2.5rem;
      background-color: #fff;
      box-shadow: 8px 10px 33px rgba(0, 0, 0, 0.08);
      border-radius: 3.6rem;

      @include media(tb) {
        display: flex;
        justify-content: space-between;
        padding: 5rem;
        margin-top: 4rem;
      }

      @include media(m) {
        align-items: center;
        padding: 4rem 6rem;
      }

      &:first-of-type {
        margin-top: 0;
      }
    }

    &__ttl2 {
      @include fontsize(18, 21);
      font-weight: bold;
      line-height: 1.5;

      @include media(m) {
        font-size: 3rem;
      }
    }

    &__box {
      margin-top: 2.5rem;
      display: flex;

      @include media(tb) {
        margin-top: 0;
        flex-direction: column;
      }

      @include media(m) {
        width: 25.5rem;
      }
    }

    &__personal {
      margin-left: 1.5rem;

      @include media(tb) {
        margin-left: 0;

      }
    }

    &__txtbox {
      @include media(tb) {
        margin-left: 3rem;
      }

      @include media(m) {
        width: calc(100% - 25.5rem);
      }
    }

    &__img {
      width: 9.4rem;
      height: 9.4rem;
      display: block;

      @include media(tb) {
        width: 19rem;
        height: 19rem;
      }

      @include media(m) {
        width: 21rem;
        height: 21rem;
      }
    }

    &__position {
      @include fontsize(12, 16);
      font-weight: bold;
      line-height: 1.5;

      @include media(tb) {
        font-size: 1.4rem;
        margin-top: 1rem;
      }
    }

    &__name {
      @include fontsize(16, 18);
      margin-top: 0.5rem;
      font-weight: bold;
      line-height: 1.2;

      @include media(tb) {}

      @include media(m) {
        font-size: 2rem;
      }

      span {
        font-size: 1.2rem;
        font-weight: 300;
        margin-top: 0.5rem;
        display: block;
      }
    }

    &__txt {
      line-height: 1.6;
      margin-top: 2rem;

      @include media(tb) {}

      @include media(m) {
        margin-top: 1rem;
      }
    }

  }

  &07 {
    &__outer {
      padding: 6rem 0 1rem;
      background-color: #F0F0F0;

      @include media(tb) {
        padding: 8rem 0 2rem;
      }

      @include media(m) {
        padding: 11rem 0 2rem;
      }
    }

    &__ttl {
      @include fontsize(20, 31);
      font-weight: bold;
      text-align: center;
      line-height: 1.4;

      @include media(tb) {}

      @include media(m) {
        font-size: 4.2rem;
      }
    }

    &__txt {
      font-weight: bold;
      text-align: center;
      line-height: 1.4;
      margin-top: 2rem;

      @include media(tb) {}

      @include media(m) {
        margin-top: 4rem;
      }
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      margin-top: 4rem;

      @include media(tb) {
        margin-top: 6rem;
        flex-direction: row;
        flex-wrap: inherit;
        justify-content: space-between;
      }

      @include media(m) {
        margin-top: 8rem;
        margin-right: auto;
        margin-left: auto;
        width: min(calc(100% - ($width-m * 2)), 1100px);
      }
    }

    &__item {
      margin-top: 3rem;
      display: flex;

      @include media(tb) {
        flex-direction: column;
        width: calc(100% / 3 - 3rem);
        margin-top: 0;
      }

      @include media(m) {}

      &:first-of-type {
        margin-top: 0;
      }

      &:last-of-type {
        .nurse07__img {
          &::after {
            @include media(tb) {
              display: none;
            }
          }
        }
      }

    }

    &__txtbox {
      margin-left: 1.5rem;

      @include media(tb) {
        margin-left: 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-top: 2rem;
      }

      @include media(m) {
        margin-top: 3rem;

      }

      >h3 {
        margin-top: 0.8rem;
        font-weight: 600;
        font-size: 1.6rem;

        @include media(tb) {
          margin-top: 0;
          margin-left: 1.5rem;
        }

        @include media(m) {
          font-size: 2.3rem;
        }
      }

      >p {
        margin-top: 1rem;
        line-height: 1.5;

        @include media(tb) {}

        @include media(m) {
          margin-top: 2rem;
        }
      }
    }

    &__num {
      font-weight: 600;
      @include fontBarlow;

      @include media(tb) {
        font-size: 1.6rem;
      }

      @include media(m) {
        font-size: 2.3rem;
      }

      &-blue {
        color: #42a3e9;
      }

      &-purple {
        color: #9c1fc1;
      }

      &-pink {
        color: #e93a83;
      }
    }

    &__img {
      @include media(tb) {
        position: relative;
      }

      @include media(m) {}

      img {
        width: 9.4rem;
        height: 9.4rem;

        @include media(tb) {
          width: 18rem;
          height: 18rem;
          margin-right: auto;
          margin-left: auto;
          display: block;
        }

        @include media(m) {
          width: 27.7rem;
          height: 27.7rem;
        }
      }

      &::after {
        @include media(tb) {
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          width: 0;
          height: 0;
          margin: auto;
          border-top: solid 16px transparent;
          border-right: solid 24px transparent;
          border-bottom: solid 16px transparent;
          border-left: solid 24px #D9D9D9;
          content: "";
          transform: translateX(calc(100% + 1.5rem));
        }
      }
    }

    &__att {
      margin-top: 5rem;
      text-align: right;

      @include media(tb) {}

      @include media(m) {
        margin-top: 8rem;

      }

      span {
        font-size: 1rem;
        display: inline-block;
        text-align: left;
        line-height: 1.5;
        @include fontsize(10, 11);

        @include media(tb) {
          text-align: right;
        }

      }
    }

  }

  &08 {
    @include media(tb) {}

    @include media(m) {
      width: min(calc(100% - ($width-m * 2)), 1265px);
      margin-right: auto;
      margin-left: auto;
      padding: 0 3.5rem;
    }

    &__outer {
      padding: 6rem 0 0;

      @include media(tb) {}

      @include media(m) {
        padding: 11rem 0 0;
      }

      &.exp08__outer {
        padding-top: 0;
      }
    }

    &__ttl {
      @include fontsize(20, 31);
      font-weight: bold;
      text-align: center;
      line-height: 1.3;

      @include media(m) {
        font-size: 4.2rem;

      }
    }

  }
}



// aside 各問い合わせ画面へのボタン一覧
.aside {
  background: linear-gradient(126.57deg, #EC4684 14.74%, #F5718A 53.56%, #F8838D 92.39%);


  &__make {
    background: linear-gradient(126.57deg, #5F23AC 14.74%, #A262D5 53.56%, #B999E3 92.39%);
  }

  &__wrap {
    @include transition;
    padding-left: 9%;
    padding-right: 9%;
    // margin-right: $width-m;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 90%;
    background-image: url("../images/common/aside_bg-sp.png");
    overflow: hidden;

    @include media(tb) {
      padding-right: 3%;
      padding-left: 3%;
      width: min(calc(100% - ($width-m * 2)), 1446px);
      background-size: 54.7rem;
      background-image: url("../images/common/aside_bg-pc.png");

    }

    @include media(m) {
      /* middle-size */
      margin-left: 0;
      margin-right: 0;
    }

    @include media(l) {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__inner {
    @include transition;
    font-weight: 600;
    padding: 9rem 0 8.4rem;
    margin: auto;

    @include media(tb) {
      /* tablet-size */
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }

    @include media(m) {

      padding: 12rem 0 9rem;
      width: min(calc(100% - ($width-m * 2)), 1110px);

    }
  }

  &__box-heading {
    @include transition;

    @include media(tb) {
      width: 42.34%;
    }
  }

  &__box-foot {
    @include transition;

    margin-top: 6rem;
    color: #fff;
    @include fontsize(32, 45);

    @include media(tb) {
      width: 37.84%;
      margin-top: 0;
    }
  }

  &__h1 {
    @include transition;
    font-size: 3.2rem;
    color: #fff;
    line-height: 1.5;
    text-align: left;

    @include media(tb) {
      /* tablet-size */
      font-size: 3rem;


    }

    @include media(m) {
      line-height: 1.3;
      font-size: 4.5rem;

    }
  }

  &__txtbox {
    margin-top: 5rem;

    &__txt {
      &:nth-of-type(1) {
        font-weight: 700;
        font-size: 1.8rem;
        line-height: 1.5;

      }

      &:nth-of-type(2) {
        margin-top: 2rem;
        font-weight: 700;
        font-size: 1.4rem;
        line-height: 1.8;
      }
    }
  }



  &__txt {
    @include transition;
    @include fontsize(14, 15);
    color: #fff;
    line-height: 1.8;
    font-weight: 600;

    @include media(m) {
      /* tablet-size */
      line-height: 2;

    }
  }

  &__note {
    @include fontsize(10, 11);
    color: #fff;
    line-height: 1.45;
    text-align: center;
    margin-top: 1rem;

    @include media(tb) {
      text-align: left;
    }
  }

  &__list {
    color: #fff;

    @include media(tb) {
      width: min(60%, 48rem);
    }
  }

  &__list2 {
    margin-top: 4rem;

    li {
      @include transition;
      border-bottom: 1px solid #fff;
      padding-top: 2rem;
      padding-bottom: 2rem;
      padding-left: 2rem;
      color: #fff;
      width: 200%;
      font-size: 2rem;

      @include media(tb) {
        /* tablet-size */
        width: 100%;
        font-size: 1.8rem;

      }

      @include media(m) {
        /* tablet-size */
        width: 100%;
        font-size: 2.4rem;

      }

      &:nth-of-type(1) {
        border-top: 1px solid #fff;
      }

    }
  }

  &__item {
    & * {
      display: block;
    }

    small {
      @include fontsize(12, 14);
    }

    em {
      @include fontsize(18, 24);
      margin-top: 1rem;

      @include media(tb) {
        margin-top: 1.2rem;
      }
    }

    a {
      @include brightness;
      @include btn-arrow-icon;

      color: inherit;
      padding: 2.5rem 4rem;
    }

    &+& {
      margin-top: 2rem;
    }
  }

  &__btn {
    @include transition;

    font-size: 1.6rem;
    margin-top: 4rem;
    padding: 2rem 0 2rem 3.6rem;
    border-radius: 10rem;
    background-color: #fff;
    display: block;
    font-weight: 600;
    border-style: solid;
    border-width: .2rem;
    border-color: transparent;
    position: relative;

    @include media(m) {
      margin-top: 3rem;
      padding: 3rem 0 3rem 3.3rem;
      font-size: 1.8rem;

    }

    &::before,
    &::after {
      @include transition;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;

    }

    &::before {
      display: block;
      right: 6.5rem;
      width: 0.1rem;
      height: calc(100% - 2.2rem);
      content: "";

      @include media(m) {
        /* tablet-size */
        height: calc(100% - 3rem);
      }
    }

    &::after {
      @include fontAwesome__sol;
      content: "\f054";
      right: 2.6rem;
      display: flex;
      align-items: center;
    }

    &:hover {
      color: #fff;
      border-color: #fff;

      &::before {
        background-color: #fff;
      }

      &::after {
        color: #fff;
      }
    }


    &-pink {
      @extend .aside__btn;
      color: $color_main09;

      &::before {
        background-color: $color_main09;
      }

      &::after {
        color: $color_main09;
      }

      &:hover {
        background-color: $color_main09;
      }
    }


    &-purple {
      @extend .aside__btn;
      color: $color_main10;


      &::before {
        background-color: $color_main10;
      }

      &::after {
        color: $color_main10;
      }

      &:hover {
        background-color: $color_main10;
      }
    }

  }

}

// aside オペクラウドVR
.aside-vr {
  @include opacity;
  display: block;
  margin: 0 ($width-m * -1);

  @include media(l) {
    margin: 0;
  }

  &__bg {
    position: relative;
    height: 40rem;
    background-image: url("../images/medical/aside-vr-sp.jpg");
    background-size: cover;
    background-repeat: no-repeat;

    @include media(tb) {
      height: 44rem;
      background-image: url("../images/medical/aside-vr-pc.jpg");
      background-position: left
    }
  }

  &__box {
    @include media(tb) {
      @include center(y);
      right: $width-m;
      margin-top: 1rem;
    }

    @include media(m) {
      right: 12.5%;
    }
  }

  &__cap {
    @include fontsize(18, 26);
    font-weight: bold;
    color: #fff;
    line-height: 1.44;
    text-align: center;
    white-space: nowrap;
    padding: 3.5rem 0 1rem;

    @include media(tb) {
      padding: 0 0 1.5rem;
      line-height: 1.38;
    }
  }

  &__logo {
    display: block;
    margin: 0 auto;
    width: 20.6rem;
    margin-bottom: 3.5rem;

    @include media(tb) {
      width: 24rem;
    }
  }

  &__fig {
    display: none;

    @include media(tb) {
      display: flex;
    }

    img {
      display: block;
      width: 26rem;
      border-radius: 4rem;

      &+img {
        margin-left: 2rem;
      }
    }
  }
}

// footer
.footer {
  padding: 4rem 4rem 2rem;

  @include media(tb) {
    padding: 8rem $width-m;
  }

  &__inner {
    width: min(100%, 1120px);

    @include media(tb) {
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
    }
  }

  &__logo {
    @include opacity;
    display: block;
    width: 16rem;
    margin-bottom: 4rem;

    @include media(tb) {
      margin-bottom: 0;
      margin-right: 2rem;
    }

    @include media(m) {
      width: 26.3rem;
    }

    img {
      width: 100%;
    }
  }

  &__logo2 {
    display: block;
    width: 16rem;
    margin-bottom: 4rem;

    @include media(tb) {
      width: 26.3rem;
      margin-bottom: 0;
    }

    img {
      width: 100%;
    }
  }

  &__logo3 {
    @include opacity;
    display: block;
    width: 16rem;
    margin-bottom: 4rem;

    @include media(tb) {
      width: 24.7rem;
      margin-right: 2rem;
      margin-bottom: 0;
    }

    @include media(m) {
      width: 26.3rem;
    }


    img {
      width: 100%;
    }
  }

  &__nav-list {
    font-size: 0;
    margin-bottom: 4rem;

    @include media(tb) {
      display: flex;
      flex-wrap: wrap;
    }

    >li {
      font-size: 1.2rem;
      font-weight: bold;
      margin-bottom: 1.5rem;

      @include media(tb) {
        display: inline-block;
        margin-bottom: 2rem;
        margin-right: 2rem;
      }

      @include media(m) {
        margin-bottom: 0;
        margin-right: 3rem;
      }

      &:last-of-type {
        @include media(m) {
          margin-right: 0;
        }
      }

      a {
        @include opacity;
        color: inherit;
      }
    }
  }

  &__nav-sublist {
    @include media(tb) {
      display: flex;
      flex-direction: column;
    }

    >li {
      font-size: 1.2rem;
      font-weight: bold;
      margin-top: 1.5rem;
      margin-left: 1em;

      @include media(tb) {
        display: inline-block;
        margin-top: 1rem;
        margin-left: 0;

      }

      a {
        @include opacity;
        color: inherit;
        color: #7e7e7e;
      }
    }
  }

  &__c {
    font-size: 1rem;

    @include media(tb) {
      text-align: right !important;
      margin-top: 1.7rem;
    }
  }
}

.back2top {
  display: block;
  position: fixed;
  bottom: 5%;
  right: 5%;
  z-index: 2;
  width: 4.8rem;
  height: 4.8rem;

  img {
    @include transition;
    @include opacity;
    width: 100%;
    border-radius: 50%;
    box-shadow: 2px 2px 6px rgba($color: #D9D5D0, $alpha: 0.4);
  }

  @include media(l) {
    right: auto;
    left: calc(50% + 56rem);
  }
}

// メディアクエリ別の表示切り替え
.sp {
  display: block;

  @include media(tb) {
    display: none;
  }
}

.pc {
  display: none;

  @include media(tb) {
    display: block;
  }
}

/* コンテンツフェードイン。top.cssより引用 */
.fade {
  transform: translateY(50px);
  transition: all .8s cubic-bezier(.23, 1, .32, 1) .2s;
  opacity: 0;

  &.blockIn {
    transform: translateY(0);
    opacity: 1;
  }
}