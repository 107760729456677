@use "media"as *;
@use "sass:math";

/* transition */
// @include transition;
@mixin transition {
  transition: all 0.3s linear;
}

/* hover */
// @include opacity;
@mixin opacity {
  &:hover {
    opacity: 0.7;
  }
}

// @include brightness;
@mixin brightness {
  &:hover {
    filter: brightness(120%);
  }
}

/* box-shadow */
// @include box-shadow-large;
@mixin box-shadow-large {
  @include border-radius-15;
  background-color: #fff;
  box-shadow: 2px 7px 20px 2px rgba(0, 0, 0, 0.08);

}

// @include box-shadow;
@mixin box-shadow {
  @include border-radius-10;
  background-color: #fff;
  box-shadow: 4px 4px 16px rgba($color: #919090, $alpha: 0.16);
}

// @include box-shadow-small;
@mixin box-shadow-small {
  @include border-radius-5;
  background-color: #fff;
  box-shadow: 4px 4px 16px rgba($color: #919090, $alpha: 0.16);
}

/* text-aligh */
// @include justify();
@mixin justify() {
  text-align: justify;
  text-justify: inter-ideograph;
}

/* button arrow-icon */
// @include btn-arrow-icon;
@mixin btn-arrow-icon {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 2rem;
    margin-top: -0.6rem;
    width: 3rem;
    height: 1.2rem;
    background-image: url("../images/common/arrow-link.png");
    @include background-image();
  }
}

/* line-height */
// @include lineheight-normal; // 通常テキストの行の高さ
@mixin lineheight-normal {
  line-height: 1.71;

  @include media(tb) {
    line-height: 1.87;
  }
}

/* border-radius */
// @include border-radius-5;
@mixin border-radius-5 {
  border-radius: 4rem;

  @include media(tb) {
    border-radius: 5rem;
  }
}

// @include border-radius-10;
@mixin border-radius-10 {
  border-radius: 4rem;

  @include media(tb) {
    border-radius: 10rem;
  }
}

// @include border-radius-10;
@mixin border-radius-15 {
  border-radius: 15px;

}


/* フォントサイズ */
// @include fontsize(14, 16);
@mixin fontsize($size-s: 14, $size-m: 16) {
  font-size: math.div($size-s, 10) * 1rem;

  @include media(tb) {
    font-size: math.div($size-m, 10) * 1rem;
  }
}

/* absolute一括指定 */
// @include absolute(0,0,0,0);
// @include absolute($top:0, $bottom:0);
@mixin absolute($top: null, $right: null, $bottom: null, $left: null) {
  position: absolute;

  @if $top !=null {
    top: $top;
  }

  @if $right !=null {
    right: $right;
  }

  @if $bottom !=null {
    bottom: $bottom;
  }

  @if $left !=null {
    left: $left;
  }
}

// @include background-image
// @contentを使うことで、呼び出し時に他のbackgroundプロパティを追加することも可能
@mixin background-image() {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  @content;
}

// 親要素に対して中央寄せ
// x：横方向中央寄せ,y：縦方向中央寄せ,xy：縦横方向中央寄せ
// @include center(x);
@mixin center($direction) {
  position: absolute;

  @if $direction==xy {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @else if $direction==x {
    left: 50%;
    transform: translateX(-50%);
  }

  @else if $direction==y {
    top: 50%;
    transform: translateY(-50%);
  }
}

// @include textgradient;
@mixin textgradient {
  background-clip: text;
  -webkit-background-clip: text;
  color: rgba(0, 0, 0, 0);
  display: inline-block;
  line-height: 1.4;
}



// @include clearfix;
@mixin clearfix {
  &::after {
    content: "";
    clear: both;
    display: block;
  }
}

// その他
@mixin replace($original, $replacement) {

  // @include replace($, $) {}
  @at-root {
    #{selector-replace(&, $original, $replacement)} {
      @content;
    }
  }
}