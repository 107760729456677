// @include fontOswald; // URW DINの代替
@mixin fontOswald {
  font-family: 'Oswald',
    sans-serif;
}

// @include fontBarlow;
@mixin fontBarlow {
  font-family: 'Barlow',
    sans-serif;
}


// @include Roboto;
@mixin fontRoboto {
  font-family: 'Roboto',
    sans-serif;
}

// @include fontIBMPlexSansCondensed;
@mixin fontIBMPlexSansCondensed {
  font-family: 'IBM Plex Sans Condensed',
    sans-serif;
}



// @include fontAwesome;
// Font Awesome 6 Free（Solid、Regular）
// Font Awesome 6 Brands（ブランドロゴアイコン）
@mixin fontAwesome__sol {
  font-family: "Font Awesome 6 Free";
  display: inline-block;
  font-weight: 900;
}

@mixin fontAwesome__reg {
  font-family: "Font Awesome 6 Free";
  display: inline-block;
  font-weight: 400;
}

@mixin fontAwesome__bra {
  font-family: "Font Awesome 6 Brands";
  display: inline-block;
  font-weight: 400;
}